import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { FormattedMessage, useIntl } from "react-intl";
import { editSection, getSection, getSectionSlug } from "store/actions";
import Loader from "components/shared/Loader";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";
import { getId } from "helpers/functions";
import { Input } from "components/shared/FormComponents";

const Heading = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const { loading, error, singleSection } = useSelector(
    (state) => state.portalSections
  );
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // get single page by slug
  useEffect(() => {
    dispatch(getSectionSlug("blogstitle"));
  }, [dispatch]);

  useEffect(() => {
    reset(singleSection);
  }, [reset, singleSection]);

  // error
  useEffect(() => {
    if (error) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }
  }, [error, formatMessage, locale]);

  const onSubmit = (data) => {
    dispatch(editSection({ id: getId(singleSection), data }));
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id={"editTitleText"} />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                {/* heading  */}
                <Row>
                  <Col lg={6} xs={12}>
                    <Input
                      langId="subTitle"
                      name="subTitle.ar"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input
                      langId="subTitle"
                      name="subTitle.en"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} xs={12}>
                    <Input
                      langId="title"
                      name="title.ar"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input
                      langId="title"
                      name="title.en"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </Row>
                <button className="btn btn-blue">Save</button>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Heading;
