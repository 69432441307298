import ControlArea from "components/Layout/ControlArea";
import React from "react";
import { useSelector } from "react-redux";
import productTypesIcon from "assets/svgs/types.svg";
import Table from "./Table";
const Index = () => {
  const { productTypes } = useSelector((state) => state.productTypes);

  return (
    <>
      <div className="stores-wrap">
        <ControlArea
          btnTxt="اضافة نوع جديد"
          cardTxt="جميع الانواع"
          icon={productTypesIcon}
          url="/producttypes/addproducttype"
        />
        <Table data={productTypes} />
      </div>
    </>
  );
};

export default Index;
