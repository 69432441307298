export const GET_PORTAL_HELP_BLOGS = "GET_PORTAL_HELP_BLOGS";
export const GET_PORTAL_HELP_BLOGS_SUCCESS = "GET_PORTAL_HELP_BLOGS_SUCCESS";
export const GET_PORTAL_HELP_BLOGS_FAILURE = "GET_PORTAL_HELP_BLOGS_FAILURE";

export const GET_SINGLE_PORTAL_HELP_BLOG = "GET_SINGLE_PORTAL_HELP_BLOG";
export const GET_SINGLE_PORTAL_HELP_BLOG_SUCCESS =
  "GET_SINGLE_PORTAL_HELP_BLOG_SUCCESS";
export const GET_SINGLE_PORTAL_HELP_BLOG_FAILURE =
  "GET_SINGLE_PORTAL_HELP_BLOG_FAILURE";

export const ADD_PORTAL_HELP_BLOG = "ADD_PORTAL_HELP_BLOG";
export const ADD_PORTAL_HELP_BLOG_SUCCESS = "ADD_PORTAL_HELP_BLOG_SUCCESS";
export const ADD_PORTAL_HELP_BLOG_FAILURE = "ADD_PORTAL_HELP_BLOG_FAILURE";

export const EDIT_PORTAL_HELP_BLOG = "EDIT_PORTAL_HELP_BLOG";
export const EDIT_PORTAL_HELP_BLOG_SUCCESS = "EDIT_PORTAL_HELP_BLOG_SUCCESS";
export const EDIT_PORTAL_HELP_BLOG_FAILURE = "EDIT_PORTAL_HELP_BLOG_FAILURE";

export const TOGGLE_PORTAL_HELP_BLOG = "TOGGLE_PORTAL_HELP_BLOG";
export const TOGGLE_PORTAL_HELP_BLOG_SUCCESS =
  "TOGGLE_PORTAL_HELP_BLOG_SUCCESS";
export const TOGGLE_PORTAL_HELP_BLOG_FAILURE =
  "TOGGLE_PORTAL_HELP_BLOG_FAILURE";

export const DELETE_PORTAL_HELP_BLOG = "DELETE_PORTAL_HELP_BLOG";
export const DELETE_PORTAL_HELP_BLOG_SUCCESS =
  "DELETE_PORTAL_HELP_BLOG_SUCCESS";
export const DELETE_PORTAL_HELP_BLOG_FAILURE =
  "DELETE_PORTAL_HELP_BLOG_FAILURE";
