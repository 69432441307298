import {
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAILURE,
  ADD_COUNTRY,
  ADD_COUNTRY_SUCCESS,
  ADD_COUNTRY_FAILURE,
  EDIT_COUNTRY,
  EDIT_COUNTRY_SUCCESS,
  EDIT_COUNTRY_FAILURE,
  DELETE_COUNTRY,
  DELETE_COUNTRY_SUCCESS,
  DELETE_COUNTRY_FAILURE,
  GET_SINGLE_COUNTRY,
  GET_SINGLE_COUNTRY_SUCCESS,
  GET_SINGLE_COUNTRY_FAILURE,
} from "./actionTypes";

export const getCountries = () => {
  return {
    type: GET_COUNTRIES,
  };
};

export const getCountriesSuccess = (country) => {
  return {
    type: GET_COUNTRIES_SUCCESS,
    payload: country,
  };
};

export const getCountriesFailure = (error) => {
  return {
    type: GET_COUNTRIES_FAILURE,
    payload: error,
  };
};

export const getSingleCountry = (id) => {
  return {
    type: GET_SINGLE_COUNTRY,
    payload: id,
  };
};

export const getSingleCountrySuccess = (country) => {
  return {
    type: GET_SINGLE_COUNTRY_SUCCESS,
    payload: country,
  };
};

export const getSingleCountryFailure = (error) => {
  return {
    type: GET_SINGLE_COUNTRY_FAILURE,
    payload: error,
  };
};

export const addCountry = (payload) => {
  return {
    type: ADD_COUNTRY,
    payload,
  };
};

export const addCountrySuccess = (payload) => {
  return {
    type: ADD_COUNTRY_SUCCESS,
    payload,
  };
};

export const addCountryFailure = (error) => {
  return {
    type: ADD_COUNTRY_FAILURE,
    payload: error,
  };
};

export const editCountry = (payload) => {
  return {
    type: EDIT_COUNTRY,
    payload,
  };
};

export const editCountrySuccess = (payload) => {
  return {
    type: EDIT_COUNTRY_SUCCESS,
    payload,
  };
};

export const editCountryFailure = (error) => {
  return {
    type: EDIT_COUNTRY_FAILURE,
    payload: error,
  };
};

export const deleteCountry = (id) => {
  return {
    type: DELETE_COUNTRY,
    payload: id,
  };
};

export const deleteCountrySuccess = (id) => {
  return {
    type: DELETE_COUNTRY_SUCCESS,
    payload: id,
  };
};

export const deleteCountryFailure = (error) => {
  return {
    type: DELETE_COUNTRY_FAILURE,
    payload: error,
  };
};
