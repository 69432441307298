import { getId } from "helpers/functions";
import {
  GET_ALL_SETTINGS,
  GET_ALL_SETTINGS_SUCCESS,
  GET_ALL_SETTINGS_FAILURE,
  EDIT_MAIN_SETTINGS,
  EDIT_MAIN_SETTINGS_SUCCESS,
  EDIT_MAIN_SETTINGS_FAILURE,
  EDIT_MESSAGE_SETTINGS,
  EDIT_MESSAGE_SETTINGS_SUCCESS,
  EDIT_MESSAGE_SETTINGS_FAILURE,
  EDIT_SOCIALS_SETTINGS,
  EDIT_SOCIALS_SETTINGS_SUCCESS,
  EDIT_SOCIALS_SETTINGS_FAILURE,
  EDIT_MAIL_SETTINGS,
  EDIT_MAIL_SETTINGS_SUCCESS,
  EDIT_MAIL_SETTINGS_FAILURE,
  EDIT_ACCOUNTS_SETTINGS,
  EDIT_ACCOUNTS_SETTINGS_SUCCESS,
  EDIT_ACCOUNTS_SETTINGS_FAILURE,
  ADD_ACCOUNTS_SETTINGS,
  ADD_ACCOUNTS_SETTINGS_SUCCESS,
  ADD_ACCOUNTS_SETTINGS_FAILURE,
  DELETE_SETTINGS,
  DELETE_SETTINGS_SUCCESS,
  DELETE_SETTINGS_FAILURE,
} from "./actionTypes";

const initialState = {
  settings: [],
  loading: false,
  error: "",
  mainSettings: [],
  socialSettings: [],
  mailSettings: [],
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    // get all settings
    case GET_ALL_SETTINGS:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;

    case GET_ALL_SETTINGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        settings: action.payload.settings,
        mainSettings: action.payload.mainSettings,
        socialSettings: action.payload.socialSettings,
        seoSettings: action.payload.seoSettings,
        mailSettings: action.payload.mailSettings,
        accountsSettings: action.payload.accountsSettings,
        messagesSettings: action.payload.messagesSettings,
      };
      break;

    case GET_ALL_SETTINGS_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      };
      break;

    // edit MAIN Settings
    case EDIT_MAIN_SETTINGS:
      state = { ...state, loading: true, error: "" };

      break;

    case EDIT_MAIN_SETTINGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        mainSettings: action.payload,
      };
      break;

    case EDIT_MAIN_SETTINGS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    // edit message Settings
    case EDIT_MESSAGE_SETTINGS:
      state = { ...state, loading: true, error: "" };

      break;

    case EDIT_MESSAGE_SETTINGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        messageSettings: action.payload,
      };
      break;

    case EDIT_MESSAGE_SETTINGS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    // add ACCOUNTS Settings-------------------------------
    case ADD_ACCOUNTS_SETTINGS:
      state = { ...state, loading: true, error: "" };

      break;

    case ADD_ACCOUNTS_SETTINGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        accountsSettings: [...state.accountsSettings, action.payload],
      };
      break;

    case ADD_ACCOUNTS_SETTINGS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    // edit ACCOUNTS Settings-------------------------------
    case EDIT_ACCOUNTS_SETTINGS:
      state = { ...state, loading: true, error: "" };

      break;

    case EDIT_ACCOUNTS_SETTINGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        accountsSettings: action.payload,
      };
      break;

    case EDIT_ACCOUNTS_SETTINGS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    // delete Settings-------------------------------
    case DELETE_SETTINGS:
      state = { ...state, loading: true, error: "" };

      break;

    case DELETE_SETTINGS_SUCCESS:
      var data = state?.[action.payload?.name]?.filter(
        (item) => getId(item) !== action.payload?.id
      );

      state = {
        ...state,
        loading: false,
        [action?.payload?.name || ""]: data,
      };
      break;

    case DELETE_SETTINGS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    // edit socials Settings
    case EDIT_SOCIALS_SETTINGS:
      state = { ...state, loading: true, error: "" };
      break;

    case EDIT_SOCIALS_SETTINGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        socialSettings: action.payload,
      };
      break;

    case EDIT_SOCIALS_SETTINGS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    // edit socials Settings
    case EDIT_MAIL_SETTINGS:
      state = { ...state, loading: true, error: "" };
      break;

    case EDIT_MAIL_SETTINGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        mailSettings: action.payload,
      };
      break;

    case EDIT_MAIL_SETTINGS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
