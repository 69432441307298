import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { editPackage, getFeatures, getSinglePackage } from "store/actions";
import { useNavigate, useParams } from "react-router-dom";
import SelectMultiple from "react-select";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "components/shared/Loader";
import { getId } from "helpers/functions";
import ImageUpload from "components/shared/ImageUpload";
const EditPackage = () => {
  const { locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { features, loading: featuresLoading } = useSelector(
    (state) => state.features
  );

  const { singlePackage, loading } = useSelector((state) => state?.packages);

  const { register, handleSubmit, control, reset } = useForm();

  useEffect(() => {
    dispatch(getFeatures());
    dispatch(getSinglePackage(id));
  }, [dispatch, id]);

  useEffect(() => {
    reset({
      name: singlePackage?.name,
      icon: singlePackage?.icon,
      price: singlePackage?.price,
      features: singlePackage?.features?.map((feat) => getId(feat)),
    });
  }, [reset, singlePackage]);

  const onSubmit = (data) => {
    // if (data.icon) data.icon = "uploads/" + data.icon.split("/").pop();
    dispatch(editPackage({ id, data, navigate }));
  };

  const renderListFeatures = () => {
    return features?.map((feature) => ({
      label: feature.name?.[locale],
      value: feature?.name?.[locale],
      id: feature?.id,
    }));
  };

  const renderListSelectedFeatures = () => {
    return singlePackage?.features?.map((feature) => ({
      label: feature.name?.[locale],
      value: feature?.name?.[locale],
      id: feature?.id,
    }));
  };

  if (loading || featuresLoading) return <Loader />;
  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>تعديل بيانات الباقة</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="icon" />
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          name="icon"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <ImageUpload
                              selectedImage={value}
                              setSelectedImage={onChange}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم الباقة بالعربية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم الباقة بالعربية"
                          {...register("name[ar]", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم الباقة بالانجليزية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم الباقة بالانجليزية"
                          {...register("name[en]", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>سعر الباقة</h5>
                      <div>
                        <input
                          type="number"
                          className="form-control form-outline"
                          placeholder="سعر الباقة "
                          {...register("price", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>مميزات الباقة</h5>
                      <div>
                        <Controller
                          control={control}
                          name="features"
                          render={({ field: { onChange } }) => (
                            <SelectMultiple
                              isRtl={true}
                              // menuIsOpen={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"
                              options={renderListFeatures()}
                              placeholder="اختار المميزات"
                              isMulti={true}
                              defaultValue={renderListSelectedFeatures()}
                              onChange={(selected) => {
                                onChange(
                                  selected.map((selected) => selected.id)
                                );
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        حفظ التعديلات
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPackage;
