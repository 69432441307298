import {
  GET_THEMES_PAGES,
  GET_THEMES_PAGES_SUCCESS,
  GET_THEMES_PAGES_FAILURE,
  GET_SINGLE_THEMES_PAGE,
  GET_SINGLE_THEMES_PAGE_SUCCESS,
  GET_SINGLE_THEMES_PAGE_FAILURE,
  ADD_THEMES_PAGE,
  ADD_THEMES_PAGE_SUCCESS,
  ADD_THEMES_PAGE_FAILURE,
  EDIT_THEMES_PAGE,
  EDIT_THEMES_PAGE_SUCCESS,
  EDIT_THEMES_PAGE_FAILURE,
  DELETE_THEMES_PAGE,
  DELETE_THEMES_PAGE_SUCCESS,
  DELETE_THEMES_PAGE_FAILURE,
} from "./actionTypes";

export const getThemesPages = (payload) => {
  return {
    type: GET_THEMES_PAGES,
    payload,
  };
};

export const getThemesPagesSuccess = (themes) => {
  return {
    type: GET_THEMES_PAGES_SUCCESS,
    payload: themes,
  };
};

export const getThemesPagesFailure = (error) => {
  return {
    type: GET_THEMES_PAGES_FAILURE,
    payload: error,
  };
};

export const getSingleThemesPage = (id) => {
  return {
    type: GET_SINGLE_THEMES_PAGE,
    payload: id,
  };
};

export const getSingleThemesPageuccess = (theme) => {
  return {
    type: GET_SINGLE_THEMES_PAGE_SUCCESS,
    payload: theme,
  };
};

export const getSingleThemesPageFailure = (error) => {
  return {
    type: GET_SINGLE_THEMES_PAGE_FAILURE,
    payload: error,
  };
};

export const addThemesPages = (payload) => {
  return {
    type: ADD_THEMES_PAGE,
    payload,
  };
};

export const addThemePageSuccess = (payload) => {
  return {
    type: ADD_THEMES_PAGE_SUCCESS,
    payload,
  };
};

export const addThemePageFailure = (error) => {
  return {
    type: ADD_THEMES_PAGE_FAILURE,
    payload: error,
  };
};

export const editThemesPages = (payload) => {
  return {
    type: EDIT_THEMES_PAGE,
    payload,
  };
};

export const editThemesPageSuccess = (payload) => {
  return {
    type: EDIT_THEMES_PAGE_SUCCESS,
    payload,
  };
};

export const editThemesPageFailure = (error) => {
  return {
    type: EDIT_THEMES_PAGE_FAILURE,
    payload: error,
  };
};

export const deleteThemesPages = (id) => {
  return {
    type: DELETE_THEMES_PAGE,
    payload: id,
  };
};

export const deleteThemesPageSuccess = (id) => {
  return {
    type: DELETE_THEMES_PAGE_SUCCESS,
    payload: id,
  };
};

export const deleteThemesPageFailure = (error) => {
  return {
    type: DELETE_THEMES_PAGE_FAILURE,
    payload: error,
  };
};
