// import axios from "axios";

// const headers = {};
// const token = localStorage.getItem("token");

// if (token) headers.Authorization = token;
// // headers.store = "625576faa69326403fa26cf3";

// export default axios.create({
//   // baseURL: "http://162.55.214.226:9800/api",
//   baseURL: "https://multistore.ejjadh.com/api",
//   // baseURL: "http://5.161.82.199:9800/api",
//   headers,
// });

import axios from "axios";

const server = () => {
  const headers = {};
  const token = localStorage.getItem("token");
  const store = localStorage.getItem("store");

  if (token) headers.Authorization = token;
  if (store) headers.store = store;

  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers,
  });
};

export default server;
