/* eslint-disable no-unused-vars */
import { Col, Modal, Row } from "react-bootstrap";
import { ReactComponent as Upload } from "assets/svgs/upload.svg";
import MultiUpload from "./MultiUpload";

const ImagesModal = ({
  handleClose,
  modalType,
  images,
  setImages,
  pathType = "path",
}) => {
  return (
    <Modal
      dialogClassName="productDataModal"
      show={modalType === "imagesModal"}
      onHide={handleClose}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Upload fill="#395cf5" />
          اضافة صور للمنتج
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <div className="form-body">
            <Row>
              <div className="multiUploadWrapper">
                <Col xs={12}>
                  <MultiUpload
                    images={images}
                    setImages={setImages}
                    pathType={pathType}
                  />
                </Col>
              </div>
            </Row>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-outline" onClick={handleClose} type="button">
          إلغاء
        </button>
        <button type="button" className="btn btn-blue" onClick={handleClose}>
          حفظ
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImagesModal;
