import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addCityApi,
  deleteCityApi,
  editCityApi,
  getCitiesApi,
  getSingleCityApi,
} from "api/cities";

// Login Redux States
import {
  ADD_CITY,
  DELETE_CITY,
  EDIT_CITY,
  GET_CITIES,
  GET_SINGLE_CITY,
} from "./actionTypes";

import {
  addCityFailure,
  addCitySuccess,
  deleteCityFailure,
  deleteCitySuccess,
  editCityFailure,
  editCitySuccess,
  getCitiesFailure,
  getCitiesSuccess,
  getSingleCityFailure,
  getSingleCitySuccess,
} from "./actions";

function* getCities() {
  try {
    const { data } = yield call(getCitiesApi);
    yield put(getCitiesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getCitiesFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* getSingleCity({ payload }) {
  try {
    const { data } = yield call(getSingleCityApi, payload);
    yield put(getSingleCitySuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleCityFailure(error?.response?.data || error));
  }
}

function* addCity({ payload }) {
  try {
    const { data } = yield call(addCityApi, payload.data);
    yield put(addCitySuccess(data));
    yield payload.navigate("/cities");
  } catch (error) {
    console.log(error);
    yield put(addCityFailure(error.response?.data?.message || error));
  }
}

function* editCity({ payload }) {
  try {
    yield call(editCityApi, payload);
    yield put(editCitySuccess(payload));
    yield payload.navigate("/cities");
  } catch (error) {
    console.log(error);
    yield put(editCityFailure(error?.response?.data || error));
  }
}

function* deleteCity({ payload }) {
  try {
    yield call(deleteCityApi, payload);
    yield put(deleteCitySuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteCityFailure(error?.response?.data || error));
  }
}

export function* watchGetCities() {
  yield takeEvery(GET_CITIES, getCities);
}

export function* watchSingleCity() {
  yield takeEvery(GET_SINGLE_CITY, getSingleCity);
}

export function* watchAddCity() {
  yield takeEvery(ADD_CITY, addCity);
}

export function* watchEditCity() {
  yield takeEvery(EDIT_CITY, editCity);
}

export function* watchDeleteCity() {
  yield takeEvery(DELETE_CITY, deleteCity);
}

function* citiesSaga() {
  yield all([fork(watchGetCities)]);
  yield all([fork(watchSingleCity)]);
  yield all([fork(watchAddCity)]);
  yield all([fork(watchEditCity)]);
  yield all([fork(watchDeleteCity)]);
}

export default citiesSaga;
