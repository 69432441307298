import {
  ADD_SECTION,
  ADD_SECTION_FAILURE,
  ADD_SECTION_SUCCESS,
  DELETE_SECTION,
  DELETE_SECTION_FAILURE,
  DELETE_SECTION_SUCCESS,
  EDIT_SECTION,
  EDIT_SECTION_FAILURE,
  EDIT_SECTION_SUCCESS,
  GET_ALL_SECTIONS,
  GET_ALL_SECTIONS_FAILURE,
  GET_ALL_SECTIONS_SUCCESS,
  GET_SECTION,
  GET_SECTION_FAILURE,
  GET_SECTION_SLUG,
  GET_SECTION_SLUG_FAILURE,
  GET_SECTION_SLUG_SUCCESS,
  GET_SECTION_SUCCESS,
} from "./actionTypes";

export const getAllSections = (payload) => {
  return {
    type: GET_ALL_SECTIONS,
    payload: payload,
  };
};

export const getAllSectionsSuccess = (payload) => {
  return {
    type: GET_ALL_SECTIONS_SUCCESS,
    payload: payload,
  };
};

export const getAllSectionsFailure = (error) => {
  return {
    type: GET_ALL_SECTIONS_FAILURE,
    payload: error,
  };
};

export const getSection = (payload) => {
  return {
    type: GET_SECTION,
    payload,
  };
};

export const getSectionSuccess = (payload) => {
  return {
    type: GET_SECTION_SUCCESS,
    payload,
  };
};

export const getSectionFailure = (error) => {
  return {
    type: GET_SECTION_FAILURE,
    payload: error,
  };
};

export const getSectionSlug = (payload) => {
  return {
    type: GET_SECTION_SLUG,
    payload,
  };
};

export const getSectionSlugSuccess = (payload) => {
  return {
    type: GET_SECTION_SLUG_SUCCESS,
    payload,
  };
};

export const getSectionSlugFailure = (error) => {
  return {
    type: GET_SECTION_SLUG_FAILURE,
    payload: error,
  };
};

export const addSection = (payload) => {
  return {
    type: ADD_SECTION,
    payload,
  };
};

export const addSectionSuccess = (payload) => {
  return {
    type: ADD_SECTION_SUCCESS,
    payload,
  };
};

export const addSectionFailure = (error) => {
  return {
    type: ADD_SECTION_FAILURE,
    payload: error,
  };
};

export const editSection = (payload) => {
  return {
    type: EDIT_SECTION,
    payload,
  };
};

export const editSectionSuccess = (payload) => {
  return {
    type: EDIT_SECTION_SUCCESS,
    payload,
  };
};

export const editSectionFailure = (error) => {
  return {
    type: EDIT_SECTION_FAILURE,
    payload: error,
  };
};

export const deleteSection = (payload) => {
  return {
    type: DELETE_SECTION,
    payload: payload,
  };
};
export const deleteSectionSuccess = (payload) => {
  return {
    type: DELETE_SECTION_SUCCESS,
    payload: payload,
  };
};

export const deleteSectionFailure = (error) => {
  return {
    type: DELETE_SECTION_FAILURE,
    payload: error,
  };
};
