import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  CURRENT_USER,
  CURRENT_USER_SUCCESS,
  CURRENT_USER_FAILURE,
} from "./actionTypes";

const initialState = {
  superAdmin: {},
  isLoggedIn: null,
  loading: false,
  error: "",
};

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        superAdmin: action.payload.superAdmin,
        isLoggedIn: true,
      };
      break;
    case LOGOUT_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGOUT_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        superAdmin: {},
        isLoggedIn: false,
      };
      break;
    case CURRENT_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CURRENT_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        superAdmin: action.payload.superAdmin,
        isLoggedIn: true,
      };
      break;

    case CURRENT_USER_FAILURE:
      state = {
        ...state,
        loading: false,
        superAdmin: {},
        isLoggedIn: false,
        error: action.payload,
      };
      break;

    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default authentication;
