import {
  GET_AREAS,
  GET_AREAS_SUCCESS,
  GET_AREAS_FAILURE,
  ADD_AREA,
  ADD_AREA_SUCCESS,
  ADD_AREA_FAILURE,
  EDIT_AREA,
  EDIT_AREA_SUCCESS,
  EDIT_AREA_FAILURE,
  DELETE_AREA,
  DELETE_AREA_SUCCESS,
  DELETE_AREA_FAILURE,
  GET_SINGLE_AREA,
  GET_SINGLE_AREA_SUCCESS,
  GET_SINGLE_AREA_FAILURE,
} from "./actionTypes";

export const getAreas = () => {
  return {
    type: GET_AREAS,
  };
};

export const getAreasSuccess = (area) => {
  return {
    type: GET_AREAS_SUCCESS,
    payload: area,
  };
};

export const getAreasFailure = (error) => {
  return {
    type: GET_AREAS_FAILURE,
    payload: error,
  };
};

export const getSingleArea = (id) => {
  return {
    type: GET_SINGLE_AREA,
    payload: id,
  };
};

export const getSingleAreaSuccess = (area) => {
  return {
    type: GET_SINGLE_AREA_SUCCESS,
    payload: area,
  };
};

export const getSingleAreaFailure = (error) => {
  return {
    type: GET_SINGLE_AREA_FAILURE,
    payload: error,
  };
};

export const addArea = (payload) => {
  return {
    type: ADD_AREA,
    payload,
  };
};

export const addAreaSuccess = (payload) => {
  return {
    type: ADD_AREA_SUCCESS,
    payload,
  };
};

export const addAreaFailure = (error) => {
  return {
    type: ADD_AREA_FAILURE,
    payload: error,
  };
};

export const editArea = (payload) => {
  return {
    type: EDIT_AREA,
    payload,
  };
};

export const editAreaSuccess = (payload) => {
  return {
    type: EDIT_AREA_SUCCESS,
    payload,
  };
};

export const editAreaFailure = (error) => {
  return {
    type: EDIT_AREA_FAILURE,
    payload: error,
  };
};

export const deleteArea = (id) => {
  return {
    type: DELETE_AREA,
    payload: id,
  };
};

export const deleteAreaSuccess = (id) => {
  return {
    type: DELETE_AREA_SUCCESS,
    payload: id,
  };
};

export const deleteAreaFailure = (error) => {
  return {
    type: DELETE_AREA_FAILURE,
    payload: error,
  };
};
