import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addLanguageApi,
  deleteLanguageApi,
  editLanguageApi,
  getLanguagesApi,
  getSingleLanguageApi,
} from "api/languages";

// Login Redux States
import {
  ADD_LANGUAGE,
  DELETE_LANGUAGE,
  EDIT_LANGUAGE,
  GET_LANGUAGES,
  GET_SINGLE_LANGUAGE,
} from "./actionTypes";
import {
  addLanguageFailure,
  addLanguagesuccess,
  deleteLanguageFailure,
  deleteLanguagesuccess,
  editLanguageFailure,
  editLanguagesuccess,
  getLanguagesFailure,
  getLanguagesSuccess,
  getSingleLanguageFailure,
  getSingleLanguagesuccess,
} from "./actions";

function* getLanguages() {
  try {
    const { data } = yield call(getLanguagesApi);
    yield put(getLanguagesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getLanguagesFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* addLanguage({ payload }) {
  try {
    const { data } = yield call(addLanguageApi, payload.data);
    yield put(addLanguagesuccess(data));
    yield payload.navigate("/languages");
  } catch (error) {
    console.log(error);
    yield put(addLanguageFailure(error.response?.data?.message || error));
  }
}

function* getSingleLanguage({ payload }) {
  try {
    const { data } = yield call(getSingleLanguageApi, payload);
    yield put(getSingleLanguagesuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleLanguageFailure(error?.response?.data || error));
  }
}

function* editLanguage({ payload }) {
  try {
    yield call(editLanguageApi, payload);
    yield put(editLanguagesuccess(payload));
    yield payload.navigate("/languages");
  } catch (error) {
    console.log(error);
    yield put(editLanguageFailure(error?.response?.data || error));
  }
}

function* deleteLanguage({ payload }) {
  try {
    yield call(deleteLanguageApi, payload);
    yield put(deleteLanguagesuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteLanguageFailure(error?.response?.data || error));
  }
}

export function* watchGetLanguages() {
  yield takeEvery(GET_LANGUAGES, getLanguages);
}

export function* watchAddLanguage() {
  yield takeEvery(ADD_LANGUAGE, addLanguage);
}

export function* watchGetSingleLanguage() {
  yield takeEvery(GET_SINGLE_LANGUAGE, getSingleLanguage);
}

export function* watchEditLanguage() {
  yield takeEvery(EDIT_LANGUAGE, editLanguage);
}

export function* watchDeleteLanguage() {
  yield takeEvery(DELETE_LANGUAGE, deleteLanguage);
}

function* languagesSaga() {
  yield all([fork(watchGetLanguages)]);
  yield all([fork(watchGetSingleLanguage)]);
  yield all([fork(watchAddLanguage)]);
  yield all([fork(watchEditLanguage)]);
  yield all([fork(watchDeleteLanguage)]);
}

export default languagesSaga;
