import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addFeatureApi,
  deleteFeatureApi,
  editFeatureApi,
  getFeaturesApi,
  getSingleFeatureApi,
} from "api/features";

// Login Redux States
import {
  ADD_FEATURE,
  DELETE_FEATURE,
  EDIT_FEATURE,
  GET_FEATURES,
  GET_SINGLE_FEATURE,
} from "./actionTypes";

import {
  addFeatureFailure,
  addFeatureSuccess,
  deleteFeatureFailure,
  deleteFeatureSuccess,
  editFeatureFailure,
  editFeatureSuccess,
  getFeaturesFailure,
  getFeaturesSuccess,
  getSingleFeatureFailure,
  getSingleFeatureSuccess,
} from "./actions";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";

function* getFeatures({ payload }) {
  try {
    const { data } = yield call(getFeaturesApi, payload);
    yield put(getFeaturesSuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(getFeaturesFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* getSingleFeature({ payload }) {
  try {
    const { data } = yield call(getSingleFeatureApi, payload);
    yield put(getSingleFeatureSuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(getSingleFeatureFailure(error?.response?.data || error));
  }
}

function* addFeature({ payload }) {
  try {
    const { data } = yield call(addFeatureApi, payload.data);
    yield put(addFeatureSuccess(data));
    yield payload.navigate("/portal/features");
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(addFeatureFailure(error.response?.data?.message || error));
  }
}

function* editFeature({ payload }) {
  try {
    const { data } = yield call(editFeatureApi, payload);
    yield put(editFeatureSuccess(data));
    yield payload.navigate("/portal/features");
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(editFeatureFailure(error?.response?.data || error));
  }
}

function* deleteFeature({ payload }) {
  try {
    yield call(deleteFeatureApi, payload);
    yield put(deleteFeatureSuccess(payload));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(deleteFeatureFailure(error?.response?.data || error));
  }
}

export function* watchGetFeatures() {
  yield takeEvery(GET_FEATURES, getFeatures);
}

export function* watchSingleFeature() {
  yield takeEvery(GET_SINGLE_FEATURE, getSingleFeature);
}

export function* watchAddFeature() {
  yield takeEvery(ADD_FEATURE, addFeature);
}

export function* watchEditFeature() {
  yield takeEvery(EDIT_FEATURE, editFeature);
}

export function* watchDeleteFeature() {
  yield takeEvery(DELETE_FEATURE, deleteFeature);
}

function* featuresSaga() {
  yield all([fork(watchGetFeatures)]);
  yield all([fork(watchSingleFeature)]);
  yield all([fork(watchAddFeature)]);
  yield all([fork(watchEditFeature)]);
  yield all([fork(watchDeleteFeature)]);
}

export default featuresSaga;
