import React, { useState } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";

import { ReactComponent as EditIcon } from "assets/builder/edit.svg";
import { ReactComponent as CopyIcon } from "assets/builder/copy.svg";
import { ReactComponent as DeleteIcon } from "assets/builder/delete.svg";
import { v4 } from "uuid";
import { useDrop } from "react-dnd";

const UseFieldArray = ({
  componentId,
  componentData,
  setComponentData,
  activeComponent,
  setActiveComponent,
  children,
}) => {
  const [activeClass, setActiveClass] = useState(false);

  const currentInput = componentData?.find(
    (component) => component.id === componentId
  );

  // eslint-disable-next-line no-unused-vars
  const [collectedProps, drop] = useDrop(
    () => ({
      accept: "text",
      drop: (item, monitor) => {
        const didDrop = monitor.didDrop();
        if (didDrop) {
          return;
        }

        setComponentData((prevDropedElements) => [
          ...prevDropedElements,
          { id: v4(), parent: componentId, ...item },
        ]);
      },
    }),
    [componentData]
  );

  const handleDeleteComponent = () => {
    const newComponentData = componentData
      .filter((component) => component?.id !== componentId)
      .filter((component) => component?.parent !== componentId);
    setComponentData(newComponentData);
  };

  const handleEditComponent = () => {
    setActiveComponent(currentInput);
    setActiveClass(true);
  };

  const handleCopyComponentWithChildren = () => {
    const newId = v4();

    const parentField = {
      ...currentInput,
      id: newId,
    };

    const children = componentData
      .filter((component) => component.parent === currentInput.id)
      .map((component) => ({ ...component, id: v4(), parent: newId }));

    setComponentData([...componentData, parentField, ...children]);
  };

  return (
    <Col ref={drop} lg={12} xs={12} className="verticalContainer">
      <div className={activeClass ? "input-view active" : "input-view"}>
        <label className="input-label">
          {/* {currentInput?.props?.label || "Enter label"} */}
        </label>
        <div className="input-actions">
          <OverlayTrigger placement="top" overlay={<Tooltip>تعديل</Tooltip>}>
            <button
              type="button"
              className={activeClass ? "edit active" : "edit"}
              onClick={handleEditComponent}
            >
              <EditIcon />
            </button>
          </OverlayTrigger>

          <OverlayTrigger placement="top" overlay={<Tooltip>نسخ</Tooltip>}>
            <button
              type="button"
              className="copy"
              onClick={() => handleCopyComponentWithChildren()}
            >
              <CopyIcon />
            </button>
          </OverlayTrigger>

          <OverlayTrigger placement="top" overlay={<Tooltip>حذف</Tooltip>}>
            <button
              type="button"
              className="delete"
              onClick={handleDeleteComponent}
            >
              <DeleteIcon />
            </button>
          </OverlayTrigger>
        </div>
        <div className="container-field">
          <h3>{currentInput?.props?.label || "Enter label"}</h3>
          <Row>{children}</Row>
        </div>
      </div>
    </Col>
  );
};

export default UseFieldArray;
