import { getId } from "helpers/functions";
import {
  GET_THEMES_COMPONENTS_TYPES,
  GET_THEMES_COMPONENTS_TYPES_SUCCESS,
  GET_THEMES_COMPONENTS_TYPES_FAILURE,
  ADD_THEMES_COMPONENTS_TYPE,
  ADD_THEMES_COMPONENTS_TYPE_SUCCESS,
  ADD_THEMES_COMPONENTS_TYPE_FAILURE,
  DELETE_THEMES_COMPONENTS_TYPE,
  DELETE_THEMES_COMPONENTS_TYPE_SUCCESS,
  DELETE_THEMES_COMPONENTS_TYPE_FAILURE,
  GET_SINGLE_THEMES_COMPONENTS_TYPE,
  GET_SINGLE_THEMES_COMPONENTS_TYPE_SUCCESS,
  GET_SINGLE_THEMES_COMPONENTS_TYPE_FAILURE,
} from "./actionTypes";

const initialState = {
  componentsTypes: [],
  loading: false,
  error: "",
  singleComponentsType: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_THEMES_COMPONENTS_TYPES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_THEMES_COMPONENTS_TYPES_SUCCESS:
      state = {
        ...state,
        loading: false,
        componentsTypes: action.payload.themeComponentTypes,
      };
      break;
    case GET_THEMES_COMPONENTS_TYPES_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_THEMES_COMPONENTS_TYPE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_THEMES_COMPONENTS_TYPE_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleComponentsType: action.payload.themeComponentType,
      };

      break;
    case GET_SINGLE_THEMES_COMPONENTS_TYPE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        singleComponentsType: {},
      };
      break;

    case ADD_THEMES_COMPONENTS_TYPE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_THEMES_COMPONENTS_TYPE_SUCCESS:
      state = {
        ...state,
        loading: false,
        componentsTypes: action.payload.componentsTypes,
      };
      break;
    case ADD_THEMES_COMPONENTS_TYPE_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_THEMES_COMPONENTS_TYPE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_THEMES_COMPONENTS_TYPE_SUCCESS:
      const themesAfterDeleteing = [
        ...state?.componentsTypes?.filter(
          (item) => getId(item) !== action.payload
        ),
      ];
      state = {
        ...state,
        loading: false,
        componentsTypes: themesAfterDeleteing,
      };
      break;
    case DELETE_THEMES_COMPONENTS_TYPE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
