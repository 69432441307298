import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addPortalHelpBlogApi,
  deletePortalHelpBlogApi,
  editPortalHelpBlogApi,
  getPortalHelpBlogsApi,
  getSinglePortalHelpBlogApi,
  togglePortalHelpBlogApi,
} from "api/portalHelpBlog";

// Login Redux States
import {
  ADD_PORTAL_HELP_BLOG,
  DELETE_PORTAL_HELP_BLOG,
  EDIT_PORTAL_HELP_BLOG,
  GET_PORTAL_HELP_BLOGS,
  GET_SINGLE_PORTAL_HELP_BLOG,
  TOGGLE_PORTAL_HELP_BLOG,
} from "./actionTypes";

import {
  addPortalHelpBlogFailure,
  addPortalHelpBlogSuccess,
  deletePortalHelpBlogFailure,
  deletePortalHelpBlogSuccess,
  editPortalHelpBlogFailure,
  editPortalHelpBlogSuccess,
  getPortalHelpBlogsFailure,
  getPortalHelpBlogsSuccess,
  getSinglePortalHelpBlogFailure,
  getSinglePortalHelpBlogSuccess,
  togglePortalHelpBlogFailure,
  togglePortalHelpBlogSuccess,
} from "./actions";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";

function* getPortalHelpBlogs({ payload }) {
  try {
    const { data } = yield call(getPortalHelpBlogsApi, payload);
    yield put(getPortalHelpBlogsSuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });

    yield put(
      getPortalHelpBlogsFailure(error?.response?.data?.errors?.[0]?.msg)
    );
  }
}

function* addPortalHelpBlog({ payload }) {
  try {
    const { data } = yield call(addPortalHelpBlogApi, payload.data);
    yield put(addPortalHelpBlogSuccess(data));
    yield payload.navigate("/portal/helpBlogs");
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(addPortalHelpBlogFailure(error.response?.data?.message || error));
  }
}

function* getSinglePortalHelpBlog({ payload }) {
  try {
    const { data } = yield call(getSinglePortalHelpBlogApi, payload);
    yield put(getSinglePortalHelpBlogSuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(getSinglePortalHelpBlogFailure(error?.response?.data || error));
  }
}

function* editPortalHelpBlog({ payload }) {
  try {
    yield call(editPortalHelpBlogApi, payload);
    yield put(editPortalHelpBlogSuccess(payload));
    if (payload.navigate) yield payload.navigate("/portal/helpBlogs");
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(editPortalHelpBlogFailure(error?.response?.data || error));
  }
}

function* togglePortalHelpBlog({ payload }) {
  try {
    const { data } = yield call(togglePortalHelpBlogApi, payload);
    yield put(togglePortalHelpBlogSuccess(data));
    // if (payload.navigate) yield payload.navigate("/portal/helpBlogs");
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(togglePortalHelpBlogFailure(error?.response?.data || error));
  }
}

function* deletePortalHelpBlog({ payload }) {
  try {
    yield call(deletePortalHelpBlogApi, payload);
    yield put(deletePortalHelpBlogSuccess(payload));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(deletePortalHelpBlogFailure(error?.response?.data || error));
  }
}

export function* watchGetPortalHelpBlogs() {
  yield takeEvery(GET_PORTAL_HELP_BLOGS, getPortalHelpBlogs);
}

export function* watchAddPortalHelpBlog() {
  yield takeEvery(ADD_PORTAL_HELP_BLOG, addPortalHelpBlog);
}

export function* watchGetSinglePortalHelpBlog() {
  yield takeEvery(GET_SINGLE_PORTAL_HELP_BLOG, getSinglePortalHelpBlog);
}

export function* watchEditPortalHelpBlog() {
  yield takeEvery(EDIT_PORTAL_HELP_BLOG, editPortalHelpBlog);
}

export function* watchTogglePortalHelpBlog() {
  yield takeEvery(TOGGLE_PORTAL_HELP_BLOG, togglePortalHelpBlog);
}

export function* watchDeletePortalHelpBlog() {
  yield takeEvery(DELETE_PORTAL_HELP_BLOG, deletePortalHelpBlog);
}

function* banksSaga() {
  yield all([fork(watchGetPortalHelpBlogs)]);
  yield all([fork(watchGetSinglePortalHelpBlog)]);
  yield all([fork(watchAddPortalHelpBlog)]);
  yield all([fork(watchEditPortalHelpBlog)]);
  yield all([fork(watchTogglePortalHelpBlog)]);
  yield all([fork(watchDeletePortalHelpBlog)]);
}

export default banksSaga;
