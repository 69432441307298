import ControlArea from "components/Layout/ControlArea";
import React from "react";
import { useSelector } from "react-redux";
import categoriesIcon from "assets/svgs/categories.svg";
import Table from "./Table";

const PortalHelpCategories = () => {
  const { portalHelpCategories, loading } = useSelector(
    (state) => state.portalHelpCategories
  );

  return (
    <>
      <div className="stores-wrap settings-page">
        <ControlArea
          btnTxt="helpCategories"
          cardTxt="helpCategories"
          icon={categoriesIcon}
          url="/portal/helpCategories/add"
        />
        <Table data={portalHelpCategories} loading={loading} />
      </div>
    </>
  );
};

export default PortalHelpCategories;
