import React, { useMemo, useState, forwardRef, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Col, Dropdown } from "react-bootstrap";
import Filter from "components/shared/Filter";
import { useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "components/shared/Loader";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as DotsIcon } from "assets/svgs/three-dots.svg";
import { ReactComponent as DeleteIcon } from "assets/svgs/delete.svg";
import { ReactComponent as EditIcon } from "assets/svgs/edit.svg";
import Swal from "sweetalert2";
import { getId } from "helpers/functions";
import { deleteSection, getAllSections } from "store/actions";

const Table = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { formatMessage } = useIntl();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    dispatch(getAllSections());

    const timeout = setTimeout(() => {
      setRows(rows);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [dispatch, params, rows]);

  const BootyCheckbox = new forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
      <input
        htmlFor="booty-check"
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" id="booty-check" />
    </div>
  ));

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = props?.data?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Filter
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handleEdit = (id) => {
    navigate(`/portal/sections/${id}`);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: formatMessage({ id: "deleteSection" }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2a89f3",
      cancelButtonColor: "#d33",
      confirmButtonText: "نعم",
      cancelButtonText: "لا",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteSection(id));
      }
    });
  };

  const columns = [
    {
      name: formatMessage({ id: "slug" }),
      selector: (row) => (
        <Link to={`/portal/sections/${getId(row)}`}>
          <span style={{ textDecoration: "underline" }}>{row?.slug}</span>
        </Link>
      ),
      sortable: true,
    },
    {
      name: formatMessage({ id: "title" }),
      selector: (row) => <span>{row?.title?.ar}</span>,
      sortable: true,
    },
    // {
    //   cell: (row) => (
    //     <div className="global-dropdown">
    //       <Dropdown>
    //         <Dropdown.Toggle>
    //           <DotsIcon />
    //         </Dropdown.Toggle>

    //         <Dropdown.Menu>
    //           <Dropdown.Item onClick={(e) => handleEdit(getId(row))}>
    //             <EditIcon className="bi bi-pencil edit" />
    //             تعديل
    //           </Dropdown.Item>
    //           {/* <Dropdown.Item onClick={(e) => handleDelete(getId(row))}>
    //             <DeleteIcon className="bi bi-trash delete" fill="#fff" />
    //             حذف
    //           </Dropdown.Item> */}
    //         </Dropdown.Menu>
    //       </Dropdown>
    //     </div>
    //   ),
    //   button: true,
    // },
  ];

  return (
    <div className="table-wrap">
      <div className="card">
        <Col xl={6} md={12} xs={12}>
          <div className="card-head">
            <div>
              <h4>
                <FormattedMessage id="sections" />
              </h4>
              <p>
                <FormattedMessage id="sectionsDesc" />
              </p>
            </div>
          </div>
        </Col>

        <div className="card-body">
          <DataTable
            columns={columns}
            data={filteredItems}
            progressPending={props?.loading}
            progressComponent={<Loader />}
            defaultSortField="name"
            selectableRows
            subHeader
            subHeaderComponent={subHeaderComponent}
            selectableRowsComponent={BootyCheckbox}
          />
        </div>
      </div>
    </div>
  );
};

export default Table;
