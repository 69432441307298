import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import {
  editPortalHelpCategory,
  getPortalHelpCategories,
  getSinglePortalHelpCategory,
} from "store/actions";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "components/shared/Loader";
import server from "api/server";
import imageUpload from "assets/svgs/imageUpload.svg";
import Select from "react-select";
import { portalHelpCategoriesTypes } from "helpers/configs";
import { FormattedMessage, useIntl } from "react-intl";
import { getId, getPublicImage, handleImage } from "helpers/functions";
import ImageUpload from "components/shared/ImageUpload";

const EditHelpCategory = () => {
  const { locale } = useIntl();
  const [selectedImage, setSelectedImage] = useState({
    preview: null,
    path: null,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { formatMessage } = useIntl();

  const { singlePortalHelpCategory, loading, portalHelpCategories } =
    useSelector((state) => state?.portalHelpCategories);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    watch,
  } = useForm();

  useEffect(() => {
    dispatch(getSinglePortalHelpCategory(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (!watch("type")) return;
    dispatch(getPortalHelpCategories(`?type=${watch("type")}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, watch("type")]);

  useEffect(() => {
    reset({
      title: singlePortalHelpCategory?.title,
      icon: singlePortalHelpCategory?.icon,
      type: singlePortalHelpCategory?.type,
      parentCategory: singlePortalHelpCategory?.parentCategory,
    });

    return () => reset({});
  }, [reset, singlePortalHelpCategory]);

  const imageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const { data } = await server().post("/upload", formData);
      setSelectedImage({
        preview: e.target.files[0],
        path: getPublicImage(data.data.files[0]),
      });
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage();
  };

  const onSubmit = (data) => {
    // if (selectedImage && selectedImage.path) data["icon"] = selectedImage.path;

    // if (data.icon) data.icon = "uploads/" + data.icon.split("/").pop();

    dispatch(editPortalHelpCategory({ id, data, navigate }));
  };

  if (loading) return <Loader />;
  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>تعديل بيانات التصنيف</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={12}>
                    <div className="form-group required">
                      <h5>صورة التصنيف</h5>
                      <div className="image-uploader image-contain">
                        <Controller
                          control={control}
                          name="icon"
                          render={({ field: { value, onChange } }) => (
                            <ImageUpload
                              selectedImage={value}
                              setSelectedImage={onChange}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم التصنيف بالعربية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم التصنيف بالعربية"
                          {...register("title.ar", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم التصنيف بالانجليزية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم التصنيف بالانجليزية"
                          {...register("title.en", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="types" />
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          name="type"
                          render={({ field: { onChange, value } }) => (
                            <Select
                              isRtl={true}
                              // menuIsOpen={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"
                              options={portalHelpCategoriesTypes?.map(
                                (type) => ({
                                  label: formatMessage({ id: type }),
                                  value: type,
                                })
                              )}
                              placeholder={formatMessage({ id: "selectType" })}
                              isMulti={false}
                              value={{
                                label: formatMessage({ id: value || " " }),
                                value,
                              }}
                              onChange={({ value }) => {
                                onChange(value);
                              }}
                            />
                          )}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.type === "required" && (
                          <FormattedMessage id="required" />
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="form-group">
                      <h5>
                        <FormattedMessage id="parentCategory" />
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          name="parentCategory"
                          render={({ field: { onChange, value } }) => (
                            <Select
                              key={value}
                              isRtl={true}
                              // menuIsOpen={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"
                              options={portalHelpCategories
                                ?.filter((cat) => getId(cat) !== id)
                                ?.map((item) => ({
                                  label: item?.title?.[locale],
                                  value: getId(item),
                                }))}
                              placeholder={formatMessage({
                                id: "selectParentCategory",
                              })}
                              value={portalHelpCategories
                                ?.map((item) => ({
                                  label: item?.title?.[locale],
                                  value: getId(item),
                                }))
                                ?.find((item) => item?.value === value)}
                              isMulti={false}
                              onChange={({ value }) => {
                                onChange(value);
                              }}
                            />
                          )}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.parentCategory === "required" && (
                          <FormattedMessage id="required" />
                        )}
                      </p>
                    </div>
                  </Col>

                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        حفظ التعديلات
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditHelpCategory;
