import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllSeoRedirects } from "store/actions";
import Table from "./Table";
import ControlArea from "components/Layout/ControlArea";
import packagesIcon from "assets/svgs/packages.svg";

const SEORedirects = () => {
  const { seoRedirects, loading } = useSelector((state) => state.seoRedirects);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllSeoRedirects());
  }, [dispatch]);

  return (
    <div className="property-wrap seo-redirects">
      <ControlArea
        btnTxt="اضافة عنصر اعادة توجيه"
        cardTxt="عناصر اعادة التوجيه"
        icon={packagesIcon}
        url="/seoRedirects/addSeoRedirects"
      />
      <Table data={seoRedirects} loading={loading} />
    </div>
  );
};

export default SEORedirects;

export const cleanUrl = (url) =>
  decodeURI(decodeURI(url?.split(".sa")?.pop()?.replaceAll(" ", "")));
