import { getId } from "helpers/functions";
import {
  ADD_PORTAL_FAQ,
  ADD_PORTAL_FAQ_FAILURE,
  ADD_PORTAL_FAQ_SUCCESS,
  DELETE_PORTAL_FAQ,
  DELETE_PORTAL_FAQ_FAILURE,
  DELETE_PORTAL_FAQ_SUCCESS,
  GET_PORTAL_FAQS,
  GET_PORTAL_FAQS_FAILURE,
  GET_PORTAL_FAQS_SUCCESS,
  GET_SINGLE_PORTAL_FAQ,
  GET_SINGLE_PORTAL_FAQ_FAILURE,
  GET_SINGLE_PORTAL_FAQ_SUCCESS,
} from "./actionTypes";

const initialState = {
  portalFaqs: [],
  loading: false,
  error: "",
  singlePortalFaq: {},
};

const portalFaqs = (state = initialState, action) => {
  switch (action.type) {
    case GET_PORTAL_FAQS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_PORTAL_FAQS_SUCCESS:
      state = {
        ...state,
        loading: false,
        portalFaqs: action.payload.portalFaqs,
      };
      break;
    case GET_PORTAL_FAQS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_PORTAL_FAQ:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_PORTAL_FAQ_SUCCESS:
      state = {
        ...state,
        loading: false,
        singlePortalFaq: action.payload.portalFaq,
      };
      break;
    case GET_SINGLE_PORTAL_FAQ_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        singlePortalFaq: {},
      };
      break;

    case ADD_PORTAL_FAQ:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_PORTAL_FAQ_SUCCESS:
      state = {
        ...state,
        loading: false,
        portalFaqs: action.payload.portalFaqs,
      };
      break;
    case ADD_PORTAL_FAQ_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_PORTAL_FAQ:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_PORTAL_FAQ_SUCCESS:
      const portalFaqsAfterDeleteing = [
        ...state?.portalFaqs?.filter(
          (portalFaq) => getId(portalFaq) !== action.payload
        ),
      ];
      state = {
        ...state,
        loading: false,
        portalFaqs: portalFaqsAfterDeleteing,
      };
      break;
    case DELETE_PORTAL_FAQ_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default portalFaqs;
