export const GET_CURRENCIES = "GET_CURRENCIES";
export const GET_CURRENCIES_SUCCESS = "GET_CURRENCIES_SUCCESS";
export const GET_CURRENCIES_FAILURE = "GET_CURRENCIES_FAILURE";

export const GET_SINGLE_CURRENCY = "GET_SINGLE_CURRENCY";
export const GET_SINGLE_CURRENCY_SUCCESS = "GET_SINGLE_CURRENCY_SUCCESS";
export const GET_SINGLE_CURRENCY_FAILURE = "GET_SINGLE_CURRENCY_FAILURE";

export const ADD_CURRENCY = "ADD_CURRENCY";
export const ADD_CURRENCY_SUCCESS = "ADD_CURRENCY_SUCCESS";
export const ADD_CURRENCY_FAILURE = "ADD_CURRENCY_FAILURE";

export const EDIT_CURRENCY = "EDIT_CURRENCY";
export const EDIT_CURRENCY_SUCCESS = "EDIT_CURRENCY_SUCCESS";
export const EDIT_CURRENCY_FAILURE = "EDIT_CURRENCY_FAILURE";

export const DELETE_CURRENCY = "DELETE_CURRENCY";
export const DELETE_CURRENCY_SUCCESS = "DELETE_CURRENCY_SUCCESS";
export const DELETE_CURRENCY_FAILURE = "DELETE_CURRENCY_FAILURE";
