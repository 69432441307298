export const GET_FEATURES = "GET_FEATURES";
export const GET_FEATURES_SUCCESS = "GET_FEATURES_SUCCESS";
export const GET_FEATURES_FAILURE = "GET_FEATURES_FAILURE";

export const GET_SINGLE_FEATURE = "GET_SINGLE_FEATURE";
export const GET_SINGLE_FEATURE_SUCCESS = "GET_SINGLE_FEATURE_SUCCESS";
export const GET_SINGLE_FEATURE_FAILURE = "GET_SINGLE_FEATURE_FAILURE";

export const ADD_FEATURE = "ADD_FEATURE";
export const ADD_FEATURE_SUCCESS = "ADD_FEATURE_SUCCESS";
export const ADD_FEATURE_FAILURE = "ADD_FEATURE_FAILURE";

export const EDIT_FEATURE = "EDIT_FEATURE";
export const EDIT_FEATURE_SUCCESS = "EDIT_FEATURE_SUCCESS";
export const EDIT_FEATURE_FAILURE = "EDIT_FEATURE_FAILURE";

export const DELETE_FEATURE = "DELETE_FEATURE";
export const DELETE_FEATURE_SUCCESS = "DELETE_FEATURE_SUCCESS";
export const DELETE_FEATURE_FAILURE = "DELETE_FEATURE_FAILURE";
