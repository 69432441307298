import { onKeyDownPreventSpace } from "helpers/functions";
import React from "react";
import { Accordion } from "react-bootstrap";
const ButtonActions = ({ currentInput, componentData, setComponentData }) => {
  const handleInputChange = (e) => {
    const newComponentData = componentData?.map((component) => {
      if (component.id !== currentInput.id) return component;
      return {
        ...component,
        props: {
          ...component.props,
          [e.target.name]: e.target.value,
        },
      };
    });

    setComponentData(newComponentData);
  };

  const handleCheckedInputChange = (e) => {
    const newComponentData = componentData?.map((component) => {
      if (component.id !== currentInput.id) return component;
      return {
        ...component,
        props: {
          ...component.props,
          [e.target.name]: e.target.checked,
        },
      };
    });

    setComponentData(newComponentData);
  };

  return (
    <div className="accordion-wrapper">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>الاعدادات الاساسية</Accordion.Header>
          <Accordion.Body>
            <div className="sidebar-form-wrapper">
              <label className="form-label">نوع الحقل (type)</label>
              <div className="form-group">
                <div className="input-holder">
                  <select
                    className="form-control form-select"
                    name="type"
                    onChange={handleInputChange}
                    value={currentInput?.props?.type}
                  >
                    <option value="button">button</option>
                    <option value="submit">submit</option>
                    <option value="reset">reset</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="sidebar-form-wrapper">
              <label className="form-label">عنوان الحقل (label)</label>
              <div className="form-group">
                <div className="input-holder">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="عنوان الحقل (label)"
                    value={currentInput?.props?.label || ""}
                    onChange={handleInputChange}
                    name="label"
                  />
                </div>
              </div>
            </div>

            <div className="sidebar-form-wrapper">
              <label className="form-label">قيمة المدخل (value)</label>
              <div className="form-group">
                <div className="input-holder">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="قيمة المدخل (value)"
                    value={currentInput?.props?.value || ""}
                    onChange={handleInputChange}
                    name="value"
                  />
                </div>
              </div>
            </div>

            <div className="sidebar-form-wrapper">
              <label className="form-label">عرض المدخل (width)</label>
              <div className="form-group">
                <div className="input-holder">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="عرض المدخل (width)"
                    value={currentInput?.props?.width || ""}
                    onChange={handleInputChange}
                    name="width"
                  />
                </div>
              </div>
            </div>
            <div className="sidebar-form-wrapper">
              <label className="form-label">طول المدخل (height)</label>
              <div className="form-group">
                <div className="input-holder">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="طول المدخل (height)"
                    value={currentInput?.props?.height || ""}
                    onChange={handleInputChange}
                    name="height"
                  />
                </div>
              </div>
            </div>

            <div className="sidebar-form-wrapper">
              <label className="form-label"> اسم الحقل (name)</label>
              <div className="form-group">
                <div className="input-holder">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="اسم الحقل (name)"
                    value={currentInput?.props?.name || ""}
                    onChange={handleInputChange}
                    name="name"
                  />
                </div>
              </div>
            </div>
            <div className="sidebar-form-wrapper">
              <label className="form-label"> عنوان المدخل (title)</label>
              <div className="form-group">
                <div className="input-holder">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="عنوان المدخل (title)"
                    value={currentInput?.props?.title || ""}
                    onChange={handleInputChange}
                    name="title"
                  />
                </div>
              </div>
            </div>
            <div className="sidebar-form-wrapper">
              <label className="form-label"> رمز الحقل (ID)</label>
              <div className="form-group">
                <div className="input-holder">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="رمز الحقل (ID)"
                    value={currentInput?.props?.id || ""}
                    onChange={handleInputChange}
                    name="id"
                    {...onKeyDownPreventSpace}
                  />
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>القيود</Accordion.Header>
          <Accordion.Body>
            <div className="sidebar-form-wrapper">
              <div className="form-group">
                <div className="label-holder">
                  <label>
                    <input
                      type="checkbox"
                      onChange={handleCheckedInputChange}
                      name="disabled"
                      defaultChecked={currentInput?.props?.disabled}
                    />
                    <span>معطل (disabled)</span>
                  </label>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default ButtonActions;
