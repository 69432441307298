import {

  DELETE_SUBSCRIBER,
  DELETE_SUBSCRIBER_FAILURE,
  DELETE_SUBSCRIBER_SUCCESS,

  GET_ALL_SUBSCRIBERS,
  GET_ALL_SUBSCRIBERS_FAILURE,
  GET_ALL_SUBSCRIBERS_SUCCESS,

} from "./actionTypes";

export const getAllSubscribers = (payload) => {
  return {
    type: GET_ALL_SUBSCRIBERS,
    payload: payload,
  };
}

export const getAllSubscribersSuccess = (payload) => {
  return {
    type: GET_ALL_SUBSCRIBERS_SUCCESS,
    payload: payload,
  };
}

export const getAllSubscribersFailure = (error) => {
  return {
    type: GET_ALL_SUBSCRIBERS_FAILURE,
    payload: error,
  };
}

export const deleteSubscriber = (payload) => {
  return {
    type: DELETE_SUBSCRIBER,
    payload: payload,
  };
}
export const deleteSubscriberSuccess = (payload) => {
  return {
    type: DELETE_SUBSCRIBER_SUCCESS,
    payload: payload,
  };
}

export const deleteSubscriberFailure = (error) => {
  return {
    type: DELETE_SUBSCRIBER_FAILURE,
    payload: error,
  };
}
