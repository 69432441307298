import server from "./server";

export const getAreasApi = async () => {
  const response = await server().get("/areas");
  return response.data;
};

export const getSingleAreaApi = async (id) => {
  const response = await server().get(`/areas/${id}`);
  return response.data;
};

export const addAreaApi = async (payload) => {
  const response = await server().post("/areas", payload);
  return response.data;
};

export const editAreaApi = async ({ id, data }) => {
  const response = await server().put(`/areas/${id}`, data);
  return response.data;
};

export const deleteAreaApi = async (id) => {
  const response = await server().delete(`/areas/${id}`);
  return response.data;
};
