import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addUnitApi,
  deleteUnitApi,
  editUnitApi,
  getSingleUnitApi,
  getUnitsApi,
} from "api/measuringUnits";

// Login Redux States
import {
  ADD_UNIT,
  DELETE_UNIT,
  EDIT_UNIT,
  GET_SINGLE_UNIT,
  GET_UNITS,
} from "./actionTypes";

import {
  addUnitFailure,
  addUnitSuccess,
  deleteUnitFailure,
  deleteUnitSuccess,
  editUnitFailure,
  editUnitSuccess,
  getUnitsFailure,
  getUnitsSuccess,
  getSingleUnitFailure,
  getSingleUnitSuccess,
} from "./actions";

function* getUnits() {
  try {
    const { data } = yield call(getUnitsApi);
    yield put(getUnitsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getUnitsFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* getSingleUnit({ payload }) {
  try {
    const { data } = yield call(getSingleUnitApi, payload);
    yield put(getSingleUnitSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleUnitFailure(error?.response?.data || error));
  }
}

function* addUnit({ payload }) {
  try {
    const { data } = yield call(addUnitApi, payload.data);
    yield put(addUnitSuccess(data));
    yield payload.navigate("/units");
  } catch (error) {
    console.log(error);
    yield put(addUnitFailure(error.response?.data?.message || error));
  }
}

function* editUnit({ payload }) {
  try {
    const { data } = yield call(editUnitApi, payload);
    yield put(editUnitSuccess(data));
    yield payload.navigate("/units");
  } catch (error) {
    console.log(error);
    yield put(editUnitFailure(error?.response?.data || error));
  }
}

function* deleteUnit({ payload }) {
  try {
    yield call(deleteUnitApi, payload);
    yield put(deleteUnitSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteUnitFailure(error?.response?.data || error));
  }
}

export function* watchGetUnits() {
  yield takeEvery(GET_UNITS, getUnits);
}

export function* watchSingleUnit() {
  yield takeEvery(GET_SINGLE_UNIT, getSingleUnit);
}

export function* watchAddUnit() {
  yield takeEvery(ADD_UNIT, addUnit);
}

export function* watchEditUnit() {
  yield takeEvery(EDIT_UNIT, editUnit);
}

export function* watchDeleteUnit() {
  yield takeEvery(DELETE_UNIT, deleteUnit);
}

function* unitsSaga() {
  yield all([fork(watchGetUnits)]);
  yield all([fork(watchSingleUnit)]);
  yield all([fork(watchAddUnit)]);
  yield all([fork(watchEditUnit)]);
  yield all([fork(watchDeleteUnit)]);
}

export default unitsSaga;
