import { getId } from "helpers/functions";
import {
  ADD_SECTION,
  ADD_SECTION_FAILURE,
  ADD_SECTION_SUCCESS,
  DELETE_SECTION,
  DELETE_SECTION_FAILURE,
  DELETE_SECTION_SUCCESS,
  EDIT_SECTION,
  EDIT_SECTION_FAILURE,
  EDIT_SECTION_SUCCESS,
  GET_ALL_SECTIONS,
  GET_ALL_SECTIONS_FAILURE,
  GET_ALL_SECTIONS_SUCCESS,
  GET_SECTION,
  GET_SECTION_FAILURE,
  GET_SECTION_SUCCESS,
} from "./actionTypes";

const initialState = {
  loading: false,
  error: "",
  sections: [],
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    // get sections
    case GET_ALL_SECTIONS:
      state = {
        ...state,
        error: "",
        loading: true,
        sections: [],
      };
      break;

    case GET_ALL_SECTIONS_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        sections: action.payload?.portalSections,
      };
      break;

    case GET_ALL_SECTIONS_FAILURE:
      state = {
        ...state,
        error: action.payload,
        sections: [],
        loading: false,
      };
      break;

    case GET_SECTION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleSection: action.payload.portalSection,
      };
      break;
    case GET_SECTION_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case ADD_SECTION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        areas: action.payload.areas,
      };
      break;
    case ADD_SECTION_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case EDIT_SECTION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case EDIT_SECTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        areas: action.payload.areas,
      };
      break;
    case EDIT_SECTION_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_SECTION:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;

    case DELETE_SECTION_SUCCESS:
      const sectionsAfterDelete = [
        ...state?.sections?.filter(
          (section) => getId(section) !== action.payload
        ),
      ];
      state = {
        ...state,
        error: "",
        loading: false,
        sections: sectionsAfterDelete,
      };
      break;

    case DELETE_SECTION_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }

  return state;
};

export default stores;
