import { getId } from "helpers/functions";
import {
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILURE,
  ADD_CATEGORY,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAILURE,
  DELETE_CATEGORY,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAILURE,
  GET_SINGLE_CATEGORY,
  GET_SINGLE_CATEGORY_SUCCESS,
  GET_SINGLE_CATEGORY_FAILURE,
} from "./actionTypes";

const initialState = {
  categories: [],
  loading: false,
  error: "",
  singleCategory: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORIES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_CATEGORIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        categories: action.payload.categories,
      };
      break;
    case GET_CATEGORIES_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_CATEGORY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_CATEGORY_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleCategory: action.payload.category,
      };

      break;
    case GET_SINGLE_CATEGORY_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        singleCategory: {},
      };
      break;

    case ADD_CATEGORY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_CATEGORY_SUCCESS:
      state = {
        ...state,
        loading: false,
        categories: action.payload.categories,
      };
      break;
    case ADD_CATEGORY_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_CATEGORY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_CATEGORY_SUCCESS:
      const categoriesAfterDeleteing = [
        ...state?.categories?.filter(
          (category) => getId(category) !== action.payload
        ),
      ];
      state = {
        ...state,
        loading: false,
        categories: categoriesAfterDeleteing,
      };
      break;
    case DELETE_CATEGORY_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
