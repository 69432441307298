import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { editArea, getCities, getSingleArea } from "store/actions";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/shared/Loader";
import Select from "react-select";
import { useIntl } from "react-intl";
const EditProductType = () => {
  const { locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { cities } = useSelector((state) => state?.cities);
  const { singleArea, loading } = useSelector((state) => state?.areas);
  const { register, handleSubmit, reset, control } = useForm();

  useEffect(() => {
    dispatch(getCities());
    dispatch(getSingleArea(id));
  }, [dispatch, id]);

  useEffect(() => {
    reset({
      name: singleArea?.name,
      city: singleArea?.city,
    });
  }, [reset, singleArea]);

  const onSubmit = (data) => {
    dispatch(editArea({ id, data, navigate }));
  };

  const renderListCities = () => {
    return cities?.map((city) => ({
      label: city.name?.[locale],
      value: city?.name?.[locale],
      id: city?.id,
    }));
  };

  if (loading) return <Loader />;
  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>تعديل بيانات المدينة</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>اسم الدولة </h5>
                      <div>
                        <Controller
                          control={control}
                          name="country"
                          render={({
                            field: { onChange, value, selected },
                          }) => (
                            <Select
                              isRtl={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"
                              options={renderListCities()}
                              placeholder="اختار المدينة"
                              isMulti={false}
                              defaultValue={renderListCities()?.find(
                                (value) => value?.id === singleArea?.city
                              )}
                              onChange={(selected) => {
                                onChange(selected?.id);
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم المنطقة بالعربية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم المنطقة بالعربية"
                          {...register("name[ar]", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم المنطقة بالانجليزية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم المنطقة بالانجليزية"
                          {...register("name[en]", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        حفظ التعديلات
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProductType;
