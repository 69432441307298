import {
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILURE,
  GET_SINGLE_CATEGORY,
  GET_SINGLE_CATEGORY_SUCCESS,
  GET_SINGLE_CATEGORY_FAILURE,
  ADD_CATEGORY,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAILURE,
  EDIT_CATEGORY,
  EDIT_CATEGORY_SUCCESS,
  EDIT_CATEGORY_FAILURE,
  DELETE_CATEGORY,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAILURE,
} from "./actionTypes";

export const getCategories = () => {
  return {
    type: GET_CATEGORIES,
  };
};

export const getCategoriesSuccess = (categories) => {
  return {
    type: GET_CATEGORIES_SUCCESS,
    payload: categories,
  };
};

export const getCategoriesFailure = (error) => {
  return {
    type: GET_CATEGORIES_FAILURE,
    payload: error,
  };
};

export const getSingleCategory = (id) => {
  return {
    type: GET_SINGLE_CATEGORY,
    payload: id,
  };
};

export const getSingleCategorySuccess = (category) => {
  return {
    type: GET_SINGLE_CATEGORY_SUCCESS,
    payload: category,
  };
};

export const getSingleCategoryFailure = (error) => {
  return {
    type: GET_SINGLE_CATEGORY_FAILURE,
    payload: error,
  };
};

export const addCategory = (payload) => {
  return {
    type: ADD_CATEGORY,
    payload,
  };
};

export const addCategorySuccess = (payload) => {
  return {
    type: ADD_CATEGORY_SUCCESS,
    payload,
  };
};

export const addCategoryFailure = (error) => {
  return {
    type: ADD_CATEGORY_FAILURE,
    payload: error,
  };
};

export const editCategory = (payload) => {
  return {
    type: EDIT_CATEGORY,
    payload,
  };
};

export const editCategorySuccess = (payload) => {
  return {
    type: EDIT_CATEGORY_SUCCESS,
    payload,
  };
};

export const editCategoryFailure = (error) => {
  return {
    type: EDIT_CATEGORY_FAILURE,
    payload: error,
  };
};

export const deleteCategory = (id) => {
  return {
    type: DELETE_CATEGORY,
    payload: id,
  };
};

export const deleteCategorySuccess = (id) => {
  return {
    type: DELETE_CATEGORY_SUCCESS,
    payload: id,
  };
};

export const deleteCategoryFailure = (error) => {
  return {
    type: DELETE_CATEGORY_FAILURE,
    payload: error,
  };
};
