export const GET_THEMES_TEMPLATES = "GET_THEMES_TEMPLATES";
export const GET_THEMES_TEMPLATES_SUCCESS = "GET_THEMES_TEMPLATES_SUCCESS";
export const GET_THEMES_TEMPLATES_FAILURE = "GET_THEMES_TEMPLATES_FAILURE";
export const GET_SINGLE_THEMES_TEMPLATE = "GET_SINGLE_THEMES_TEMPLATE";
export const GET_SINGLE_THEMES_TEMPLATE_SUCCESS =
  "GET_SINGLE_THEMES_TEMPLATE_SUCCESS";
export const GET_SINGLE_THEMES_TEMPLATE_FAILURE =
  "GET_SINGLE_THEMES_TEMPLATE_FAILURE";
export const ADD_THEMES_TEMPLATE = "ADD_THEMES_TEMPLATE";
export const ADD_THEMES_TEMPLATE_SUCCESS = "ADD_THEMES_TEMPLATE_SUCCESS";
export const ADD_THEMES_TEMPLATE_FAILURE = "ADD_THEMES_TEMPLATE_FAILURE";
export const EDIT_THEMES_TEMPLATE = "EDIT_THEMES_TEMPLATE";
export const EDIT_THEMES_TEMPLATE_SUCCESS = "EDIT_THEMES_TEMPLATE_SUCCESS";
export const EDIT_THEMES_TEMPLATE_FAILURE = "EDIT_THEMES_TEMPLATE_FAILURE";
export const DELETE_THEMES_TEMPLATE = "DELETE_THEMES_TEMPLATE";
export const DELETE_THEMES_TEMPLATE_SUCCESS = "DELETE_THEMES_TEMPLATE_SUCCESS";
export const DELETE_THEMES_TEMPLATE_FAILURE = "DELETE_THEMES_TEMPLATE_FAILURE";
