import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addThemeApi,
  deleteThemeApi,
  editThemeApi,
  getSingleThemeApi,
  getThemesApi,
} from "api/themesComponentsTypes";

// Login Redux States
import {
  GET_THEMES_COMPONENTS_TYPES,
  ADD_THEMES_COMPONENTS_TYPE,
  DELETE_THEMES_COMPONENTS_TYPE,
  GET_SINGLE_THEMES_COMPONENTS_TYPE,
  EDIT_THEMES_COMPONENTS_TYPE,
} from "./actionTypes";
import {
  addThemesComponentsTypeFailure,
  addThemesComponentsTypeSuccess,
  deleteThemesComponentsTypeFailure,
  deleteThemesComponentsTypeSuccess,
  editThemesComponentsTypeFailure,
  editThemesComponentsTypeSuccess,
  getSingleThemesComponentsTypeFailure,
  getSingleThemesComponentsTypeSuccess,
  getThemesComponentsTypesFailure,
  getThemesComponentsTypesSuccess,
} from "./actions";

function* getThemesComponentsTypes({ payload }) {
  try {
    const { data } = yield call(getThemesApi, payload);
    yield put(getThemesComponentsTypesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(
      getThemesComponentsTypesFailure(error?.response?.data?.errors?.[0]?.msg)
    );
  }
}

function* getSingleThemesComponentsTypes({ payload }) {
  try {
    const { data } = yield call(getSingleThemeApi, payload);
    yield put(getSingleThemesComponentsTypeSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(
      getSingleThemesComponentsTypeFailure(error?.response?.data || error)
    );
  }
}

function* addThemesComponentsType({ payload }) {
  try {
    const { data } = yield call(addThemeApi, payload);
    yield put(addThemesComponentsTypeSuccess(data));
    yield payload.navigate(
      `/themes/componentTypes?deviceType=${payload?.deviceType}`
    );
  } catch (error) {
    console.log(error);
    yield put(
      addThemesComponentsTypeFailure(error.response?.data?.message || error)
    );
  }
}

function* editThemesComponentsTypes({ payload }) {
  try {
    const { data } = yield call(editThemeApi, payload);
    yield put(editThemesComponentsTypeSuccess(data));
    yield payload.navigate(
      `/themes/componentTypes?deviceType=${payload?.deviceType}`
    );
  } catch (error) {
    console.log(error);
    yield put(editThemesComponentsTypeFailure(error?.response?.data || error));
  }
}

function* deleteThemessComponentsTypes({ payload }) {
  try {
    yield call(deleteThemeApi, payload);
    yield put(deleteThemesComponentsTypeSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(
      deleteThemesComponentsTypeFailure(error?.response?.data || error)
    );
  }
}

export function* watchGetThemes() {
  yield takeEvery(GET_THEMES_COMPONENTS_TYPES, getThemesComponentsTypes);
}

export function* watchGetSingleTheme() {
  yield takeEvery(
    GET_SINGLE_THEMES_COMPONENTS_TYPE,
    getSingleThemesComponentsTypes
  );
}

export function* watchAddTheme() {
  yield takeEvery(ADD_THEMES_COMPONENTS_TYPE, addThemesComponentsType);
}

export function* watchEditTheme() {
  yield takeEvery(EDIT_THEMES_COMPONENTS_TYPE, editThemesComponentsTypes);
}

export function* watchDeleteThemes() {
  yield takeEvery(DELETE_THEMES_COMPONENTS_TYPE, deleteThemessComponentsTypes);
}

function* themesSaga() {
  yield all([fork(watchGetThemes)]);
  yield all([fork(watchGetSingleTheme)]);
  yield all([fork(watchAddTheme)]);
  yield all([fork(watchEditTheme)]);
  yield all([fork(watchDeleteThemes)]);
}

export default themesSaga;
