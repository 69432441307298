import {
  GET_CITIES,
  GET_CITIES_SUCCESS,
  GET_CITIES_FAILURE,
  ADD_CITY,
  ADD_CITY_SUCCESS,
  ADD_CITY_FAILURE,
  EDIT_CITY,
  EDIT_CITY_SUCCESS,
  EDIT_CITY_FAILURE,
  DELETE_CITY,
  DELETE_CITY_SUCCESS,
  DELETE_CITY_FAILURE,
  GET_SINGLE_CITY,
  GET_SINGLE_CITY_SUCCESS,
  GET_SINGLE_CITY_FAILURE,
} from "./actionTypes";

export const getCities = () => {
  return {
    type: GET_CITIES,
  };
};

export const getCitiesSuccess = (city) => {
  return {
    type: GET_CITIES_SUCCESS,
    payload: city,
  };
};

export const getCitiesFailure = (error) => {
  return {
    type: GET_CITIES_FAILURE,
    payload: error,
  };
};

export const getSingleCity = (id) => {
  return {
    type: GET_SINGLE_CITY,
    payload: id,
  };
};

export const getSingleCitySuccess = (city) => {
  return {
    type: GET_SINGLE_CITY_SUCCESS,
    payload: city,
  };
};

export const getSingleCityFailure = (error) => {
  return {
    type: GET_SINGLE_CITY_FAILURE,
    payload: error,
  };
};

export const addCity = (payload) => {
  return {
    type: ADD_CITY,
    payload,
  };
};

export const addCitySuccess = (payload) => {
  return {
    type: ADD_CITY_SUCCESS,
    payload,
  };
};

export const addCityFailure = (error) => {
  return {
    type: ADD_CITY_FAILURE,
    payload: error,
  };
};

export const editCity = (payload) => {
  return {
    type: EDIT_CITY,
    payload,
  };
};

export const editCitySuccess = (payload) => {
  return {
    type: EDIT_CITY_SUCCESS,
    payload,
  };
};

export const editCityFailure = (error) => {
  return {
    type: EDIT_CITY_FAILURE,
    payload: error,
  };
};

export const deleteCity = (id) => {
  return {
    type: DELETE_CITY,
    payload: id,
  };
};

export const deleteCitySuccess = (id) => {
  return {
    type: DELETE_CITY_SUCCESS,
    payload: id,
  };
};

export const deleteCityFailure = (error) => {
  return {
    type: DELETE_CITY_FAILURE,
    payload: error,
  };
};
