import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getId } from "helpers/functions";
import {
  addItem,
  addItemUser,
  editItem,
  editItemUser,
  getSingleItem,
} from "store/actions";
import "styles/permissions.scss";
import Loader from "components/shared/Loader";

const AddUserPopup = ({ handleToggle, show, setShow, roles, userId }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [loading, setLoading] = useState(true);
  const { singlePermissionData, loading } = useSelector(
    (state) => state?.permissionsData
  );

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    let formatData = {
      name: data?.name,
      username: data?.username,
      email: data?.email,
      phone: data?.phone,
      role: data?.role,
    };
    if (userId) {
      dispatch(
        editItemUser({
          dataKey: "auth/superAdmins",
          data: formatData,
          id: userId,
          navigate: () => {
            navigate("/permissions/users");
            handleToggle();
            setShow();
            reset();
            toast?.success(
              formatMessage({
                id: "updatedSuccessfully",
              })
            );
          },
        })
      );
    } else {
      dispatch(
        addItemUser({
          dataKey: "auth/superAdmins",
          data,
          navigate: () => {
            // handleToggle();
            setShow();
            reset();
            toast?.success(
              formatMessage({
                id: "addedSuccessfully",
              })
            );
          },
        })
      );
    }
  };

  useEffect(() => {
    if (show && userId) {
      dispatch(getSingleItem({ dataKey: "auth/superAdmins", id: userId }));
    }
  }, [dispatch, userId, show]);

  useEffect(() => {
    if (show) {
      if (singlePermissionData?.superAdmin && userId) {
        reset(singlePermissionData?.superAdmin);
        setValue("role", getId(singlePermissionData.superAdmin.role)); // Set the role field
      } else {
        reset(); // Reset form to initial values
      }
    }
  }, [reset, singlePermissionData, setValue, show, userId]);

  useEffect(() => {
    if (!show) {
      reset();
    }
  }, [show, reset]);

  // if (loading) return <Loader />;
  return (
    <Modal
      show={show}
      onHide={() => {
        handleToggle();
        // reset();
        setShow(false);
      }}
      size="md"
      className={`addUserModal`}
    >
      <Modal.Header closeButton>
        <h3 className="title">
          <FormattedMessage id={userId ? "editUser2" : "addUser2"} />
        </h3>
      </Modal.Header>
      <Modal.Body>
        <div className="first-group">
          {loading ? (
            <Loader />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="inner">
                <Row>
                  <Col xs={12} md={12} lg={12} xl={12}>
                    <div className="input-div">
                      <label>
                        <FormattedMessage id="name" />
                      </label>
                      <input
                        type="text"
                        maxLength="64"
                        {...register("name", {
                          required: true,
                        })}
                      />
                    </div>
                    {errors?.name && (
                      <p className="error">
                        <FormattedMessage id="youMustEnterTheName" />
                      </p>
                    )}
                  </Col>
                  <Col xs={12} md={12} lg={12} xl={12}>
                    <div className="input-div">
                      <label>
                        <FormattedMessage id="username" />
                      </label>
                      <input
                        type="text"
                        maxLength="64"
                        {...register("username", {
                          required: true,
                        })}
                      />
                    </div>
                    {errors?.username && (
                      <p className="error">
                        <FormattedMessage id="youMustEnterTheUsername" />
                      </p>
                    )}
                  </Col>
                  <Col xs={12} md={12} lg={12} xl={12}>
                    <div className="input-div">
                      <label>
                        <FormattedMessage id="email" />
                      </label>
                      <input
                        type="email"
                        {...register("email", {
                          required: true,
                          pattern:
                            /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        })}
                        onBlur={() => {
                          const emailValue = watch("email");
                          if (
                            !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
                              emailValue
                            )
                          ) {
                            setError("email", { type: "pattern" });
                          } else {
                            clearErrors("email");
                          }
                        }}
                      />
                    </div>
                    {errors?.email && (
                      <p className="error">
                        {errors.email.type === "required" ? (
                          <FormattedMessage id="youMustEnterTheEmail" />
                        ) : (
                          <FormattedMessage id="invalidEmail" />
                        )}
                      </p>
                    )}
                  </Col>
                  {/* <FormattedMessage id="youMustEnterTheEmail" /> */}
                  {!userId && (
                    <Col xs={12} md={12} lg={12} xl={12}>
                      <div className="input-div">
                        <label>
                          <FormattedMessage id="password" />
                        </label>
                        <input
                          type="password"
                          {...register("password", { required: true })}
                        />
                      </div>
                      {errors?.password && (
                        <p className="error">
                          <FormattedMessage id="youMustEnterThePassword" />
                        </p>
                      )}
                    </Col>
                  )}

                  <Col xs={12} md={12} lg={12} xl={12}>
                    <div className="input-div">
                      <label>
                        <FormattedMessage id="phone" />
                      </label>
                      <input
                        type="text"
                        {...register("phone", {
                          required: true,
                          validate: (value) => {
                            const phoneWithoutCountryCode = value.replace(
                              /[^0-9]/g,
                              ""
                            );
                            if (phoneWithoutCountryCode.length !== 10) {
                              return "phoneNumberMustBeExactly10Digits";
                            }
                            return true;
                          },
                        })}
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </div>

                    {errors?.phone && (
                      <p className="error">
                        {
                          <FormattedMessage
                            id={
                              errors?.phone?.message || "youMustEnterThePhone"
                            }
                          />
                        }
                      </p>
                    )}
                  </Col>
                  <Col xs={12} md={12} lg={12} xl={12}>
                    <div className="input-div">
                      <label>
                        <FormattedMessage id="role" />
                      </label>
                      <select
                        className="form-select"
                        {...register("role", { required: true })}
                      >
                        <option value="">
                          <FormattedMessage id="pleaseChooseARole" />
                        </option>
                        {roles?.map((role) => (
                          <option key={getId(role)} value={getId(role)}>
                            {role?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {errors?.role && (
                      <p className="error">
                        <FormattedMessage id="youMustSelectARole" />
                      </p>
                    )}
                  </Col>
                </Row>
              </div>

              <div className="modal-footer">
                <button type="submit" className="send">
                  {userId ? (
                    <FormattedMessage id="update" />
                  ) : (
                    <FormattedMessage id="add" />
                  )}
                </button>
                <button
                  type="button"
                  className="cancel"
                  onClick={() => {
                    handleToggle();
                    setShow(false);
                    reset();
                  }}
                >
                  <FormattedMessage id="cancel" />
                </button>
              </div>
            </form>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddUserPopup;
