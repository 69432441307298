import server from "./server";

export const getCitiesApi = async () => {
  const response = await server().get("/cities");
  return response.data;
};

export const getSingleCityApi = async (id) => {
  const response = await server().get(`/cities/${id}`);
  return response.data;
};

export const addCityApi = async (payload) => {
  const response = await server().post("/cities", payload);
  return response.data;
};

export const editCityApi = async ({ id, data }) => {
  const response = await server().put(`/cities/${id}`, data);
  return response.data;
};

export const deleteCityApi = async (id) => {
  const response = await server().delete(`/cities/${id}`);
  return response.data;
};
