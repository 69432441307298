import { useRef } from "react";
import { ReactComponent as UploadIcon } from "assets/svgs/upload.svg";
import { ReactComponent as RemoveImg } from "assets/svgs/close-icon.svg";
import server from "api/server";
import { getPublicImage, handleImage } from "helpers/functions";

const ImageUpload = ({
  selectedImage,
  setSelectedImage,
  imageUrl = "",
  className = "",
}) => {
  const inputRef = useRef(null);

  const imageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const { data } = await server().post("/upload", formData);

      setSelectedImage(getPublicImage(data.data.files[0]));
    }
  };

  const removeSelectedImage = () => {
    if (inputRef.current != null) inputRef.current.value = "";
    setSelectedImage("");
  };

  return (
    <div className={`image-uploader image-contain ${className}`}>
      <label>
        {selectedImage ? (
          <div>
            <img
              src={
                selectedImage
                  ? handleImage(selectedImage)
                  : handleImage(imageUrl)
              }
              width={160}
              height={160}
              alt=""
            />
            <button
              type="button"
              className="removeImg"
              onClick={removeSelectedImage}
            >
              <RemoveImg />
            </button>
          </div>
        ) : (
          <div className="img-pat">
            <UploadIcon />
          </div>
        )}
        <input
          accept="image/*"
          type="file"
          onChange={imageChange}
          ref={inputRef}
        />
      </label>
    </div>
  );
};

export default ImageUpload;
