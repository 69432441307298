import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import { getProductTypesApi } from "api/productTypes";

// Login Redux States
import {
  ADD_PRODUCT_TYPE,
  DELETE_PRODUCT_TYPE,
  EDIT_PRODUCT_TYPE,
  GET_PRODUCT_TYPES,
} from "./actionTypes";
import {
  addProductTypeFailure,
  addProductTypeSuccess,
  deleteProductTypeFailure,
  deleteProductTypeSuccess,
  editProductTypeFailure,
  editProductTypeSuccess,
  getProductTypesFailure,
  getProductTypesSuccess,
} from "./actions";

function* getProductTypes() {
  try {
    const { data } = yield call(getProductTypesApi);
    yield put(getProductTypesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getProductTypesFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* addProductType({ payload }) {
  try {
    const { data } = yield call(addProductType, payload.data);
    yield put(addProductTypeSuccess(data));
    yield payload.navigate("/producttypes");
  } catch (error) {
    console.log(error);
    yield put(addProductTypeFailure(error.response?.data?.message || error));
  }
}

function* editProductType({ payload }) {
  try {
    yield call(editProductType, payload);
    yield put(editProductTypeSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(editProductTypeFailure(error?.response?.data || error));
  }
}

function* deleteProductType({ payload }) {
  try {
    yield call(deleteProductType, payload);
    yield put(deleteProductTypeSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteProductTypeFailure(error?.response?.data || error));
  }
}

export function* watchProductTypes() {
  yield takeEvery(GET_PRODUCT_TYPES, getProductTypes);
}
export function* watchAddProductType() {
  yield takeEvery(ADD_PRODUCT_TYPE, addProductType);
}
export function* watchEditProductType() {
  yield takeEvery(EDIT_PRODUCT_TYPE, editProductType);
}
export function* watchDeleteProductTypes() {
  yield takeEvery(DELETE_PRODUCT_TYPE, deleteProductType);
}

function* productTypesSaga() {
  yield all([fork(watchProductTypes)]);
  yield all([fork(watchAddProductType)]);
  yield all([fork(watchEditProductType)]);
  yield all([fork(watchDeleteProductTypes)]);
}

export default productTypesSaga;
