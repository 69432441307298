import { getId } from "helpers/functions";
import {
  GET_ALL_SEO_REDIRECTS,
  GET_ALL_SEO_REDIRECTS_SUCCESS,
  GET_ALL_SEO_REDIRECTS_FAILURE,
  DELETE_SEO_REDIRECTS,
  DELETE_SEO_REDIRECTS_SUCCESS,
  DELETE_SEO_REDIRECTS_FAILURE,
  GET_SEO_REDIRECTS,
  GET_SEO_REDIRECTS_SUCCESS,
  GET_SEO_REDIRECTS_FAILURE,
  ADD_SEO_REDIRECTS,
  ADD_SEO_REDIRECTS_SUCCESS,
  ADD_SEO_REDIRECTS_FAILURE,
  EDIT_SEO_REDIRECTS,
  EDIT_SEO_REDIRECTS_SUCCESS,
  EDIT_SEO_REDIRECTS_FAILURE,
} from "./actionTypes";

const initialState = {
  seoRedirects: [],
  loading: false,
  error: "",
  singleSeoRedirect: {},
  metadata: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    // get all seoRedirects
    case GET_ALL_SEO_REDIRECTS:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_ALL_SEO_REDIRECTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        seoRedirects: action.payload.seoRedirects,
        metadata: action.payload.metadata,
      };
      break;
    case GET_ALL_SEO_REDIRECTS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // delete contact
    case DELETE_SEO_REDIRECTS:
      state = {
        ...state,
        loading: true,
      };

      break;

    case DELETE_SEO_REDIRECTS_SUCCESS:
      const contactsAfterDeleteing = [
        ...state?.seoRedirects?.filter(
          (contact) => getId(contact) !== action.payload
        ),
      ];
      state = {
        ...state,
        loading: false,
        seoRedirects: contactsAfterDeleteing,
      };
      break;

    case DELETE_SEO_REDIRECTS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    // delete contact
    case GET_SEO_REDIRECTS:
      state = {
        ...state,
        loading: true,
      };

      break;

    case GET_SEO_REDIRECTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleSeoRedirect: action.payload?.seoRedirect,
      };
      break;

    case GET_SEO_REDIRECTS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    // add
    case ADD_SEO_REDIRECTS:
      state = {
        ...state,
        loading: true,
      };

      break;

    case ADD_SEO_REDIRECTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleSeoRedirect: action.payload,
      };
      break;

    case ADD_SEO_REDIRECTS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    // edit
    case EDIT_SEO_REDIRECTS:
      state = {
        ...state,
        loading: true,
      };

      break;

    case EDIT_SEO_REDIRECTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleSeoRedirect: action.payload?.seoRedirect,
      };
      break;

    case EDIT_SEO_REDIRECTS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
