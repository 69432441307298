export const GET_BANKS = "GET_BANKS";
export const GET_BANKS_SUCCESS = "GET_BANKS_SUCCESS";
export const GET_BANKS_FAILURE = "GET_BANKS_FAILURE";

export const GET_SINGLE_BANK = "GET_SINGLE_BANK";
export const GET_SINGLE_BANK_SUCCESS = "GET_SINGLE_BANK_SUCCESS";
export const GET_SINGLE_BANK_FAILURE = "GET_SINGLE_BANK_FAILURE";

export const ADD_BANK = "ADD_BANK";
export const ADD_BANK_SUCCESS = "ADD_BANK_SUCCESS";
export const ADD_BANK_FAILURE = "ADD_BANK_FAILURE";

export const EDIT_BANK = "EDIT_BANK";
export const EDIT_BANK_SUCCESS = "EDIT_BANK_SUCCESS";
export const EDIT_BANK_FAILURE = "EDIT_BANK_FAILURE";

export const DELETE_BANK = "DELETE_BANK";
export const DELETE_BANK_SUCCESS = "DELETE_BANK_SUCCESS";
export const DELETE_BANK_FAILURE = "DELETE_BANK_FAILURE";
