import ControlArea from "components/Layout/ControlArea";
import React from "react";
import { useSelector } from "react-redux";
import peopleIcon from "assets/svgs/people.svg";
import Table from "./Table";
const Index = () => {
  const { users } = useSelector((state) => state.users);
  return (
    <>
      <div className="users-wrap">
        <ControlArea
          btnTxt="إضافة عميل"
          cardTxt="العملاء"
          icon={peopleIcon}
          url="/users/adduser"
        />
        <Table data={users} />
      </div>
    </>
  );
};

export default Index;
