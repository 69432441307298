import { getId } from "helpers/functions";
import {
  ADD_PORTAL_RESERVATION,
  ADD_PORTAL_RESERVATION_FAILURE,
  ADD_PORTAL_RESERVATION_SUCCESS,
  DELETE_PORTAL_RESERVATION,
  DELETE_PORTAL_RESERVATION_FAILURE,
  DELETE_PORTAL_RESERVATION_SUCCESS,
  GET_PORTAL_RESERVATIONS,
  GET_PORTAL_RESERVATIONS_FAILURE,
  GET_PORTAL_RESERVATIONS_SUCCESS,
  GET_SINGLE_PORTAL_RESERVATION,
  GET_SINGLE_PORTAL_RESERVATION_FAILURE,
  GET_SINGLE_PORTAL_RESERVATION_SUCCESS,
} from "./actionTypes";

const initialState = {
  portalReservations: [],
  loading: false,
  error: "",
  singlePortalReservation: {},
};

const portalReservations = (state = initialState, action) => {
  switch (action.type) {
    case GET_PORTAL_RESERVATIONS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_PORTAL_RESERVATIONS_SUCCESS:
      state = {
        ...state,
        loading: false,
        portalReservations: action.payload.portalReservations,
        metadata: action.payload.metadata,
      };
      break;
    case GET_PORTAL_RESERVATIONS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_PORTAL_RESERVATION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_PORTAL_RESERVATION_SUCCESS:
      state = {
        ...state,
        loading: false,
        singlePortalReservation: action.payload.portalReservation,
      };
      break;
    case GET_SINGLE_PORTAL_RESERVATION_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        singlePortalReservation: {},
      };
      break;

    case ADD_PORTAL_RESERVATION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_PORTAL_RESERVATION_SUCCESS:
      state = {
        ...state,
        loading: false,
        portalReservations: action.payload.portalReservations,
      };
      break;
    case ADD_PORTAL_RESERVATION_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_PORTAL_RESERVATION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_PORTAL_RESERVATION_SUCCESS:
      const portalReservationsAfterDeleteing = [
        ...state?.portalReservations?.filter(
          (portalReservation) => getId(portalReservation) !== action.payload
        ),
      ];
      state = {
        ...state,
        loading: false,
        portalReservations: portalReservationsAfterDeleteing,
      };
      break;
    case DELETE_PORTAL_RESERVATION_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default portalReservations;
