export const GET_ITEMS = "GET_ITEMS";
export const GET_ITEMS_SUCCESS = "GET_ITEMS_SUCCESS";
export const GET_ITEMS_FAILURE = "GET_ITEMS_FAILURE";

export const GET_SINGLE_ITEM = "GET_SINGLE_ITEM";
export const GET_SINGLE_ITEM_SUCCESS = "GET_SINGLE_ITEM_SUCCESS";
export const GET_SINGLE_ITEM_FAILURE = "GET_SINGLE_ITEM_FAILURE";

export const ADD_ITEM = "ADD_ITEM";
export const ADD_ITEM_SUCCESS = "ADD_ITEM_SUCCESS";
export const ADD_ITEM_FAILURE = "ADD_ITEM_FAILURE";

export const EDIT_ITEM = "EDIT_ITEM";
export const EDIT_ITEM_SUCCESS = "EDIT_ITEM_SUCCESS";
export const EDIT_ITEM_FAILURE = "EDIT_ITEM_FAILURE";

export const DELETE_ITEM = "DELETE_ITEM";
export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS";
export const DELETE_ITEM_FAILURE = "DELETE_ITEM_FAILURE";

export const ADD_ITEM_USER = "ADD_ITEM_USER";
export const ADD_ITEM_USER_SUCCESS = "ADD_ITEM_USER_SUCCESS";
export const ADD_ITEM_USER_FAILURE = "ADD_ITEM_USER_FAILURE";

export const EDIT_ITEM_USER = "EDIT_ITEM_USER";
export const EDIT_ITEM_USER_SUCCESS = "EDIT_ITEM_USER_SUCCESS";
export const EDIT_ITEM_USER_FAILURE = "EDIT_ITEM_USER_FAILURE";

export const Toggle_Active_USER = "Toggle_Active_USER";
export const Toggle_Active_USER_SUCCESS = "Toggle_Active_USER_SUCCESS";
export const Toggle_Active_USER_FAILURE = "Toggle_Active_USER_FAILURE";

export const DELETE_ITEM_USER = "DELETE_ITEM_USER";
export const DELETE_ITEM_USER_SUCCESS = "DELETE_ITEM_USER_SUCCESS";
export const DELETE_ITEM_USER_FAILURE = "DELETE_ITEM_USER_FAILURE";
