import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addPortalTechnicalSupportApi,
  deletePortalTechnicalSupportApi,
  editPortalTechnicalSupportApi,
  getPortalTechnicalSupportsApi,
  getSinglePortalTechnicalSupportApi,
} from "api/portalTechnicalSupport";

// Login Redux States
import {
  ADD_PORTAL_TECHNICAL_SUPPORT,
  DELETE_PORTAL_TECHNICAL_SUPPORT,
  EDIT_PORTAL_TECHNICAL_SUPPORT,
  GET_PORTAL_TECHNICAL_SUPPORTS,
  GET_SINGLE_PORTAL_TECHNICAL_SUPPORT,
} from "./actionTypes";

import {
  addPortalTechnicalSupportFailure,
  addPortalTechnicalSupportSuccess,
  deletePortalTechnicalSupportFailure,
  deletePortalTechnicalSupportSuccess,
  editPortalTechnicalSupportFailure,
  editPortalTechnicalSupportSuccess,
  getPortalTechnicalSupportsFailure,
  getPortalTechnicalSupportsSuccess,
  getSinglePortalTechnicalSupportFailure,
  getSinglePortalTechnicalSupportSuccess,
} from "./actions";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";

function* getPortalTechnicalSupports() {
  try {
    const { data } = yield call(getPortalTechnicalSupportsApi);
    yield put(getPortalTechnicalSupportsSuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(
      getPortalTechnicalSupportsFailure(error?.response?.data?.errors?.[0]?.msg)
    );
  }
}

function* addPortalTechnicalSupport({ payload }) {
  try {
    const { data } = yield call(addPortalTechnicalSupportApi, payload.data);
    yield put(addPortalTechnicalSupportSuccess(data));
    yield payload.navigate("/portalTechnicalSupport");
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(
      addPortalTechnicalSupportFailure(error.response?.data?.message || error)
    );
  }
}

function* getSinglePortalTechnicalSupport({ payload }) {
  try {
    const { data } = yield call(getSinglePortalTechnicalSupportApi, payload);
    yield put(getSinglePortalTechnicalSupportSuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(
      getSinglePortalTechnicalSupportFailure(error?.response?.data || error)
    );
  }
}

function* editPortalTechnicalSupport({ payload }) {
  try {
    yield call(editPortalTechnicalSupportApi, payload);
    yield put(editPortalTechnicalSupportSuccess(payload));
    yield payload.navigate("/portalTechnicalSupport");
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(
      editPortalTechnicalSupportFailure(error?.response?.data || error)
    );
  }
}

function* deletePortalTechnicalSupport({ payload }) {
  try {
    yield call(deletePortalTechnicalSupportApi, payload);
    yield put(deletePortalTechnicalSupportSuccess(payload));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(
      deletePortalTechnicalSupportFailure(error?.response?.data || error)
    );
  }
}

export function* watchGetPortalTechnicalSupports() {
  yield takeEvery(GET_PORTAL_TECHNICAL_SUPPORTS, getPortalTechnicalSupports);
}

export function* watchAddPortalTechnicalSupport() {
  yield takeEvery(ADD_PORTAL_TECHNICAL_SUPPORT, addPortalTechnicalSupport);
}

export function* watchGetSinglePortalTechnicalSupport() {
  yield takeEvery(
    GET_SINGLE_PORTAL_TECHNICAL_SUPPORT,
    getSinglePortalTechnicalSupport
  );
}

export function* watchEditPortalTechnicalSupport() {
  yield takeEvery(EDIT_PORTAL_TECHNICAL_SUPPORT, editPortalTechnicalSupport);
}

export function* watchDeletePortalTechnicalSupport() {
  yield takeEvery(
    DELETE_PORTAL_TECHNICAL_SUPPORT,
    deletePortalTechnicalSupport
  );
}

function* banksSaga() {
  yield all([fork(watchGetPortalTechnicalSupports)]);
  yield all([fork(watchGetSinglePortalTechnicalSupport)]);
  yield all([fork(watchAddPortalTechnicalSupport)]);
  yield all([fork(watchEditPortalTechnicalSupport)]);
  yield all([fork(watchDeletePortalTechnicalSupport)]);
}

export default banksSaga;
