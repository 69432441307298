import { getId } from "helpers/functions";
import {
  GET_PRODUCT_TYPES,
  GET_PRODUCT_TYPES_SUCCESS,
  GET_PRODUCT_TYPES_FAILURE,
  ADD_PRODUCT_TYPE,
  ADD_PRODUCT_TYPE_SUCCESS,
  ADD_PRODUCT_TYPE_FAILURE,
  DELETE_PRODUCT_TYPE,
  DELETE_PRODUCT_TYPE_SUCCESS,
  DELETE_PRODUCT_TYPE_FAILURE,
} from "./actionTypes";

const initialState = {
  productTypes: [],
  loading: false,
  error: "",
};

const productTypes = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCT_TYPES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_PRODUCT_TYPES_SUCCESS:
      state = {
        ...state,
        loading: false,
        productTypes: action.payload,
      };

      break;
    case GET_PRODUCT_TYPES_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case ADD_PRODUCT_TYPE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_PRODUCT_TYPE_SUCCESS:
      state = {
        ...state,
        loading: false,
        productTypes: action.payload.productTypes,
      };
      break;
    case ADD_PRODUCT_TYPE_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_PRODUCT_TYPE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_PRODUCT_TYPE_SUCCESS:
      const typesAfterDeleteing = [
        ...state?.productTypes?.filter(
          (productType) => getId(productType) !== action.payload
        ),
      ];
      state = {
        ...state,
        loading: false,
        brands: typesAfterDeleteing,
      };
      break;
    case DELETE_PRODUCT_TYPE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default productTypes;
