import ControlArea from "components/Layout/ControlArea";
import React from "react";
import { useSelector } from "react-redux";
import unitsIcon from "assets/svgs/units.svg";
import Table from "./Table";
const Index = () => {
  const { units } = useSelector((state) => state.measuringUnits) || [];

  return (
    <>
      <div className="stores-wrap">
        <ControlArea
          btnTxt="اضافة وحدة جديدة"
          cardTxt="وحدات القياس"
          icon={unitsIcon}
          url="/units/add"
        />
        <Table data={units} />
      </div>
    </>
  );
};

export default Index;
