import ControlArea from "components/Layout/ControlArea";
import React from "react";
import { useSelector } from "react-redux";
import categoriesIcon from "assets/svgs/categories.svg";
import Table from "./Table";
import { useIntl } from "react-intl";

const HelpBlogs = () => {
  const { formatMessage } = useIntl();
  const { portalHelpBlogs, loading } = useSelector(
    (state) => state.portalHelpBlogs
  );
  return (
    <>
      <div className="stores-wrap settings-page">
        <ControlArea
          btnTxt={formatMessage({ id: "addHelpBlog" })}
          cardTxt={formatMessage({ id: "helpBlogs" })}
          icon={categoriesIcon}
          url="/portal/helpBlogs/add"
        />
        <Table data={portalHelpBlogs} loading={loading} />
      </div>
    </>
  );
};

export default HelpBlogs;
