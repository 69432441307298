import { getId } from "helpers/functions";
import {
  GET_PACKAGES,
  GET_PACKAGES_SUCCESS,
  GET_PACKAGES_FAILURE,
  ADD_PACKAGE,
  ADD_PACKAGE_FAILURE,
  ADD_PACKAGE_SUCCESS,
  DELETE_PACKAGE,
  DELETE_PACKAGE_SUCCESS,
  DELETE_PACKAGE_FAILURE,
  GET_SINGLE_PACKAGE,
  GET_SINGLE_PACKAGE_SUCCESS,
  GET_SINGLE_PACKAGE_FAILURE,
} from "./actionTypes";

const initialState = {
  packages: [],
  loading: false,
  error: "",
  singlePackage: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_PACKAGES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_PACKAGES_SUCCESS:
      state = {
        ...state,
        loading: false,
        packages: action.payload.packages,
      };
      break;
    case GET_PACKAGES_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_PACKAGE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_PACKAGE_SUCCESS:
      state = {
        ...state,
        loading: false,
        singlePackage: action.payload.package,
      };

      break;
    case GET_SINGLE_PACKAGE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        singlePackage: {},
      };
      break;

    case ADD_PACKAGE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_PACKAGE_SUCCESS:
      state = {
        ...state,
        loading: false,
        packages: action.payload.packages,
      };
      break;
    case ADD_PACKAGE_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_PACKAGE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_PACKAGE_SUCCESS:
      const packagesAfterDeleteing = [
        ...state?.packages?.filter(
          (packageItem) => getId(packageItem) !== action.payload
        ),
      ];
      state = {
        ...state,
        loading: false,
        packages: packagesAfterDeleteing,
      };
      break;
    case DELETE_PACKAGE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
