import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addBlogApi,
  deleteBlogApi,
  editBlogApi,
  getBlogsApi,
  getSingleBlogApi,
  getTagsApi,
} from "api/blogs";

// Login Redux States
import {
  ADD_BLOG,
  DELETE_BLOG,
  EDIT_BLOG,
  GET_BLOGS,
  GET_SINGLE_BLOG,
  GET_TAGS,
} from "./actionTypes";
import {
  addBlogFailure,
  addBlogsuccess,
  deleteBlogFailure,
  deleteBlogsuccess,
  editBlogFailure,
  editBlogsuccess,
  getBlogsFailure,
  getBlogsSuccess,
  getSingleBlogFailure,
  getSingleBlogsuccess,
  getTagsFailure,
  getTagsSuccess,
} from "./actions";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";

function* getBlogs() {
  try {
    const { data } = yield call(getBlogsApi);
    yield put(getBlogsSuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });
    yield put(getBlogsFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* addBlog({ payload }) {
  try {
    const { data } = yield call(addBlogApi, payload.data);
    yield put(addBlogsuccess(data));
    yield payload.navigate("/portal/blogs");
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });
    yield put(addBlogFailure(error.response?.data?.message || error));
  }
}

function* getSingleBlog({ payload }) {
  try {
    const { data } = yield call(getSingleBlogApi, payload);
    yield put(getSingleBlogsuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });
    yield put(getSingleBlogFailure(error?.response?.data || error));
  }
}

function* editBlog({ payload }) {
  try {
    yield call(editBlogApi, payload);
    yield put(editBlogsuccess(payload));
    yield payload.navigate("/portal/blogs");
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });
    yield put(editBlogFailure(error?.response?.data || error));
  }
}

function* deleteBlog({ payload }) {
  try {
    yield call(deleteBlogApi, payload);
    yield put(deleteBlogsuccess(payload));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });
    yield put(deleteBlogFailure(error?.response?.data || error));
  }
}

function* getTags() {
  try {
    const { data } = yield call(getTagsApi);
    yield put(getTagsSuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });
    yield put(getTagsFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

export function* watchGetBlogs() {
  yield takeEvery(GET_BLOGS, getBlogs);
}

export function* watchAddBlog() {
  yield takeEvery(ADD_BLOG, addBlog);
}

export function* watchGetSingleBlog() {
  yield takeEvery(GET_SINGLE_BLOG, getSingleBlog);
}

export function* watchEditBlog() {
  yield takeEvery(EDIT_BLOG, editBlog);
}

export function* watchDeleteBlog() {
  yield takeEvery(DELETE_BLOG, deleteBlog);
}

export function* watchGetTags() {
  yield takeEvery(GET_TAGS, getTags);
}

function* blogsSaga() {
  yield all([fork(watchGetBlogs)]);
  yield all([fork(watchAddBlog)]);
  yield all([fork(watchGetSingleBlog)]);
  yield all([fork(watchEditBlog)]);
  yield all([fork(watchDeleteBlog)]);
  yield all([fork(watchGetTags)]);
}

export default blogsSaga;
