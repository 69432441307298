// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
// import "react-quill/dist/quill.core.css";
// import "react-quill/dist/quill.bubble.css";
import "quill/dist/quill.snow.css";
import { useEffect, useState } from "react";
import { useQuill } from "react-quilljs";

// export function Editor({ initialValue, onChange, init }) {
//   useEffect(() => {
//     document.addEventListener("focusin", (e) => {
//       console.log(e);
//     });
//   }, []);
//   return (
//     <ReactQuill
//       key={init?.directionality}
//       defaultValue={initialValue}
//       onChange={(_, __, ___, editor) => {
//         onChange(_, { getContent: () => _ });
//       }}
//       style={{ direction: init?.directionality || "ltr" }}
//       theme={"snow"}
//       modules={{
//         toolbar: [
//           ["bold", "italic", "underline", "strike", "blockquote"],
//           [
//             { list: "ordered" },
//             { list: "bullet" },
//             { list: "check" },
//             { indent: "-1" },
//             { indent: "+1" },
//           ],
//           ["link", "image", "video", "formula"],
//           ["code-block"],
//           [{ align: "" }],
//           [{ align: "center" }],
//           [{ align: "right" }],

//           [{ direction: init?.directionality || "ltr" }],

//           [
//             {
//               color: [
//                 "#000000",
//                 "#ffffff",
//                 "#e60000",
//                 "#ff9900",
//                 "#ffff00",
//                 "#008a00",
//                 "#0066cc",
//                 "#9933ff",
//                 "#facccc",
//                 "#ffebcc",
//                 "#ffffcc",
//                 "#cce8cc",
//                 "#cce0e5",
//                 "#ebd6ff",
//                 "#bbbbbb",
//                 "#f06666",
//                 "#ffc266",
//                 "#ffff66",
//                 "#66b966",
//                 "#66a3e0",
//                 "#c285ff",
//                 "#888888",
//               ],
//             },
//             {
//               background: [
//                 "#000000",
//                 "#ffffff",
//                 "#e60000",
//                 "#ff9900",
//                 "#ffff00",
//                 "#008a00",
//                 "#0066cc",
//                 "#9933ff",
//                 "#facccc",
//                 "#ffebcc",
//                 "#ffffcc",
//                 "#cce8cc",
//                 "#cce0e5",
//                 "#ebd6ff",
//                 "#bbbbbb",
//                 "#f06666",
//                 "#ffc266",
//                 "#ffff66",
//                 "#66b966",
//                 "#66a3e0",
//                 "#c285ff",
//                 "#888888",
//               ],
//             },

//             {
//               font: ["serif", "sans-serif", "monospace"],
//             },
//           ],

//           [{ header: [1, 2, 3, 4, 5, 6, false] }],
//           [{ script: "sub" }, { script: "super" }], // superscript/subscript

//           ["clean"],
//         ],
//       }}
//       // value={this.state.value}
//     />
//   );
// }

export function Editor({ initialValue, onChange, init }) {
  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: [
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { list: "check" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image", "video", "formula"],
        ["code-block"],
        [{ align: "" }],
        [{ align: "center" }],
        [{ align: "right" }],

        [{ direction: "" }, { direction: "rtl" }],

        [
          {
            color: [
              "#000000",
              "#ffffff",
              "#e60000",
              "#ff9900",
              "#ffff00",
              "#008a00",
              "#0066cc",
              "#9933ff",
              "#facccc",
              "#ffebcc",
              "#ffffcc",
              "#cce8cc",
              "#cce0e5",
              "#ebd6ff",
              "#bbbbbb",
              "#f06666",
              "#ffc266",
              "#ffff66",
              "#66b966",
              "#66a3e0",
              "#c285ff",
              "#888888",
            ],
          },
          {
            background: [
              "#000000",
              "#ffffff",
              "#e60000",
              "#ff9900",
              "#ffff00",
              "#008a00",
              "#0066cc",
              "#9933ff",
              "#facccc",
              "#ffebcc",
              "#ffffcc",
              "#cce8cc",
              "#cce0e5",
              "#ebd6ff",
              "#bbbbbb",
              "#f06666",
              "#ffc266",
              "#ffff66",
              "#66b966",
              "#66a3e0",
              "#c285ff",
              "#888888",
            ],
          },

          {
            font: ["serif", "sans-serif", "monospace"],
          },
        ],

        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript

        ["clean"],
      ],
    },
  });

  const [firstLoad, setFirstLoad] = useState(false);

  useEffect(() => {
    if (quill) {
      quill.on("text-change", (delta, oldDelta, source) => {
        // console.log("Text change!");
        // console.log(quill.getText()); // Get text only
        // console.log(quill.getContents()); // Get delta contents
        // console.log(quill.root.innerHTML); // Get innerHTML using quill
        // console.log(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef

        onChange("", { getContent: () => quill.root.innerHTML });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quill, quillRef]);

  useEffect(() => {
    if (
      quill &&
      !!initialValue &&
      `${initialValue}`?.length > 0 &&
      !firstLoad
    ) {
      quill.clipboard.dangerouslyPasteHTML(`${initialValue}` || " ");
      setFirstLoad(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quill, initialValue]);

  return (
    <div className="quill">
      <div ref={quillRef} />
      <style>
        {`
          [data-theme=dark] .quill {
            background: #a6a6a6;
          }
          .ql-editor .ql-align-right {
            direction: rtl;
          }
          .quill .ql-editor span {
            // all:inherit;
            font-size:inherit;
          }
          .quill svg {
            position: unset !important;
            margin-top: 0 !important;
            transform: none;
          }
          .quill .ql-editor {
            max-height: 500px;
            min-height: 150px;
            overflow: auto;
          }
          .quill .ql-picker {
            direction: ltr;
          }
          .ql-editor li:not(.ql-direction-rtl)::before {
            margin-inline-start: -1.5em;
            margin-inline-end: 0.3em;
            text-align: end;
          }
        `}
      </style>
    </div>
  );
}
