import ControlArea from "components/Layout/ControlArea";
import React from "react";
import { useSelector } from "react-redux";
import featuresIcon from "assets/svgs/features.svg";
import Table from "./Table";
import { useIntl } from "react-intl";
const Index = () => {
  const { formatMessage } = useIntl();
  const { features, loading } = useSelector((state) => state.features);
  return (
    <>
      <div className="stores-wrap">
        <ControlArea
          btnTxt={formatMessage({ id: "addFeature" })}
          cardTxt={formatMessage({ id: "features" })}
          icon={featuresIcon}
          url="/portal/features/addfeature"
        />
        <Table data={features} loading={loading} />
      </div>
    </>
  );
};

export default Index;
