import {
  GET_THEMES_COMPONENTS_TYPES,
  GET_THEMES_COMPONENTS_TYPES_SUCCESS,
  GET_THEMES_COMPONENTS_TYPES_FAILURE,
  GET_SINGLE_THEMES_COMPONENTS_TYPE,
  GET_SINGLE_THEMES_COMPONENTS_TYPE_SUCCESS,
  GET_SINGLE_THEMES_COMPONENTS_TYPE_FAILURE,
  ADD_THEMES_COMPONENTS_TYPE,
  ADD_THEMES_COMPONENTS_TYPE_SUCCESS,
  ADD_THEMES_COMPONENTS_TYPE_FAILURE,
  EDIT_THEMES_COMPONENTS_TYPE,
  EDIT_THEMES_COMPONENTS_TYPE_SUCCESS,
  EDIT_THEMES_COMPONENTS_TYPE_FAILURE,
  DELETE_THEMES_COMPONENTS_TYPE,
  DELETE_THEMES_COMPONENTS_TYPE_SUCCESS,
  DELETE_THEMES_COMPONENTS_TYPE_FAILURE,
} from "./actionTypes";

export const getThemesComponentsTypes = (payload) => {
  return {
    type: GET_THEMES_COMPONENTS_TYPES,
    payload,
  };
};

export const getThemesComponentsTypesSuccess = (themes) => {
  return {
    type: GET_THEMES_COMPONENTS_TYPES_SUCCESS,
    payload: themes,
  };
};

export const getThemesComponentsTypesFailure = (error) => {
  return {
    type: GET_THEMES_COMPONENTS_TYPES_FAILURE,
    payload: error,
  };
};

export const getSingleThemesComponentsType = (id) => {
  return {
    type: GET_SINGLE_THEMES_COMPONENTS_TYPE,
    payload: id,
  };
};

export const getSingleThemesComponentsTypeSuccess = (theme) => {
  return {
    type: GET_SINGLE_THEMES_COMPONENTS_TYPE_SUCCESS,
    payload: theme,
  };
};

export const getSingleThemesComponentsTypeFailure = (error) => {
  return {
    type: GET_SINGLE_THEMES_COMPONENTS_TYPE_FAILURE,
    payload: error,
  };
};

export const addThemesComponentsType = (payload) => {
  return {
    type: ADD_THEMES_COMPONENTS_TYPE,
    payload,
  };
};

export const addThemesComponentsTypeSuccess = (payload) => {
  return {
    type: ADD_THEMES_COMPONENTS_TYPE_SUCCESS,
    payload,
  };
};

export const addThemesComponentsTypeFailure = (error) => {
  return {
    type: ADD_THEMES_COMPONENTS_TYPE_FAILURE,
    payload: error,
  };
};

export const editThemesComponentsType = (payload) => {
  return {
    type: EDIT_THEMES_COMPONENTS_TYPE,
    payload,
  };
};

export const editThemesComponentsTypeSuccess = (payload) => {
  return {
    type: EDIT_THEMES_COMPONENTS_TYPE_SUCCESS,
    payload,
  };
};

export const editThemesComponentsTypeFailure = (error) => {
  return {
    type: EDIT_THEMES_COMPONENTS_TYPE_FAILURE,
    payload: error,
  };
};

export const deleteThemesComponentsType = (id) => {
  return {
    type: DELETE_THEMES_COMPONENTS_TYPE,
    payload: id,
  };
};

export const deleteThemesComponentsTypeSuccess = (id) => {
  return {
    type: DELETE_THEMES_COMPONENTS_TYPE_SUCCESS,
    payload: id,
  };
};

export const deleteThemesComponentsTypeFailure = (error) => {
  return {
    type: DELETE_THEMES_COMPONENTS_TYPE_FAILURE,
    payload: error,
  };
};
