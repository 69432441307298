import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  ADD_SECTION,
  DELETE_SECTION,
  EDIT_SECTION,
  GET_ALL_SECTIONS,
  GET_SECTION,
  GET_SECTION_SLUG,
} from "./actionTypes";
import {
  deleteSectionApi,
  addSectionApi,
  editSectionApi,
  getSectionsApi,
  getSectionApi,
  getSectionSlugApi,
} from "api/portalSections";

import {
  addSectionFailure,
  addSectionSuccess,
  deleteSectionFailure,
  deleteSectionSuccess,
  editSectionFailure,
  editSectionSuccess,
  getAllSectionsFailure,
  getAllSectionsSuccess,
  getSectionFailure,
  getSectionSuccess,
} from "./actions";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";

function* getAllSections({ payload }) {
  try {
    const { data } = yield call(getSectionsApi, payload);
    yield put(getAllSectionsSuccess(data));
  } catch (error) {
    yield put(getAllSectionsFailure(error.response.data || error));
    toastErrorMessage({ error: error?.response?.data || error });
  }
}

function* getSection({ payload }) {
  try {
    const { data } = yield call(getSectionApi, payload);
    yield put(getSectionSuccess(data));
  } catch (error) {
    yield put(getSectionFailure(error.response.data || error));
    toastErrorMessage({ error: error?.response?.data || error });
  }
}

function* getSectionSlug({ payload }) {
  try {
    const { data } = yield call(getSectionSlugApi, payload);
    yield put(getSectionSuccess(data));
  } catch (error) {
    yield put(getSectionFailure(error.response.data || error));
    toastErrorMessage({ error: error?.response?.data || error });
  }
}

function* deleteSection({ payload }) {
  try {
    yield call(deleteSectionApi, payload);

    yield put(deleteSectionSuccess(payload));
  } catch (error) {
    yield put(deleteSectionFailure(error.response.data || error));
    toastErrorMessage({ error: error?.response?.data || error });
  }
}

function* addSection({ payload }) {
  try {
    const { data } = yield call(addSectionApi, payload.data);
    yield put(addSectionSuccess(data));
    if (payload?.navigate) yield payload.navigate("/portal/sections");
  } catch (error) {
    console.log(error);
    yield put(addSectionFailure(error.response?.data?.message || error));
  }
}

function* editSection({ payload }) {
  try {
    yield call(editSectionApi, payload);
    yield put(editSectionSuccess(payload));
    if (payload?.navigate) yield payload.navigate("/portal/sections");
  } catch (error) {
    console.log(error);
    yield put(editSectionFailure(error?.response?.data || error));
  }
}

export function* watchDeleteSection() {
  yield takeEvery(DELETE_SECTION, deleteSection);
}

export function* watchGetSection() {
  yield takeEvery(GET_SECTION, getSection);
}

export function* watchGetSectionSlug() {
  yield takeEvery(GET_SECTION_SLUG, getSectionSlug);
}

export function* watchAddSection() {
  yield takeEvery(ADD_SECTION, addSection);
}

export function* watchEditSection() {
  yield takeEvery(EDIT_SECTION, editSection);
}
export function* watchGetAllPageSection() {
  yield takeEvery(GET_ALL_SECTIONS, getAllSections);
}

function* subscribersSaga() {
  yield all([
    fork(watchGetAllPageSection),
    fork(watchGetSectionSlug),
    fork(watchGetSection),
    fork(watchDeleteSection),
    fork(watchAddSection),
    fork(watchEditSection),
  ]);
}

export default subscribersSaga;
