import {
  GET_FONTS,
  GET_FONTS_SUCCESS,
  GET_FONTS_FAILURE,
  GET_SINGLE_FONT,
  GET_SINGLE_FONT_SUCCESS,
  GET_SINGLE_FONT_FAILURE,
  ADD_FONT,
  ADD_FONT_SUCCESS,
  ADD_FONT_FAILURE,
  EDIT_FONT,
  EDIT_FONT_SUCCESS,
  EDIT_FONT_FAILURE,
  DELETE_FONT,
  DELETE_FONT_SUCCESS,
  DELETE_FONT_FAILURE,
} from "./actionTypes";

export const getFonts = (payload) => {
  return {
    type: GET_FONTS,
    payload,
  };
};

export const getFontsSuccess = (fonts) => {
  return {
    type: GET_FONTS_SUCCESS,
    payload: fonts,
  };
};

export const getFontsFailure = (error) => {
  return {
    type: GET_FONTS_FAILURE,
    payload: error,
  };
};

export const getSingleFont = (id) => {
  return {
    type: GET_SINGLE_FONT,
    payload: id,
  };
};

export const getSingleFontSuccess = (font) => {
  return {
    type: GET_SINGLE_FONT_SUCCESS,
    payload: font,
  };
};

export const getSingleFontFailure = (error) => {
  return {
    type: GET_SINGLE_FONT_FAILURE,
    payload: error,
  };
};

export const addFont = (payload) => {
  return {
    type: ADD_FONT,
    payload,
  };
};

export const addFontsuccess = (payload) => {
  return {
    type: ADD_FONT_SUCCESS,
    payload,
  };
};

export const addFontFailure = (error) => {
  return {
    type: ADD_FONT_FAILURE,
    payload: error,
  };
};

export const editFont = (payload) => {
  return {
    type: EDIT_FONT,
    payload,
  };
};

export const editFontsuccess = (payload) => {
  return {
    type: EDIT_FONT_SUCCESS,
    payload,
  };
};

export const editFontFailure = (error) => {
  return {
    type: EDIT_FONT_FAILURE,
    payload: error,
  };
};

export const deleteFont = (id) => {
  return {
    type: DELETE_FONT,
    payload: id,
  };
};

export const deleteFontsuccess = (id) => {
  return {
    type: DELETE_FONT_SUCCESS,
    payload: id,
  };
};

export const deleteFontFailure = (error) => {
  return {
    type: DELETE_FONT_FAILURE,
    payload: error,
  };
};
