import {
  ADD_PORTAL_RESERVATION,
  ADD_PORTAL_RESERVATION_FAILURE,
  ADD_PORTAL_RESERVATION_SUCCESS,
  DELETE_PORTAL_RESERVATION,
  DELETE_PORTAL_RESERVATION_FAILURE,
  DELETE_PORTAL_RESERVATION_SUCCESS,
  EDIT_PORTAL_RESERVATION,
  EDIT_PORTAL_RESERVATION_FAILURE,
  EDIT_PORTAL_RESERVATION_SUCCESS,
  GET_PORTAL_RESERVATIONS,
  GET_PORTAL_RESERVATIONS_FAILURE,
  GET_PORTAL_RESERVATIONS_SUCCESS,
  GET_SINGLE_PORTAL_RESERVATION,
  GET_SINGLE_PORTAL_RESERVATION_FAILURE,
  GET_SINGLE_PORTAL_RESERVATION_SUCCESS,
} from "./actionTypes";

export const getPortalReservations = (payload) => {
  return {
    type: GET_PORTAL_RESERVATIONS,
    payload,
  };
};

export const getPortalReservationsSuccess = (banks) => {
  return {
    type: GET_PORTAL_RESERVATIONS_SUCCESS,
    payload: banks,
  };
};

export const getPortalReservationsFailure = (error) => {
  return {
    type: GET_PORTAL_RESERVATIONS_FAILURE,
    payload: error,
  };
};

export const getSinglePortalReservation = (id) => {
  return {
    type: GET_SINGLE_PORTAL_RESERVATION,
    payload: id,
  };
};

export const getSinglePortalReservationSuccess = (bank) => {
  return {
    type: GET_SINGLE_PORTAL_RESERVATION_SUCCESS,
    payload: bank,
  };
};

export const getSinglePortalReservationFailure = (error) => {
  return {
    type: GET_SINGLE_PORTAL_RESERVATION_FAILURE,
    payload: error,
  };
};

export const addPortalReservation = (payload) => {
  return {
    type: ADD_PORTAL_RESERVATION,
    payload,
  };
};

export const addPortalReservationSuccess = (payload) => {
  return {
    type: ADD_PORTAL_RESERVATION_SUCCESS,
    payload,
  };
};

export const addPortalReservationFailure = (error) => {
  return {
    type: ADD_PORTAL_RESERVATION_FAILURE,
    payload: error,
  };
};

export const editPortalReservation = (payload) => {
  return {
    type: EDIT_PORTAL_RESERVATION,
    payload,
  };
};

export const editPortalReservationSuccess = (payload) => {
  return {
    type: EDIT_PORTAL_RESERVATION_SUCCESS,
    payload,
  };
};

export const editPortalReservationFailure = (error) => {
  return {
    type: EDIT_PORTAL_RESERVATION_FAILURE,
    payload: error,
  };
};

export const deletePortalReservation = (id) => {
  return {
    type: DELETE_PORTAL_RESERVATION,
    payload: id,
  };
};

export const deletePortalReservationSuccess = (id) => {
  return {
    type: DELETE_PORTAL_RESERVATION_SUCCESS,
    payload: id,
  };
};

export const deletePortalReservationFailure = (error) => {
  return {
    type: DELETE_PORTAL_RESERVATION_FAILURE,
    payload: error,
  };
};
