import ControlArea from "components/Layout/ControlArea";
import React from "react";
import { useSelector } from "react-redux";
import currencyIcon from "assets/svgs/currency.svg";
import Table from "./Table";
import BootyPagination from "components/shared/Pagination";
const Index = () => {
  const { currencies, metadata } = useSelector((state) => state.currencies);
  return (
    <>
      <div className="stores-wrap">
        <ControlArea
          btnTxt="اضافة عملة جديدة"
          cardTxt="جميع العملات"
          icon={currencyIcon}
          url="/currencies/add"
        />
        <Table data={currencies} />
        <BootyPagination metaData={metadata} />
      </div>
    </>
  );
};

export default Index;
