export const GET_PRODUCT_TYPES = "GET_PRODUCT_TYPES";
export const GET_PRODUCT_TYPES_SUCCESS = "GET_PRODUCT_TYPES_SUCCESS";
export const GET_PRODUCT_TYPES_FAILURE = "GET_PRODUCT_TYPES_FAILURE";
export const ADD_PRODUCT_TYPE = "ADD_PRODUCT_TYPE";
export const ADD_PRODUCT_TYPE_SUCCESS = "ADD_PRODUCT_TYPE_SUCCESS";
export const ADD_PRODUCT_TYPE_FAILURE = "ADD_PRODUCT_TYPE_FAILURE";
export const EDIT_PRODUCT_TYPE = "EDIT_PRODUCT_TYPE";
export const EDIT_PRODUCT_TYPE_SUCCESS = "EDIT_PRODUCT_TYPE_SUCCESS";
export const EDIT_PRODUCT_TYPE_FAILURE = "EDIT_PRODUCT_TYPE_FAILURE";
export const DELETE_PRODUCT_TYPE = "DELETE_PRODUCT_TYPE";
export const DELETE_PRODUCT_TYPE_SUCCESS = "DELETE_PRODUCT_TYPE_SUCCESS";
export const DELETE_PRODUCT_TYPE_FAILURE = "DELETE_PRODUCT_TYPE_FAILURE";
