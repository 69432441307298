import { getId } from "helpers/functions";
import {
  GET_FONTS,
  GET_FONTS_SUCCESS,
  GET_FONTS_FAILURE,
  GET_SINGLE_FONT,
  GET_SINGLE_FONT_SUCCESS,
  GET_SINGLE_FONT_FAILURE,
  ADD_FONT,
  ADD_FONT_SUCCESS,
  ADD_FONT_FAILURE,
  DELETE_FONT,
  DELETE_FONT_SUCCESS,
  DELETE_FONT_FAILURE,
} from "./actionTypes";

const initialState = {
  fonts: [],
  loading: false,
  error: "",
  singleFont: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_FONTS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_FONTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        fonts: action.payload.fonts,
      };
      break;
    case GET_FONTS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_FONT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_FONT_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleFont: action.payload.font,
      };
      break;
    case GET_SINGLE_FONT_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        singleFont: {},
      };
      break;

    case ADD_FONT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_FONT_SUCCESS:
      state = {
        ...state,
        loading: false,
        fonts: action.payload.fonts,
      };
      break;
    case ADD_FONT_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_FONT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_FONT_SUCCESS:
      const fontsAfterDeleteing = [
        ...state?.fonts?.filter((font) => getId(font) !== action.payload),
      ];
      state = {
        ...state,
        loading: false,
        fonts: fontsAfterDeleteing,
      };
      break;
    case DELETE_FONT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
