import { getId } from "helpers/functions";
import {
  GET_AREAS,
  GET_AREAS_SUCCESS,
  GET_AREAS_FAILURE,
  ADD_AREA,
  ADD_AREA_SUCCESS,
  ADD_AREA_FAILURE,
  DELETE_AREA,
  DELETE_AREA_SUCCESS,
  DELETE_AREA_FAILURE,
  GET_SINGLE_AREA,
  GET_SINGLE_AREA_SUCCESS,
  GET_SINGLE_AREA_FAILURE,
} from "./actionTypes";

const initialState = {
  areas: [],
  loading: false,
  error: "",
  singleArea: {},
};

const brands = (state = initialState, action) => {
  switch (action.type) {
    case GET_AREAS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_AREAS_SUCCESS:
      state = {
        ...state,
        loading: false,
        areas: action.payload.areas,
      };
      break;
    case GET_AREAS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_AREA:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_AREA_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleArea: action.payload.area,
      };
      break;
    case GET_SINGLE_AREA_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        singleArea: {},
      };
      break;

    case ADD_AREA:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_AREA_SUCCESS:
      state = {
        ...state,
        loading: false,
        areas: action.payload.areas,
      };
      break;
    case ADD_AREA_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_AREA:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_AREA_SUCCESS:
      const areasAfterDeleteing = [
        ...state?.areas?.filter((area) => getId(area) !== action.payload),
      ];
      state = {
        ...state,
        loading: false,
        areas: areasAfterDeleteing,
      };
      break;
    case DELETE_AREA_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default brands;
