export const GET_PORTAL_RESERVATIONS = "GET_PORTAL_RESERVATIONS";
export const GET_PORTAL_RESERVATIONS_SUCCESS =
  "GET_PORTAL_RESERVATIONS_SUCCESS";
export const GET_PORTAL_RESERVATIONS_FAILURE =
  "GET_PORTAL_RESERVATIONS_FAILURE";

export const GET_SINGLE_PORTAL_RESERVATION = "GET_SINGLE_PORTAL_RESERVATION";
export const GET_SINGLE_PORTAL_RESERVATION_SUCCESS =
  "GET_SINGLE_PORTAL_RESERVATION_SUCCESS";
export const GET_SINGLE_PORTAL_RESERVATION_FAILURE =
  "GET_SINGLE_PORTAL_RESERVATION_FAILURE";

export const ADD_PORTAL_RESERVATION = "ADD_PORTAL_RESERVATION";
export const ADD_PORTAL_RESERVATION_SUCCESS = "ADD_PORTAL_RESERVATION_SUCCESS";
export const ADD_PORTAL_RESERVATION_FAILURE = "ADD_PORTAL_RESERVATION_FAILURE";

export const EDIT_PORTAL_RESERVATION = "EDIT_PORTAL_RESERVATION";
export const EDIT_PORTAL_RESERVATION_SUCCESS =
  "EDIT_PORTAL_RESERVATION_SUCCESS";
export const EDIT_PORTAL_RESERVATION_FAILURE =
  "EDIT_PORTAL_RESERVATION_FAILURE";

export const DELETE_PORTAL_RESERVATION = "DELETE_PORTAL_RESERVATION";
export const DELETE_PORTAL_RESERVATION_SUCCESS =
  "DELETE_PORTAL_RESERVATION_SUCCESS";
export const DELETE_PORTAL_RESERVATION_FAILURE =
  "DELETE_PORTAL_RESERVATION_FAILURE";
