import { useState } from "react";
import server from "api/server";

import { ReactComponent as Remove } from "assets/svgs/x.svg";
import { ReactComponent as Upload } from "assets/svgs/upload.svg";
import { Col, Row } from "react-bootstrap";
import StickyLoader from "components/shared/StickyLoader";
import { getPublicImage, handleImage } from "helpers/functions";

const MultiUpload = ({
  images,
  setImages,
  pathType = "path",
  lg = "6",
  xl = "4",
  xxl = "4",
  accept = "image/*",
}) => {
  const [loading, setLoading] = useState(false);

  const onImageChange = async (e) => {
    setLoading(true);
    if (e.target.files && e.target.files.length > 0) {
      for (let i = 0; i < e.target.files.length; i++) {
        const formData = new FormData();
        formData.append("file", e.target.files[i]);

        const { data } = await server().post("/upload", formData, {
          headers: {
            uploadsType: "products",
          },
        });

        setImages((oldImages) => [
          ...oldImages,
          {
            preview: e.target.files[i],
            image: getPublicImage(data.data.files[0]),
            isMain: false,
          },
        ]);
      }
      setLoading(false);
    } else setLoading(false);
  };

  const removeImages = (image) => {
    setImages(images.filter((img) => img.image !== image.image));
  };

  return (
    <div className="form-group required">
      <div>
        <label className="form-uploader">
          <input
            type="file"
            multiple
            accept={accept}
            onChange={onImageChange}
            id="filo"
            name="images"
          />

          <span className="txt">
            <Upload fill="#395cf5" />
            اضافة ميديا جديدة للمنتج
          </span>
        </label>
        {images.length > 0 ? (
          <div className="thumbs">
            <Row>
              {[...images]
                ?.sort((a, b) => a?.image?.localeCompare(b?.image))
                ?.map((imageSrc, index) => (
                  <Col
                    xxl={xxl}
                    xl={xl}
                    lg={lg}
                    md={6}
                    sm={6}
                    xs={12}
                    key={index}
                  >
                    <div className="thumb">
                      <button
                        type="button"
                        className="btn-remove"
                        onClick={() => removeImages(imageSrc)}
                      >
                        <Remove fill="#fff" />
                      </button>
                      {
                        <img
                          src={
                            imageSrc.preview
                              ? URL.createObjectURL(imageSrc.preview)
                              : handleImage(imageSrc?.image)
                          }
                          width={100}
                          height={100}
                          alt=""
                        />
                      }
                      <div>
                        <label>
                          <input
                            type="radio"
                            name="mainImage"
                            onChange={(e) => {
                              const images1 = [...images];

                              setImages(() =>
                                images1
                                  ?.sort((a) =>
                                    a?.image === imageSrc?.image ? -1 : 0
                                  )
                                  ?.map((image, i) => ({
                                    ...image,
                                    isMain: i === 0,
                                  }))
                              );
                            }}
                            checked={imageSrc.isMain}
                          />
                          <span>الصورة الرئيسية</span>
                        </label>
                      </div>
                    </div>
                  </Col>
                ))}
            </Row>
            {loading && <StickyLoader fill="#395cf5" />}
          </div>
        ) : (
          <div className="emptyPhotos">
            <Upload fill="#ddd" />
            لا توجد صور لهذا المنتج , يمكنك اضافة صور جديدة !
          </div>
        )}
      </div>
    </div>
  );
};

export default MultiUpload;
