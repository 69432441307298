export const GET_THEMES_PAGES = "GET_THEMES_PAGES";
export const GET_THEMES_PAGES_SUCCESS = "GET_THEMES_PAGES_SUCCESS";
export const GET_THEMES_PAGES_FAILURE = "GET_THEMES_PAGES_FAILURE";
export const GET_SINGLE_THEMES_PAGE = "GET_SINGLE_THEMES_PAGE";
export const GET_SINGLE_THEMES_PAGE_SUCCESS = "GET_SINGLE_THEMES_PAGE_SUCCESS";
export const GET_SINGLE_THEMES_PAGE_FAILURE = "GET_SINGLE_THEMES_PAGE_FAILURE";
export const ADD_THEMES_PAGE = "ADD_THEMES_PAGE";
export const ADD_THEMES_PAGE_SUCCESS = "ADD_THEMES_PAGE_SUCCESS";
export const ADD_THEMES_PAGE_FAILURE = "ADD_THEMES_PAGE_FAILURE";
export const EDIT_THEMES_PAGE = "EDIT_THEMES_PAGE";
export const EDIT_THEMES_PAGE_SUCCESS = "EDIT_THEMES_PAGE_SUCCESS";
export const EDIT_THEMES_PAGE_FAILURE = "EDIT_THEMES_PAGE_FAILURE";
export const DELETE_THEMES_PAGE = "DELETE_THEMES_PAGE";
export const DELETE_THEMES_PAGE_SUCCESS = "DELETE_THEMES_PAGE_SUCCESS";
export const DELETE_THEMES_PAGE_FAILURE = "DELETE_THEMES_PAGE_FAILURE";
