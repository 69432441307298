import server from "./server";

export const getSectionsApi = async () => {
  const response = await server().get("/portalSections");
  return response.data;
};

export const getSectionApi = async (id) => {
  const response = await server().get(`/portalSections/${id}`);
  return response.data;
};

export const getSectionSlugApi = async (id) => {
  const response = await server().get(`/portalSections/getSectionBySlug/${id}`);
  return response.data;
};

export const addSectionApi = async (payload) => {
  const response = await server().post("/portalSections", payload);
  return response.data;
};

export const editSectionApi = async ({ id, data }) => {
  const response = await server().put(`/portalSections/${id}`, data);
  return response.data;
};

export const deleteSectionApi = async (id) => {
  const response = await server().delete(`/portalSections/${id}`);
  return response.data;
};
