import server from "./server";

export const getAllSeoRedirectsApi = async () => {
  const response = await server().get(`/seoRedirects`);

  return response.data;
};

export const addSeoRedirectApi = async (payload) => {
  const response = await server().post("/seoRedirects", payload);
  return response.data;
};

export const editSeoRedirectApi = async ({ id, data }) => {
  const response = await server().put(`/seoRedirects/${id}`, data);
  return response.data;
};

export const deleteSeoRedirectApi = async (id) => {
  const response = await server().delete(`/seoRedirects/${id}`);

  return response.data;
};

export const getSeoRedirectsApi = async (id) => {
  const response = await server().get(`/seoRedirects/${id}`);

  return response.data;
};
