import { combineReducers } from "redux";

// Authentication
import authentication from "./authentication/reducer";
import stores from "./stores/reducer";
import categories from "./categories/reducer";
import fonts from "./fonts/reducer";
import packages from "./packages/reducer";
import users from "./users/reducer";
import features from "./features/reducer";
import paymentMethods from "./paymentMethods/reducer";
import themes from "./themes/reducer";

import components from "./themesComponents/reducer";
import componentsTypes from "./themesComponentsTypes/reducer";
import pages from "./themesPages/reducer";
import templates from "./themesTemplates/reducer";
import templateComponents from "./themesTemplates/components/reducer";

import areas from "./areas/reducer";
import cities from "./cities/reducer";
import countries from "./countries/reducer";
import productTypes from "./productTypes/reducer";
import languages from "./languages/reducer";
import measuringUnits from "./measuringUnits/reducer";
import banks from "./banks/reducer";
import currencies from "./currencies/reducer";
import portalTechnicalSupport from "./portalTechnicalSupport/reducer";
import portalReservations from "./portalReservations/reducer";
import portalHelpCategories from "./portalHelpCategories/reducer";
import portalHelpBlogs from "./portalHelpBlogs/reducer";
import blogs from "./blogs/reducer";
import blogCategories from "./blogCategories/reducer";
import subscribers from "./subscribers/reducer";
import portalFaqs from "./portalFaqs/reducer";
import portalSections from "./portalSections/reducer";
import settings from "./settings/reducer";
import permissionsData from "./permissionsData/reducer";
import seoRedirects from "./seoRedirects/reducer";

const rootReducer = combineReducers({
  authentication,
  stores,
  categories,
  fonts,
  packages,
  users,
  features,
  paymentMethods,
  themes,

  components,
  componentsTypes,
  pages,
  templates,
  templateComponents,

  areas,
  cities,
  countries,
  productTypes,
  languages,
  measuringUnits,
  banks,
  currencies,
  portalTechnicalSupport,
  portalReservations,
  portalHelpCategories,
  portalHelpBlogs,
  blogs,
  blogCategories,
  subscribers,
  portalFaqs,
  portalSections,
  settings,
  permissionsData,
  seoRedirects,
});

export default rootReducer;
