import BlocksTop from "../components/Home/BlocksTop";

function Dashboard() {
  return (
    <>
      <BlocksTop />
    </>
  );
}

export default Dashboard;
