import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addPortalHelpCategoryApi,
  deletePortalHelpCategoryApi,
  editPortalHelpCategoryApi,
  getPortalHelpCategoriesApi,
  getSinglePortalHelpCategoryApi,
} from "api/portalHelpCategories";

// Login Redux States
import {
  ADD_PORTAL_HELP_CATEGORY,
  DELETE_PORTAL_HELP_CATEGORY,
  EDIT_PORTAL_HELP_CATEGORY,
  GET_PORTAL_HELP_CATEGORIES,
  GET_SINGLE_PORTAL_HELP_CATEGORY,
} from "./actionTypes";

import {
  addPortalHelpCategoryFailure,
  addPortalHelpCategorySuccess,
  deletePortalHelpCategoryFailure,
  deletePortalHelpCategorySuccess,
  editPortalHelpCategoryFailure,
  editPortalHelpCategorySuccess,
  getPortalHelpCategoriesFailure,
  getPortalHelpCategoriesSuccess,
  getSinglePortalHelpCategoryFailure,
  getSinglePortalHelpCategorySuccess,
} from "./actions";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";

function* getPortalHelpCategories({ payload }) {
  try {
    const { data } = yield call(getPortalHelpCategoriesApi, payload);
    yield put(getPortalHelpCategoriesSuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(
      getPortalHelpCategoriesFailure(error?.response?.data?.errors?.[0]?.msg)
    );
  }
}

function* addPortalHelpCategory({ payload }) {
  try {
    const { data } = yield call(addPortalHelpCategoryApi, payload.data);
    yield put(addPortalHelpCategorySuccess(data));
    yield payload.navigate("/portal/helpCategories");
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(
      addPortalHelpCategoryFailure(error.response?.data?.message || error)
    );
  }
}

function* getSinglePortalHelpCategory({ payload }) {
  try {
    const { data } = yield call(getSinglePortalHelpCategoryApi, payload);
    yield put(getSinglePortalHelpCategorySuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(
      getSinglePortalHelpCategoryFailure(error?.response?.data || error)
    );
  }
}

function* editPortalHelpCategor({ payload }) {
  try {
    yield call(editPortalHelpCategoryApi, payload);
    yield put(editPortalHelpCategorySuccess(payload));
    yield payload.navigate("/portal/helpCategories");
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(editPortalHelpCategoryFailure(error?.response?.data || error));
  }
}

function* deletePortalHelpCategory({ payload }) {
  try {
    yield call(deletePortalHelpCategoryApi, payload?.id);
    yield put(deletePortalHelpCategorySuccess(payload));
    yield getPortalHelpCategories({ payload: payload?.paramsStr || "" });
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(deletePortalHelpCategoryFailure(error?.response?.data || error));
  }
}

export function* watchGetPortalHelpCategors() {
  yield takeEvery(GET_PORTAL_HELP_CATEGORIES, getPortalHelpCategories);
}

export function* watchAddPortalHelpCategor() {
  yield takeEvery(ADD_PORTAL_HELP_CATEGORY, addPortalHelpCategory);
}

export function* watchGetSinglePortalHelpCategory() {
  yield takeEvery(GET_SINGLE_PORTAL_HELP_CATEGORY, getSinglePortalHelpCategory);
}

export function* watchEditPortalHelpCategor() {
  yield takeEvery(EDIT_PORTAL_HELP_CATEGORY, editPortalHelpCategor);
}

export function* watchDeletePortalHelpCategor() {
  yield takeEvery(DELETE_PORTAL_HELP_CATEGORY, deletePortalHelpCategory);
}

function* banksSaga() {
  yield all([fork(watchGetPortalHelpCategors)]);
  yield all([fork(watchGetSinglePortalHelpCategory)]);
  yield all([fork(watchAddPortalHelpCategor)]);
  yield all([fork(watchEditPortalHelpCategor)]);
  yield all([fork(watchDeletePortalHelpCategor)]);
}

export default banksSaga;
