import {
  GET_THEMES_COMPONENTS,
  GET_THEMES_COMPONENTS_SUCCESS,
  GET_THEMES_COMPONENTS_FAILURE,
  GET_SINGLE_THEMES_COMPONENT,
  GET_SINGLE_THEMES_COMPONENT_SUCCESS,
  GET_SINGLE_THEMES_COMPONENT_FAILURE,
  ADD_THEMES_COMPONENT,
  ADD_THEMES_COMPONENT_SUCCESS,
  ADD_THEMES_COMPONENT_FAILURE,
  EDIT_THEMES_COMPONENT,
  EDIT_THEMES_COMPONENT_SUCCESS,
  EDIT_THEMES_COMPONENT_FAILURE,
  DELETE_THEMES_COMPONENT,
  DELETE_THEMES_COMPONENT_SUCCESS,
  DELETE_THEMES_COMPONENT_FAILURE,
} from "./actionTypes";

export const getThemeComponents = (payload) => {
  return {
    type: GET_THEMES_COMPONENTS,
    payload,
  };
};

export const getThemesComponentsSuccess = (themes) => {
  return {
    type: GET_THEMES_COMPONENTS_SUCCESS,
    payload: themes,
  };
};

export const getThemesComponentsFailure = (error) => {
  return {
    type: GET_THEMES_COMPONENTS_FAILURE,
    payload: error,
  };
};

export const getSingleThemesComponent = (id) => {
  return {
    type: GET_SINGLE_THEMES_COMPONENT,
    payload: id,
  };
};

export const getSingleThemesComponentSuccess = (theme) => {
  return {
    type: GET_SINGLE_THEMES_COMPONENT_SUCCESS,
    payload: theme,
  };
};

export const getSingleThemesComponentFailure = (error) => {
  return {
    type: GET_SINGLE_THEMES_COMPONENT_FAILURE,
    payload: error,
  };
};

export const addThemesComponent = (payload) => {
  return {
    type: ADD_THEMES_COMPONENT,
    payload,
  };
};

export const addThemesComponentSuccess = (payload) => {
  return {
    type: ADD_THEMES_COMPONENT_SUCCESS,
    payload,
  };
};

export const addThemesComponentFailure = (error) => {
  return {
    type: ADD_THEMES_COMPONENT_FAILURE,
    payload: error,
  };
};

export const editThemesComponent = (payload) => {
  return {
    type: EDIT_THEMES_COMPONENT,
    payload,
  };
};

export const editThemesComponentSuccess = (payload) => {
  return {
    type: EDIT_THEMES_COMPONENT_SUCCESS,
    payload,
  };
};

export const editThemesComponentFailure = (error) => {
  return {
    type: EDIT_THEMES_COMPONENT_FAILURE,
    payload: error,
  };
};

export const deleteThemesComponent = (id) => {
  return {
    type: DELETE_THEMES_COMPONENT,
    payload: id,
  };
};

export const deleteThemesComponentSuccess = (id) => {
  return {
    type: DELETE_THEMES_COMPONENT_SUCCESS,
    payload: id,
  };
};

export const deleteThemesComponentFailure = (error) => {
  return {
    type: DELETE_THEMES_COMPONENT_FAILURE,
    payload: error,
  };
};
