import React from "react";
import removeImg from "assets/svgs/close-icon.svg";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MultiLangsWrapper from "components/shared/MultiLangsWrapper";
// import MultiLangWrapper from "components/shared/MultiLangsWrapper";

const SlidersArray = ({ sliders, setSliders }) => {
  const handleInputOnChange = (e, id) => {
    const { name, value } = e.target;
    const newSlidersArray = sliders.map((slider) => {
      if (slider?.id !== id) return slider;

      // make an object with locals inside the big one
      const inputName = name?.split(".")?.[0];
      const local = name?.split(".")?.[1];

      if (local) {
        return {
          ...slider,
          [inputName]: {
            ...slider?.[inputName],
            [local]: value,
          },
        };
      }
      return { ...slider, [name]: value };
    });

    setSliders(newSlidersArray);
  };

  const removeSlider = (sliderId) => {
    const newSliders = sliders.filter(
      (slider, index) => slider?.id !== sliderId
    );
    setSliders(() => newSliders);
  };

  const addSlider = () => {
    // console.log("[...sliders, {}]", [...sliders, {}]);
    setSliders(() => [...sliders, { id: sliders?.length }]);
  };

  return (
    <div className="form-group branches sliders">
      <Row>
        <Col lg={12}>
          <h5>
            <FormattedMessage id={"sliders"} />
          </h5>

          {sliders.map((slider, index) => {
            return (
              <>
                {/* <input
                  key={index}
                  type="checkbox"
                  id={`checkbox${index}`}
                  defaultChecked={index === 0}
                  className="hide-show"
                /> */}
                <label
                  htmlFor={slider?.id}
                  key={slider?.id}
                  className="add-multi-component w-100 cursor-pointer"
                >
                  <button
                    type="button"
                    onClick={() => removeSlider(slider?.id)}
                    className="removeImg"
                  >
                    <img src={removeImg} alt="" />
                  </button>
                  <Row>
                    <Col lg={12} className="check-show">
                      <h5>
                        <FormattedMessage id="page" /> :
                        <FormattedMessage id={slider?.slug || " "} />
                      </h5>
                    </Col>
                    <Col xs="12" md="6" key={index} className="check-show">
                      <div className="form-group">
                        <h5 className="mb-1">
                          <FormattedMessage id={"pageName"} />
                          <p className="small" style={{ color: "inherit" }}>
                            *<FormattedMessage id="dontChange" />
                          </p>
                        </h5>
                        <input
                          type="text"
                          name={`pageName`}
                          className={`form-control form-outline`}
                          onChange={(e) => handleInputOnChange(e, index)}
                          value={slider?.pageName}
                        />
                      </div>
                    </Col>
                    <Col xs="12" md="6" key={index} className="check-show">
                      <Row>
                        <MultiLangsWrapper
                          label={
                            <label>
                              <FormattedMessage id={"title"} />
                            </label>
                          }
                          customClass="form-group selectHolder col-12"
                        >
                          {({ lang, classes, key }) => (
                            <input
                              key={`${index}${key}`}
                              type="text"
                              name={`title.${lang}`}
                              className={`form-control form-outline ${classes}`}
                              onChange={(e) => handleInputOnChange(e, index)}
                              value={slider?.title?.[lang]}
                            />
                          )}
                        </MultiLangsWrapper>
                      </Row>
                    </Col>{" "}
                    {/* -------------------------- */}
                    <Col xs="12" md="6" key={index} className="check-show">
                      <Row>
                        <MultiLangsWrapper
                          label={
                            <label>
                              <FormattedMessage id={"description"} />
                            </label>
                          }
                          customClass="form-group selectHolder col-12"
                        >
                          {({ lang, classes, key }) => (
                            <textarea
                              key={`${index}${key}`}
                              type="text"
                              name={`description.${lang}`}
                              style={{ height: "auto" }}
                              className={`form-control form-outline ${classes}`}
                              onChange={(e) => handleInputOnChange(e, index)}
                              value={slider?.description?.[lang]}
                            />
                          )}
                        </MultiLangsWrapper>
                      </Row>
                    </Col>
                    {/* -------------------------- */}
                    <Col xs="12" md="6" key={index} className="check-show">
                      <Row>
                        <MultiLangsWrapper
                          label={
                            <label>
                              <FormattedMessage id={"keywords"} />
                            </label>
                          }
                          customClass="form-group selectHolder col-12"
                        >
                          {({ lang, classes, key }) => (
                            <input
                              key={`${index}${key}`}
                              type="text"
                              name={`keywords.${lang}`}
                              className={`form-control form-outline ${classes}`}
                              onChange={(e) => handleInputOnChange(e, index)}
                              value={slider?.keywords?.[lang]}
                            />
                          )}
                        </MultiLangsWrapper>
                      </Row>
                    </Col>
                    <Col xs="12" md="6" key={index} className="check-show">
                      <div className="form-group">
                        <h5 className="mb-1">
                          <FormattedMessage id={"canonical"} />
                        </h5>
                        <input
                          type="text"
                          name={`canonical`}
                          className={`form-control form-outline`}
                          onChange={(e) => handleInputOnChange(e, index)}
                          value={slider?.canonical}
                        />
                      </div>
                    </Col>
                  </Row>
                </label>
              </>
            );
          })}
        </Col>

        <Col lg={4} xs={12} className="text-left mt-2">
          <button
            type="button"
            className="btn btn-green add_new_section__ mt-2"
            onClick={() => {
              addSlider();
            }}
          >
            <FormattedMessage id={"addSlider"} />
          </button>
        </Col>
      </Row>
    </div>
  );
};

export default SlidersArray;
