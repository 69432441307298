import { getId } from "helpers/functions";
import {
  ADD_PORTAL_TECHNICAL_SUPPORT,
  ADD_PORTAL_TECHNICAL_SUPPORT_FAILURE,
  ADD_PORTAL_TECHNICAL_SUPPORT_SUCCESS,
  DELETE_PORTAL_TECHNICAL_SUPPORT,
  DELETE_PORTAL_TECHNICAL_SUPPORT_FAILURE,
  DELETE_PORTAL_TECHNICAL_SUPPORT_SUCCESS,
  GET_PORTAL_TECHNICAL_SUPPORTS,
  GET_PORTAL_TECHNICAL_SUPPORTS_FAILURE,
  GET_PORTAL_TECHNICAL_SUPPORTS_SUCCESS,
  GET_SINGLE_PORTAL_TECHNICAL_SUPPORT,
  GET_SINGLE_PORTAL_TECHNICAL_SUPPORT_FAILURE,
  GET_SINGLE_PORTAL_TECHNICAL_SUPPORT_SUCCESS,
} from "./actionTypes";

const initialState = {
  portalTechnicalSupports: [],
  loading: false,
  error: "",
  singlePortalTechnicalSupport: {},
};

const portalTechnicalSupports = (state = initialState, action) => {
  switch (action.type) {
    case GET_PORTAL_TECHNICAL_SUPPORTS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_PORTAL_TECHNICAL_SUPPORTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        portalTechnicalSupports: action.payload.technicalSupports,
      };
      break;
    case GET_PORTAL_TECHNICAL_SUPPORTS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_PORTAL_TECHNICAL_SUPPORT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_PORTAL_TECHNICAL_SUPPORT_SUCCESS:
      state = {
        ...state,
        loading: false,
        singlePortalTechnicalSupport: action.payload.technicalSupport,
      };
      break;
    case GET_SINGLE_PORTAL_TECHNICAL_SUPPORT_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        singlePortalTechnicalSupport: {},
      };
      break;

    case ADD_PORTAL_TECHNICAL_SUPPORT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_PORTAL_TECHNICAL_SUPPORT_SUCCESS:
      state = {
        ...state,
        loading: false,
        portalTechnicalSupports: action.payload.technicalSupports,
      };
      break;
    case ADD_PORTAL_TECHNICAL_SUPPORT_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_PORTAL_TECHNICAL_SUPPORT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_PORTAL_TECHNICAL_SUPPORT_SUCCESS:
      const portalTechnicalSupportsAfterDeleteing = [
        ...state?.portalTechnicalSupports?.filter(
          (portalTechnicalSupport) =>
            getId(portalTechnicalSupport) !== action.payload
        ),
      ];
      state = {
        ...state,
        loading: false,
        portalTechnicalSupports: portalTechnicalSupportsAfterDeleteing,
      };
      break;
    case DELETE_PORTAL_TECHNICAL_SUPPORT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default portalTechnicalSupports;
