import React, { useEffect, useMemo, useState } from "react";
import removeImg from "assets/svgs/close-icon.svg";
import { v4 } from "uuid";
import ImageUpdate from "./ImageUpdate";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Editor } from "components/shared/Editor";

const SlidersArray = ({ sliders, setSliders, editPageFlag = false }) => {
  const { formatMessage } = useIntl();
  const [shownInputs, setShownInputs] = useState([]);

  const inputsNames = useMemo(
    () => [
      "icon",
      "image",
      "video",
      "secondImage",
      "title",
      "subTitle",
      "name",
      "job",
      "link",
      "description",
      "content",
    ],
    []
  );

  useEffect(() => {
    if (shownInputs?.length > 0) return;
    const namesHasValue = [],
      firstSlide = sliders?.[0];
    inputsNames?.map((name) => {
      firstSlide?.[name] !== undefined && namesHasValue.push(name);
    });

    setShownInputs(namesHasValue);
  }, [inputsNames, shownInputs?.length, sliders]);

  const handleInputOnChange = (e, id) => {
    const { name, value } = e.target;
    const newSlidersArray = sliders.map((slider) => {
      if (slider.id !== id) return slider;

      // make an object with locals inside the big one
      const inputName = name?.split(".")?.[0];
      const local = name?.split(".")?.[1];

      if (local) {
        return {
          ...slider,
          [inputName]: {
            ...slider?.[inputName],
            [local]: value,
          },
        };
      }

      return { ...slider, [name]: value };
    });

    setSliders(newSlidersArray);
  };

  const removeSlider = (sliderId) => {
    const newSliders = sliders.filter(
      (slider, index) => slider.id !== sliderId
    );
    setSliders(newSliders);
  };

  const addSlider = () => {
    setSliders([
      ...sliders,
      { id: v4(), title: {}, description: {}, icon: "" },
    ]);
  };

  const handleShownInputs = (e) => {
    const inputName = e?.target?.getAttribute("data-name");
    shownInputs?.includes(inputName)
      ? setShownInputs(shownInputs?.filter((name) => name !== inputName))
      : setShownInputs([...shownInputs, inputName]);
  };
  const checkInputShown = (name) => {
    return shownInputs?.includes(name) ? null : "d-none";
  };

  const checkboxInput = (name) => (
    <input
      type="checkbox"
      checked={shownInputs?.includes(name)}
      onChange={handleShownInputs}
      data-name={name}
      className="me-1"
      title={formatMessage({ id: "displayThisInput" })}
    />
  );
  const renderInputsNames = inputsNames?.map((name, index) => (
    <Col key={index}>
      <label className="d-flex bg-secondary bg-opacity-50 p-1 rounded justify-content-between align-items-center w-100 h-100">
        {checkboxInput(name)}
        <h5 className="mb-1 text-center flex-fill">
          <FormattedMessage id={name} />
        </h5>
      </label>
    </Col>
  ));

  return (
    <div className="form-group branches sliders">
      <Row>
        {sliders?.length > 0 && !editPageFlag && (
          <Col lg={2}>
            <div className="filter-toolbar p-2 py-3">
              <Row className="gap-2">
                <h5 className="text-center">
                  <FormattedMessage id="controlShownInputs" />
                </h5>
                {renderInputsNames}
              </Row>
            </div>
          </Col>
        )}
        <Col>
          <h5>
            <FormattedMessage id={"sliders"} />
          </h5>

          {(sliders || []).map((slider, index) => (
            <div key={index} className="add-multi-component">
              <button
                type="button"
                onClick={() => removeSlider(slider.id)}
                className="removeImg"
              >
                <img src={removeImg} alt="" />
              </button>
              <Row>
                <Col lg={12} className={checkInputShown("icon")}>
                  {/* {checkboxInput("icon")} */}
                  <ImageUpdate
                    item={slider}
                    sliders={sliders}
                    setSliders={setSliders}
                    name="icon"
                  />
                </Col>
                <Col lg={12} className={checkInputShown("video")}>
                  {/* {checkboxInput("icon")} */}
                  <ImageUpdate
                    item={slider}
                    sliders={sliders}
                    setSliders={setSliders}
                    name="video"
                  />
                </Col>
                <Col lg={12} className={checkInputShown("image")}>
                  {/* {checkboxInput("image")} */}
                  <ImageUpdate
                    item={slider}
                    sliders={sliders}
                    setSliders={setSliders}
                    name="image"
                  />
                </Col>
                <Col lg={12} className={checkInputShown("secondImage")}>
                  {/* {checkboxInput("secondImage")} */}
                  <ImageUpdate
                    item={slider}
                    sliders={sliders}
                    setSliders={setSliders}
                    name="secondImage"
                  />
                </Col>
                <Col lg={6} xs={12} className={checkInputShown("name")}>
                  <div className="form-group">
                    <h5>
                      {/* {checkboxInput("name")} */}
                      <FormattedMessage id={"name"} />
                    </h5>
                    <input
                      type="text"
                      name="name"
                      className="form-control form-outline"
                      onChange={(e) => handleInputOnChange(e, slider.id)}
                      value={slider?.name}
                    />
                  </div>
                </Col>
                <Col lg={6} xs={12} className={checkInputShown("job")}>
                  <div className="form-group">
                    <h5>
                      {/* {checkboxInput("job")} */}
                      <FormattedMessage id={"job"} />
                    </h5>
                    <input
                      type="text"
                      name="job"
                      className="form-control form-outline"
                      onChange={(e) => handleInputOnChange(e, slider.id)}
                      value={slider?.job}
                    />
                  </div>
                </Col>

                <Col lg={6} xs={12} className={checkInputShown("title")}>
                  <div className="form-group">
                    <h5>
                      {/* {checkboxInput("title")} */}
                      <FormattedMessage id={"title"} /> (
                      <FormattedMessage id={"arabic"} /> )
                    </h5>
                    <input
                      type="text"
                      name="title.ar"
                      className="form-control form-outline"
                      onChange={(e) => handleInputOnChange(e, slider.id)}
                      value={slider?.title?.ar}
                    />
                  </div>
                </Col>
                <Col lg={6} xs={12} className={checkInputShown("title")}>
                  <div className="form-group">
                    <h5>
                      {/* {checkboxInput("title")} */}
                      <FormattedMessage id={"title"} /> (
                      <FormattedMessage id={"english"} /> )
                    </h5>
                    <input
                      type="text"
                      name="title.en"
                      className="form-control form-outline"
                      onChange={(e) => handleInputOnChange(e, slider.id)}
                      value={slider?.title?.en}
                    />
                  </div>
                </Col>
                <Col lg={6} xs={12} className={checkInputShown("subTitle")}>
                  <div className="form-group">
                    <h5>
                      {/* {checkboxInput("subTitle")} */}
                      <FormattedMessage id={"subTitle"} /> (
                      <FormattedMessage id={"arabic"} /> )
                    </h5>
                    <input
                      type="text"
                      name="subTitle.ar"
                      className="form-control form-outline"
                      onChange={(e) => handleInputOnChange(e, slider.id)}
                      value={slider?.subTitle?.ar}
                    />
                  </div>
                </Col>
                <Col lg={6} xs={12} className={checkInputShown("subTitle")}>
                  <div className="form-group">
                    <h5>
                      {/* {checkboxInput("subTitle")} */}
                      <FormattedMessage id={"subTitle"} /> (
                      <FormattedMessage id={"english"} /> )
                    </h5>
                    <input
                      type="text"
                      name="subTitle.en"
                      className="form-control form-outline"
                      onChange={(e) => handleInputOnChange(e, slider.id)}
                      value={slider?.subTitle?.en}
                    />
                  </div>
                </Col>

                <Col lg={6} xs={12} className={checkInputShown("description")}>
                  <div className="form-group">
                    <h5>
                      {/* {checkboxInput("description")} */}
                      <FormattedMessage id={"description"} /> (
                      <FormattedMessage id={"arabic"} /> )
                    </h5>

                    <textarea
                      className="form-control form-outline"
                      name="description.ar"
                      onChange={(e) => handleInputOnChange(e, slider.id)}
                      value={slider?.description?.ar}
                    ></textarea>
                    <input type="hidden" value={v4()} />
                  </div>
                </Col>
                <Col lg={6} xs={12} className={checkInputShown("description")}>
                  <div className="form-group">
                    <h5>
                      {/* {checkboxInput("description")} */}
                      <FormattedMessage id={"description"} /> (
                      <FormattedMessage id={"english"} /> )
                    </h5>

                    <textarea
                      className="form-control form-outline"
                      name="description.en"
                      onChange={(e) => handleInputOnChange(e, slider.id)}
                      value={slider?.description?.en}
                    ></textarea>
                    <input type="hidden" value={v4()} />
                  </div>
                </Col>

                <Col lg={6} xs={12} className={checkInputShown("content")}>
                  <div className="form-group">
                    <h5>
                      {/* {checkboxInput("content")} */}
                      <FormattedMessage id={"content"} /> (
                      <FormattedMessage id={"arabic"} /> )
                    </h5>
                    <Editor
                      key={index}
                      initialValue={slider?.content?.ar}
                      name="content.ar"
                      onChange={(_, data) =>
                        handleInputOnChange(
                          {
                            target: {
                              value: data?.getContent(),
                              name: "content.ar",
                            },
                          },
                          slider.id
                        )
                      }
                    />

                    <input type="hidden" value={v4()} />
                  </div>
                </Col>
                <Col lg={6} xs={12} className={checkInputShown("content")}>
                  <div className="form-group">
                    <h5>
                      {/* {checkboxInput("content")} */}
                      <FormattedMessage id={"content"} /> (
                      <FormattedMessage id={"english"} /> )
                    </h5>
                    <Editor
                      key={index}
                      initialValue={slider?.content?.en}
                      name="content.en"
                      onChange={(_, data) =>
                        handleInputOnChange(
                          {
                            target: {
                              value: data?.getContent(),
                              name: "content.en",
                            },
                          },
                          slider.id
                        )
                      }
                    />

                    <input type="hidden" value={v4()} />
                  </div>
                </Col>

                <Col lg={6} xs={12} className={checkInputShown("link")}>
                  <div className="form-group">
                    <h5>
                      {/* {checkboxInput("link")} */}
                      <FormattedMessage id={"link"} />
                    </h5>
                    <input
                      type="text"
                      name="link"
                      className="form-control form-outline"
                      onChange={(e) => handleInputOnChange(e, slider.id)}
                      value={slider?.link}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          ))}
        </Col>
        <Row />
        <Col lg={4} xs={12} className="text-left">
          <button
            type="button"
            className="btn btn-green add_new_section__"
            onClick={() => {
              addSlider();
            }}
          >
            <FormattedMessage id={"addSlider"} />
          </button>
        </Col>
      </Row>
    </div>
  );
};

export default SlidersArray;
