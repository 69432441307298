import { getId } from "helpers/functions";
import {
  GET_CITIES,
  GET_CITIES_SUCCESS,
  GET_CITIES_FAILURE,
  ADD_CITY,
  ADD_CITY_SUCCESS,
  ADD_CITY_FAILURE,
  DELETE_CITY,
  DELETE_CITY_SUCCESS,
  DELETE_CITY_FAILURE,
  GET_SINGLE_CITY,
  GET_SINGLE_CITY_SUCCESS,
  GET_SINGLE_CITY_FAILURE,
} from "./actionTypes";

const initialState = {
  cities: [],
  loading: false,
  error: "",
  singleCity: {},
};

const brands = (state = initialState, action) => {
  switch (action.type) {
    case GET_CITIES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_CITIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        cities: action.payload.cities,
      };
      break;
    case GET_CITIES_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_CITY:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_SINGLE_CITY_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleCity: action.payload.city,
      };
      break;

    case GET_SINGLE_CITY_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        singleCity: {},
      };
      break;

    case ADD_CITY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_CITY_SUCCESS:
      state = {
        ...state,
        loading: false,
        cities: action.payload.cities,
      };
      break;
    case ADD_CITY_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_CITY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_CITY_SUCCESS:
      const citiesAfterDeleteing = [
        ...state?.cities?.filter((city) => getId(city) !== action.payload),
      ];
      state = {
        ...state,
        loading: false,
        cities: citiesAfterDeleteing,
      };
      break;
    case DELETE_CITY_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default brands;
