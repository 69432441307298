export const GET_PORTAL_FAQS = "GET_PORTAL_FAQS";
export const GET_PORTAL_FAQS_SUCCESS = "GET_PORTAL_FAQS_SUCCESS";
export const GET_PORTAL_FAQS_FAILURE = "GET_PORTAL_FAQS_FAILURE";

export const GET_SINGLE_PORTAL_FAQ = "GET_SINGLE_PORTAL_FAQ";
export const GET_SINGLE_PORTAL_FAQ_SUCCESS = "GET_SINGLE_PORTAL_FAQ_SUCCESS";
export const GET_SINGLE_PORTAL_FAQ_FAILURE = "GET_SINGLE_PORTAL_FAQ_FAILURE";

export const ADD_PORTAL_FAQ = "ADD_PORTAL_FAQ";
export const ADD_PORTAL_FAQ_SUCCESS = "ADD_PORTAL_FAQ_SUCCESS";
export const ADD_PORTAL_FAQ_FAILURE = "ADD_PORTAL_FAQ_FAILURE";

export const EDIT_PORTAL_FAQ = "EDIT_PORTAL_FAQ";
export const EDIT_PORTAL_FAQ_SUCCESS = "EDIT_PORTAL_FAQ_SUCCESS";
export const EDIT_PORTAL_FAQ_FAILURE = "EDIT_PORTAL_FAQ_FAILURE";

export const DELETE_PORTAL_FAQ = "DELETE_PORTAL_FAQ";
export const DELETE_PORTAL_FAQ_SUCCESS = "DELETE_PORTAL_FAQ_SUCCESS";
export const DELETE_PORTAL_FAQ_FAILURE = "DELETE_PORTAL_FAQ_FAILURE";
