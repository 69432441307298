import { getId } from "helpers/functions";
import {
  GET_THEMES_PAGES,
  GET_THEMES_PAGES_SUCCESS,
  GET_THEMES_PAGES_FAILURE,
  ADD_THEMES_PAGE,
  ADD_THEMES_PAGE_SUCCESS,
  ADD_THEMES_PAGE_FAILURE,
  DELETE_THEMES_PAGE,
  DELETE_THEMES_PAGE_SUCCESS,
  DELETE_THEMES_PAGE_FAILURE,
  GET_SINGLE_THEMES_PAGE,
  GET_SINGLE_THEMES_PAGE_SUCCESS,
  GET_SINGLE_THEMES_PAGE_FAILURE,
} from "./actionTypes";

const initialState = {
  pages: [],
  loading: false,
  error: "",
  singlePage: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_THEMES_PAGES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_THEMES_PAGES_SUCCESS:
      state = {
        ...state,
        loading: false,
        pages: action.payload.themePages,
      };
      break;
    case GET_THEMES_PAGES_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_THEMES_PAGE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_THEMES_PAGE_SUCCESS:
      state = {
        ...state,
        loading: false,
        singlePage: action.payload.themePage,
      };

      break;
    case GET_SINGLE_THEMES_PAGE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        singlePage: {},
      };
      break;

    case ADD_THEMES_PAGE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_THEMES_PAGE_SUCCESS:
      state = {
        ...state,
        loading: false,
        pages: action.payload.pages,
      };
      break;
    case ADD_THEMES_PAGE_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_THEMES_PAGE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_THEMES_PAGE_SUCCESS:
      const themesAfterDeleteing = [
        ...state?.pages?.filter((theme) => getId(theme) !== action.payload),
      ];
      state = {
        ...state,
        loading: false,
        pages: themesAfterDeleteing,
      };
      break;
    case DELETE_THEMES_PAGE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
