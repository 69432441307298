import server from "./server";

export const getThemesApi = async (deviceType) => {
  const response = await server().get("/themeTemplate", {
    headers: {
      deviceType: deviceType,
    },
  });
  return response.data;
};

export const getSingleThemeApi = async (id) => {
  const response = await server().get(`/themeTemplate/${id}`);
  return response.data;
};

export const addThemeApi = async (payload) => {
  const response = await server().post("/themeTemplate", payload?.data, {
    headers: {
      deviceType: payload?.deviceType,
    },
  });
  return response.data;
};

export const editThemeApi = async ({ id, data }) => {
  const response = await server().put(`/themeTemplate/${id}`, data);
  return response.data;
};

export const deleteThemeApi = async (id) => {
  const response = await server().delete(`/themeTemplate/${id}`);
  return response.data;
};
