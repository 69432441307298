import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { addCurrency } from "store/actions";
import { useNavigate } from "react-router-dom";
import server from "api/server";
import imageUpload from "assets/svgs/imageUpload.svg";
import { getPublicImage } from "helpers/functions";
const AddCurrency = () => {
  const [selectedImage, setSelectedImage] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const imageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const { data } = await server().post("/upload", formData);
      setSelectedImage({
        preview: e.target.files[0],
        path: getPublicImage(data.data.files[0]),
      });
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage();
  };

  const onSubmit = (data) => {
    if (selectedImage && selectedImage.path) data["logo"] = selectedImage.path;
    dispatch(addCurrency({ data, navigate }));
  };

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>اضف عملة جديدة</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={12}>
                    <div className="form-group required">
                      <h5>شعار العملة</h5>
                      <div className="image-uploader image-contain">
                        <div className="img-pat">
                          <img
                            src={imageUpload}
                            width={46}
                            height={46}
                            alt=""
                          />
                        </div>
                        <label>
                          {selectedImage && (
                            <div>
                              <img
                                src={
                                  selectedImage &&
                                  selectedImage.preview &&
                                  URL.createObjectURL(selectedImage.preview)
                                }
                                width={160}
                                height={160}
                                alt=""
                              />
                              <button
                                className="removeImg"
                                onClick={removeSelectedImage}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  fill="currentColor"
                                  className="bi bi-x-lg"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                                  />
                                </svg>
                              </button>
                            </div>
                          )}
                          <input
                            accept="image/*"
                            type="file"
                            onChange={imageChange}
                          />
                        </label>

                        <p className="error-hint">
                          {errors.image?.type === "required" &&
                            "يرجي اختيار الشعار "}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم العملة بالعربية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم  العملة بالعربية"
                          {...register("name[ar]", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.["ar"]?.type === "required" &&
                          "يرجي ادخال اسم  العملة بالعربية"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم العملة بالانجليزية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم العملة  بالانجليزية"
                          {...register("name[en]", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.["en"]?.type === "required" &&
                          "يرجي ادخال اسم  العملة بالانجليزية"}
                      </p>
                    </div>
                  </Col>

                  <Col xs={12}>
                    <div className="form-group required">
                      <h5>رمز العملة</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="رمز العملة"
                          {...register("symbol", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.symbol?.type === "required" &&
                          "يرجي ادخال  رمز العملة "}
                      </p>
                    </div>
                  </Col>

                  <input type="hidden" {...register("logo")} />
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        اضافة العملة
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCurrency;
