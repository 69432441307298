import {
  ADD_PORTAL_HELP_CATEGORY,
  ADD_PORTAL_HELP_CATEGORY_FAILURE,
  ADD_PORTAL_HELP_CATEGORY_SUCCESS,
  DELETE_PORTAL_HELP_CATEGORY,
  DELETE_PORTAL_HELP_CATEGORY_FAILURE,
  DELETE_PORTAL_HELP_CATEGORY_SUCCESS,
  EDIT_PORTAL_HELP_CATEGORY,
  EDIT_PORTAL_HELP_CATEGORY_FAILURE,
  EDIT_PORTAL_HELP_CATEGORY_SUCCESS,
  GET_PORTAL_HELP_CATEGORIES,
  GET_PORTAL_HELP_CATEGORIES_FAILURE,
  GET_PORTAL_HELP_CATEGORIES_SUCCESS,
  GET_SINGLE_PORTAL_HELP_CATEGORY,
  GET_SINGLE_PORTAL_HELP_CATEGORY_FAILURE,
  GET_SINGLE_PORTAL_HELP_CATEGORY_SUCCESS,
} from "./actionTypes";

export const getPortalHelpCategories = (payload) => {
  return {
    type: GET_PORTAL_HELP_CATEGORIES,
    payload,
  };
};

export const getPortalHelpCategoriesSuccess = (payload) => {
  return {
    type: GET_PORTAL_HELP_CATEGORIES_SUCCESS,
    payload,
  };
};

export const getPortalHelpCategoriesFailure = (error) => {
  return {
    type: GET_PORTAL_HELP_CATEGORIES_FAILURE,
    payload: error,
  };
};

export const getSinglePortalHelpCategory = (id) => {
  return {
    type: GET_SINGLE_PORTAL_HELP_CATEGORY,
    payload: id,
  };
};

export const getSinglePortalHelpCategorySuccess = (bank) => {
  return {
    type: GET_SINGLE_PORTAL_HELP_CATEGORY_SUCCESS,
    payload: bank,
  };
};

export const getSinglePortalHelpCategoryFailure = (error) => {
  return {
    type: GET_SINGLE_PORTAL_HELP_CATEGORY_FAILURE,
    payload: error,
  };
};

export const addPortalHelpCategory = (payload) => {
  return {
    type: ADD_PORTAL_HELP_CATEGORY,
    payload,
  };
};

export const addPortalHelpCategorySuccess = (payload) => {
  return {
    type: ADD_PORTAL_HELP_CATEGORY_SUCCESS,
    payload,
  };
};

export const addPortalHelpCategoryFailure = (error) => {
  return {
    type: ADD_PORTAL_HELP_CATEGORY_FAILURE,
    payload: error,
  };
};

export const editPortalHelpCategory = (payload) => {
  return {
    type: EDIT_PORTAL_HELP_CATEGORY,
    payload,
  };
};

export const editPortalHelpCategorySuccess = (payload) => {
  return {
    type: EDIT_PORTAL_HELP_CATEGORY_SUCCESS,
    payload,
  };
};

export const editPortalHelpCategoryFailure = (error) => {
  return {
    type: EDIT_PORTAL_HELP_CATEGORY_FAILURE,
    payload: error,
  };
};

export const deletePortalHelpCategory = (id) => {
  return {
    type: DELETE_PORTAL_HELP_CATEGORY,
    payload: id,
  };
};

export const deletePortalHelpCategorySuccess = (id) => {
  return {
    type: DELETE_PORTAL_HELP_CATEGORY_SUCCESS,
    payload: id,
  };
};

export const deletePortalHelpCategoryFailure = (error) => {
  return {
    type: DELETE_PORTAL_HELP_CATEGORY_FAILURE,
    payload: error,
  };
};
