export const GET_PORTAL_HELP_CATEGORIES = "GET_PORTAL_HELP_CATEGORIES";
export const GET_PORTAL_HELP_CATEGORIES_SUCCESS =
  "GET_PORTAL_HELP_CATEGORIES_SUCCESS";
export const GET_PORTAL_HELP_CATEGORIES_FAILURE =
  "GET_PORTAL_HELP_CATEGORIES_FAILURE";

export const GET_SINGLE_PORTAL_HELP_CATEGORY =
  "GET_SINGLE_PORTAL_HELP_CATEGORY";
export const GET_SINGLE_PORTAL_HELP_CATEGORY_SUCCESS =
  "GET_SINGLE_PORTAL_HELP_CATEGORY_SUCCESS";
export const GET_SINGLE_PORTAL_HELP_CATEGORY_FAILURE =
  "GET_SINGLE_PORTAL_HELP_CATEGORY_FAILURE";

export const ADD_PORTAL_HELP_CATEGORY = "ADD_PORTAL_HELP_CATEGORY";
export const ADD_PORTAL_HELP_CATEGORY_SUCCESS =
  "ADD_PORTAL_HELP_CATEGORY_SUCCESS";
export const ADD_PORTAL_HELP_CATEGORY_FAILURE =
  "ADD_PORTAL_HELP_CATEGORY_FAILURE";

export const EDIT_PORTAL_HELP_CATEGORY = "EDIT_PORTAL_HELP_CATEGORY";
export const EDIT_PORTAL_HELP_CATEGORY_SUCCESS =
  "EDIT_PORTAL_HELP_CATEGORY_SUCCESS";
export const EDIT_PORTAL_HELP_CATEGORY_FAILURE =
  "EDIT_PORTAL_HELP_CATEGORY_FAILURE";

export const DELETE_PORTAL_HELP_CATEGORY = "DELETE_PORTAL_HELP_CATEGORY";
export const DELETE_PORTAL_HELP_CATEGORY_SUCCESS =
  "DELETE_PORTAL_HELP_CATEGORY_SUCCESS";
export const DELETE_PORTAL_HELP_CATEGORY_FAILURE =
  "DELETE_PORTAL_HELP_CATEGORY_FAILURE";
