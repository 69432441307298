import { getId } from "helpers/functions";
import {
  ADD_ITEM,
  ADD_ITEM_FAILURE,
  ADD_ITEM_SUCCESS,
  ADD_ITEM_USER,
  ADD_ITEM_USER_FAILURE,
  ADD_ITEM_USER_SUCCESS,
  DELETE_ITEM,
  DELETE_ITEM_FAILURE,
  DELETE_ITEM_SUCCESS,
  DELETE_ITEM_USER,
  DELETE_ITEM_USER_FAILURE,
  DELETE_ITEM_USER_SUCCESS,
  EDIT_ITEM_SUCCESS,
  EDIT_ITEM_USER,
  EDIT_ITEM_USER_FAILURE,
  EDIT_ITEM_USER_SUCCESS,
  GET_ITEMS,
  GET_ITEMS_FAILURE,
  GET_ITEMS_SUCCESS,
  GET_SINGLE_ITEM,
  GET_SINGLE_ITEM_FAILURE,
  GET_SINGLE_ITEM_SUCCESS,
  Toggle_Active_USER,
  Toggle_Active_USER_FAILURE,
  Toggle_Active_USER_SUCCESS,
} from "./actionTypes";

const initialState = {
  permissionsData: [],
  loading: false,
  error: "",
  singlePermissionData: {},
};

const permissionsData = (state = initialState, action) => {
  switch (action.type) {
    case GET_ITEMS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ITEMS_SUCCESS:
      state = {
        ...state,
        loading: false,
        permissionsData: { ...state?.permissionsData, ...action.payload },
      };
      break;
    case GET_ITEMS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_ITEM:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_ITEM_SUCCESS:
      state = {
        ...state,
        loading: false,
        singlePermissionData: {
          ...state?.singlePermissionData,
          ...action.payload,
        },
      };
      break;
    case GET_SINGLE_ITEM_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        singlePermissionData: {},
      };
      break;

    case ADD_ITEM:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_ITEM_SUCCESS:
      state = {
        ...state,
        loading: false,
        permissionsData: { ...state?.permissionsData, ...action.payload },
      };
      break;
    case ADD_ITEM_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // ==================================
    case ADD_ITEM_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_ITEM_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        permissionsData: {
          ...state?.permissionsData,
          superAdmins: [...state?.permissionsData.superAdmins, action.payload],
        },
      };
      break;
    case ADD_ITEM_USER_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;
    // =======================================
    // ==================================
    case EDIT_ITEM_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case EDIT_ITEM_USER_SUCCESS:
      const updatedReview = action.payload;
      const dataAfterEditingUser = state?.permissionsData.superAdmins.map(
        (item) => {
          if (getId(item) === getId(updatedReview)) {
            return updatedReview;
          }
          return item;
        }
      );

      state = {
        ...state,
        loading: false,
        permissionsData: {
          ...state?.permissionsData,
          superAdmins: dataAfterEditingUser,
        },
      };
      break;
    case EDIT_ITEM_USER_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;
    // ==================================
    case Toggle_Active_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case Toggle_Active_USER_SUCCESS:
      const toggledUser = action.payload;
      const dataAfterToggle = state?.permissionsData.superAdmins.map((item) =>
        getId(item) === getId(toggledUser) ? toggledUser : item
      );

      state = {
        ...state,
        loading: false,
        permissionsData: {
          ...state?.permissionsData,
          superAdmins: dataAfterToggle,
        },
      };
      break;
    case Toggle_Active_USER_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // ==================================
    case DELETE_ITEM_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_ITEM_USER_SUCCESS:
      const dataAfterDeleteingUser = {
        ...state?.permissionsData,
        superAdmins: [
          ...state?.permissionsData.superAdmins.filter(
            (a) => getId(a) !== getId(action.payload)
          ),
        ],
      };

      state = {
        ...state,
        loading: false,
        permissionsData: dataAfterDeleteingUser,
      };
      break;
    case DELETE_ITEM_USER_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;
    // =======================================
    // =======================================
    case DELETE_ITEM:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_ITEM_SUCCESS:
      const dataKey = action.payload?.dataKey;
      if (!dataKey) break;

      const dataAfterDeleteing = {
        ...state?.permissionsData,
        [dataKey]: [
          ...state?.permissionsData?.[dataKey]?.filter(
            (portalReservation) =>
              getId(portalReservation) !== action.payload?.id
          ),
        ],
      };
      state = {
        ...state,
        loading: false,
        permissionsData: dataAfterDeleteing,
      };
      break;
    case DELETE_ITEM_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default permissionsData;
