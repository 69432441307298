export const GET_UNITS = "GET_UNITS";
export const GET_UNITS_SUCCESS = "GET_UNITS_SUCCESS";
export const GET_UNITS_FAILURE = "GET_UNITS_FAILURE";

export const GET_SINGLE_UNIT = "GET_SINGLE_UNIT";
export const GET_SINGLE_UNIT_SUCCESS = "GET_SINGLE_UNIT_SUCCESS";
export const GET_SINGLE_UNIT_FAILURE = "GET_SINGLE_UNIT_FAILURE";

export const ADD_UNIT = "ADD_UNIT";
export const ADD_UNIT_SUCCESS = "ADD_UNIT_SUCCESS";
export const ADD_UNIT_FAILURE = "ADD_UNIT_FAILURE";

export const EDIT_UNIT = "EDIT_UNIT";
export const EDIT_UNIT_SUCCESS = "EDIT_UNIT_SUCCESS";
export const EDIT_UNIT_FAILURE = "EDIT_UNIT_FAILURE";

export const DELETE_UNIT = "DELETE_UNIT";
export const DELETE_UNIT_SUCCESS = "DELETE_UNIT_SUCCESS";
export const DELETE_UNIT_FAILURE = "DELETE_UNIT_FAILURE";
