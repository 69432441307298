import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addThemeApi,
  deleteThemeApi,
  editThemeApi,
  getSingleThemeApi,
  getThemesApi,
} from "api/themesPages";

// Login Redux States
import {
  GET_THEMES_PAGES,
  ADD_THEMES_PAGE,
  EDIT_THEMES_PAGE,
  DELETE_THEMES_PAGE,
  GET_SINGLE_THEMES_PAGE,
} from "./actionTypes";
import {
  addThemePageFailure,
  addThemePageSuccess,
  deleteThemesPageFailure,
  deleteThemesPageSuccess,
  editThemesPageFailure,
  editThemesPageSuccess,
  getSingleThemesPageFailure,
  getSingleThemesPageuccess,
  getThemesPagesFailure,
  getThemesPagesSuccess,
} from "./actions";

function* getThemesPages({ payload }) {
  try {
    const { data } = yield call(getThemesApi, payload);
    yield put(getThemesPagesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getThemesPagesFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* getSingleThemePage({ payload }) {
  try {
    const { data } = yield call(getSingleThemeApi, payload);
    yield put(getSingleThemesPageuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleThemesPageFailure(error?.response?.data || error));
  }
}

function* addThemePage({ payload }) {
  try {
    const { data } = yield call(addThemeApi, payload);
    yield put(addThemePageSuccess(data));
    yield payload.navigate(`/themes/pages?deviceType=${payload?.deviceType}`);
  } catch (error) {
    console.log(error);
    yield put(addThemePageFailure(error.response?.data?.message || error));
  }
}

function* editThemesPage({ payload }) {
  try {
    const { data } = yield call(editThemeApi, payload);
    yield put(editThemesPageSuccess(data));
    yield payload.navigate(`/themes/pages?deviceType=${payload?.deviceType}`);
  } catch (error) {
    console.log(error);
    yield put(editThemesPageFailure(error?.response?.data || error));
  }
}

function* deleteThemePage({ payload }) {
  try {
    yield call(deleteThemeApi, payload);
    yield put(deleteThemesPageSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteThemesPageFailure(error?.response?.data || error));
  }
}

export function* watchGetThemes() {
  yield takeEvery(GET_THEMES_PAGES, getThemesPages);
}

export function* watchGetSingleTheme() {
  yield takeEvery(GET_SINGLE_THEMES_PAGE, getSingleThemePage);
}

export function* watchAddTheme() {
  yield takeEvery(ADD_THEMES_PAGE, addThemePage);
}

export function* watchEditThemesPage() {
  yield takeEvery(EDIT_THEMES_PAGE, editThemesPage);
}

export function* watchDeleteTheme() {
  yield takeEvery(DELETE_THEMES_PAGE, deleteThemePage);
}

function* themesSaga() {
  yield all([fork(watchGetThemes)]);
  yield all([fork(watchGetSingleTheme)]);
  yield all([fork(watchAddTheme)]);
  yield all([fork(watchEditThemesPage)]);
  yield all([fork(watchDeleteTheme)]);
}

export default themesSaga;
