import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addPortalFaqApi,
  deletePortalFaqApi,
  editPortalFaqApi,
  getPortalFaqsApi,
  getSinglePortalFaqApi,
} from "api/portalFaqs";

// Login Redux States
import {
  ADD_PORTAL_FAQ,
  DELETE_PORTAL_FAQ,
  EDIT_PORTAL_FAQ,
  GET_PORTAL_FAQS,
  GET_SINGLE_PORTAL_FAQ,
} from "./actionTypes";

import {
  addPortalFaqFailure,
  addPortalFaqSuccess,
  deletePortalFaqFailure,
  deletePortalFaqSuccess,
  editPortalFaqFailure,
  editPortalFaqSuccess,
  getPortalFaqsFailure,
  getPortalFaqsSuccess,
  getSinglePortalFaqFailure,
  getSinglePortalFaqSuccess,
} from "./actions";

function* getPortalFaqs({ payload }) {
  try {
    const { data } = yield call(getPortalFaqsApi, payload);
    yield put(getPortalFaqsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getPortalFaqsFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* addPortalFaq({ payload }) {
  try {
    const { data } = yield call(addPortalFaqApi, payload.data);
    yield put(addPortalFaqSuccess(data));
    yield payload.navigate("/portal/Faqs");
  } catch (error) {
    console.log(error);
    yield put(addPortalFaqFailure(error.response?.data?.message || error));
  }
}

function* getSinglePortalFaq({ payload }) {
  try {
    const { data } = yield call(getSinglePortalFaqApi, payload);
    yield put(getSinglePortalFaqSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSinglePortalFaqFailure(error?.response?.data || error));
  }
}

function* editPortalFaq({ payload }) {
  try {
    yield call(editPortalFaqApi, payload);
    yield put(editPortalFaqSuccess(payload));
    yield payload.navigate("/portal/Faqs");
  } catch (error) {
    console.log(error);
    yield put(editPortalFaqFailure(error?.response?.data || error));
  }
}

function* deletePortalFaq({ payload }) {
  try {
    yield call(deletePortalFaqApi, payload);
    yield put(deletePortalFaqSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deletePortalFaqFailure(error?.response?.data || error));
  }
}

export function* watchGetPortalFaqs() {
  yield takeEvery(GET_PORTAL_FAQS, getPortalFaqs);
}

export function* watchAddPortalFaq() {
  yield takeEvery(ADD_PORTAL_FAQ, addPortalFaq);
}

export function* watchGetSinglePortalFaq() {
  yield takeEvery(GET_SINGLE_PORTAL_FAQ, getSinglePortalFaq);
}

export function* watchEditPortalFaq() {
  yield takeEvery(EDIT_PORTAL_FAQ, editPortalFaq);
}

export function* watchDeletePortalFaq() {
  yield takeEvery(DELETE_PORTAL_FAQ, deletePortalFaq);
}

function* banksSaga() {
  yield all([fork(watchGetPortalFaqs)]);
  yield all([fork(watchGetSinglePortalFaq)]);
  yield all([fork(watchAddPortalFaq)]);
  yield all([fork(watchEditPortalFaq)]);
  yield all([fork(watchDeletePortalFaq)]);
}

export default banksSaga;
