import ControlArea from "components/Layout/ControlArea";
import React from "react";
import { useSelector } from "react-redux";
import areasIcon from "assets/svgs/areas.svg";
import Table from "./Table";
const Index = () => {
  const { areas } = useSelector((state) => state.areas);
  return (
    <>
      <div className="stores-wrap">
        <ControlArea
          btnTxt="اضافة منطقة جديدة"
          cardTxt="جميع المناطق"
          icon={areasIcon}
          url="/areas/addarea"
        />
        <Table data={areas} />
      </div>
    </>
  );
};

export default Index;
