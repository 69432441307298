export const GET_LANGUAGES = "GET_LANGUAGES";
export const GET_LANGUAGES_SUCCESS = "GET_LANGUAGES_SUCCESS";
export const GET_LANGUAGES_FAILURE = "GET_LANGUAGES_FAILURE";

export const GET_SINGLE_LANGUAGE = "GET_SINGLE_LANGUAGE";
export const GET_SINGLE_LANGUAGE_SUCCESS = "GET_SINGLE_LANGUAGE_SUCCESS";
export const GET_SINGLE_LANGUAGE_FAILURE = "GET_SINGLE_LANGUAGE_FAILURE";

export const ADD_LANGUAGE = "ADD_LANGUAGE";
export const ADD_LANGUAGE_SUCCESS = "ADD_LANGUAGE_SUCCESS";
export const ADD_LANGUAGE_FAILURE = "ADD_LANGUAGE_FAILURE";

export const EDIT_LANGUAGE = "EDIT_LANGUAGE";
export const EDIT_LANGUAGE_SUCCESS = "EDIT_LANGUAGE_SUCCESS";
export const EDIT_LANGUAGE_FAILURE = "EDIT_LANGUAGE_FAILURE";

export const DELETE_LANGUAGE = "DELETE_LANGUAGE";
export const DELETE_LANGUAGE_SUCCESS = "DELETE_LANGUAGE_SUCCESS";
export const DELETE_LANGUAGE_FAILURE = "DELETE_LANGUAGE_FAILURE";
