import server from "./server";

export const getPaymentMethodsApi = async () => {
  const response = await server().get("/paymentMethods");
  return response.data;
};

export const getSinglePaymentMethodApi = async (id) => {
  const response = await server().get(`/paymentMethods/${id}`);
  return response.data;
};

export const addPaymentMethodApi = async (payload) => {
  const response = await server().post("/paymentMethods", payload);
  return response.data;
};

export const editPaymentMethodApi = async ({ id, data }) => {
  const response = await server().put(`/paymentMethods/${id}`, data);
  return response.data;
};

export const deletePaymentMethodApi = async (id) => {
  const response = await server().delete(`/paymentMethods/${id}`);
  return response.data;
};
