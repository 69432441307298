import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  getUsersApi,
  addUserApi,
  editUserApi,
  deleteUserApi,
  getSingleUserApi,
} from "../../api/admins";

// Login Redux States
import {
  ADD_USER,
  DELETE_USER,
  EDIT_USER,
  GET_SINGLE_USER,
  GET_USERS,
} from "./actionTypes";
import {
  addUserFailure,
  addUserSuccess,
  getUsersSuccess,
  getUsersFailure,
  editUserSuccess,
  editUserFailure,
  deleteUserSuccess,
  deleteUserFailure,
  getSingleUserSuccess,
  getSingleUserFailure,
} from "./actions";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";

function* getUsers({ payload }) {
  try {
    const { data } = yield call(getUsersApi, payload);
    yield put(getUsersSuccess(data));
  } catch (error) {
    toastErrorMessage({ error: error?.response?.data });
    console.log(error);
    yield put(getUsersFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* getSingleUser({ payload }) {
  try {
    const { data } = yield call(getSingleUserApi, payload);
    yield put(getSingleUserSuccess(data));
  } catch (error) {
    toastErrorMessage({ error: error?.response?.data });
    console.log(error);
    yield put(getSingleUserFailure(error?.response?.data || error));
  }
}

function* addUser({ payload }) {
  try {
    const { data } = yield call(addUserApi, payload.data);
    yield put(addUserSuccess(data));
    yield payload.navigate("/users");
  } catch (error) {
    toastErrorMessage({ error: error?.response?.data });
    console.log(error);
    yield put(addUserFailure(error.response?.data?.message || error));
  }
}

function* editUser({ payload }) {
  try {
    const { data } = yield call(editUserApi, payload);
    yield put(editUserSuccess(data));
    yield payload.navigate("/users");
  } catch (error) {
    toastErrorMessage({ error: error?.response?.data });
    console.log(error);
    yield put(editUserFailure(error.response?.data?.message || error));
  }
}

function* deleteUser({ payload }) {
  try {
    yield call(deleteUserApi, payload);
    yield put(deleteUserSuccess(payload));
  } catch (error) {
    toastErrorMessage({ error: error?.response?.data });
    console.log(error);
    yield put(deleteUserFailure(error?.response?.data || error));
  }
}

export function* watchGetUsers() {
  yield takeEvery(GET_USERS, getUsers);
}

export function* watchSingleUser() {
  yield takeEvery(GET_SINGLE_USER, getSingleUser);
}

export function* watchAddUser() {
  yield takeEvery(ADD_USER, addUser);
}

export function* watchEditUser() {
  yield takeEvery(EDIT_USER, editUser);
}

export function* watchDeleteUser() {
  yield takeEvery(DELETE_USER, deleteUser);
}

function* UsersSaga() {
  yield all([fork(watchGetUsers)]);
  yield all([fork(watchSingleUser)]);
  yield all([fork(watchAddUser)]);
  yield all([fork(watchEditUser)]);
  yield all([fork(watchDeleteUser)]);
}

export default UsersSaga;
