import server from "./server";

export const getLanguagesApi = async () => {
  const response = await server().get("/languages");
  return response.data;
};

export const getSingleLanguageApi = async (id) => {
  const response = await server().get(`/languages/${id}`);
  return response.data;
};

export const addLanguageApi = async (payload) => {
  const response = await server().post("/languages", payload);
  return response.data;
};

export const editLanguageApi = async ({ id, data }) => {
  const response = await server().put(`/languages/${id}`, data);
  return response.data;
};

export const deleteLanguageApi = async (id) => {
  const response = await server().delete(`/languages/${id}`);
  return response.data;
};
