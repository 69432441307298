import server from "./server";

export const getFeaturesApi = async (payload) => {
  const response = await server().get(`/features${payload || ""}`);
  return response.data;
};

export const getSingleFeatureApi = async (id) => {
  const response = await server().get(`/features/${id}`);
  return response.data;
};

export const addFeatureApi = async (payload) => {
  const response = await server().post("/features", payload);
  return response.data;
};

export const editFeatureApi = async ({ id, data }) => {
  const response = await server().put(`/features/${id}`, data);
  return response.data;
};

export const deleteFeatureApi = async (id) => {
  const response = await server().delete(`/features/${id}`);
  return response.data;
};
