import server from "./server";

export const getThemesApi = async () => {
  const response = await server().get("/themes");
  return response.data;
};

export const getSingleThemeApi = async (id) => {
  const response = await server().get(`/themes/${id}`);
  return response.data;
};

export const addThemeApi = async (payload) => {
  const response = await server().post("/themes", payload);
  return response.data;
};

export const editThemeApi = async ({ id, data }) => {
  const response = await server().put(`/themes/${id}`, data);
  return response.data;
};

export const deleteThemeApi = async (id) => {
  const response = await server().delete(`/themes/${id}`);
  return response.data;
};
