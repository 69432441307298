import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { editLanguage, getSingleLanguage } from "store/actions";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/shared/Loader";
const EditLanguage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { singleLanguage, loading } = useSelector((state) => state?.languages);
  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    dispatch(getSingleLanguage(id));
  }, [dispatch, id]);

  useEffect(() => {
    reset({
      name: singleLanguage?.name,
      locale: singleLanguage?.locale,
      dir: singleLanguage?.dir,
    });
  }, [reset, singleLanguage]);

  const onSubmit = (data) => {
    dispatch(editLanguage({ id, data, navigate }));
  };

  if (loading) return <Loader />;
  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>تعديل بيانات اللغة</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم اللغة</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم اللغة"
                          {...register("name", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اختصار اللغة (locale)</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="locale"
                          {...register("locale", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اتجاه اللغة </h5>
                      <div>
                        <select
                          className="form-control"
                          name="dir"
                          {...register("dir", { required: true })}
                        >
                          <option value="">يرجي تحديد اتجاه اللغة</option>
                          <option value="ltr">من اليسار الي اليمين</option>
                          <option value="rtl">من اليمين الي اليسار</option>
                        </select>
                      </div>
                    </div>
                  </Col>
                  <Row />
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        حفظ التعديلات
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditLanguage;
