import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { editUnit, getSingleUnit } from "store/actions";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/shared/Loader";
const EditUnit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { singleUnit, loading } = useSelector((state) => state?.measuringUnits);

  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    dispatch(getSingleUnit(id));
  }, [dispatch, id]);

  useEffect(() => {
    reset({ name: singleUnit?.name });
  }, [reset, singleUnit]);

  const onSubmit = (data) => {
    dispatch(editUnit({ id, data, navigate }));
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>تعديل بيانات الوحدة</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم الوحدة بالعربية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم الوحدة بالعربية"
                          {...register("name[ar]", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم الوحدة بالانجليزية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم الوحدة بالانجليزية"
                          {...register("name[en]", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        حفظ التعديلات
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditUnit;
