export const GET_STORES = "GET_STORES";
export const GET_STORES_SUCCESS = "GET_STORES_SUCCESS";
export const GET_STORES_FAILURE = "GET_STORES_FAILURE";

export const GET_SINGLE_STORE = "GET_SINGLE_STORE";
export const GET_SINGLE_STORE_SUCCESS = "GET_SINGLE_STORE_SUCCESS";
export const GET_SINGLE_STORE_FAILURE = "GET_SINGLE_STORE_FAILURE";

export const ADD_STORE = "ADD_STORE";
export const ADD_STORE_SUCCESS = "ADD_STORE_SUCCESS";
export const ADD_STORE_FAILURE = "ADD_STORE_FAILURE";

export const EDIT_STORE = "EDIT_STORE";
export const EDIT_STORE_SUCCESS = "EDIT_STORE_SUCCESS";
export const EDIT_STORE_FAILURE = "EDIT_STORE_FAILURE";

export const DELETE_STORE = "DELETE_STORE";
export const DELETE_STORE_SUCCESS = "DELETE_STORE_SUCCESS";
export const DELETE_STORE_FAILURE = "DELETE_STORE_FAILURE";
