import React, { useState } from "react";
import ThemeCustomView from "./ThemeCustomView";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ElementsSidebar from "../sidebars/ElementsSidebar";
import ActionsSidebar from "../sidebars/ActionsSidebar";
import { v4 } from "uuid";
const Index = ({
  componentData,
  setComponentData,
  activeComponent,
  setActiveComponent,
  currentInput,
}) => {
  const [pages, setPages] = useState([
    {
      id: v4(),
      name: "الصفحة الرئيسية",
    },
  ]);

  const [selectedPage, setSelectedPage] = useState(pages[0]);

  const handleAddPage = () => {
    const newPages = [
      ...pages,
      {
        id: v4(),
        name: "page name",
      },
    ];
    setPages(newPages);
  };

  const handleDeletePage = (id) => {
    const newPages = pages.filter((page) => page.id !== id);
    setPages(newPages);
  };

  const handleEditPage = (id) => {
    const newPages = pages.map((page) => {
      const index = newPages.findIndex((page) => page.id);

      if (page.id === id) {
        return {
          ...page,
          name: newPages[index].name,
        };
      } else {
        return page;
      }
    });
    setPages(newPages);
  };

  const handlePageNameChange = (e) => {
    const newPages = [...pages];
    const index = newPages.findIndex((page) => page.id);
    newPages[index].name = e.target.value;
    setPages(newPages);
  };

  return (
    <div className="workspace">
      <DndProvider backend={HTML5Backend}>
        <ElementsSidebar />
        <ThemeCustomView
          componentData={componentData}
          setComponentData={setComponentData}
          activeComponent={activeComponent}
          setActiveComponent={setActiveComponent}
          pages={pages}
          setPages={setPages}
          handleAddPage={handleAddPage}
          handleDeletePage={handleDeletePage}
          handleEditPage={handleEditPage}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
        />
      </DndProvider>
      <ActionsSidebar
        activeComponent={activeComponent}
        setActiveComponent={setActiveComponent}
        componentData={componentData}
        setComponentData={setComponentData}
        currentInput={currentInput}
        pages={pages}
        setPages={setPages}
        handleAddPage={handleAddPage}
        handleDeletePage={handleDeletePage}
        handleEditPage={handleEditPage}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        handlePageNameChange={handlePageNameChange}
      />
      {/* {
        activeComponent.id && (
        )
      } */}
    </div>
  );
};

export default Index;
