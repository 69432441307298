import React from "react";

// Icons
import { ReactComponent as TextIcon } from "assets/builder/inputs/text.svg";
import { ReactComponent as TextAreaIcon } from "assets/builder/inputs/textarea.svg";
import { ReactComponent as NumberIcon } from "assets/builder/inputs/number.svg";
import { ReactComponent as DateIcon } from "assets/builder/inputs/date.svg";
import { ReactComponent as TimeIcon } from "assets/builder/inputs/time.svg";
import { ReactComponent as DateTimeIcon } from "assets/builder/inputs/datetime.svg";
import { ReactComponent as CheckBoxIcon } from "assets/builder/inputs/checkbox.svg";
import { ReactComponent as RadioIcon } from "assets/builder/inputs/radio.svg";
import { ReactComponent as ToggleIcon } from "assets/builder/inputs/toggle.svg";
import { ReactComponent as DropDownIcon } from "assets/builder/inputs/dropdown.svg";
import { ReactComponent as VerticalContainerIcon } from "assets/builder/inputs/verticalcontainer.svg";
import { ReactComponent as ImageIcon } from "assets/builder/inputs/image.svg";
import { ReactComponent as VideoIcon } from "assets/builder/inputs/video.svg";
import { ReactComponent as ColorIcon } from "assets/builder/inputs/color.svg";
import { ReactComponent as ButtonIcon } from "assets/builder/inputs/button.svg";

import InputDropTemplate from "../ComponentDataBuilder/InputDropTemplate";

const ElementsSidebar = () => {
  const textInputs = [
    {
      type: "text",
      icon: <TextIcon width={15} height={15} />,
      label: "حقل ادخال نصي",
    },
    {
      type: "textarea",
      icon: <TextAreaIcon width={15} height={15} />,
      label: "منطقة ادخال نصي (textarea)",
    },
    {
      type: "number",
      icon: <NumberIcon width={15} height={15} />,
      label: "حقل ادخال ارقام",
    },
  ];

  const DateInputs = [
    {
      type: "date",
      icon: <DateIcon width={15} height={15} />,
      label: "حقل ادخال تاريخ",
    },
    {
      type: "time",
      icon: <TimeIcon width={15} height={15} />,
      label: "حقل ادخال وقت",
    },
    {
      type: "datetime-local",
      icon: <DateTimeIcon width={15} height={15} />,
      label: "حقل ادخال تاريخ ووقت",
    },
  ];

  const mediaInputs = [
    {
      type: "singleImageUpload",
      icon: <ImageIcon width={15} height={15} />,
      label: "اضافة صورة",
    },
    {
      type: "video",
      icon: <VideoIcon width={15} height={15} />,
      label: "اضافة فيديو",
    },
    {
      type: "color",
      icon: <ColorIcon width={15} height={15} />,
      label: "حقل لاضافة لون",
    },
    {
      type: "button",
      icon: <ButtonIcon width={15} height={15} />,
      label: "اضافة زر",
    },
  ];

  const extraInputs = [
    {
      type: "checkbox",
      icon: <CheckBoxIcon width={15} height={15} />,
      label: "حقل مربع اختيار (checkbox)",
    },
    {
      type: "radio",
      icon: <RadioIcon width={15} height={15} />,
      label: "حقل زرار راديو (radio button)",
    },
    {
      type: "switch",
      icon: <ToggleIcon width={15} height={15} />,
      label: "حقل توجيه (toggle)",
    },
    {
      type: "select",
      icon: <DropDownIcon width={15} height={15} />,
      label: "حقل قائمة منسدلة (select)",
    },
    {
      type: "verticalContainer",
      icon: <VerticalContainerIcon width={20} height={20} />,
      label: "حقل محاذاة عمودية (vertical container)",
    },
    {
      type: "useFieldArray",
      icon: <VerticalContainerIcon width={20} height={20} />,
      label: "حقل مجموعة حقول (use field array)",
    },
  ];

  const renderSidebarComponents = (inputType) => {
    return inputType.map((input, index) => {
      return (
        <div key={index}>
          <InputDropTemplate input={input} />
        </div>
      );
    });
  };

  return (
    <div className="workspace-sidebar elements-sidebar">
      <div className="workspace-sidebar-header">
        <h4>اضافة حقول</h4>
      </div>
      <div className="workspace-sidebar-body">
        <div className="elementsList-wrapper">
          <div className="inputsHolder">
            <h4>الحقول النصية</h4>
            <div className="holderBody">
              {renderSidebarComponents(textInputs)}
            </div>
          </div>

          <div className="inputsHolder">
            <h4>حقول التاريخ والوقت</h4>
            <div className="holderBody">
              {renderSidebarComponents(DateInputs)}
            </div>
          </div>

          <div className="inputsHolder">
            <h4>حقول الميديا</h4>
            <div className="holderBody">
              {renderSidebarComponents(mediaInputs)}
            </div>
          </div>

          <div className="inputsHolder">
            <h4>حقول اخرى</h4>
            <div className="holderBody">
              {renderSidebarComponents(extraInputs)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElementsSidebar;
