import server from "./server";

export const getPortalFaqsApi = async () => {
  const response = await server().get("/portalFaqs");
  return response.data;
};

export const getSinglePortalFaqApi = async (id) => {
  const response = await server().get(`/portalFaqs/${id}`);
  return response.data;
};

export const addPortalFaqApi = async (payload) => {
  const response = await server().post("/portalFaqs", payload);
  return response.data;
};

export const editPortalFaqApi = async ({ id, data }) => {
  const response = await server().put(`/portalFaqs/${id}`, data);
  return response.data;
};

export const deletePortalFaqApi = async (id) => {
  const response = await server().delete(`/portalFaqs/${id}`);
  return response.data;
};
