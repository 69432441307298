import server from "./server";

export const getFontsApi = async ({ deviceType }) => {
  const response = await server().get("/fonts", {
    headers: {
      deviceType: deviceType,
    },
  });

  return response.data;
};

export const getSingleFontApi = async (id) => {
  const response = await server().get(`/fonts/${id}`);
  return response.data;
};

export const addFontApi = async ({ data, deviceType }) => {
  const response = await server().post("/fonts", data, {
    headers: {
      deviceType: deviceType,
    },
  });
  return response.data;
};

export const editFontApi = async ({ id, data }) => {
  const response = await server().put(`/fonts/${id}`, data);
  return response.data;
};

export const deleteFontApi = async (id) => {
  const response = await server().delete(`/fonts/${id}`);
  return response.data;
};
