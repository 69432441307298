import {
  ADD_PORTAL_HELP_BLOG,
  ADD_PORTAL_HELP_BLOG_FAILURE,
  ADD_PORTAL_HELP_BLOG_SUCCESS,
  DELETE_PORTAL_HELP_BLOG,
  DELETE_PORTAL_HELP_BLOG_FAILURE,
  DELETE_PORTAL_HELP_BLOG_SUCCESS,
  EDIT_PORTAL_HELP_BLOG,
  EDIT_PORTAL_HELP_BLOG_FAILURE,
  EDIT_PORTAL_HELP_BLOG_SUCCESS,
  GET_PORTAL_HELP_BLOGS,
  GET_PORTAL_HELP_BLOGS_FAILURE,
  GET_PORTAL_HELP_BLOGS_SUCCESS,
  GET_SINGLE_PORTAL_HELP_BLOG,
  GET_SINGLE_PORTAL_HELP_BLOG_FAILURE,
  GET_SINGLE_PORTAL_HELP_BLOG_SUCCESS,
  TOGGLE_PORTAL_HELP_BLOG,
  TOGGLE_PORTAL_HELP_BLOG_FAILURE,
  TOGGLE_PORTAL_HELP_BLOG_SUCCESS,
} from "./actionTypes";

export const getPortalHelpBlogs = (payload) => {
  return {
    type: GET_PORTAL_HELP_BLOGS,
    payload,
  };
};

export const getPortalHelpBlogsSuccess = (banks) => {
  return {
    type: GET_PORTAL_HELP_BLOGS_SUCCESS,
    payload: banks,
  };
};

export const getPortalHelpBlogsFailure = (error) => {
  return {
    type: GET_PORTAL_HELP_BLOGS_FAILURE,
    payload: error,
  };
};

export const getSinglePortalHelpBlog = (id) => {
  return {
    type: GET_SINGLE_PORTAL_HELP_BLOG,
    payload: id,
  };
};

export const getSinglePortalHelpBlogSuccess = (bank) => {
  return {
    type: GET_SINGLE_PORTAL_HELP_BLOG_SUCCESS,
    payload: bank,
  };
};

export const getSinglePortalHelpBlogFailure = (error) => {
  return {
    type: GET_SINGLE_PORTAL_HELP_BLOG_FAILURE,
    payload: error,
  };
};

export const addPortalHelpBlog = (payload) => {
  return {
    type: ADD_PORTAL_HELP_BLOG,
    payload,
  };
};

export const addPortalHelpBlogSuccess = (payload) => {
  return {
    type: ADD_PORTAL_HELP_BLOG_SUCCESS,
    payload,
  };
};

export const addPortalHelpBlogFailure = (error) => {
  return {
    type: ADD_PORTAL_HELP_BLOG_FAILURE,
    payload: error,
  };
};

export const editPortalHelpBlog = (payload) => {
  return {
    type: EDIT_PORTAL_HELP_BLOG,
    payload,
  };
};

export const editPortalHelpBlogSuccess = (payload) => {
  return {
    type: EDIT_PORTAL_HELP_BLOG_SUCCESS,
    payload,
  };
};

export const editPortalHelpBlogFailure = (error) => {
  return {
    type: EDIT_PORTAL_HELP_BLOG_FAILURE,
    payload: error,
  };
};

export const togglePortalHelpBlog = (payload) => {
  return {
    type: TOGGLE_PORTAL_HELP_BLOG,
    payload,
  };
};

export const togglePortalHelpBlogSuccess = (payload) => {
  return {
    type: TOGGLE_PORTAL_HELP_BLOG_SUCCESS,
    payload,
  };
};

export const togglePortalHelpBlogFailure = (error) => {
  return {
    type: TOGGLE_PORTAL_HELP_BLOG_FAILURE,
    payload: error,
  };
};

export const deletePortalHelpBlog = (id) => {
  return {
    type: DELETE_PORTAL_HELP_BLOG,
    payload: id,
  };
};

export const deletePortalHelpBlogSuccess = (id) => {
  return {
    type: DELETE_PORTAL_HELP_BLOG_SUCCESS,
    payload: id,
  };
};

export const deletePortalHelpBlogFailure = (error) => {
  return {
    type: DELETE_PORTAL_HELP_BLOG_FAILURE,
    payload: error,
  };
};
