import { getId } from "helpers/functions";
import {
  GET_THEMES_COMPONENTS,
  GET_THEMES_COMPONENTS_SUCCESS,
  GET_THEMES_COMPONENTS_FAILURE,
  ADD_THEMES_COMPONENT,
  ADD_THEMES_COMPONENT_SUCCESS,
  ADD_THEMES_COMPONENT_FAILURE,
  DELETE_THEMES_COMPONENT,
  DELETE_THEMES_COMPONENT_SUCCESS,
  DELETE_THEMES_COMPONENT_FAILURE,
  GET_SINGLE_THEMES_COMPONENT,
  GET_SINGLE_THEMES_COMPONENT_SUCCESS,
  GET_SINGLE_THEMES_COMPONENT_FAILURE,
} from "./actionTypes";

const initialState = {
  components: [],
  loading: false,
  error: "",
  singleComponent: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_THEMES_COMPONENTS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_THEMES_COMPONENTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        components: action.payload.themeComponents,
      };
      break;
    case GET_THEMES_COMPONENTS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_THEMES_COMPONENT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_THEMES_COMPONENT_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleComponent: action.payload.themeComponent,
      };

      break;
    case GET_SINGLE_THEMES_COMPONENT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        singleComponent: {},
      };
      break;

    case ADD_THEMES_COMPONENT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_THEMES_COMPONENT_SUCCESS:
      state = {
        ...state,
        loading: false,
        components: action.payload.components,
      };
      break;
    case ADD_THEMES_COMPONENT_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_THEMES_COMPONENT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_THEMES_COMPONENT_SUCCESS:
      const componentsAfterDeleteing = [
        ...state?.components?.filter(
          (component) => getId(component) !== action.payload
        ),
      ];
      state = {
        ...state,
        loading: false,
        components: componentsAfterDeleteing,
      };
      break;
    case DELETE_THEMES_COMPONENT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
