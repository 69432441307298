import { getId } from "helpers/functions";
import {
  GET_LANGUAGES,
  GET_LANGUAGES_SUCCESS,
  GET_LANGUAGES_FAILURE,
  GET_SINGLE_LANGUAGE,
  GET_SINGLE_LANGUAGE_SUCCESS,
  GET_SINGLE_LANGUAGE_FAILURE,
  ADD_LANGUAGE,
  ADD_LANGUAGE_SUCCESS,
  ADD_LANGUAGE_FAILURE,
  DELETE_LANGUAGE,
  DELETE_LANGUAGE_SUCCESS,
  DELETE_LANGUAGE_FAILURE,
} from "./actionTypes";

const initialState = {
  languages: [],
  loading: false,
  error: "",
  singleLanguage: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_LANGUAGES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_LANGUAGES_SUCCESS:
      state = {
        ...state,
        loading: false,
        languages: action.payload.languages,
      };
      break;
    case GET_LANGUAGES_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_LANGUAGE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_LANGUAGE_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleLanguage: action.payload.language,
      };
      break;
    case GET_SINGLE_LANGUAGE_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        singleLanguage: {},
      };
      break;

    case ADD_LANGUAGE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_LANGUAGE_SUCCESS:
      state = {
        ...state,
        loading: false,
        languages: action.payload.languages,
      };
      break;
    case ADD_LANGUAGE_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_LANGUAGE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_LANGUAGE_SUCCESS:
      const languagesAfterDeleteing = [
        ...state?.languages?.filter(
          (language) => getId(language) !== action.payload
        ),
      ];
      state = {
        ...state,
        loading: false,
        languages: languagesAfterDeleteing,
      };
      break;
    case DELETE_LANGUAGE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
