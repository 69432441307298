export const GET_THEMES_COMPONENTS_TYPES = "GET_THEMES_COMPONENTS_TYPES";
export const GET_THEMES_COMPONENTS_TYPES_SUCCESS =
  "GET_THEMES_COMPONENTS_TYPES_SUCCESS";
export const GET_THEMES_COMPONENTS_TYPES_FAILURE =
  "GET_THEMES_COMPONENTS_TYPES_FAILURE";
export const GET_SINGLE_THEMES_COMPONENTS_TYPE =
  "GET_SINGLE_THEMES_COMPONENTS_TYPE";
export const GET_SINGLE_THEMES_COMPONENTS_TYPE_SUCCESS =
  "GET_SINGLE_THEMES_COMPONENTS_TYPE_SUCCESS";
export const GET_SINGLE_THEMES_COMPONENTS_TYPE_FAILURE =
  "GET_SINGLE_THEMES_COMPONENTS_TYPE_FAILURE";
export const ADD_THEMES_COMPONENTS_TYPE = "ADD_THEMES_COMPONENTS_TYPE";
export const ADD_THEMES_COMPONENTS_TYPE_SUCCESS =
  "ADD_THEMES_COMPONENTS_TYPE_SUCCESS";
export const ADD_THEMES_COMPONENTS_TYPE_FAILURE =
  "ADD_THEMES_COMPONENTS_TYPE_FAILURE";
export const EDIT_THEMES_COMPONENTS_TYPE = "EDIT_THEMES_COMPONENTS_TYPE";
export const EDIT_THEMES_COMPONENTS_TYPE_SUCCESS =
  "EDIT_THEMES_COMPONENTS_TYPE_SUCCESS";
export const EDIT_THEMES_COMPONENTS_TYPE_FAILURE =
  "EDIT_THEMES_COMPONENTS_TYPE_FAILURE";
export const DELETE_THEMES_COMPONENTS_TYPE = "DELETE_THEMES_COMPONENTS_TYPE";
export const DELETE_THEMES_COMPONENTS_TYPE_SUCCESS =
  "DELETE_THEMES_COMPONENTS_TYPE_SUCCESS";
export const DELETE_THEMES_COMPONENTS_TYPE_FAILURE =
  "DELETE_THEMES_COMPONENTS_TYPE_FAILURE";
