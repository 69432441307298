import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addPortalReservationApi,
  deletePortalReservationApi,
  editPortalReservationApi,
  getPortalReservationsApi,
  getSinglePortalReservationApi,
} from "api/portalReservations";

// Login Redux States
import {
  ADD_PORTAL_RESERVATION,
  DELETE_PORTAL_RESERVATION,
  EDIT_PORTAL_RESERVATION,
  GET_PORTAL_RESERVATIONS,
  GET_SINGLE_PORTAL_RESERVATION,
} from "./actionTypes";

import {
  addPortalReservationFailure,
  addPortalReservationSuccess,
  deletePortalReservationFailure,
  deletePortalReservationSuccess,
  editPortalReservationFailure,
  editPortalReservationSuccess,
  getPortalReservationsFailure,
  getPortalReservationsSuccess,
  getSinglePortalReservationFailure,
  getSinglePortalReservationSuccess,
} from "./actions";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";

function* getPortalReservations({ payload }) {
  try {
    const { data } = yield call(getPortalReservationsApi, payload);
    yield put(getPortalReservationsSuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(
      getPortalReservationsFailure(error?.response?.data?.errors?.[0]?.msg)
    );
  }
}

function* addPortalReservation({ payload }) {
  try {
    const { data } = yield call(addPortalReservationApi, payload.data);
    yield put(addPortalReservationSuccess(data));
    yield payload.navigate("/portalReservation");
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(
      addPortalReservationFailure(error.response?.data?.message || error)
    );
  }
}

function* getSinglePortalReservation({ payload }) {
  try {
    const { data } = yield call(getSinglePortalReservationApi, payload);
    yield put(getSinglePortalReservationSuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(
      getSinglePortalReservationFailure(error?.response?.data || error)
    );
  }
}

function* editPortalReservation({ payload }) {
  try {
    yield call(editPortalReservationApi, payload);
    yield put(editPortalReservationSuccess(payload));
    yield payload.navigate("/portalReservation");
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(editPortalReservationFailure(error?.response?.data || error));
  }
}

function* deletePortalReservation({ payload }) {
  try {
    yield call(deletePortalReservationApi, payload);
    yield put(deletePortalReservationSuccess(payload));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(deletePortalReservationFailure(error?.response?.data || error));
  }
}

export function* watchGetPortalReservations() {
  yield takeEvery(GET_PORTAL_RESERVATIONS, getPortalReservations);
}

export function* watchAddPortalReservation() {
  yield takeEvery(ADD_PORTAL_RESERVATION, addPortalReservation);
}

export function* watchGetSinglePortalReservation() {
  yield takeEvery(GET_SINGLE_PORTAL_RESERVATION, getSinglePortalReservation);
}

export function* watchEditPortalReservation() {
  yield takeEvery(EDIT_PORTAL_RESERVATION, editPortalReservation);
}

export function* watchDeletePortalReservation() {
  yield takeEvery(DELETE_PORTAL_RESERVATION, deletePortalReservation);
}

function* banksSaga() {
  yield all([fork(watchGetPortalReservations)]);
  yield all([fork(watchGetSinglePortalReservation)]);
  yield all([fork(watchAddPortalReservation)]);
  yield all([fork(watchEditPortalReservation)]);
  yield all([fork(watchDeletePortalReservation)]);
}

export default banksSaga;
