import ControlArea from "components/Layout/ControlArea";
import React from "react";
import { useSelector } from "react-redux";
import categoriesIcon from "assets/svgs/categories.svg";
import Table from "./Table";
const Index = () => {
  const { categories } = useSelector((state) => state.categories);
  return (
    <>
      <div className="stores-wrap">
        <ControlArea
          btnTxt="اضافة تصنيف"
          cardTxt="التصنيفات"
          icon={categoriesIcon}
          url="/categories/addcategory"
        />
        <Table data={categories} />
      </div>
    </>
  );
};

export default Index;
