import {
  GET_PRODUCT_TYPES,
  GET_PRODUCT_TYPES_SUCCESS,
  GET_PRODUCT_TYPES_FAILURE,
  ADD_PRODUCT_TYPE,
  ADD_PRODUCT_TYPE_SUCCESS,
  ADD_PRODUCT_TYPE_FAILURE,
  EDIT_PRODUCT_TYPE,
  EDIT_PRODUCT_TYPE_SUCCESS,
  EDIT_PRODUCT_TYPE_FAILURE,
  DELETE_PRODUCT_TYPE,
  DELETE_PRODUCT_TYPE_SUCCESS,
  DELETE_PRODUCT_TYPE_FAILURE,
} from "./actionTypes";

export const getProductTypes = () => {
  return {
    type: GET_PRODUCT_TYPES,
  };
};

export const getProductTypesSuccess = (productTypes) => {
  return {
    type: GET_PRODUCT_TYPES_SUCCESS,
    payload: productTypes,
  };
};

export const getProductTypesFailure = (error) => {
  return {
    type: GET_PRODUCT_TYPES_FAILURE,
    payload: error,
  };
};

export const addProductType = (payload) => {
  return {
    type: ADD_PRODUCT_TYPE,
    payload,
  };
};

export const addProductTypeSuccess = (payload) => {
  return {
    type: ADD_PRODUCT_TYPE_SUCCESS,
    payload,
  };
};

export const addProductTypeFailure = (error) => {
  return {
    type: ADD_PRODUCT_TYPE_FAILURE,
    payload: error,
  };
};

export const editProductType = (payload) => {
  return {
    type: EDIT_PRODUCT_TYPE,
    payload,
  };
};

export const editProductTypeSuccess = (payload) => {
  return {
    type: EDIT_PRODUCT_TYPE_SUCCESS,
    payload,
  };
};

export const editProductTypeFailure = (error) => {
  return {
    type: EDIT_PRODUCT_TYPE_FAILURE,
    payload: error,
  };
};

export const deleteProductType = (id) => {
  return {
    type: DELETE_PRODUCT_TYPE,
    payload: id,
  };
};

export const deleteProductTypeSuccess = (id) => {
  return {
    type: DELETE_PRODUCT_TYPE_SUCCESS,
    payload: id,
  };
};

export const deleteProductTypeFailure = (error) => {
  return {
    type: DELETE_PRODUCT_TYPE_FAILURE,
    payload: error,
  };
};
