import React from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";

import store from "./store";
import App from "./App";

import "bootstrap/dist/css/bootstrap.rtl.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "styles/global.scss";
import "styles/responsive.css";
import "styles/dark.css";
import "styles/ltr.css";
import Wrapper from "./wrapper";
import "./i18nextInit";
// import ErrorBoundary from "components/shared/ErrorBoundary";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    {/* <ErrorBoundary> */}
    <Provider store={store}>
      <ToastContainer rtl position="bottom-left" limit={3} />
      <Wrapper>
        <App />
      </Wrapper>
    </Provider>
    {/* </ErrorBoundary> */}
  </React.StrictMode>
);
// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <ToastContainer rtl position="bottom-left" limit={3} />
//       <Wrapper>
//         <App />
//       </Wrapper>
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById("root")
// );
