import BooleanIndex from "components/builder/ComponentDataBuilder/htmlComponents/boolean/Index";
import ButtonControler from "components/builder/ComponentDataBuilder/htmlComponents/button/Index";
import Input from "components/builder/ComponentDataBuilder/htmlComponents/input/Index";
import Select from "components/builder/ComponentDataBuilder/htmlComponents/select/Index";
import SingleImageUpload from "components/builder/ComponentDataBuilder/htmlComponents/singleImageUpload/Index";
import Switch from "components/builder/ComponentDataBuilder/htmlComponents/switch/Index";
import TeaxtArea from "components/builder/ComponentDataBuilder/htmlComponents/textarea/Index";
import VerticalContainer from "components/builder/ComponentDataBuilder/htmlComponents/verticalContainer/Index";
import UseFieldArray from "../builder/ComponentDataBuilder/htmlComponents/useFieldArray/Index";

export const renderBuilderElements = ({
  inputsTree = [],
  index,
  componentData,
  setComponentData,
  activeComponent,
  setActiveComponent,
}) => {
  return inputsTree.map((input) => {
    switch (input?.type) {
      case "text":
      case "number":
      case "email":
      case "password":
      case "tel":
      case "url":
      case "month":
      case "week":
      case "range":
      case "search":
      case "file":
      case "date":
      case "time":
      case "datetime-local":
      case "color":
        return (
          <Input
            key={index}
            componentId={input.id}
            componentData={componentData}
            setComponentData={setComponentData}
            activeComponent={activeComponent}
            setActiveComponent={setActiveComponent}
            componentType={
              input.type === "datetime-local" ? "datetime-local" : input.type
            }
          />
        );
      case "checkbox":
      case "radio":
        return (
          <BooleanIndex
            key={index}
            componentId={input.id}
            componentData={componentData}
            setComponentData={setComponentData}
            activeComponent={activeComponent}
            setActiveComponent={setActiveComponent}
            componentType={input.type}
          />
        );
      case "singleImageUpload":
        return (
          <SingleImageUpload
            key={index}
            componentId={input.id}
            componentData={componentData}
            setComponentData={setComponentData}
            activeComponent={activeComponent}
            setActiveComponent={setActiveComponent}
            componentType={input.type}
          />
        );
      case "button":
        return (
          <ButtonControler
            key={index}
            componentId={input.id}
            componentData={componentData}
            setComponentData={setComponentData}
            activeComponent={activeComponent}
            setActiveComponent={setActiveComponent}
            componentType={input.type}
          />
        );

      case "textarea":
        return (
          <TeaxtArea
            key={index}
            componentId={input.id}
            componentData={componentData}
            setComponentData={setComponentData}
            activeComponent={activeComponent}
            setActiveComponent={setActiveComponent}
            componentType={input.type}
          />
        );

      case "video":
        return (
          <SingleImageUpload
            key={index}
            componentId={input.id}
            componentData={componentData}
            setComponentData={setComponentData}
            activeComponent={activeComponent}
            setActiveComponent={setActiveComponent}
            componentType={input.type}
          />
        );

      case "switch":
        return (
          <Switch
            key={index}
            componentId={input.id}
            componentData={componentData}
            setComponentData={setComponentData}
            activeComponent={activeComponent}
            setActiveComponent={setActiveComponent}
            componentType={input.type}
          />
        );

      case "select":
        return (
          <Select
            key={index}
            componentId={input.id}
            componentData={componentData}
            setComponentData={setComponentData}
            activeComponent={activeComponent}
            setActiveComponent={setActiveComponent}
            componentType={input.type}
          />
        );

      case "verticalContainer":
        return (
          <VerticalContainer
            key={index}
            componentId={input.id}
            componentData={componentData}
            setComponentData={setComponentData}
            activeComponent={activeComponent}
            setActiveComponent={setActiveComponent}
            componentType={input.type}
          >
            {input?.childrens?.length > 0 &&
              renderBuilderElements({
                inputsTree: input?.childrens,
                componentData,
                setComponentData,
                activeComponent,
                setActiveComponent,
              })}
          </VerticalContainer>
        );

      case "useFieldArray":
        return (
          <UseFieldArray
            key={index}
            componentId={input.id}
            componentData={componentData}
            setComponentData={setComponentData}
            activeComponent={activeComponent}
            setActiveComponent={setActiveComponent}
            componentType={input.type}
          >
            {input?.childrens?.length > 0 &&
              renderBuilderElements({
                inputsTree: input?.childrens,
                componentData,
                setComponentData,
                activeComponent,
                setActiveComponent,
              })}
          </UseFieldArray>
        );

      default:
        return <div>Not found</div>;
    }
  });
};

export const getInputTree = (formInputs = [], firstParent = null) => {
  const getTree = (formInputs, parent) => {
    return formInputs
      ?.filter((input) => input.parent === parent)
      .map((input) => {
        return {
          ...input,
          childrens: getTree(formInputs, input.id),
        };
      });
  };
  return getTree(formInputs, firstParent);
};
