import { getId } from "helpers/functions";
import {
  ADD_CURRENCY,
  ADD_CURRENCY_FAILURE,
  ADD_CURRENCY_SUCCESS,
  DELETE_CURRENCY,
  DELETE_CURRENCY_FAILURE,
  DELETE_CURRENCY_SUCCESS,
  GET_CURRENCIES,
  GET_CURRENCIES_FAILURE,
  GET_CURRENCIES_SUCCESS,
  GET_SINGLE_CURRENCY,
  GET_SINGLE_CURRENCY_FAILURE,
  GET_SINGLE_CURRENCY_SUCCESS,
} from "./actionTypes";

const initialState = {
  currencies: [],
  loading: false,
  error: "",
  singleCurrency: {},
  metadata: {},
};

const currencies = (state = initialState, action) => {
  switch (action.type) {
    case GET_CURRENCIES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_CURRENCIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        currencies: action.payload.currencies,
        metadata: action.payload.metadata,
      };
      break;
    case GET_CURRENCIES_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_CURRENCY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_CURRENCY_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleCurrency: action.payload.currency,
      };
      break;
    case GET_SINGLE_CURRENCY_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        singleCurrency: {},
      };
      break;

    case ADD_CURRENCY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_CURRENCY_SUCCESS:
      state = {
        ...state,
        loading: false,
        currencies: action.payload.currencies,
      };
      break;
    case ADD_CURRENCY_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_CURRENCY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_CURRENCY_SUCCESS:
      const currenciesAfterDeleteing = [
        ...state?.currencies?.filter(
          (currency) => getId(currency) !== action.payload
        ),
      ];
      state = {
        ...state,
        loading: false,
        currencies: currenciesAfterDeleteing,
      };
      break;
    case DELETE_CURRENCY_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default currencies;
