import React from "react";
import { useSelector } from "react-redux";
import Table from "./Table";

const Subscribers = () => {
  const { subscribers, loading } = useSelector((state) => state.subscribers);
  return (
    <>
      <div className="stores-wrap">
        <Table data={subscribers} loading={loading} />
      </div>
    </>
  );
};

export default Subscribers;
