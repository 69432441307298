import {
  ADD_PORTAL_TECHNICAL_SUPPORT,
  ADD_PORTAL_TECHNICAL_SUPPORT_FAILURE,
  ADD_PORTAL_TECHNICAL_SUPPORT_SUCCESS,
  DELETE_PORTAL_TECHNICAL_SUPPORT,
  DELETE_PORTAL_TECHNICAL_SUPPORT_FAILURE,
  DELETE_PORTAL_TECHNICAL_SUPPORT_SUCCESS,
  EDIT_PORTAL_TECHNICAL_SUPPORT,
  EDIT_PORTAL_TECHNICAL_SUPPORT_FAILURE,
  EDIT_PORTAL_TECHNICAL_SUPPORT_SUCCESS,
  GET_PORTAL_TECHNICAL_SUPPORTS,
  GET_PORTAL_TECHNICAL_SUPPORTS_FAILURE,
  GET_PORTAL_TECHNICAL_SUPPORTS_SUCCESS,
  GET_SINGLE_PORTAL_TECHNICAL_SUPPORT,
  GET_SINGLE_PORTAL_TECHNICAL_SUPPORT_FAILURE,
  GET_SINGLE_PORTAL_TECHNICAL_SUPPORT_SUCCESS,
} from "./actionTypes";

export const getPortalTechnicalSupports = () => {
  return {
    type: GET_PORTAL_TECHNICAL_SUPPORTS,
  };
};

export const getPortalTechnicalSupportsSuccess = (banks) => {
  return {
    type: GET_PORTAL_TECHNICAL_SUPPORTS_SUCCESS,
    payload: banks,
  };
};

export const getPortalTechnicalSupportsFailure = (error) => {
  return {
    type: GET_PORTAL_TECHNICAL_SUPPORTS_FAILURE,
    payload: error,
  };
};

export const getSinglePortalTechnicalSupport = (id) => {
  return {
    type: GET_SINGLE_PORTAL_TECHNICAL_SUPPORT,
    payload: id,
  };
};

export const getSinglePortalTechnicalSupportSuccess = (bank) => {
  return {
    type: GET_SINGLE_PORTAL_TECHNICAL_SUPPORT_SUCCESS,
    payload: bank,
  };
};

export const getSinglePortalTechnicalSupportFailure = (error) => {
  return {
    type: GET_SINGLE_PORTAL_TECHNICAL_SUPPORT_FAILURE,
    payload: error,
  };
};

export const addPortalTechnicalSupport = (payload) => {
  return {
    type: ADD_PORTAL_TECHNICAL_SUPPORT,
    payload,
  };
};

export const addPortalTechnicalSupportSuccess = (payload) => {
  return {
    type: ADD_PORTAL_TECHNICAL_SUPPORT_SUCCESS,
    payload,
  };
};

export const addPortalTechnicalSupportFailure = (error) => {
  return {
    type: ADD_PORTAL_TECHNICAL_SUPPORT_FAILURE,
    payload: error,
  };
};

export const editPortalTechnicalSupport = (payload) => {
  return {
    type: EDIT_PORTAL_TECHNICAL_SUPPORT,
    payload,
  };
};

export const editPortalTechnicalSupportSuccess = (payload) => {
  return {
    type: EDIT_PORTAL_TECHNICAL_SUPPORT_SUCCESS,
    payload,
  };
};

export const editPortalTechnicalSupportFailure = (error) => {
  return {
    type: EDIT_PORTAL_TECHNICAL_SUPPORT_FAILURE,
    payload: error,
  };
};

export const deletePortalTechnicalSupport = (id) => {
  return {
    type: DELETE_PORTAL_TECHNICAL_SUPPORT,
    payload: id,
  };
};

export const deletePortalTechnicalSupportSuccess = (id) => {
  return {
    type: DELETE_PORTAL_TECHNICAL_SUPPORT_SUCCESS,
    payload: id,
  };
};

export const deletePortalTechnicalSupportFailure = (error) => {
  return {
    type: DELETE_PORTAL_TECHNICAL_SUPPORT_FAILURE,
    payload: error,
  };
};
