export const GET_THEMES = "GET_THEMES";
export const GET_THEMES_SUCCESS = "GET_THEMES_SUCCESS";
export const GET_THEMES_FAILURE = "GET_THEMES_FAILURE";
export const GET_SINGLE_THEME = "GET_SINGLE_THEME";
export const GET_SINGLE_THEME_SUCCESS = "GET_SINGLE_THEME_SUCCESS";
export const GET_SINGLE_THEME_FAILURE = "GET_SINGLE_THEME_FAILURE";
export const ADD_THEME = "ADD_THEME";
export const ADD_THEME_SUCCESS = "ADD_THEME_SUCCESS";
export const ADD_THEME_FAILURE = "ADD_THEME_FAILURE";
export const EDIT_THEME = "EDIT_THEME";
export const EDIT_THEME_SUCCESS = "EDIT_THEME_SUCCESS";
export const EDIT_THEME_FAILURE = "EDIT_THEME_FAILURE";
export const DELETE_THEME = "DELETE_THEME";
export const DELETE_THEME_SUCCESS = "DELETE_THEME_SUCCESS";
export const DELETE_THEME_FAILURE = "DELETE_THEME_FAILURE";
