import server from "./server";

export const getUsersApi = async () => {
  const response = await server().get("/users");
  return response.data;
};

export const getSingleUserApi = async (id) => {
  const response = await server().get(`/users/${id}`);
  return response.data;
};

export const addUserApi = async (payload) => {
  const response = await server().post("/users", payload);
  return response.data;
};

export const editUserApi = async ({ id, data }) => {
  const response = await server().put(`/users/${id}`, data);
  return response.data;
};

export const deleteUserApi = async (id) => {
  const response = await server().delete(`/users/${id}`);
  return response.data;
};
