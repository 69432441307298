import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addCategoryApi,
  deleteCategoryApi,
  editCategoryApi,
  getCategoriesApi,
  getSingleCategoryApi,
} from "api/categories";

// Login Redux States
import {
  ADD_CATEGORY,
  DELETE_CATEGORY,
  EDIT_CATEGORY,
  GET_CATEGORIES,
  GET_SINGLE_CATEGORY,
} from "./actionTypes";
import {
  addCategoryFailure,
  addCategorySuccess,
  deleteCategoryFailure,
  deleteCategorySuccess,
  editCategoryFailure,
  editCategorySuccess,
  getCategoriesFailure,
  getCategoriesSuccess,
  getSingleCategoryFailure,
  getSingleCategorySuccess,
} from "./actions";

function* getCategories() {
  try {
    const { data } = yield call(getCategoriesApi);
    yield put(getCategoriesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getCategoriesFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* getSingleCategory({ payload }) {
  try {
    const { data } = yield call(getSingleCategoryApi, payload);
    yield put(getSingleCategorySuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleCategoryFailure(error?.response?.data || error));
  }
}

function* addCategory({ payload }) {
  try {
    const { data } = yield call(addCategoryApi, payload.data);
    yield put(addCategorySuccess(data));
    yield payload.navigate("/categories");
  } catch (error) {
    console.log(error);
    yield put(addCategoryFailure(error.response?.data?.message || error));
  }
}

function* editCategory({ payload }) {
  try {
    const { data } = yield call(editCategoryApi, payload);
    yield put(editCategorySuccess(data));
    yield payload.navigate("/categories");
  } catch (error) {
    console.log(error);
    yield put(editCategoryFailure(error?.response?.data || error));
  }
}

function* deleteCategory({ payload }) {
  try {
    yield call(deleteCategoryApi, payload);
    yield put(deleteCategorySuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteCategoryFailure(error?.response?.data || error));
  }
}

export function* watchGetCategories() {
  yield takeEvery(GET_CATEGORIES, getCategories);
}

export function* watchSingleCategory() {
  yield takeEvery(GET_SINGLE_CATEGORY, getSingleCategory);
}

export function* watchAddCategory() {
  yield takeEvery(ADD_CATEGORY, addCategory);
}

export function* watchEditCategory() {
  yield takeEvery(EDIT_CATEGORY, editCategory);
}

export function* watchDeleteCategory() {
  yield takeEvery(DELETE_CATEGORY, deleteCategory);
}

function* categoriesSaga() {
  yield all([fork(watchGetCategories)]);
  yield all([fork(watchSingleCategory)]);
  yield all([fork(watchAddCategory)]);
  yield all([fork(watchEditCategory)]);
  yield all([fork(watchDeleteCategory)]);
}

export default categoriesSaga;
