import { getId } from "helpers/functions";
import {
  GET_THEMES,
  GET_THEMES_SUCCESS,
  GET_THEMES_FAILURE,
  ADD_THEME,
  ADD_THEME_SUCCESS,
  ADD_THEME_FAILURE,
  DELETE_THEME,
  DELETE_THEME_SUCCESS,
  DELETE_THEME_FAILURE,
  GET_SINGLE_THEME,
  GET_SINGLE_THEME_SUCCESS,
  GET_SINGLE_THEME_FAILURE,
} from "./actionTypes";

const initialState = {
  themes: [],
  loading: false,
  error: "",
  singleTheme: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_THEMES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_THEMES_SUCCESS:
      state = {
        ...state,
        loading: false,
        themes: action.payload.themes,
      };
      break;
    case GET_THEMES_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_THEME:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_THEME_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleTheme: action.payload.theme,
      };

      break;
    case GET_SINGLE_THEME_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        singleTheme: {},
      };
      break;

    case ADD_THEME:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_THEME_SUCCESS:
      state = {
        ...state,
        loading: false,
        themes: action.payload.themes,
      };
      break;
    case ADD_THEME_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_THEME:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_THEME_SUCCESS:
      const themesAfterDeleteing = [
        ...state?.themes?.filter((theme) => getId(theme) !== action.payload),
      ];
      state = {
        ...state,
        loading: false,
        themes: themesAfterDeleteing,
      };
      break;
    case DELETE_THEME_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
