import {
  GET_FEATURES,
  GET_FEATURES_SUCCESS,
  GET_FEATURES_FAILURE,
  GET_SINGLE_FEATURE,
  GET_SINGLE_FEATURE_SUCCESS,
  GET_SINGLE_FEATURE_FAILURE,
  ADD_FEATURE,
  ADD_FEATURE_SUCCESS,
  ADD_FEATURE_FAILURE,
  EDIT_FEATURE,
  EDIT_FEATURE_SUCCESS,
  EDIT_FEATURE_FAILURE,
  DELETE_FEATURE,
  DELETE_FEATURE_SUCCESS,
  DELETE_FEATURE_FAILURE,
} from "./actionTypes";

export const getFeatures = (payload) => {
  return {
    type: GET_FEATURES,payload
  };
};

export const getFeaturesSuccess = (features) => {
  return {
    type: GET_FEATURES_SUCCESS,
    payload: features,
  };
};

export const getFeaturesFailure = (error) => {
  return {
    type: GET_FEATURES_FAILURE,
    payload: error,
  };
};

export const getSingleFeature = (id) => {
  return {
    type: GET_SINGLE_FEATURE,
    payload: id,
  };
};

export const getSingleFeatureSuccess = (feature) => {
  return {
    type: GET_SINGLE_FEATURE_SUCCESS,
    payload: feature,
  };
};

export const getSingleFeatureFailure = (error) => {
  return {
    type: GET_SINGLE_FEATURE_FAILURE,
    payload: error,
  };
};

export const addFeature = (payload) => {
  return {
    type: ADD_FEATURE,
    payload,
  };
};

export const addFeatureSuccess = (payload) => {
  return {
    type: ADD_FEATURE_SUCCESS,
    payload,
  };
};

export const addFeatureFailure = (error) => {
  return {
    type: ADD_FEATURE_FAILURE,
    payload: error,
  };
};

export const editFeature = (payload) => {
  return {
    type: EDIT_FEATURE,
    payload,
  };
};

export const editFeatureSuccess = (payload) => {
  return {
    type: EDIT_FEATURE_SUCCESS,
    payload,
  };
};

export const editFeatureFailure = (error) => {
  return {
    type: EDIT_FEATURE_FAILURE,
    payload: error,
  };
};

export const deleteFeature = (id) => {
  return {
    type: DELETE_FEATURE,
    payload: id,
  };
};

export const deleteFeatureSuccess = (id) => {
  return {
    type: DELETE_FEATURE_SUCCESS,
    payload: id,
  };
};

export const deleteFeatureFailure = (error) => {
  return {
    type: DELETE_FEATURE_FAILURE,
    payload: error,
  };
};
