import React, { useMemo, useState, forwardRef, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Col, Dropdown, Nav } from "react-bootstrap";

import Filter from "components/shared/Filter";
import { useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import {
  deletePortalHelpCategory,
  getPortalHelpCategories,
} from "store/actions";
import Loader from "components/shared/Loader";
import { useHref, useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as DotsIcon } from "assets/svgs/three-dots.svg";
import { ReactComponent as EditIcon } from "assets/svgs/edit.svg";
import { ReactComponent as DeleteIcon } from "assets/svgs/delete.svg";
import { ReactComponent as GridIcon } from "assets/svgs/gridLayout.svg";
import Swal from "sweetalert2";
import { getId, getImageVariant, handleImage } from "helpers/functions";
import { portalHelpCategoriesTypes } from "helpers/configs";

const Table = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [params] = useSearchParams();
  const href = useHref();
  const { locale, formatMessage } = useIntl();
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (!params.get("type"))
      updateParams("type", portalHelpCategoriesTypes?.[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // let searchParams1 = new URLSearchParams(params);
    // searchParams1.delete("arrange");
    if (!params.get("type")) return;
    const paramsStr = params?.size > 0 ? `?${params.toString()}` : "";
    dispatch(getPortalHelpCategories(paramsStr));

    const timeout = setTimeout(() => {
      setRows(rows);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [dispatch, params, rows]);

  const updateParams = (name, value) => {
    params.set(name, value);
    const paramsStr = params?.size > 0 ? `?${params.toString()}` : "";
    navigate(`${href}${paramsStr}`, {
      replace: true,
    });
  };

  const BootyCheckbox = new forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
      <input
        htmlFor="booty-check"
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" id="booty-check" />
    </div>
  ));

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = props?.data?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Filter
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handleEdit = (id) => {
    navigate(`/portal/helpCategories/${id}`);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: formatMessage({ id: "deleteHelpCategories" }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2a89f3",
      cancelButtonColor: "#d33",
      confirmButtonText: "نعم",
      cancelButtonText: "لا",
    }).then((result) => {
      if (result.isConfirmed) {
        const paramsStr = params?.size > 0 ? `?${params.toString()}` : "";
        dispatch(deletePortalHelpCategory({ id, paramsStr }));
      }
    });
  };

  const columns = [
    {
      name: formatMessage({ id: "image" }),
      selector: (row) => (
        <img
          src={getImageVariant(row?.icon, "thumbnail")}
          className="bank-logo"
          alt=""
        />
      ),
      sortable: true,
    },
    {
      name: formatMessage({ id: "title" }),
      selector: (row) => <span>{row?.title?.[locale]}</span>,
      sortable: true,
    },

    {
      cell: (row) => (
        <div className="global-dropdown">
          <Dropdown>
            <Dropdown.Toggle>
              <DotsIcon />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={(e) => handleEdit(getId(row))}>
                <EditIcon className="bi bi-pencil edit" />
                تعديل
              </Dropdown.Item>
              <Dropdown.Item onClick={(e) => handleDelete(getId(row))}>
                <DeleteIcon className="bi bi-trash delete" fill="#fff" />
                حذف
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  return (
    <>
      <div className="table-wrap settings">
        <div className="card">
          <Col xl={6} md={12} xs={12}>
            <div className="card-head">
              <div>
                <h4>
                  <FormattedMessage id="helpCategories" />
                </h4>
                <p>
                  <FormattedMessage id="helpCategoriesDesc" />
                </p>
              </div>
            </div>
          </Col>

          <div className="settingsHead">
            <div className="card">
              <div>
                <span>
                  <GridIcon />
                </span>
                <FormattedMessage id="types" />
              </div>
              <Nav>
                {portalHelpCategoriesTypes?.map((type, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link
                      eventKey={0}
                      active={params?.get("type") === type}
                      onClick={() => updateParams("type", type)}
                    >
                      <FormattedMessage id={type} />
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </div>
          </div>

          <div className="card-body">
            <DataTable
              columns={columns}
              data={filteredItems?.flatMap((item) => [
                item,
                ...(item?.subCategories || [])?.filter((e) => e?.type),
              ])}
              progressPending={props?.loading}
              progressComponent={<Loader />}
              defaultSortField="name"
              selectableRows
              // subHeader
              // subHeaderComponent={subHeaderComponent}
              selectableRowsComponent={BootyCheckbox}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
