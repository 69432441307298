import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { addThemesPages } from "store/actions";
import { useNavigate, useSearchParams } from "react-router-dom";

import Loader from "components/shared/Loader";
import MultiUpload from "components/shared/MultiUpload";
const AddTheme = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const deviceType = searchParams.get("deviceType");
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();
  const { loading } = useSelector((state) => state?.themes);
  const [selectedImage, setSelectedImage] = useState([]);

  const onSubmit = (data) => {
    data.icon = selectedImage?.[0];
    // if (data.icon?.length > 0)
    //   data.icon = "uploads/" + data.icon.split("/").pop();

    dispatch(addThemesPages({ data, navigate, deviceType }));
  };
  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>اضف صفحة جديد</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={12} className="multiUploadWrapper">
                    <div className="form-group required">
                      <h5>ايقونة الصفحة</h5>
                      <MultiUpload
                        breakPoints={{ xs: 6, md: 3, lg: 2 }}
                        images={selectedImage}
                        setImages={setSelectedImage}
                        single={true}
                        name="الصفحة"
                      />
                    </div>
                  </Col>

                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم الصفحة بالعربية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم الصفحة "
                          {...register("name[ar]", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.["ar"]?.type === "required" &&
                          "يرجي ادخال اسم الصفحة بالعربية "}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم الصفحة بالانجليزية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم الصفحة "
                          {...register("name[en]", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.["en"]?.type === "required" &&
                          "يرجي ادخال اسم الصفحة بالانجليزية "}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>رابط الصفحة </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="رابط الصفحة "
                          {...register("url", { required: true })}
                          style={{ direction: "ltr" }}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.url?.type === "required" &&
                          "يرجي ادخال رابط الصفحة بشكل صحيح "}
                      </p>
                    </div>
                  </Col>
                </Row>
                <Col lg={4} xs={12}>
                  <div className="form-group">
                    <button type="submit" className="btn btn-blue">
                      اضافة الصفحة
                    </button>
                  </div>
                </Col>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTheme;
