import server from "./server";

export const getStoresApi = async () => {
  const response = await server().get("/stores");
  return response.data;
};

export const getSingleStoreApi = async (id) => {
  const response = await server().get(`/stores/${id}`);
  return response.data;
};

export const addStoreApi = async (payload) => {
  const response = await server().post("/stores", payload);
  return response.data;
};

export const editStoreApi = async ({ id, data }) => {
  const response = await server().put(`/stores/${id}`, data);
  return response.data;
};

export const deleteStoreApi = async (id) => {
  const response = await server().delete(`/stores/${id}`);
  return response.data;
};
