import React from "react";
import { Row } from "react-bootstrap";
import TopBlockItem from "./TopBlockItem";
import { sidebarData } from "../../data";

const BlocksTop = () => {
  const topCards = sidebarData
    ?.filter((item) => !item?.separator)
    .map((topcard) => {
      return <TopBlockItem key={topcard.id} topcard={topcard} />;
    });
  return (
    <>
      <div className="topCards">
        <Row>{topCards}</Row>
      </div>
    </>
  );
};

export default BlocksTop;
