import React from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
const TopBlockItem = ({ topcard }) => {
  const { svg, text, to } = topcard;
  const { t } = useTranslation();
  return (
    <>
      <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={6}>
        <div className="card">
          <NavLink to={to}>
            <i>{svg}</i>
            <h4>{t(text)}</h4>
          </NavLink>
        </div>
      </Col>
    </>
  );
};

export default TopBlockItem;
