import { getId } from "helpers/functions";
import {
  ADD_PORTAL_HELP_BLOG,
  ADD_PORTAL_HELP_BLOG_FAILURE,
  ADD_PORTAL_HELP_BLOG_SUCCESS,
  DELETE_PORTAL_HELP_BLOG,
  DELETE_PORTAL_HELP_BLOG_FAILURE,
  DELETE_PORTAL_HELP_BLOG_SUCCESS,
  GET_PORTAL_HELP_BLOGS,
  GET_PORTAL_HELP_BLOGS_FAILURE,
  GET_PORTAL_HELP_BLOGS_SUCCESS,
  GET_SINGLE_PORTAL_HELP_BLOG,
  GET_SINGLE_PORTAL_HELP_BLOG_FAILURE,
  GET_SINGLE_PORTAL_HELP_BLOG_SUCCESS,
  TOGGLE_PORTAL_HELP_BLOG,
  TOGGLE_PORTAL_HELP_BLOG_FAILURE,
  TOGGLE_PORTAL_HELP_BLOG_SUCCESS,
} from "./actionTypes";

const initialState = {
  portalHelpBlogs: [],
  loading: false,
  error: "",
  singlePortalHelpBlog: {},
};

const portalHelpBlogs = (state = initialState, action) => {
  switch (action.type) {
    case GET_PORTAL_HELP_BLOGS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_PORTAL_HELP_BLOGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        portalHelpBlogs: action.payload.helpCenterTopics,
      };
      break;
    case GET_PORTAL_HELP_BLOGS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_PORTAL_HELP_BLOG:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_PORTAL_HELP_BLOG_SUCCESS:
      state = {
        ...state,
        loading: false,
        singlePortalHelpBlog: action.payload.helpCenterTopic,
      };
      break;
    case GET_SINGLE_PORTAL_HELP_BLOG_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        singlePortalHelpBlog: {},
      };
      break;

    case ADD_PORTAL_HELP_BLOG:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_PORTAL_HELP_BLOG_SUCCESS:
      state = {
        ...state,
        loading: false,
        portalHelpBlogs: action.payload.helpCenterTopics,
      };
      break;
    case ADD_PORTAL_HELP_BLOG_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case TOGGLE_PORTAL_HELP_BLOG:
      state = {
        ...state,
        loading: true,
      };
      break;
    case TOGGLE_PORTAL_HELP_BLOG_SUCCESS:
      const portalHelpBlogsAfterToggling = state?.portalHelpBlogs?.map(
        (portalHelpBlog) =>
          getId(portalHelpBlog) !== getId(action.payload?.helpCenterTopic)
            ? portalHelpBlog
            : action?.payload?.helpCenterTopic
      );

      state = {
        ...state,
        loading: false,
        portalHelpBlogs: portalHelpBlogsAfterToggling,
      };

      break;
    case TOGGLE_PORTAL_HELP_BLOG_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_PORTAL_HELP_BLOG:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_PORTAL_HELP_BLOG_SUCCESS:
      const portalHelpBlogsAfterDeleteing = [
        ...state?.portalHelpBlogs?.filter(
          (portalHelpBlog) => getId(portalHelpBlog) !== action.payload
        ),
      ];
      state = {
        ...state,
        loading: false,
        portalHelpBlogs: portalHelpBlogsAfterDeleteing,
      };
      break;
    case DELETE_PORTAL_HELP_BLOG_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default portalHelpBlogs;
