import server from "./server";

export const getPortalHelpCategoriesApi = async (payload) => {
  const response = await server().get(`/helpCenterCategories${payload || ""}`);
  return response.data;
};

export const getSinglePortalHelpCategoryApi = async (id) => {
  const response = await server().get(`/helpCenterCategories/${id}`);
  return response.data;
};

export const addPortalHelpCategoryApi = async (payload) => {
  const response = await server().post("/helpCenterCategories", payload);
  return response.data;
};

export const editPortalHelpCategoryApi = async ({ id, data }) => {
  const response = await server().put(`/helpCenterCategories/${id}`, data);
  return response.data;
};

export const deletePortalHelpCategoryApi = async (id) => {
  const response = await server().delete(`/helpCenterCategories/${id}`);
  return response.data;
};
