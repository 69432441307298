export const GET_AREAS = "GET_AREAS";
export const GET_AREAS_SUCCESS = "GET_AREAS_SUCCESS";
export const GET_AREAS_FAILURE = "GET_AREAS_FAILURE";

export const GET_SINGLE_AREA = "GET_SINGLE_AREA";
export const GET_SINGLE_AREA_SUCCESS = "GET_SINGLE_AREA_SUCCESS";
export const GET_SINGLE_AREA_FAILURE = "GET_SINGLE_AREA_FAILURE";

export const ADD_AREA = "ADD_AREA";
export const ADD_AREA_SUCCESS = "ADD_AREA_SUCCESS";
export const ADD_AREA_FAILURE = "ADD_AREA_FAILURE";

export const EDIT_AREA = "EDIT_AREA";
export const EDIT_AREA_SUCCESS = "EDIT_AREA_SUCCESS";
export const EDIT_AREA_FAILURE = "EDIT_AREA_FAILURE";

export const DELETE_AREA = "DELETE_AREA";
export const DELETE_AREA_SUCCESS = "DELETE_AREA_SUCCESS";
export const DELETE_AREA_FAILURE = "DELETE_AREA_FAILURE";
