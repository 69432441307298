import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { checkPermissions } from "./checkPermissions";
import { sidebarData } from "data";

const AuthRoute = ({ route, children }) => {
  const { isLoggedIn } = useSelector((state) => state.authentication);
  const permissions = sidebarData?.find((e) => e?.to === route)?.permissions;

  const check =
    isLoggedIn && (checkPermissions(permissions || []) || route === undefined);

  return check ? children : <Navigate to="/login" />;
};

export default AuthRoute;
