import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "components/shared/Loader";
import { useState } from "react";
import SliderArray from "./SliderArray";
// import SaveChangesButton from "components/shared/SaveChangesButton";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";
// import { editPage, getPage } from "store/actions";
import { useNavigate } from "react-router-dom";
import { editSection, getSectionSlug } from "store/actions";
import { getId } from "helpers/functions";
import { toast } from "react-toastify";

export default function SeoData() {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, singleSection } = useSelector(
    (state) => state.portalSections
  );
  // const singleSection?.content?.items = useMemo(
  //   () =>
  //     Object.entries(singleSection?.content || {}).map(([key, value], id) => ({
  //       ...value,
  //       id,
  //     })),
  //   [singleSection]
  // );

  const { reset, handleSubmit } = useForm();
  const [sliders, setSliders] = useState([]);

  // get single page by slug
  useEffect(() => {
    dispatch(getSectionSlug("seo-data"));
  }, [dispatch]);

  useEffect(() => {
    setSliders(
      singleSection?.content?.items?.map((item, index) => ({
        ...item,
        id: index,
      })) || []
    );
    reset(singleSection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleSection?.content?.items]);

  // error
  useEffect(() => {
    if (error) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }
  }, [error, formatMessage, locale]);

  const onSubmit = (data) => {
    // let index = 0;
    // data.content = sliders.reduce((result, current) => {
    //   // const key = Object.keys(current)[0];
    //   result[`section${index}`] = current;
    //   index++;
    //   return result;
    // }, {});
    data.content = { items: sliders };

    dispatch(
      editSection({
        id: getId(singleSection),
        data,
        navigate: () => toast.success(formatMessage({ id: "dataSaved" })),
      })
    );
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="seoData" />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col>
                <Row>
                  <Col lg={12}>
                    <SliderArray sliders={sliders} setSliders={setSliders} />
                  </Col>
                </Row>

                <button className="btn btn-blue">Save</button>
                {/* <SaveChangesButton /> */}
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
