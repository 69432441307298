import server from "./server";

export const getThemesApi = async ({ id, deviceType }) => {
  const response = await server().get(`/themeTemplate/${id}/components`, {
    headers: {
      deviceType: deviceType,
    },
  });
  return response.data;
};

export const getSingleThemeApi = async (data) => {
  const response = await server().get(
    `/themeTemplate/${data?.templateId}/components/${data?.id}`
  );
  return response.data;
};

export const addThemeApi = async ({ data, templateId, deviceType }) => {
  const response = await server().post(
    `/themeTemplate/${templateId}/components`,
    data,
    {
      headers: {
        deviceType: deviceType,
      },
    }
  );
  return response.data;
};

export const editThemeApi = async ({ templateId, id, data }) => {
  const response = await server().put(
    `/themeTemplate/${templateId}/components/${id}`,
    data
  );
  return response.data;
};

export const deleteThemeApi = async (data) => {
  const response = await server().delete(
    `/themeTemplate/${data?.templateId}/components/${data?.id}`
  );
  return response.data;
};
