import server from "./server";

export const getUnitsApi = async () => {
  const response = await server().get("/measurementUnits");
  return response.data;
};

export const getSingleUnitApi = async (id) => {
  const response = await server().get(`/measurementUnits/${id}`);
  return response.data;
};

export const addUnitApi = async (payload) => {
  const response = await server().post("/measurementUnits", payload);
  return response.data;
};

export const editUnitApi = async ({ id, data }) => {
  const response = await server().put(`/measurementUnits/${id}`, data);
  return response.data;
};

export const deleteUnitApi = async (id) => {
  const response = await server().delete(`/measurementUnits/${id}`);
  return response.data;
};
