import ControlArea from "components/Layout/ControlArea";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import themesIcon from "assets/svgs/themes.svg";
import Table from "./Table";
import { getId } from "helpers/functions";
import { useParams, useSearchParams } from "react-router-dom";
import { getThemesTemplates } from "store/actions";
const Index = () => {
  const dispatch = useDispatch();
  const { templates } = useSelector((state) => state.templates);
  const { templateId } = useParams();
  const selectedTemplate = templates?.find((e) => getId(e) === templateId);
  const { components } = useSelector((state) => state.templateComponents);
  const [searchParams] = useSearchParams();
  const deviceType = searchParams.get("deviceType");

  useEffect(() => {
    // if (!templates?.length > 0)
    dispatch(getThemesTemplates(deviceType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, templateId]);

  return (
    <>
      <div className="stores-wrap">
        <ControlArea
          btnTxt={` اضافة عنصر للقالب (${
            selectedTemplate?.name?.["ar"] || ""
          })`}
          cardTxt={`عناصر القالب (${selectedTemplate?.name?.["ar"] || ""}) `}
          icon={themesIcon}
          url={`/themes/template/${getId(
            selectedTemplate
          )}/component/add?deviceType=${deviceType}`}
        />
        <Table data={components} />
      </div>
    </>
  );
};

export default Index;
