import React, { useEffect, useState } from "react";

// import Header from "components/builder/Header";
import Workspace from "components/builder/workspace/Index";

import "styles/builder.scss";

import { ReactComponent as RotateIcon } from "assets/builder/screen.svg";

const Builder = ({ componentData, setComponentData }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [activeComponent, setActiveComponent] = useState({});

  const currentInput = componentData?.find(
    (input) => input?.id === activeComponent?.id
  );

  const handleResize = () => {
    if (window.innerWidth < 1024) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  return (
    <div className="builder-wrapper">
      <div className="userMessage">
        <h3>
          <RotateIcon width={60} height={60} />
          لتتمكن من استخدام البيلدر بشكل جيد يرجي فتح الموقع على جهازك الكمبيوتر
        </h3>
      </div>

      {isMobile && (
        <>
          {/* <Header /> */}
          <Workspace
            componentData={componentData}
            setComponentData={setComponentData}
            activeComponent={activeComponent}
            setActiveComponent={setActiveComponent}
            currentInput={currentInput}
          />
        </>
      )}
    </div>
  );
};
export default Builder;
