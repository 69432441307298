import {
  GET_LANGUAGES,
  GET_LANGUAGES_SUCCESS,
  GET_LANGUAGES_FAILURE,
  GET_SINGLE_LANGUAGE,
  GET_SINGLE_LANGUAGE_SUCCESS,
  GET_SINGLE_LANGUAGE_FAILURE,
  ADD_LANGUAGE,
  ADD_LANGUAGE_SUCCESS,
  ADD_LANGUAGE_FAILURE,
  EDIT_LANGUAGE,
  EDIT_LANGUAGE_SUCCESS,
  EDIT_LANGUAGE_FAILURE,
  DELETE_LANGUAGE,
  DELETE_LANGUAGE_SUCCESS,
  DELETE_LANGUAGE_FAILURE,
} from "./actionTypes";

export const getLanguages = () => {
  return {
    type: GET_LANGUAGES,
  };
};

export const getLanguagesSuccess = (languages) => {
  return {
    type: GET_LANGUAGES_SUCCESS,
    payload: languages,
  };
};

export const getLanguagesFailure = (error) => {
  return {
    type: GET_LANGUAGES_FAILURE,
    payload: error,
  };
};

export const getSingleLanguage = (id) => {
  return {
    type: GET_SINGLE_LANGUAGE,
    payload: id,
  };
};

export const getSingleLanguagesuccess = (language) => {
  return {
    type: GET_SINGLE_LANGUAGE_SUCCESS,
    payload: language,
  };
};

export const getSingleLanguageFailure = (error) => {
  return {
    type: GET_SINGLE_LANGUAGE_FAILURE,
    payload: error,
  };
};

export const addLanguage = (payload) => {
  return {
    type: ADD_LANGUAGE,
    payload,
  };
};

export const addLanguagesuccess = (payload) => {
  return {
    type: ADD_LANGUAGE_SUCCESS,
    payload,
  };
};

export const addLanguageFailure = (error) => {
  return {
    type: ADD_LANGUAGE_FAILURE,
    payload: error,
  };
};

export const editLanguage = (payload) => {
  return {
    type: EDIT_LANGUAGE,
    payload,
  };
};

export const editLanguagesuccess = (payload) => {
  return {
    type: EDIT_LANGUAGE_SUCCESS,
    payload,
  };
};

export const editLanguageFailure = (error) => {
  return {
    type: EDIT_LANGUAGE_FAILURE,
    payload: error,
  };
};

export const deleteLanguage = (id) => {
  return {
    type: DELETE_LANGUAGE,
    payload: id,
  };
};

export const deleteLanguagesuccess = (id) => {
  return {
    type: DELETE_LANGUAGE_SUCCESS,
    payload: id,
  };
};

export const deleteLanguageFailure = (error) => {
  return {
    type: DELETE_LANGUAGE_FAILURE,
    payload: error,
  };
};
