import { getId } from "helpers/functions";
import {
  GET_BLOGS,
  GET_BLOGS_SUCCESS,
  GET_BLOGS_FAILURE,
  ADD_BLOG,
  ADD_BLOG_SUCCESS,
  ADD_BLOG_FAILURE,
  GET_SINGLE_BLOG,
  GET_SINGLE_BLOG_SUCCESS,
  GET_SINGLE_BLOG_FAILURE,
  DELETE_BLOG,
  DELETE_BLOG_SUCCESS,
  DELETE_BLOG_FAILURE,
  GET_TAGS,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAILURE,
} from "./actionTypes";

const initialState = {
  blogs: [],
  loading: false,
  error: "",
  singleBlog: {},
  blogTags: [],
  metadata: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_BLOGS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_BLOGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        blogs: action.payload.blogs,
        metadata: action?.payload?.metadata,
      };
      break;
    case GET_BLOGS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_BLOG:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_BLOG_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleBlog: action.payload.blog,
      };
      break;
    case GET_SINGLE_BLOG_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        singleBlog: {},
      };
      break;

    case ADD_BLOG:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_BLOG_SUCCESS:
      state = {
        ...state,
        loading: false,
        blogs: action.payload.blogs,
      };
      break;
    case ADD_BLOG_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_BLOG:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_BLOG_SUCCESS:
      const blogsAfterDeleteing = [
        ...state?.blogs?.filter((blog) => getId(blog) !== action.payload),
      ];
      state = {
        ...state,
        loading: false,
        blogs: blogsAfterDeleteing,
      };
      break;
    case DELETE_BLOG_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    case GET_TAGS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_TAGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        blogTags: action.payload.tags,
      };
      break;
    case GET_TAGS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
