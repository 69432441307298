import {
  ADD_ITEM,
  ADD_ITEM_FAILURE,
  ADD_ITEM_SUCCESS,
  ADD_ITEM_USER,
  ADD_ITEM_USER_FAILURE,
  ADD_ITEM_USER_SUCCESS,
  DELETE_ITEM,
  DELETE_ITEM_FAILURE,
  DELETE_ITEM_SUCCESS,
  DELETE_ITEM_USER,
  DELETE_ITEM_USER_FAILURE,
  DELETE_ITEM_USER_SUCCESS,
  EDIT_ITEM,
  EDIT_ITEM_FAILURE,
  EDIT_ITEM_SUCCESS,
  EDIT_ITEM_USER,
  EDIT_ITEM_USER_FAILURE,
  EDIT_ITEM_USER_SUCCESS,
  GET_ITEMS,
  GET_ITEMS_FAILURE,
  GET_ITEMS_SUCCESS,
  GET_SINGLE_ITEM,
  GET_SINGLE_ITEM_FAILURE,
  GET_SINGLE_ITEM_SUCCESS,
  Toggle_Active_USER,
  Toggle_Active_USER_FAILURE,
  Toggle_Active_USER_SUCCESS,
} from "./actionTypes";

export const getItems = (payload) => {
  return {
    type: GET_ITEMS,
    payload,
  };
};

export const getItemsSuccess = (payload) => {
  return {
    type: GET_ITEMS_SUCCESS,
    payload,
  };
};

export const getItemsFailure = (payload) => {
  return {
    type: GET_ITEMS_FAILURE,
    payload,
  };
};

export const getSingleItem = (payload) => {
  return {
    type: GET_SINGLE_ITEM,
    payload,
  };
};

export const getSingleItemSuccess = (payload) => {
  return {
    type: GET_SINGLE_ITEM_SUCCESS,
    payload,
  };
};

export const getSingleItemFailure = (payload) => {
  return {
    type: GET_SINGLE_ITEM_FAILURE,
    payload,
  };
};

export const addItem = (payload) => {
  return {
    type: ADD_ITEM,
    payload,
  };
};

export const addItemSuccess = (payload) => {
  return {
    type: ADD_ITEM_SUCCESS,
    payload,
  };
};

export const addItemFailure = (payload) => {
  return {
    type: ADD_ITEM_FAILURE,
    payload,
  };
};

export const editItem = (payload) => {
  return {
    type: EDIT_ITEM,
    payload,
  };
};

export const editItemSuccess = (payload) => {
  return {
    type: EDIT_ITEM_SUCCESS,
    payload,
  };
};

export const editItemFailure = (payload) => {
  return {
    type: EDIT_ITEM_FAILURE,
    payload,
  };
};
// ============================
export const addItemUser = (payload) => {
  return {
    type: ADD_ITEM_USER,
    payload,
  };
};

export const addItemUserSuccess = (payload) => {
  return {
    type: ADD_ITEM_USER_SUCCESS,
    payload,
  };
};

export const addItemUserFailure = (payload) => {
  return {
    type: ADD_ITEM_USER_FAILURE,
    payload,
  };
};
// ============================
// ============================
export const deleteItemUser = (payload) => {
  return {
    type: DELETE_ITEM_USER,
    payload,
  };
};

export const deleteItemUserSuccess = (payload) => {
  return {
    type: DELETE_ITEM_USER_SUCCESS,
    payload,
  };
};

export const deleteItemUserFailure = (payload) => {
  return {
    type: DELETE_ITEM_USER_FAILURE,
    payload,
  };
};
// ============================
// ============================
export const editItemUser = (payload) => {
  return {
    type: EDIT_ITEM_USER,
    payload,
  };
};

export const editItemUserSuccess = (payload) => {
  return {
    type: EDIT_ITEM_USER_SUCCESS,
    payload,
  };
};

export const editItemUserFailure = (payload) => {
  return {
    type: EDIT_ITEM_USER_FAILURE,
    payload,
  };
};
// ============================
export const toggleActiveUser = (payload) => {
  return {
    type: Toggle_Active_USER,
    payload,
  };
};

export const toggleActiveUserSuccess = (payload) => {
  return {
    type: Toggle_Active_USER_SUCCESS,
    payload,
  };
};

export const toggleActiveUserFailure = (payload) => {
  return {
    type: Toggle_Active_USER_FAILURE,
    payload,
  };
};
// ============================

export const deleteItem = (payload) => {
  return {
    type: DELETE_ITEM,
    payload,
  };
};

export const deleteItemSuccess = (payload) => {
  return {
    type: DELETE_ITEM_SUCCESS,
    payload,
  };
};

export const deleteItemFailure = (payload) => {
  return {
    type: DELETE_ITEM_FAILURE,
    payload,
  };
};
