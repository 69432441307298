import ControlArea from "components/Layout/ControlArea";
import React from "react";
import { useSelector } from "react-redux";
import languageIcon from "assets/svgs/language.svg";
import Table from "./Table";
const Index = () => {
  const { languages } = useSelector((state) => state.languages);
  return (
    <>
      <div className="stores-wrap">
        <ControlArea
          btnTxt="اضافة لغة جديدة"
          cardTxt="جميع اللغات"
          icon={languageIcon}
          url="/languages/add"
        />
        <Table data={languages} />
      </div>
    </>
  );
};

export default Index;
