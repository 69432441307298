import {
  takeEvery,
  fork,
  put,
  all,
  call,
  takeLatest,
} from "redux-saga/effects";

// Import Api files
import { getCurrentUserApi, postUserLogin } from "../../api/users";

// Login Redux States
import { CURRENT_USER, LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import {
  apiError,
  getCurrentUserFailure,
  getCurrentUserSuccess,
  loginSuccess,
  logoutUserSuccess,
} from "./actions";

function* loginUser({ payload }) {
  try {
    const { data } = yield call(postUserLogin, payload);
    yield put(loginSuccess(data));
    localStorage.setItem("token", data.token);
  } catch (error) {
    console.log(error);
    yield put(apiError(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* logoutUser() {
  try {
    yield put(logoutUserSuccess());
    localStorage.removeItem("token");
  } catch (error) {
    yield put(apiError(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* currentUserSaga() {
  try {
    const { data } = yield call(getCurrentUserApi);
    yield put(getCurrentUserSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getCurrentUserFailure(error?.response?.data?.error || error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export function* watchCurrentUser() {
  yield takeEvery(CURRENT_USER, currentUserSaga);
}

function* authSaga() {
  yield all([fork(watchUserLogin)]);
  yield all([fork(watchUserLogout)]);
  yield all([fork(watchCurrentUser)]);
}

export default authSaga;
