import {
  GET_STORES,
  GET_STORES_FAILURE,
  GET_STORES_SUCCESS,
  GET_SINGLE_STORE,
  GET_SINGLE_STORE_SUCCESS,
  GET_SINGLE_STORE_FAILURE,
  ADD_STORE,
  ADD_STORE_SUCCESS,
  ADD_STORE_FAILURE,
  EDIT_STORE,
  EDIT_STORE_SUCCESS,
  EDIT_STORE_FAILURE,
  DELETE_STORE,
  DELETE_STORE_SUCCESS,
  DELETE_STORE_FAILURE,
} from "./actionTypes";

export const getStores = () => {
  return {
    type: GET_STORES,
  };
};

export const getStoresSuccess = (stores) => {
  return {
    type: GET_STORES_SUCCESS,
    payload: stores,
  };
};

export const getStoresFailure = (error) => {
  return {
    type: GET_STORES_FAILURE,
    payload: error,
  };
};

export const getSingleStore = (id) => {
  return {
    type: GET_SINGLE_STORE,
    payload: id,
  };
};

export const getSingleStoreSuccess = (store) => {
  return {
    type: GET_SINGLE_STORE_SUCCESS,
    payload: store,
  };
};

export const getSingleStoreFailure = (error) => {
  return {
    type: GET_SINGLE_STORE_FAILURE,
    payload: error,
  };
};

export const addStore = (payload) => {
  return {
    type: ADD_STORE,
    payload,
  };
};

export const addStoreSuccess = (payload) => {
  return {
    type: ADD_STORE_SUCCESS,
    payload,
  };
};

export const addStoreFailure = (error) => {
  return {
    type: ADD_STORE_FAILURE,
    payload: error,
  };
};

export const editStore = (payload) => {
  return {
    type: EDIT_STORE,
    payload,
  };
};

export const editStoreSuccess = (payload) => {
  return {
    type: EDIT_STORE_SUCCESS,
    payload,
  };
};

export const editStoreFailure = (error) => {
  return {
    type: EDIT_STORE_FAILURE,
    payload: error,
  };
};

export const deleteStore = (id) => {
  return {
    type: DELETE_STORE,
    payload: id,
  };
};

export const deleteStoreSuccess = (id) => {
  return {
    type: DELETE_STORE_SUCCESS,
    payload: id,
  };
};

export const deleteStoreFailure = (error) => {
  return {
    type: DELETE_STORE_FAILURE,
    payload: error,
  };
};
