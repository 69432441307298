import React from "react";
import InputActions from "../ComponentDataBuilder/htmlComponents/input/InputActions";
import TextAreaActions from "../ComponentDataBuilder/htmlComponents/textarea/TextAreaActions";
import SingleImageUploadActions from "../ComponentDataBuilder/htmlComponents/singleImageUpload/SingleImageUploadActions";
import ButtonActions from "../ComponentDataBuilder/htmlComponents/button/ButtonActions";
import Actions from "../ComponentDataBuilder/htmlComponents/boolean/Actions";
import SelectActions from "../ComponentDataBuilder/htmlComponents/select/SelectActions";
import VerticalContainerActions from "../ComponentDataBuilder/htmlComponents/verticalContainer/VerticalContainerActions";
import UseFieldArrayActions from "../ComponentDataBuilder/htmlComponents/useFieldArray/UseFieldArrayActions";
const ActionsSidebar = ({
  currentInput,
  componentData,
  setComponentData,
  activeComponent,
  setActiveComponent,
  pages,
  setPages,
  handleAddPage,
  handleDeletePage,
  handleEditPage,
  selectedPage,
  setSelectedPage,
  handlePageNameChange,
}) => {
  const renderComponentType = (component) => {
    switch (component?.type) {
      case "text":
      case "number":
      case "date":
      case "email":
      case "password":
      case "tel":
      case "url":
      case "time":
      case "color":
      case "datetime":
      case "datetime-local":
      case "month":
      case "week":
      case "range":
      case "search":
      case "file":
        return (
          <InputActions
            currentInput={currentInput}
            componentData={componentData}
            setComponentData={setComponentData}
            activeComponent={activeComponent}
            setActiveComponent={setActiveComponent}
          />
        );
      case "textarea":
        return (
          <TextAreaActions
            currentInput={currentInput}
            componentData={componentData}
            setComponentData={setComponentData}
            activeComponent={activeComponent}
            setActiveComponent={setActiveComponent}
          />
        );

      case "singleImageUpload":
        return (
          <SingleImageUploadActions
            currentInput={currentInput}
            componentData={componentData}
            setComponentData={setComponentData}
            activeComponent={activeComponent}
            setActiveComponent={setActiveComponent}
          />
        );

      case "button":
        return (
          <ButtonActions
            currentInput={currentInput}
            componentData={componentData}
            setComponentData={setComponentData}
            activeComponent={activeComponent}
            setActiveComponent={setActiveComponent}
          />
        );

      case "checkbox":
      case "radio":
      case "switch":
        return (
          <Actions
            currentInput={currentInput}
            componentData={componentData}
            setComponentData={setComponentData}
            activeComponent={activeComponent}
            setActiveComponent={setActiveComponent}
          />
        );

      case "select":
        return (
          <SelectActions
            currentInput={currentInput}
            componentData={componentData}
            setComponentData={setComponentData}
            activeComponent={activeComponent}
            setActiveComponent={setActiveComponent}
          />
        );

      case "verticalContainer":
        return (
          <VerticalContainerActions
            currentInput={currentInput}
            componentData={componentData}
            setComponentData={setComponentData}
            activeComponent={activeComponent}
            setActiveComponent={setActiveComponent}
          />
        );

      case "useFieldArray":
        return (
          <UseFieldArrayActions
            currentInput={currentInput}
            componentData={componentData}
            setComponentData={setComponentData}
            activeComponent={activeComponent}
            setActiveComponent={setActiveComponent}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div className="workspace-sidebar actions-sidebar">
      <div className="workspace-sidebar-header">
        <h4>تعديل الحقل</h4>
      </div>
      <div className="workspace-sidebar-body">
        {/* {pages ? (
          <div className="pages">
            <div className="sidebar-form-wrapper">
              <label className="form-label">عنوان الحقل (label)</label>
              <div className="form-group">
                <div className="input-holder">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="عنوان الحقل (label)"
                    onChange={(e) => handlePageNameChange(e)}
                    value={selectedPage.name}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : ( */}
        {renderComponentType(activeComponent)}
        {/* )} */}
      </div>
      {/* <div className="workspace-sidebar-footer">
        <button
          className={
            pages.length < 1 && activeComponent.id === undefined
              ? "btn btn-primary disabled"
              : "btn btn-primary"
          }
          onClick={() => {
            if (activeComponent.id !== undefined) {
              setActiveComponent({});
            }
          }}
        >
          حفظ
        </button>
      </div> */}
    </div>
  );
};

export default ActionsSidebar;
