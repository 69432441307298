import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { Editor } from "components/shared/Editor";
import StickyLoader from "components/shared/StickyLoader";
import { addSection } from "store/actions";
import ImageUpload from "components/shared/ImageUpload";
import SlidersArray from "./SliderArray";

const AddSection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const [sliders, setSliders] = useState([]);

  const { loading } = useSelector((state) => state.portalSections);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const onSubmit = (data) => {
    // if (data.backgroundImage)
    //   data.backgroundImage = "uploads/" + data.backgroundImage.split("/").pop();

    if (!data?.name && data?.title?.en) data.name = data.title.en;

    data.content = { ...(data.content || {}) };

    data.content.items = sliders?.map((item) => ({
      ...item,
      image:
        // "uploads/" +
        item?.image,
      // ?.split("/").pop(),
    }));

    dispatch(addSection({ data, navigate }));
  };

  return (
    <>
      {loading && <StickyLoader fill="#346bf4" />}

      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="addSection" />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col md={12}>
                <Row>
                  <Col xs={12} className="multiUploadWrapper">
                    <div className="form-group backgroundImage">
                      <h5>
                        <FormattedMessage id="backgroundImage" />
                      </h5>
                      <Controller
                        control={control}
                        name="backgroundImage"
                        render={({ field: { value, onChange } }) => (
                          <ImageUpload
                            selectedImage={value}
                            setSelectedImage={(image) => onChange(image)}
                          />
                        )}
                      />
                    </div>
                  </Col>

                  <Col lg={6} xs={12}>
                    <div className="form-group">
                      <h5>
                        <FormattedMessage id="title" /> (
                        <FormattedMessage id="arabic" />)
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          {...register("title.ar")}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group">
                      <h5>
                        <FormattedMessage id="title" /> (
                        <FormattedMessage id="english" />)
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          {...register("title.en")}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group">
                      <h5>
                        <FormattedMessage id="subTitle" /> (
                        <FormattedMessage id="arabic" />)
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          {...register("subTitle.ar")}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group">
                      <h5>
                        <FormattedMessage id="subTitle" /> (
                        <FormattedMessage id="english" />)
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          {...register("subTitle.en")}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col xl={6} xs={12}>
                    <div className="form-group">
                      <h5>
                        <FormattedMessage id="description" /> (
                        <FormattedMessage id="arabic" />)
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          name="subTitle.description.ar"
                          render={({ field: { value, onChange } }) => (
                            <Editor
                              initialValue={value}
                              onChange={(e, editor) => {
                                const data = editor.getContent();
                                onChange(data);
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col xl={6} xs={12}>
                    <div className="form-group">
                      <h5>
                        <FormattedMessage id="description" /> (
                        <FormattedMessage id="english" />)
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          name="subTitle.description.en"
                          render={({ field: { value, onChange } }) => (
                            <Editor
                              initialValue={value}
                              onChange={(e, editor) => {
                                const data = editor.getContent();
                                onChange(data);
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                  <SlidersArray {...{ sliders, setSliders }} />

                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id="add" />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSection;
