import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addThemeApi,
  deleteThemeApi,
  editThemeApi,
  getSingleThemeApi,
  getThemesApi,
} from "api/themesTemplates";

// Login Redux States
import {
  GET_THEMES_TEMPLATES,
  ADD_THEMES_TEMPLATE,
  EDIT_THEMES_TEMPLATE,
  DELETE_THEMES_TEMPLATE,
  GET_SINGLE_THEMES_TEMPLATE,
} from "./actionTypes";
import {
  addThemesTemplateFailure,
  addThemesTemplateSuccess,
  deleteThemesTemplateFailure,
  deleteThemesTemplateSuccess,
  editThemesTemplateFailure,
  editThemesTemplateSuccess,
  getSingleThemesTemplateFailure,
  getSingleThemesTemplateSuccess,
  getThemesTemplatesFailure,
  getThemesTemplatesSuccess,
} from "./actions";

function* getThemes({ payload }) {
  try {
    const { data } = yield call(getThemesApi, payload);
    yield put(getThemesTemplatesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(
      getThemesTemplatesFailure(error?.response?.data?.errors?.[0]?.msg)
    );
  }
}

function* getSingleTheme({ payload }) {
  try {
    const { data } = yield call(getSingleThemeApi, payload);
    yield put(getSingleThemesTemplateSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleThemesTemplateFailure(error?.response?.data || error));
  }
}

function* addTheme({ payload }) {
  try {
    const { data } = yield call(addThemeApi, payload);
    yield put(addThemesTemplateSuccess(data));
    yield payload.navigate(
      `/themes/templates?deviceType=${payload?.deviceType}`
    );
  } catch (error) {
    console.log(error);
    yield put(addThemesTemplateFailure(error.response?.data?.message || error));
  }
}

function* editTheme({ payload }) {
  try {
    const { data } = yield call(editThemeApi, payload);
    yield put(editThemesTemplateSuccess(data));
    yield payload.navigate(
      `/themes/templates?deviceType=${payload?.deviceType}`
    );
  } catch (error) {
    console.log(error);
    yield put(editThemesTemplateFailure(error?.response?.data || error));
  }
}

function* deleteTheme({ payload }) {
  try {
    yield call(deleteThemeApi, payload);
    yield put(deleteThemesTemplateSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteThemesTemplateFailure(error?.response?.data || error));
  }
}

export function* watchGetThemes() {
  yield takeEvery(GET_THEMES_TEMPLATES, getThemes);
}

export function* watchGetSingleTheme() {
  yield takeEvery(GET_SINGLE_THEMES_TEMPLATE, getSingleTheme);
}

export function* watchAddTheme() {
  yield takeEvery(ADD_THEMES_TEMPLATE, addTheme);
}

export function* watchEditTheme() {
  yield takeEvery(EDIT_THEMES_TEMPLATE, editTheme);
}

export function* watchDeleteTheme() {
  yield takeEvery(DELETE_THEMES_TEMPLATE, deleteTheme);
}

function* themesSaga() {
  yield all([fork(watchGetThemes)]);
  yield all([fork(watchGetSingleTheme)]);
  yield all([fork(watchAddTheme)]);
  yield all([fork(watchEditTheme)]);
  yield all([fork(watchDeleteTheme)]);
}

export default themesSaga;
