import {
  GET_USERS,
  GET_USERS_FAILURE,
  GET_USERS_SUCCESS,
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  GET_SINGLE_USER,
  GET_SINGLE_USER_SUCCESS,
  GET_SINGLE_USER_FAILURE,
} from "./actionTypes";

export const getUsers = () => {
  return {
    type: GET_USERS,
  };
};

export const getUsersSuccess = (users) => {
  return {
    type: GET_USERS_SUCCESS,
    payload: users,
  };
};

export const getUsersFailure = (error) => {
  return {
    type: GET_USERS_FAILURE,
    payload: error,
  };
};

export const getSingleUser = (id) => {
  return {
    type: GET_SINGLE_USER,
    payload: id,
  };
};

export const getSingleUserSuccess = (user) => {
  return {
    type: GET_SINGLE_USER_SUCCESS,
    payload: user,
  };
};

export const getSingleUserFailure = (error) => {
  return {
    type: GET_SINGLE_USER_FAILURE,
    payload: error,
  };
};

export const addUser = (payload) => {
  return {
    type: ADD_USER,
    payload,
  };
};

export const addUserSuccess = (payload) => {
  return {
    type: ADD_USER_SUCCESS,
    payload,
  };
};

export const addUserFailure = (error) => {
  return {
    type: ADD_USER_FAILURE,
    payload: error,
  };
};

export const editUser = (payload) => {
  return {
    type: EDIT_USER,
    payload,
  };
};

export const editUserSuccess = (payload) => {
  return {
    type: EDIT_USER_SUCCESS,
    payload,
  };
};

export const editUserFailure = (error) => {
  return {
    type: EDIT_USER_FAILURE,
    payload: error,
  };
};

export const deleteUser = (id) => {
  return {
    type: DELETE_USER,
    payload: id,
  };
};

export const deleteUserSuccess = (id) => {
  return {
    type: DELETE_USER_SUCCESS,
    payload: id,
  };
};

export const deleteUserFailure = (error) => {
  return {
    type: DELETE_USER_FAILURE,
    payload: error,
  };
};
