import React from "react";
import { useDrag } from "react-dnd";
// import { ReactComponent as GrapperIcon } from "assets/builder/inputs/grapper.svg";

const InputDropTemplate = ({ input }) => {
  const [collected, drag, dragPreview] = useDrag(() => ({
    type: "text",
    item: {
      type: input.type,
    },
  }));

  return collected.isDragging ? (
    <div ref={dragPreview} />
  ) : (
    <div ref={drag} {...collected} className="inputItem">
      {input.icon}
      {input.label}
      {/* <GrapperIcon className="grapper" /> */}
    </div>
  );
};

export default InputDropTemplate;
