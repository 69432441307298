import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { addThemesComponentsType, getThemesPages } from "store/actions";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "components/shared/Loader";
import { useIntl } from "react-intl";
import { getId } from "helpers/functions";

const AddTheme = () => {
  const { pages } = useSelector((state) => state?.pages);

  const { locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state?.componentsTypes);
  const [searchParams] = useSearchParams();
  const deviceType = searchParams.get("deviceType");

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm();

  const [toggleButtonText, setToggleButtonText] = useState("اختيار الكل");

  const [selectedPages, setSelectedPages] = useState([]);

  useEffect(() => {
    dispatch(getThemesPages(deviceType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchParams]);

  useEffect(() => {
    if (getValues("pages")?.length === pages?.length)
      return setToggleButtonText("عدم اختيار الكل");
    setToggleButtonText(" اختيار الكل");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("pages")]);

  const onSubmit = (data) => {
    if (!data?.pages) data.pages = [];

    dispatch(addThemesComponentsType({ data, navigate, deviceType }));
  };
  if (loading) return <Loader />;

  const renderPages = pages?.map((page, index) => (
    <Col md="4" lg="3" xs="12" key={index}>
      <label
        className={`form-group ${
          selectedPages?.includes(getId(page)) ? "active" : ""
        }`}
      >
        <h5>
          <input
            type="checkbox"
            // className="form-control form-outline"
            {...register("pages[]", {
              onChange: ({ target: { value } }) =>
                setSelectedPages((prev) => {
                  return prev?.includes(value)
                    ? prev?.filter((e) => e !== value)
                    : [...prev, value];
                }),
            })}
            value={getId(page)}
          />
          {page?.name?.[locale]}
        </h5>
      </label>
    </Col>
  ));

  const handleToggleSelect = () => {
    if (getValues("pages")?.length === pages?.length) {
      setValue("pages", []);
    } else {
      setValue(
        "pages",
        pages?.map((p) => getId(p))
      );
    }
  };

  return (
    <div className="acc-form component-type">
      <div className="card">
        <div className="card-head">
          <h4>اضف نوع عنصر جديد</h4>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Col xl={10} md={12}>
              <Row>
                <Col lg={6} xs={12}>
                  <div className="form-group required">
                    <h5>اسم نوع العنصر بالعربية</h5>
                    <div>
                      <input
                        type="text"
                        className="form-control form-outline"
                        placeholder="اسم نوع العنصر بالعربية"
                        {...register("name[ar]", { required: true })}
                      />
                    </div>
                    <p className="error-hint">
                      {errors?.name?.["ar"]?.type === "required" &&
                        "يرجي ادخال اسم نوع العنصر بالعربية"}
                    </p>
                  </div>
                </Col>
                <Col lg={6} xs={12}>
                  <div className="form-group required">
                    <h5>اسم نوع العنصر بالانجليزية</h5>
                    <div>
                      <input
                        type="text"
                        className="form-control form-outline"
                        placeholder="اسم نوع العنصر بالانجليزية"
                        {...register("name[en]", { required: true })}
                      />
                    </div>
                    <p className="error-hint">
                      {errors?.name?.["en"]?.type === "required" &&
                        "يرجي ادخال اسم نوع العنصر بالانجليزية"}
                    </p>
                  </div>
                </Col>
                <Col xs={12}>
                  <div
                    className="form-group pages-select-wrap"
                    // style={{ maxHeight: "60vh", overflow: "auto" }}
                  >
                    <h5>
                      <button
                        type="button"
                        className=""
                        onClick={handleToggleSelect}
                      >
                        {toggleButtonText}
                      </button>
                      اختر الصفحات
                    </h5>
                    <Row>{renderPages}</Row>
                    {/* <p className="error-hint">
                      {errors?.name?.["en"]?.type === "required" &&
                        "يرجي ادخال اسم نوع العنصر بالانجليزية"}
                    </p> */}
                  </div>
                </Col>
              </Row>
              <Col lg={4} xs={12}>
                <div className="form-group">
                  <button type="submit" className="btn btn-blue">
                    اضافة نوع العنصر
                  </button>
                </div>
              </Col>
            </Col>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddTheme;
