import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import {
  editCategory,
  editPortalHelpBlog,
  getPortalHelpCategories,
  getSingleCategory,
  getSinglePortalHelpBlog,
  getTags,
} from "store/actions";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "components/shared/Loader";
import server from "api/server";
import imageUpload from "assets/svgs/imageUpload.svg";
import StickyLoader from "components/shared/StickyLoader";
import { FormattedMessage, useIntl } from "react-intl";
import MultiUpload from "components/themes/MultiUpload";
import ReactSelect from "react-select";
import { getId } from "helpers/functions";
import { portalHelpCategoriesTypes } from "helpers/configs";
import { Editor } from "components/shared/Editor";
import CreatableSelect from "react-select/creatable";
import SeoForm from "components/shared/SeoForm";

const EditHelpBlog = () => {
  const [selectedImages, setSelectedImages] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formatMessage, locale } = useIntl();
  const { id } = useParams();
  const [tags, setTags] = useState([]);

  const { singlePortalHelpBlog, loading } = useSelector(
    (state) => state?.portalHelpBlogs
  );
  const { portalHelpCategories, loading: loadingCategories } = useSelector(
    (state) => state?.portalHelpCategories
  );
  const { blogTags } = useSelector((state) => state.blogs) || {};
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { errors },
    getValues,
  } = useForm();

  useEffect(() => {
    dispatch(getSinglePortalHelpBlog(id));
    dispatch(getTags());
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getPortalHelpCategories(`?type=${watch("type")}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, watch("type")]);

  useEffect(() => {
    setValue(
      "images",
      selectedImages?.map(({ image }) => image || "")
    );
  }, [selectedImages, setValue]);

  useEffect(() => {
    reset({
      title: singlePortalHelpBlog?.title,
      content: singlePortalHelpBlog?.content,
      seo: singlePortalHelpBlog?.seo,
      category: getId(singlePortalHelpBlog?.category),
      parentCategory:
        getId(singlePortalHelpBlog?.category?.parentCategory) ||
        getId(singlePortalHelpBlog?.category),
      type: singlePortalHelpBlog?.type,
    });

    setTags(singlePortalHelpBlog?.tags || []);

    setSelectedImages(
      singlePortalHelpBlog?.images?.map((image, index) => ({
        preview: "",
        image: image,
        isMain: index === 0 ? true : false,
      })) || []
    );
  }, [reset, singlePortalHelpBlog]);

  const onSubmit = (data) => {
    data.tags = tags;
    data.seo.keywords = tags;

    dispatch(
      editPortalHelpBlog({
        id,
        data,
        navigate: (route) => {
          navigate(route);
          reset({});
        },
      })
    );
  };

  if (loading) return <Loader />;
  return (
    <>
      {/* {loadingCategories && <StickyLoader fill="#346bf4" />} */}
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="editHelpBlog" />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col xs={12} className="multiUploadWrapper">
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="blogImages" />
                      </h5>
                      <MultiUpload
                        images={selectedImages}
                        setImages={setSelectedImages}
                        accept="image/*,video/*"
                      />
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group">
                      <h5>
                        <FormattedMessage id="link" />
                      </h5>
                      <div>
                        <input
                          type="url"
                          className="form-control form-outline"
                          {...register("link")}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="blogTitle" />
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          {...register("title", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="types" />
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          name="type"
                          render={({ field: { onChange, value } }) => (
                            <ReactSelect
                              isRtl={true}
                              // menuIsOpen={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"
                              options={portalHelpCategoriesTypes?.map(
                                (type) => ({
                                  label: formatMessage({ id: type }),
                                  value: type,
                                })
                              )}
                              placeholder={formatMessage({ id: "selectType" })}
                              isMulti={false}
                              value={{
                                label: formatMessage({ id: value || " " }),
                                value: value,
                              }}
                              onChange={({ value }) => {
                                onChange(value);
                              }}
                            />
                          )}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.type?.type === "required" && (
                          <FormattedMessage id="required" />
                        )}
                      </p>
                    </div>
                  </Col>

                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="parentCategory" />
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          name="parentCategory"
                          render={({ field: { onChange, value } }) => (
                            <ReactSelect
                              isDisabled={!portalHelpCategories?.length}
                              isRtl={true}
                              // menuIsOpen={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"
                              options={portalHelpCategories?.map((item) => ({
                                label: item?.title?.[locale],
                                value: getId(item),
                                subs: item?.subCategories?.length,
                              }))}
                              placeholder={formatMessage({
                                id: "selectMainCategory",
                              })}
                              isMulti={false}
                              value={portalHelpCategories
                                ?.map((item) => ({
                                  label: item?.title?.[locale],
                                  value: getId(item),
                                }))
                                ?.find((cat) => cat?.value === value)}
                              onChange={({ value, subs }) => {
                                onChange(value);
                                if (subs === 0 || !subs)
                                  setValue("category", value);
                              }}
                            />
                          )}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.parentCategory?.type === "required" && (
                          <FormattedMessage id="required" />
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="category" />
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          name="category"
                          // rules={{ required: true }}
                          render={({ field: { onChange, value } }) => {
                            const subs = portalHelpCategories?.find(
                              (cat) => getId(cat) === watch("parentCategory")
                            )?.subCategories;

                            return (
                              <ReactSelect
                                isRtl={true}
                                isDisabled={!subs?.length}
                                // menuIsOpen={true}
                                isSearchable={false}
                                className="basic-single"
                                classNamePrefix="select"
                                options={subs?.map((item) => ({
                                  label: item?.title?.[locale],
                                  value: getId(item),
                                }))}
                                placeholder={formatMessage({
                                  id: "selectType",
                                })}
                                value={subs
                                  ?.map((item) => ({
                                    label: item?.title?.[locale],
                                    value: getId(item),
                                  }))
                                  ?.find((sub) => sub?.value === value)}
                                isMulti={false}
                                onChange={({ value }) => {
                                  onChange(value);
                                }}
                              />
                            );
                          }}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.category?.type === "required" && (
                          <FormattedMessage id="required" />
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="blogDescription" />
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          name="content"
                          render={({ field: { value, onChange } }) => (
                            <Editor
                              initialValue={value}
                              onChange={(e, editor) => {
                                const data = editor.getContent();
                                onChange(data);
                              }}
                              tinymceScriptSrc="https://cdn.tiny.cloud/1/qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc/tinymce/5-stable/tinymce.min.js"
                            />
                          )}
                        />
                        <p className="error-hint">
                          {errors?.content?.type === "required" && (
                            <FormattedMessage id="required" />
                          )}
                        </p>
                      </div>
                    </div>
                  </Col>

                  <Col lg={12}>
                    <div className="form-group">
                      <h5>اضافة كلمات دلالية</h5>
                      <div>
                        <Controller
                          control={control}
                          name="tags"
                          render={({
                            field: { onChange, value, selected },
                          }) => (
                            <CreatableSelect
                              isMulti
                              isRtl={true}
                              className="basic-single creatable-select"
                              classNamePrefix="select"
                              placeholder="اضف كلمات دلالية"
                              onChange={(selected) => {
                                const newTags = selected.map(
                                  (tag) => tag.label
                                );

                                setTags(newTags);
                              }}
                              value={
                                blogTags
                                  .map((tag) => ({
                                    value: tag,
                                    label: tag,
                                  }))
                                  ?.filter(({ value }) =>
                                    tags?.includes(value)
                                  ) || []
                              }
                              options={blogTags.map((tag) => ({
                                value: tag,
                                label: tag,
                              }))}
                            />
                          )}
                        />
                      </div>
                      <p className="error-hint">
                        {errors.tags?.type === "required" &&
                          "يرجي ادخال الكلمات الدلالية"}
                      </p>
                    </div>
                  </Col>
                  <SeoForm errors={errors} register={register} />

                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        حفظ التعديلات
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditHelpBlog;
