import server from "api/server";
import React, { useEffect, useState } from "react";
import imageUpload from "assets/svgs/imageUpload.svg";
import removeImg from "assets/svgs/close-icon.svg";
import { FormattedMessage } from "react-intl";
import { getPublicImage, handleImage } from "helpers/functions";

const ImageUpdate = ({ item, sliders, setSliders, name = "image" }) => {
  const [selectedImage, setSelectedImage] = useState({
    preview: null,
    path: "",
  });

  const imageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);

      const { data } = await server().post("/upload", formData);

      setSelectedImage({
        preview: e.target.files[0],
        path: getPublicImage(data?.data?.files?.[0]),
      });

      const newSections = sliders.map((section) => {
        if (section.id !== item.id) return section;
        return {
          ...section,
          [name]: `${getPublicImage(data?.data?.files?.[0])}`,
        };
      });

      setSliders(() => newSections);

      e.target.value = null;
    }
  };

  const removeSelectedImage = (e) => {
    e.preventDefault();

    setSelectedImage("");
    const newSections = sliders.map((section) => {
      if (section.id !== item.id) return section;
      return {
        ...section,
        [name]: ``,
      };
    });

    setSliders(() => newSections);
  };

  const fetchAndProcessSVG = async (url) => {
    const blob = await (await fetch(url)).blob();
    if (!blob.type.includes("svg")) return;

    const svgString = await blob.text();
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgString, "image/svg+xml");
    const svgElem = svgDoc.documentElement;

    svgElem.setAttribute("fill", "eventOdd");
    const serializer = new XMLSerializer();
    const newSvgString = serializer.serializeToString(svgElem);
    const newBase64String = btoa(newSvgString);
    const newBase64Data = `data:image/svg+xml;base64,${newBase64String}`;

    return newBase64Data;
  };

  const processImage = async (e) => {
    const url = e.target.src || "";
    const base64Data = await fetchAndProcessSVG(url);
    if (base64Data) {
      e.target.style.background = `url(${base64Data}) no-repeat center / cover`;
      // e.target.style.filter = "brightness(0) invert(1)";
    }
  };
  return (
    <div className="form-group">
      <h5>
        <FormattedMessage id={name} />
      </h5>

      <div className="image-uploader image-contain">
        <div className="img-pat">
          <img src={imageUpload} width={46} height={46} alt="" />
        </div>
        <label>
          {(selectedImage.preview || item?.[name]) && (
            <div>
              {name === "video" ? (
                <video
                  src={
                    selectedImage && selectedImage.preview
                      ? URL.createObjectURL(selectedImage.preview)
                      : handleImage(item?.[name])
                  }
                  width={160}
                  height={160}
                  autoPlay
                  muted
                />
              ) : (
                <img
                  src={
                    selectedImage && selectedImage.preview
                      ? URL.createObjectURL(selectedImage.preview)
                      : handleImage(item?.[name])
                  }
                  onLoad={async (e) => {
                    if (!e?.target?.getAttribute("data-error")) {
                      processImage(e);
                      e.target.setAttribute("data-error", "true");
                    }
                  }}
                  width={160}
                  height={160}
                  alt=""
                />
              )}
              <button className="removeImg" onClick={removeSelectedImage}>
                <img src={removeImg} alt="" />
              </button>
            </div>
          )}
          <input
            accept={name !== "video" ? "image/*" : "video/*"}
            type="file"
            onChange={imageChange}
          />
        </label>
      </div>
    </div>
  );
};

export default ImageUpdate;
