// export const handleImage = (image) => {
//   if (image) {
//     if (!image.startsWith("/")) image = `/${image}`;
//     return `${process.env.REACT_APP_API_URL}${image}`;
//   }
//   return "https://picsum.photos/seed/picsum/200/300";
// };

export const handleImage = (image) => {
  if (image) {
    if (image?.includes("http")) return image;
    if (
      typeof image == "string" &&
      image?.length > 0 &&
      !image?.startsWith("/")
    )
      image = `/${image}`;
    return `${process.env.REACT_APP_BASE_URL}${image}`;
  }
  return "";
};

export const checkIfUserHasPermission = (
  userPermissions = [],
  testedPermission
) => {
  if (userPermissions.length === 0) return false;
  const hasPermission = userPermissions.includes(testedPermission);
  return hasPermission;
};

export const handleSearchParamsChange = (search, params) => {
  const url = new URLSearchParams("");
  search = { ...search, ...params };
  Object.entries(search).map(([key, value]) => {
    return url.set(key, value);
  });
  return "?" + url;
};
export const themeSwitcherFunction = () => {
  document.querySelector(".darkSwitcher")?.classList.toggle("fire");
  document.querySelector(".themetoggle").classList.toggle("fire");
  document.querySelector("body")?.classList.toggle("dark");
};

export const sidebarToggle = () => {
  document.querySelector("body").classList.toggle("sidebarToggled");
  document.querySelector(".overlay-s").classList.toggle("fire");
  document.querySelector("html").classList.toggle("offScroll");
};

export const sidebarToggleFunction = () => {
  document.querySelector("body")?.classList.remove("sidebarToggled");
  document.querySelector(".overlay-s")?.classList.remove("fire");
  document.querySelector("html")?.classList.remove("offScroll");
};

export const getId = (obj) =>
  obj?._id || obj?.id || (obj !== undefined ? `${obj}` : "");

export const getDecodedParam = (param, searchParams) => {
  const decodedValue = decodeURI(searchParams?.get(param) || "") || "[]";
  let parsedText = "";
  try {
    parsedText = JSON.parse(decodedValue);
  } catch (error) {
    console.log(error);
  }
  return parsedText;
};

export const getSettingsBySlug = (settings, slug) => {
  return settings.find((setting) => {
    return getId(setting) === slug;
  });
};
export const onKeyDownPreventSpace = {
  onKeyPress: (event) => {
    if (/[ ]/.test(event.key)) {
      event.preventDefault();
    }
  },
};

export const getPublicImage = (arr) =>
  (arr?.variants || arr)?.find((e) => e?.includes("public"));

export const getImageVariant = (url, variant = "public") => {
  if (!url) return;
  const rawUrl = url.split("public")[0];
  if (!rawUrl.includes("https://imagedelivery.net")) return url;
  return rawUrl + variant;
};
