import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Accordion, Form } from "react-bootstrap";

import { ReactComponent as FilterIcon } from "assets/svgs/filter.svg";
import { ReactComponent as CloseIcon } from "assets/svgs/close-icon.svg";
// import statusIcon from "assets/svgs/order-status.svg";

import { useIntl, FormattedMessage } from "react-intl";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getDecodedParam, getId } from "helpers/functions";

const FilterSidebar = ({ filtersObj }) => {
  const { locale } = useIntl();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const href = window.location.pathname;

  const [, setUpdater] = useState(0);

  // const [isLoading, setIsLoading] = useState(false);

  const updateParams = (name, value, arrFlag = false) => {
    let finalValue = "";
    // if (searchParams?.has(name)) {
    if (arrFlag) {
      finalValue = getDecodedParam(name, searchParams) || [];
      if (!finalValue?.includes(value))
        finalValue = JSON.stringify([...finalValue, value]);
      else {
        finalValue = finalValue?.filter((item) => item !== value);
        if (finalValue?.length === 0) finalValue = "";
        else finalValue = JSON.stringify(finalValue);
      }
    } else {
      if (value?.length === 0) return removeParams(name);
      finalValue = value;
    }
    // }
    if (finalValue?.length === 0) return removeParams(name);
    searchParams.set(name, finalValue);
    setUpdater(Date.now());
  };
  const removeParams = (name) => {
    searchParams.delete(name);
    setUpdater(Date.now());
  };

  const handleFilterSubmit = async () => {
    const paramsStr =
      searchParams?.size > 0 ? `?${searchParams.toString()}` : "";
    navigate(`${href}${paramsStr}`, { replace: true });
    handleclose();
  };

  const handleReset = () => {
    const deviceType = searchParams.get("deviceType");
    searchParams.forEach((value, key) => {
      if (key !== "deviceType") searchParams.delete(key);
    });
    searchParams.set("deviceType", deviceType);
    const paramsStr =
      searchParams?.size > 0 ? `?${searchParams.toString()}` : "";
    navigate(`${href}${paramsStr}`, {
      replace: true,
    });
    handleclose();
  };

  const handleclose = () => {
    document.querySelector(".ordersFilterSidebar")?.classList.toggle("active");
    document.querySelector(".overlay-g")?.classList.toggle("active");
  };

  const renderFilters = useMemo(
    () =>
      Object.keys(filtersObj).map((filterName, index) => {
        const filterObj = filtersObj?.[filterName];
        let data = filterObj?.data;
        // if (filterName === "areas")
        //   data = data?.filter(
        //     (a) => getId(a?.city) === getId(searchParams?.get("city"))
        //   );
        if (filterObj?.type === "none" || data?.length === 0) return null;
        // if (filterObj?.type === "areas")
        //   return (
        //     <AreasFilter filterObj={filtersObj} index={index} key={index} />
        //   );
        if (filterObj?.type === "dateRange")
          return (
            <Accordion.Item eventKey={index} key={index}>
              <Accordion.Header>
                <span>
                  <FilterIcon />
                  <FormattedMessage id={filterName} />
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>
                    <label>
                      <span>
                        <FormattedMessage id="from" />
                      </span>
                      <Form.Control
                        type="date"
                        defaultValue={getDecodedParam("from", searchParams)}
                        onChange={({ target: { value } }) =>
                          updateParams("from", value)
                        }
                      />
                    </label>
                  </li>
                  <li>
                    <label>
                      <span>
                        <FormattedMessage id="to" />
                      </span>
                      <Form.Control
                        type="date"
                        defaultValue={getDecodedParam("to", searchParams)}
                        onChange={({ target: { value } }) =>
                          updateParams("to", value)
                        }
                      />
                    </label>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          );
        return (
          <Accordion.Item eventKey={index} key={index}>
            <Accordion.Header>
              <span>
                <FilterIcon />
                <FormattedMessage id={filterName} />
              </span>
            </Accordion.Header>
            <Accordion.Body className={filterObj?.className || ""}>
              <ul>
                {data?.map((filter, index) => (
                  <li key={index}>
                    <label>
                      <input
                        type={filterObj?.type}
                        name={filterName}
                        value={getId(filter)}
                        onClick={({ target: { value } }) =>
                          updateParams(
                            filterName,
                            value,
                            filterObj?.type === "checkbox"
                          )
                        }
                        checked={getDecodedParam(
                          filterName,
                          searchParams
                        )?.includes(getId(filter))}
                      />
                      <span>
                        {filter?.name?.[locale] || (
                          <FormattedMessage id={filter} />
                        )}
                      </span>
                    </label>
                  </li>
                ))}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        );
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(filtersObj), locale, updateParams, `${searchParams}`]
  );

  // if (isLoading) return <StickyLoader fill="#FC6B14" />;

  return (
    <div className="ordersFilterSidebar">
      <div className="sidebarHead">
        <span>
          <FilterIcon />
          فرز المنتحات حسب
        </span>
        <button className="closeSidebar">
          <CloseIcon />
        </button>
      </div>
      <div className="sidebarBody">
        <Accordion defaultActiveKey="0" alwaysOpen>
          {renderFilters}
        </Accordion>
      </div>
      <div className="sidebarFooter">
        <button className="btn btn-blue" onClick={handleFilterSubmit}>
          حفظ التغييرات
        </button>
        <button className="btn btn-red" onClick={handleReset}>
          إلغاء
        </button>
      </div>
    </div>
  );
};

export default FilterSidebar;
