export const GET_THEMES_COMPONENTS = "GET_THEMES_COMPONENTS";
export const GET_THEMES_COMPONENTS_SUCCESS = "GET_THEMES_COMPONENTS_SUCCESS";
export const GET_THEMES_COMPONENTS_FAILURE = "GET_THEMES_COMPONENTS_FAILURE";
export const GET_SINGLE_THEMES_COMPONENT = "GET_SINGLE_THEMES_COMPONENT";
export const GET_SINGLE_THEMES_COMPONENT_SUCCESS =
  "GET_SINGLE_THEMES_COMPONENT_SUCCESS";
export const GET_SINGLE_THEMES_COMPONENT_FAILURE =
  "GET_SINGLE_THEMES_COMPONENT_FAILURE";
export const ADD_THEMES_COMPONENT = "ADD_THEMES_COMPONENT";
export const ADD_THEMES_COMPONENT_SUCCESS = "ADD_THEMES_COMPONENT_SUCCESS";
export const ADD_THEMES_COMPONENT_FAILURE = "ADD_THEMES_COMPONENT_FAILURE";
export const EDIT_THEMES_COMPONENT = "EDIT_THEMES_COMPONENT";
export const EDIT_THEMES_COMPONENT_SUCCESS = "EDIT_THEMES_COMPONENT_SUCCESS";
export const EDIT_THEMES_COMPONENT_FAILURE = "EDIT_THEMES_COMPONENT_FAILURE";
export const DELETE_THEMES_COMPONENT = "DELETE_THEMES_COMPONENT";
export const DELETE_THEMES_COMPONENT_SUCCESS =
  "DELETE_THEMES_COMPONENT_SUCCESS";
export const DELETE_THEMES_COMPONENT_FAILURE =
  "DELETE_THEMES_COMPONENT_FAILURE";
