import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addFontApi,
  deleteFontApi,
  editFontApi,
  getFontsApi,
  getSingleFontApi,
} from "api/fonts";

// Login Redux States
import {
  ADD_FONT,
  DELETE_FONT,
  EDIT_FONT,
  GET_FONTS,
  GET_SINGLE_FONT,
} from "./actionTypes";
import {
  addFontFailure,
  addFontsuccess,
  deleteFontFailure,
  deleteFontsuccess,
  editFontFailure,
  editFontsuccess,
  getFontsFailure,
  getFontsSuccess,
  getSingleFontFailure,
  getSingleFontSuccess,
} from "./actions";

function* getFonts({ payload }) {
  try {
    const { data } = yield call(getFontsApi, payload);

    yield put(getFontsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getFontsFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* addFont({ payload }) {
  try {
    const { data } = yield call(addFontApi, payload);
    yield put(addFontsuccess(data));
    yield payload.navigate(`/fonts?deviceType=${payload?.deviceType}`);
  } catch (error) {
    console.log(error);
    yield put(addFontFailure(error.response?.data?.message || error));
  }
}

function* getSingleFont({ payload }) {
  try {
    const { data } = yield call(getSingleFontApi, payload);
    yield put(getSingleFontSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleFontFailure(error?.response?.data || error));
  }
}

function* editFont({ payload }) {
  try {
    yield call(editFontApi, payload);
    yield put(editFontsuccess(payload));
    yield payload.navigate(`/fonts?deviceType=${payload?.deviceType}`);
  } catch (error) {
    console.log(error);
    yield put(editFontFailure(error?.response?.data || error));
  }
}

function* deleteFont({ payload }) {
  try {
    yield call(deleteFontApi, payload);
    yield put(deleteFontsuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteFontFailure(error?.response?.data || error));
  }
}

export function* watchGetFonts() {
  yield takeEvery(GET_FONTS, getFonts);
}

export function* watchAddFont() {
  yield takeEvery(ADD_FONT, addFont);
}

export function* watchGetSingleFont() {
  yield takeEvery(GET_SINGLE_FONT, getSingleFont);
}

export function* watchEditFont() {
  yield takeEvery(EDIT_FONT, editFont);
}

export function* watchDeleteFont() {
  yield takeEvery(DELETE_FONT, deleteFont);
}

function* fontsSaga() {
  yield all([fork(watchGetFonts)]);
  yield all([fork(watchGetSingleFont)]);
  yield all([fork(watchAddFont)]);
  yield all([fork(watchEditFont)]);
  yield all([fork(watchDeleteFont)]);
}

export default fontsSaga;
