import server from "./server";

export const getCategoriesApi = async () => {
  const response = await server().get("/categories");
  return response.data;
};

export const getSingleCategoryApi = async (id) => {
  const response = await server().get(`/categories/${id}`);
  return response.data;
};

export const addCategoryApi = async (payload) => {
  const response = await server().post("/categories", payload);
  return response.data;
};

export const editCategoryApi = async ({ id, data }) => {
  const response = await server().put(`/categories/${id}`, data);
  return response.data;
};

export const deleteCategoryApi = async (id) => {
  const response = await server().delete(`/categories/${id}`);
  return response.data;
};
