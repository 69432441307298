import server from "./server";

export const getAllSubscribersApi = async (payload) => {
  const response = await server().get(`/portalSubscribers${payload || ""}`);
  return response.data;
};

export const deleteSubscriberApi = async (id) => {
  const response = await server().delete(`/portalSubscribers/${id}`);
  return response.data;
};
