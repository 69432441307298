import ControlArea from "components/Layout/ControlArea";
import React from "react";
import { useSelector } from "react-redux";
import languageIcon from "assets/svgs/fonts.svg";
import Table from "./Table";
import { useSearchParams } from "react-router-dom";
const FontsIndex = () => {
  const { fonts } = useSelector((state) => state.fonts);
  const [searchParams] = useSearchParams();
  const deviceType = searchParams.get("deviceType");

  return (
    <>
      <div className="stores-wrap">
        <ControlArea
          btnTxt={deviceType === "mobile" ? "اضافة خط موبيل" : "اضافة خط جديد"}
          cardTxt="جميع الخطوط"
          icon={languageIcon}
          url={`/fonts/add?deviceType=${deviceType}`}
        />
        <Table data={fonts} />
      </div>
    </>
  );
};

export default FontsIndex;
