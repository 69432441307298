import ControlArea from "components/Layout/ControlArea";
import React from "react";
import { useSelector } from "react-redux";
import categoriesIcon from "assets/svgs/categories.svg";
import Table from "./Table";
import { useIntl } from "react-intl";

const Faqs = () => {
  const { formatMessage } = useIntl();
  const { portalFaqs, loading } = useSelector((state) => state.portalFaqs);
  return (
    <>
      <div className="stores-wrap">
        <ControlArea
          btnTxt={formatMessage({ id: "addFaq" })}
          cardTxt={formatMessage({ id: "faqs" })}
          icon={categoriesIcon}
          url="/portal/faqs/add"
        />
        <Table data={portalFaqs} loading={loading} />
      </div>
    </>
  );
};

export default Faqs;
