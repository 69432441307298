import server from "./server";

export const getPortalHelpBlogsApi = async (payload) => {
  const response = await server().get(`helpCenter${payload || ""}`);
  return response.data;
};

export const getSinglePortalHelpBlogApi = async (id) => {
  // static type for backend workaround
  const response = await server().get(`helpCenter/discussions/${id}`);
  return response.data;
};

export const addPortalHelpBlogApi = async (payload) => {
  const response = await server().post("helpCenter", payload);
  return response.data;
};

export const editPortalHelpBlogApi = async ({ id, data }) => {
  const response = await server().put(`helpCenter/${id}`, data);
  return response.data;
};

export const togglePortalHelpBlogApi = async (id) => {
  const response = await server().post(`helpCenter/approveSuggestion/${id}`);
  return response.data;
};

export const deletePortalHelpBlogApi = async (id) => {
  const response = await server().delete(`helpCenter/${id}`);
  return response.data;
};
