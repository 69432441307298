import server from "./server";

export const getPortalReservationsApi = async (payload) => {
  const response = await server().get(`/portalReservations${payload}`);
  return response.data;
};

export const getSinglePortalReservationApi = async (id) => {
  const response = await server().get(`/portalReservations/${id}`);
  return response.data;
};

export const addPortalReservationApi = async (payload) => {
  const response = await server().post("/portalReservations", payload);
  return response.data;
};

export const editPortalReservationApi = async ({ id, data }) => {
  const response = await server().put(`/portalReservations/${id}`, data);
  return response.data;
};

export const deletePortalReservationApi = async (id) => {
  const response = await server().delete(`/portalReservations/${id}`);
  return response.data;
};
