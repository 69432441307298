import {
  GET_UNITS,
  GET_UNITS_SUCCESS,
  GET_UNITS_FAILURE,
  GET_SINGLE_UNIT,
  GET_SINGLE_UNIT_SUCCESS,
  GET_SINGLE_UNIT_FAILURE,
  ADD_UNIT,
  ADD_UNIT_SUCCESS,
  ADD_UNIT_FAILURE,
  EDIT_UNIT,
  EDIT_UNIT_SUCCESS,
  EDIT_UNIT_FAILURE,
  DELETE_UNIT,
  DELETE_UNIT_SUCCESS,
  DELETE_UNIT_FAILURE,
} from "./actionTypes";

export const getUnits = () => {
  return {
    type: GET_UNITS,
  };
};

export const getUnitsSuccess = (units) => {
  return {
    type: GET_UNITS_SUCCESS,
    payload: units,
  };
};

export const getUnitsFailure = (error) => {
  return {
    type: GET_UNITS_FAILURE,
    payload: error,
  };
};

export const getSingleUnit = (id) => {
  return {
    type: GET_SINGLE_UNIT,
    payload: id,
  };
};

export const getSingleUnitSuccess = (unit) => {
  return {
    type: GET_SINGLE_UNIT_SUCCESS,
    payload: unit,
  };
};

export const getSingleUnitFailure = (error) => {
  return {
    type: GET_SINGLE_UNIT_FAILURE,
    payload: error,
  };
};

export const addUnit = (payload) => {
  return {
    type: ADD_UNIT,
    payload,
  };
};

export const addUnitSuccess = (payload) => {
  return {
    type: ADD_UNIT_SUCCESS,
    payload,
  };
};

export const addUnitFailure = (error) => {
  return {
    type: ADD_UNIT_FAILURE,
    payload: error,
  };
};

export const editUnit = (payload) => {
  return {
    type: EDIT_UNIT,
    payload,
  };
};

export const editUnitSuccess = (payload) => {
  return {
    type: EDIT_UNIT_SUCCESS,
    payload,
  };
};

export const editUnitFailure = (error) => {
  return {
    type: EDIT_UNIT_FAILURE,
    payload: error,
  };
};

export const deleteUnit = (id) => {
  return {
    type: DELETE_UNIT,
    payload: id,
  };
};

export const deleteUnitSuccess = (id) => {
  return {
    type: DELETE_UNIT_SUCCESS,
    payload: id,
  };
};

export const deleteUnitFailure = (error) => {
  return {
    type: DELETE_UNIT_FAILURE,
    payload: error,
  };
};
