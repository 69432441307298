import React from "react";
import { Accordion } from "react-bootstrap";
import { ReactComponent as DeleteIcon } from "assets/builder/delete.svg";
import { v4 } from "uuid";
import { onKeyDownPreventSpace } from "helpers/functions";
const SelectActions = ({ currentInput, componentData, setComponentData }) => {
  const handleInputChange = (e) => {
    const newComponentData = componentData?.map((component) => {
      if (component.id !== currentInput.id) return component;
      return {
        ...component,
        props: {
          ...component.props,
          [e.target.name]: e.target.value,
        },
      };
    });

    setComponentData(newComponentData);
  };

  const handleCheckedInputChange = (e) => {
    const newComponentData = componentData?.map((component) => {
      if (component.id !== currentInput.id) return component;
      return {
        ...component,
        props: {
          ...component.props,
          [e.target.name]: e.target.checked,
        },
      };
    });

    setComponentData(newComponentData);
  };

  const handleSelectOptionsChange = (e, index) => {
    const newComponentData = componentData?.map((component) => {
      if (component.id !== currentInput.id) return component;
      return {
        ...component,
        props: {
          ...component.props,
          options: component.props.options.map((option, i) => {
            if (i !== index) return option;
            return {
              ...option,
              [e.target.name]: e.target.value,
            };
          }),
        },
      };
    });

    setComponentData(newComponentData);
  };

  const addOptionsToComponent = (options) => {
    const newComponentData = componentData?.map((component) => {
      if (component.id !== currentInput.id) return component;
      return {
        ...component,
        props: {
          ...component.props,
          options: options,
        },
      };
    });

    setComponentData(newComponentData);
  };

  const handleDeleteOption = (index) => {
    const newComponentData = componentData?.map((component) => {
      if (component.id !== currentInput.id) return component;
      return {
        ...component,
        props: {
          ...component.props,
          options: component.props.options.filter((option, i) => i !== index),
        },
      };
    });

    setComponentData(newComponentData);
  };

  return (
    <div className="accordion-wrapper">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>الاعدادات الاساسية</Accordion.Header>
          <Accordion.Body>
            <div className="sidebar-form-wrapper">
              <label className="form-label">عنوان الحقل (label)</label>
              <div className="form-group">
                <div className="input-holder">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="عنوان الحقل (label)"
                    value={currentInput?.props?.label || ""}
                    onChange={handleInputChange}
                    name="label"
                  />
                </div>
              </div>
            </div>

            <div className="sidebar-form-wrapper">
              <label className="form-label">اضافة عناصر</label>
              <div className="form-group">
                <div className="items-holder">
                  <div className="i-head">
                    <button
                      type="button"
                      className="add-items"
                      onClick={() => {
                        const newOptions = currentInput?.props?.options || [];
                        newOptions.push({ label: "", value: v4() });
                        addOptionsToComponent(newOptions);
                      }}
                    >
                      + اضافة عناصر (add)
                    </button>
                  </div>
                  {currentInput?.props?.options?.length > 0 && (
                    <div className="i-body">
                      {currentInput?.props?.options?.map((option, index) => (
                        <div className="input-holder" key={index}>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="العنصر"
                            value={option.label}
                            onChange={(e) =>
                              handleSelectOptionsChange(e, index)
                            }
                            name="label"
                          />
                          <button
                            type="button"
                            className="remove-item"
                            onClick={() => handleDeleteOption(index)}
                          >
                            <DeleteIcon fill="#fff" />
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {currentInput?.props?.options?.length > 5 && (
              <div className="sidebar-form-wrapper">
                <label className="form-label">
                  {" "}
                  عدد العناصر المعروضة (size)
                </label>
                <div className="form-group">
                  <div className="input-holder">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="عدد العناصر المعروضة (size)"
                      value={currentInput?.props?.size || ""}
                      onChange={handleInputChange}
                      name="size"
                      min={0}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="sidebar-form-wrapper">
              <label className="form-label"> اسم الحقل (name)</label>
              <div className="form-group">
                <div className="input-holder">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="اسم الحقل (name)"
                    value={currentInput?.props?.name || ""}
                    onChange={handleInputChange}
                    name="name"
                  />
                </div>
              </div>
            </div>
            <div className="sidebar-form-wrapper">
              <label className="form-label"> عنوان الحقل (title)</label>
              <div className="form-group">
                <div className="input-holder">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="عنوان الحقل (title)"
                    value={currentInput?.props?.title || ""}
                    onChange={handleInputChange}
                    name="title"
                  />
                </div>
              </div>
            </div>
            <div className="sidebar-form-wrapper">
              <label className="form-label"> رمز الحقل (ID)</label>
              <div className="form-group">
                <div className="input-holder">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="رمز الحقل (ID)"
                    value={currentInput?.props?.id || ""}
                    onChange={handleInputChange}
                    name="id"
                    {...onKeyDownPreventSpace}
                  />
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>القيود</Accordion.Header>
          <Accordion.Body>
            <div className="sidebar-form-wrapper">
              <div className="form-group">
                <div className="label-holder">
                  <label>
                    <input
                      type="checkbox"
                      onChange={handleCheckedInputChange}
                      name="required"
                      defaultChecked={currentInput?.props?.required}
                    />
                    <span>مطلوب (required)</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="sidebar-form-wrapper">
              <div className="form-group">
                <div className="label-holder">
                  <label>
                    <input
                      type="checkbox"
                      onChange={handleCheckedInputChange}
                      name="disabled"
                      defaultChecked={currentInput?.props?.disabled}
                    />
                    <span>معطل (disabled)</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="sidebar-form-wrapper">
              <div className="form-group">
                <div className="label-holder">
                  <label>
                    <input
                      type="checkbox"
                      onChange={handleCheckedInputChange}
                      name="multiple"
                      defaultChecked={currentInput?.props?.multiple}
                    />
                    <span> متعدد (multiple)</span>
                  </label>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default SelectActions;
