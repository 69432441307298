import server from "./server";

export const getCurrenciesApi = async () => {
  const response = await server().get("/currencies");
  return response.data;
};

export const getSingleCurrencyApi = async (id) => {
  const response = await server().get(`/currencies/${id}`);
  return response.data;
};

export const addCurrencyApi = async (payload) => {
  const response = await server().post("/currencies", payload);
  return response.data;
};

export const editCurrencyApi = async ({ id, data }) => {
  const response = await server().put(`/currencies/${id}`, data);
  return response.data;
};

export const deleteCurrencyApi = async (id) => {
  const response = await server().delete(`/currencies/${id}`);
  return response.data;
};
