import ControlArea from "components/Layout/ControlArea";
import React from "react";
import { useSelector } from "react-redux";
import themesIcon from "assets/svgs/themes.svg";
import Table from "./Table";
import { useSearchParams } from "react-router-dom";
const Index = () => {
  const { componentsTypes } = useSelector((state) => state.componentsTypes);
  const [searchParams] = useSearchParams();

  const deviceType = searchParams.get("deviceType");

  return (
    <>
      <div className="stores-wrap">
        <ControlArea
          btnTxt="اضافة نوع عنصر"
          cardTxt="انواع العناصر"
          icon={themesIcon}
          url={`/themes/componentTypes/add?deviceType=${deviceType}`}
        />
        <Table data={componentsTypes} />
      </div>
    </>
  );
};

export default Index;
