import React from "react";

import InputsHolder from "../ComponentDataBuilder/InputsHolder";
// import WorkSpaceHeader from "./WorkSpaceHeader";

const ThemeCustomView = ({
  componentData,
  setComponentData,
  activeComponent,
  setActiveComponent,
  pages,
  setPages,
  handleAddPage,
  handleDeletePage,
  handleEditPage,
  selectedPage,
  setSelectedPage,
}) => {
  return (
    <div
      className="workspace-view"
      // style={activeComponent.id ? { width: "70%" } : { width: "85%" }}
    >
      {/* <WorkSpaceHeader
        pages={pages}
        setPages={setPages}
        handleAddPage={handleAddPage}
        handleDeletePage={handleDeletePage}
        handleEditPage={handleEditPage}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
      /> */}
      <div className="page-blocks">
        <InputsHolder
          componentData={componentData}
          setComponentData={setComponentData}
          activeComponent={activeComponent}
          setActiveComponent={setActiveComponent}
        />
      </div>
    </div>
  );
};

export default ThemeCustomView;
