import server from "api/server";
import React, { useEffect, useState } from "react";

import { Col, OverlayTrigger, Tooltip } from "react-bootstrap";

import { ReactComponent as EditIcon } from "assets/builder/edit.svg";
import { ReactComponent as CopyIcon } from "assets/builder/copy.svg";
import { ReactComponent as DeleteIcon } from "assets/builder/delete.svg";
// import FileIcon from "assets/builder/pdf.svg";
// import preview from "assets/builder/preview.png";
import { ReactComponent as ImageIcon } from "assets/builder/inputs/imageuploader.svg";
import { v4 } from "uuid";
import { getPublicImage } from "helpers/functions";

const SingleImageUpload = ({
  componentId,
  componentData,
  setComponentData,
  activeComponent,
  setActiveComponent,
}) => {
  const [selectedImage, setSelectedImage] = useState();
  const [activeClass, setActiveClass] = useState(false);

  const currentInput = componentData?.find(
    (component) => component.id === componentId
  );

  const handleDeleteComponent = () => {
    const newComponentData = componentData.filter(
      (component) => component.id !== currentInput.id
    );

    setComponentData(newComponentData);
    currentInput.id === activeComponent.id && setActiveComponent({});
  };

  const handleCopyComponent = () => {
    const newComponentData = [
      ...componentData,
      {
        ...currentInput,
        id: v4(),
      },
    ];
    setComponentData(newComponentData);
  };

  const imageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const { data } = await server().post("/upload", formData);

      const newComponentData = componentData?.map((component) => {
        if (component.id !== currentInput.id) return component;
        return {
          ...component,
          props: {
            ...component.props,
            value: getPublicImage(data.data.files[0]),
          },
        };
      });

      setComponentData(newComponentData);

      setSelectedImage({
        preview: e.target.files[0],
        path: getPublicImage(data.data.files[0]),
      });
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage();
  };

  const handleEditComponent = () => {
    setActiveComponent(currentInput);
    setActiveClass(true);
  };

  useEffect(() => {
    if (currentInput?.props?.value) {
      setSelectedImage({
        preview: currentInput?.props?.value,
        path: currentInput?.props?.value,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Col sm={currentInput?.props?.columns || 12}>
        <div className={activeClass ? "input-view active" : "input-view"}>
          <label className="input-label">
            {currentInput?.props?.label || "Enter label"}
          </label>
          <div className="input-actions">
            <OverlayTrigger placement="top" overlay={<Tooltip>تعديل</Tooltip>}>
              <button
                type="button"
                className={activeClass ? "edit active" : "edit"}
                onClick={handleEditComponent}
              >
                <EditIcon />
              </button>
            </OverlayTrigger>

            <OverlayTrigger placement="top" overlay={<Tooltip>نسخ</Tooltip>}>
              <button
                type="button"
                className="copy"
                onClick={handleCopyComponent}
              >
                <CopyIcon />
              </button>
            </OverlayTrigger>

            <OverlayTrigger placement="top" overlay={<Tooltip>حذف</Tooltip>}>
              <button
                type="button"
                className="delete"
                onClick={handleDeleteComponent}
              >
                <DeleteIcon />
              </button>
            </OverlayTrigger>
          </div>
          <div className="input-wrapper">
            <div className="image-uploader">
              <label>
                <input accept="image/*" type="file" onChange={imageChange} />
                <div>
                  <ImageIcon />
                  <p>ارفع ملف(لا يزيد عن 20 ميجا) (pdf / svg /xls)</p>
                </div>
              </label>
              {selectedImage && (
                <div className="image-uploader-items">
                  <div className="image-uploader-item">
                    <div className="item-right">
                      <img
                        src={
                          selectedImage?.path ||
                          selectedImage?.preview ||
                          selectedImage?.preview
                        }
                        alt=""
                      />
                      <span>
                        {selectedImage &&
                          selectedImage.preview &&
                          selectedImage.preview.name}
                      </span>
                    </div>
                    <button
                      type="button"
                      className="delete"
                      onClick={removeSelectedImage}
                    >
                      <DeleteIcon />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Col>
    </>
  );
};

export default SingleImageUpload;
