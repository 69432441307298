import server from "./server";

export const getCountriesApi = async () => {
  const response = await server().get("/countries");
  return response.data;
};

export const getSingleCountryApi = async (id) => {
  const response = await server().get(`/countries/${id}`);
  return response.data;
};

export const addCountryApi = async (payload) => {
  const response = await server().post("/countries", payload);
  return response.data;
};

export const editCountryApi = async ({ id, data }) => {
  const response = await server().put(`/countries/${id}`, data);
  return response.data;
};

export const deleteCountryApi = async (id) => {
  const response = await server().delete(`/countries/${id}`);
  return response.data;
};
