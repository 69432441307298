import loading from "assets/images/loader.svg";
const Loader = () => {
  return (
    <section className="loader">
      <img src={loading} alt="" />
    </section>
  );
};

export default Loader;
