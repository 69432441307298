import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { addArea, getCities } from "store/actions";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
const AddArea = () => {
  const { locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cities } = useSelector((state) => state?.cities);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  useEffect(() => {
    dispatch(getCities());
  }, [dispatch]);

  const onSubmit = (data) => {
    dispatch(addArea({ data, navigate }));
  };

  const renderListCities = () => {
    return cities?.map((city) => ({
      label: city.name?.[locale],
      value: city?.name?.[locale],
      id: city?.id,
    }));
  };

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>اضف منطقة جديدة</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>اسم المدينة </h5>
                      <div>
                        <Controller
                          control={control}
                          name="city"
                          render={({ field: { onChange } }) => (
                            <Select
                              isRtl={true}
                              // menuIsOpen={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"
                              options={renderListCities()}
                              placeholder="اختار المدينة"
                              isMulti={false}
                              onChange={(value) => {
                                onChange(value.id);
                              }}
                            />
                          )}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.country?.type === "required" &&
                          "يرجي اختيار اسم المدينة"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم المنطقة بالعربية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم المنطقة بالعربية"
                          {...register("name[ar]", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.["ar"]?.type === "required" &&
                          "يرجي ادخال اسم المنطقة بالعربية"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم المنطقة بالانجليزية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم المنطقة بالانجليزية"
                          {...register("name[en]", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.["en"]?.type === "required" &&
                          "يرجي ادخال اسم المنطقة بالانجليزية"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        اضافة المنطقة
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddArea;
