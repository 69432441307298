import {
  ADD_CURRENCY,
  ADD_CURRENCY_FAILURE,
  ADD_CURRENCY_SUCCESS,
  DELETE_CURRENCY,
  DELETE_CURRENCY_FAILURE,
  DELETE_CURRENCY_SUCCESS,
  EDIT_CURRENCY,
  EDIT_CURRENCY_FAILURE,
  EDIT_CURRENCY_SUCCESS,
  GET_CURRENCIES,
  GET_CURRENCIES_FAILURE,
  GET_CURRENCIES_SUCCESS,
  GET_SINGLE_CURRENCY,
  GET_SINGLE_CURRENCY_FAILURE,
  GET_SINGLE_CURRENCY_SUCCESS,
} from "./actionTypes";

export const getCurrencies = () => {
  return {
    type: GET_CURRENCIES,
  };
};

export const getCurrenciesSuccess = (currencies) => {
  return {
    type: GET_CURRENCIES_SUCCESS,
    payload: currencies,
  };
};

export const getCurrenciesFailure = (error) => {
  return {
    type: GET_CURRENCIES_FAILURE,
    payload: error,
  };
};

export const getSingleCurrency = (id) => {
  return {
    type: GET_SINGLE_CURRENCY,
    payload: id,
  };
};

export const getSingleCurrencysuccess = (currency) => {
  return {
    type: GET_SINGLE_CURRENCY_SUCCESS,
    payload: currency,
  };
};

export const getSingleCurrencyFailure = (error) => {
  return {
    type: GET_SINGLE_CURRENCY_FAILURE,
    payload: error,
  };
};

export const addCurrency = (payload) => {
  return {
    type: ADD_CURRENCY,
    payload,
  };
};

export const addCurrencysuccess = (payload) => {
  return {
    type: ADD_CURRENCY_SUCCESS,
    payload,
  };
};

export const addCurrencyFailure = (error) => {
  return {
    type: ADD_CURRENCY_FAILURE,
    payload: error,
  };
};

export const editCurrency = (payload) => {
  return {
    type: EDIT_CURRENCY,
    payload,
  };
};

export const editCurrencysuccess = (payload) => {
  return {
    type: EDIT_CURRENCY_SUCCESS,
    payload,
  };
};

export const editCurrencyFailure = (error) => {
  return {
    type: EDIT_CURRENCY_FAILURE,
    payload: error,
  };
};

export const deleteCurrency = (id) => {
  return {
    type: DELETE_CURRENCY,
    payload: id,
  };
};

export const deleteCurrencysuccess = (id) => {
  return {
    type: DELETE_CURRENCY_SUCCESS,
    payload: id,
  };
};

export const deleteCurrencyFailure = (error) => {
  return {
    type: DELETE_CURRENCY_FAILURE,
    payload: error,
  };
};
