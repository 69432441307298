import { useRef, useState } from "react";
import server from "api/server";

import { ReactComponent as Remove } from "assets/svgs/x.svg";
import { ReactComponent as Upload } from "assets/builder/inputs/imageuploader.svg";
import { Col, Row } from "react-bootstrap";
import StickyLoader from "./StickyLoader";
import { getPublicImage, handleImage } from "helpers/functions";

const MultiUpload = ({
  images,
  setImages,
  pathType = "path",
  breakPoints = { md: 6, sm: 6, xs: 12 },
  isMain = false,
  single = false,
  name = "",
}) => {
  const [loading, setLoading] = useState(false);
  const fileInput = useRef();
  const onImageChange = async (e) => {
    setLoading(true);
    if (e.target.files && e.target.files.length > 0) {
      for (let i = 0; i < e.target.files.length; i++) {
        const formData = new FormData();
        formData.append("file", e.target.files[i]);

        const { data } = await server().post("/upload", formData, {
          headers: {
            uploadsType: "products",
          },
        });

        setImages((oldImages) => [
          ...(single ? [] : oldImages),
          !!isMain
            ? {
                preview: e.target.files[i],
                image: getPublicImage(data.data.files[0]),
                isMain: false,
              }
            : getPublicImage(data.data.files[0]),
        ]);
        if (fileInput?.current) fileInput.current.value = "";
        setLoading(false);
      }
    } else setLoading(false);
  };

  const removeImages = (image) => {
    setImages(
      images.filter((img) => img.image !== image.image || img !== image)
    );
  };

  return (
    <div className="form-group required">
      <div>
        <label className="form-uploader">
          <input
            ref={fileInput}
            type="file"
            {...(single ? {} : { multiple: true })}
            accept="image/*"
            onChange={onImageChange}
            id="filo"
            name="images"
          />

          <span className="txt">
            <Upload fill="#395cf5" />
            {name}
          </span>
        </label>

        <div className="thumbs">
          <Row>
            {images?.map((imageSrc, index) => {
              return (
                <Col {...breakPoints} key={index}>
                  <div className="thumb">
                    <button
                      type="button"
                      className="btn-remove"
                      onClick={() => removeImages(imageSrc)}
                    >
                      <Remove fill="#f00" />
                    </button>
                    <img
                      src={
                        imageSrc?.preview
                          ? URL.createObjectURL(imageSrc.preview)
                          : imageSrc?.image || handleImage(imageSrc)
                      }
                      width={100}
                      alt=""
                    />
                    {!!isMain && (
                      <div>
                        <label>
                          <input
                            type="radio"
                            name="mainImage"
                            onChange={(e) => {
                              setImages(() =>
                                images?.map((image, i) => {
                                  return i === index
                                    ? { ...image, isMain: true }
                                    : { ...image, isMain: false };
                                })
                              );
                            }}
                            defaultChecked={imageSrc.isMain}
                          />
                          <span>الصورة الرئيسية</span>
                        </label>
                      </div>
                    )}
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>

        {loading && <StickyLoader fill="#346bf4" />}
      </div>
    </div>
  );
};

export default MultiUpload;
