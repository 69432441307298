export const GET_PORTAL_TECHNICAL_SUPPORTS = "GET_PORTAL_TECHNICAL_SUPPORTS";
export const GET_PORTAL_TECHNICAL_SUPPORTS_SUCCESS =
  "GET_PORTAL_TECHNICAL_SUPPORTS_SUCCESS";
export const GET_PORTAL_TECHNICAL_SUPPORTS_FAILURE =
  "GET_PORTAL_TECHNICAL_SUPPORTS_FAILURE";

export const GET_SINGLE_PORTAL_TECHNICAL_SUPPORT =
  "GET_SINGLE_PORTAL_TECHNICAL_SUPPORT";
export const GET_SINGLE_PORTAL_TECHNICAL_SUPPORT_SUCCESS =
  "GET_SINGLE_PORTAL_TECHNICAL_SUPPORT_SUCCESS";
export const GET_SINGLE_PORTAL_TECHNICAL_SUPPORT_FAILURE =
  "GET_SINGLE_PORTAL_TECHNICAL_SUPPORT_FAILURE";

export const ADD_PORTAL_TECHNICAL_SUPPORT = "ADD_PORTAL_TECHNICAL_SUPPORT";
export const ADD_PORTAL_TECHNICAL_SUPPORT_SUCCESS =
  "ADD_PORTAL_TECHNICAL_SUPPORT_SUCCESS";
export const ADD_PORTAL_TECHNICAL_SUPPORT_FAILURE =
  "ADD_PORTAL_TECHNICAL_SUPPORT_FAILURE";

export const EDIT_PORTAL_TECHNICAL_SUPPORT = "EDIT_PORTAL_TECHNICAL_SUPPORT";
export const EDIT_PORTAL_TECHNICAL_SUPPORT_SUCCESS =
  "EDIT_PORTAL_TECHNICAL_SUPPORT_SUCCESS";
export const EDIT_PORTAL_TECHNICAL_SUPPORT_FAILURE =
  "EDIT_PORTAL_TECHNICAL_SUPPORT_FAILURE";

export const DELETE_PORTAL_TECHNICAL_SUPPORT =
  "DELETE_PORTAL_TECHNICAL_SUPPORT";
export const DELETE_PORTAL_TECHNICAL_SUPPORT_SUCCESS =
  "DELETE_PORTAL_TECHNICAL_SUPPORT_SUCCESS";
export const DELETE_PORTAL_TECHNICAL_SUPPORT_FAILURE =
  "DELETE_PORTAL_TECHNICAL_SUPPORT_FAILURE";
