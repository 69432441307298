export const GET_FONTS = "GET_FONTS";
export const GET_FONTS_SUCCESS = "GET_FONTS_SUCCESS";
export const GET_FONTS_FAILURE = "GET_FONTS_FAILURE";

export const GET_SINGLE_FONT = "GET_SINGLE_FONT";
export const GET_SINGLE_FONT_SUCCESS = "GET_SINGLE_FONT_SUCCESS";
export const GET_SINGLE_FONT_FAILURE = "GET_SINGLE_FONT_FAILURE";

export const ADD_FONT = "ADD_FONT";
export const ADD_FONT_SUCCESS = "ADD_FONT_SUCCESS";
export const ADD_FONT_FAILURE = "ADD_FONT_FAILURE";

export const EDIT_FONT = "EDIT_FONT";
export const EDIT_FONT_SUCCESS = "EDIT_FONT_SUCCESS";
export const EDIT_FONT_FAILURE = "EDIT_FONT_FAILURE";

export const DELETE_FONT = "DELETE_FONT";
export const DELETE_FONT_SUCCESS = "DELETE_FONT_SUCCESS";
export const DELETE_FONT_FAILURE = "DELETE_FONT_FAILURE";
