import {
  ADD_BANK,
  ADD_BANK_FAILURE,
  ADD_BANK_SUCCESS,
  DELETE_BANK,
  DELETE_BANK_FAILURE,
  DELETE_BANK_SUCCESS,
  EDIT_BANK,
  EDIT_BANK_FAILURE,
  EDIT_BANK_SUCCESS,
  GET_BANKS,
  GET_BANKS_FAILURE,
  GET_BANKS_SUCCESS,
  GET_SINGLE_BANK,
  GET_SINGLE_BANK_FAILURE,
  GET_SINGLE_BANK_SUCCESS,
} from "./actionTypes";

export const getBanks = () => {
  return {
    type: GET_BANKS,
  };
};

export const getBanksSuccess = (banks) => {
  return {
    type: GET_BANKS_SUCCESS,
    payload: banks,
  };
};

export const getBanksFailure = (error) => {
  return {
    type: GET_BANKS_FAILURE,
    payload: error,
  };
};

export const getSingleBank = (id) => {
  return {
    type: GET_SINGLE_BANK,
    payload: id,
  };
};

export const getSingleBanksuccess = (bank) => {
  return {
    type: GET_SINGLE_BANK_SUCCESS,
    payload: bank,
  };
};

export const getSingleBankFailure = (error) => {
  return {
    type: GET_SINGLE_BANK_FAILURE,
    payload: error,
  };
};

export const addBank = (payload) => {
  return {
    type: ADD_BANK,
    payload,
  };
};

export const addBanksuccess = (payload) => {
  return {
    type: ADD_BANK_SUCCESS,
    payload,
  };
};

export const addBankFailure = (error) => {
  return {
    type: ADD_BANK_FAILURE,
    payload: error,
  };
};

export const editBank = (payload) => {
  return {
    type: EDIT_BANK,
    payload,
  };
};

export const editBanksuccess = (payload) => {
  return {
    type: EDIT_BANK_SUCCESS,
    payload,
  };
};

export const editBankFailure = (error) => {
  return {
    type: EDIT_BANK_FAILURE,
    payload: error,
  };
};

export const deleteBank = (id) => {
  return {
    type: DELETE_BANK,
    payload: id,
  };
};

export const deleteBanksuccess = (id) => {
  return {
    type: DELETE_BANK_SUCCESS,
    payload: id,
  };
};

export const deleteBankFailure = (error) => {
  return {
    type: DELETE_BANK_FAILURE,
    payload: error,
  };
};
