import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addAreaApi,
  deleteAreaApi,
  editAreaApi,
  getAreasApi,
  getSingleAreaApi,
} from "api/areas";

// Login Redux States
import {
  ADD_AREA,
  DELETE_AREA,
  EDIT_AREA,
  GET_AREAS,
  GET_SINGLE_AREA,
} from "./actionTypes";

import {
  addAreaFailure,
  addAreaSuccess,
  deleteAreaFailure,
  deleteAreaSuccess,
  editAreaFailure,
  editAreaSuccess,
  getAreasFailure,
  getAreasSuccess,
  getSingleAreaFailure,
  getSingleAreaSuccess,
} from "./actions";

function* getAreas() {
  try {
    const { data } = yield call(getAreasApi);
    yield put(getAreasSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getAreasFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* getSingleArea({ payload }) {
  try {
    const { data } = yield call(getSingleAreaApi, payload);
    yield put(getSingleAreaSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleAreaFailure(error?.response?.data || error));
  }
}

function* addArea({ payload }) {
  try {
    const { data } = yield call(addAreaApi, payload.data);
    yield put(addAreaSuccess(data));
    yield payload.navigate("/areas");
  } catch (error) {
    console.log(error);
    yield put(addAreaFailure(error.response?.data?.message || error));
  }
}

function* editArea({ payload }) {
  try {
    yield call(editAreaApi, payload);
    yield put(editAreaSuccess(payload));
    yield payload.navigate("/areas");
  } catch (error) {
    console.log(error);
    yield put(editAreaFailure(error?.response?.data || error));
  }
}

function* deleteArea({ payload }) {
  try {
    yield call(deleteAreaApi, payload);
    yield put(deleteAreaSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteAreaFailure(error?.response?.data || error));
  }
}

export function* watchGetAreas() {
  yield takeEvery(GET_AREAS, getAreas);
}

export function* watchSingleArea() {
  yield takeEvery(GET_SINGLE_AREA, getSingleArea);
}

export function* watchAddArea() {
  yield takeEvery(ADD_AREA, addArea);
}

export function* watchEditArea() {
  yield takeEvery(EDIT_AREA, editArea);
}

export function* watchDeleteArea() {
  yield takeEvery(DELETE_AREA, deleteArea);
}

function* areasSaga() {
  yield all([fork(watchGetAreas)]);
  yield all([fork(watchSingleArea)]);
  yield all([fork(watchAddArea)]);
  yield all([fork(watchEditArea)]);
  yield all([fork(watchDeleteArea)]);
}

export default areasSaga;
