import { getId } from "helpers/functions";
import {
  GET_FEATURES,
  GET_FEATURES_SUCCESS,
  GET_FEATURES_FAILURE,
  ADD_FEATURE,
  ADD_FEATURE_SUCCESS,
  ADD_FEATURE_FAILURE,
  DELETE_FEATURE,
  DELETE_FEATURE_SUCCESS,
  DELETE_FEATURE_FAILURE,
  GET_SINGLE_FEATURE,
  GET_SINGLE_FEATURE_SUCCESS,
  GET_SINGLE_FEATURE_FAILURE,
} from "./actionTypes";

const initialState = {
  features: [],
  loading: false,
  error: "",
  singleFeature: {},
};

const features = (state = initialState, action) => {
  switch (action.type) {
    case GET_FEATURES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_FEATURES_SUCCESS:
      state = {
        ...state,
        loading: false,
        features: action.payload.features,
      };
      break;
    case GET_FEATURES_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_FEATURE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_FEATURE_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleFeature: action.payload.feature,
      };

      break;
    case GET_SINGLE_FEATURE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        singleFeature: {},
      };
      break;

    case ADD_FEATURE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_FEATURE_SUCCESS:
      state = {
        ...state,
        loading: false,
        features: action.payload.features,
      };
      break;
    case ADD_FEATURE_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_FEATURE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_FEATURE_SUCCESS:
      const featuresAfterDeleteing = [
        ...state?.features?.filter(
          (feature) => getId(feature) !== action.payload
        ),
      ];
      state = {
        ...state,
        loading: false,
        features: featuresAfterDeleteing,
      };
      break;
    case DELETE_FEATURE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default features;
