const { default: server } = require("./server");

export const getSettingsApi = async (slug) => {
  const response = await server().get(`/portalSettings/${slug}`);
  return response.data;
};

export const editSettingsApi = async (data) => {
  const response = await server().put(`/portalSettings/siteInfo`, data);

  return response.data;
};

export const editStoreBasicSettingsApi = async ({ data, slug }) => {
  const response = await server().put(
    `/portalSettings/basicSettings/${slug}`,
    data
  );

  return response.data;
};

export const editStoreSlugSettingsApi = async ({ data, slug }) => {
  const response = await server().put(`/portalSettings/${slug}`, data);

  return response.data;
};

export const editStoreLangsApi = async ({ data }) => {
  const response = await server().put(`/portalSettings/languageSettings`, data);

  return response.data;
};

export const editStoreCurrenciesApi = async ({ data }) => {
  const response = await server().put(`/portalSettings/currencySettings`, data);

  return response.data;
};

export const addBankAccountApi = async () => {
  const response = await server().post(`/bankAccounts`);

  return response.data;
};

export const editBankAccountApi = async ({ data, id }) => {
  const response = await server().put(`/bankAccounts/${id}`, data);

  return response.data;
};

// -------------------------------------------------------

export const getAllSettingsApi = async () => {
  const response = await server().get("/portalSettings");

  return response.data;
};

export const editMainSettingsApi = async (data) => {
  const response = await server().put("/portalSettings/main", data);

  return response.data;
};

export const editMessageSettingsApi = async (data) => {
  const response = await server().put("/portalSettings/messagesSettings", data);

  return response.data;
};

export const addAccountsSettingsApi = async (data) => {
  const response = await server().post("/portalSettings/", data);

  return response.data;
};

export const editAccountsSettingsApi = async (data) => {
  const response = await server().put("/portalSettings/bankAccounts", data);

  return response.data;
};

export const deleteSettingsApi = async ({ id }) => {
  const response = await server().delete(`/portalSettings/${id}`);

  return response.data;
};

export const editSocialsSettingsApi = async (data) => {
  const response = await server().put("/portalSettings/social", data);

  return response.data;
};
export const editMailSettingsApi = async (data) => {
  const response = await server().put("/portalSettings/EmailContent", data);

  return response.data;
};
