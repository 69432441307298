import React, { useState } from "react";
import { Col, OverlayTrigger, Tooltip } from "react-bootstrap";

import { ReactComponent as EditIcon } from "assets/builder/edit.svg";
import { ReactComponent as CopyIcon } from "assets/builder/copy.svg";
import { ReactComponent as DeleteIcon } from "assets/builder/delete.svg";
import { v4 } from "uuid";

const Switch = ({
  componentId,
  componentData,
  setComponentData,
  activeComponent,
  setActiveComponent,
}) => {
  const [activeClass, setActiveClass] = useState(false);

  const currentInput = componentData?.find(
    (component) => component.id === componentId
  );
  const handleInputChange = (e) => {
    const newComponentData = componentData.map((component) => {
      if (component.id !== currentInput.id) return component;
      return {
        ...component,
        value: e.target.checked,
      };
    });
    setComponentData(newComponentData);
  };

  const handleDeleteComponent = () => {
    const newComponentData = componentData.filter(
      (component) => component.id !== currentInput.id
    );

    setComponentData(newComponentData);
    currentInput.id === activeComponent.id && setActiveComponent({});
  };

  const handleCopyComponent = () => {
    const newComponentData = [
      ...componentData,
      {
        ...currentInput,
        id: v4(),
      },
    ];
    setComponentData(newComponentData);
  };

  const handleEditComponent = () => {
    setActiveComponent(currentInput);
    setActiveClass(true);
  };

  return (
    <Col sm={currentInput?.props?.columns || 12}>
      <div className={activeClass ? "input-view active" : "input-view"}>
        <label className="input-label">
          {currentInput?.props?.label || "Enter label"}
        </label>
        <div className="input-actions">
          <OverlayTrigger placement="top" overlay={<Tooltip>تعديل</Tooltip>}>
            <button
              type="button"
              className={activeClass ? "edit active" : "edit"}
              onClick={handleEditComponent}
            >
              <EditIcon />
            </button>
          </OverlayTrigger>

          <OverlayTrigger placement="top" overlay={<Tooltip>نسخ</Tooltip>}>
            <button
              type="button"
              className="copy"
              onClick={handleCopyComponent}
            >
              <CopyIcon />
            </button>
          </OverlayTrigger>

          <OverlayTrigger placement="top" overlay={<Tooltip>حذف</Tooltip>}>
            <button
              type="button"
              className="delete"
              onClick={handleDeleteComponent}
            >
              <DeleteIcon />
            </button>
          </OverlayTrigger>
        </div>
        <div className="input-wrapper">
          <div className="theme-switcher">
            <input
              type="checkbox"
              id={currentInput?.id}
              {...currentInput?.props}
              defaultChecked={currentInput?.value}
              onChange={handleInputChange}
            />
            <label htmlFor={currentInput?.id}></label>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default Switch;
