import { getId } from "helpers/functions";
import {
  GET_UNITS,
  GET_UNITS_SUCCESS,
  GET_UNITS_FAILURE,
  ADD_UNIT,
  ADD_UNIT_SUCCESS,
  ADD_UNIT_FAILURE,
  DELETE_UNIT,
  DELETE_UNIT_SUCCESS,
  DELETE_UNIT_FAILURE,
  GET_SINGLE_UNIT,
  GET_SINGLE_UNIT_SUCCESS,
  GET_SINGLE_UNIT_FAILURE,
} from "./actionTypes";

const initialState = {
  units: [],
  loading: false,
  error: "",
  singleUnit: {},
};

const features = (state = initialState, action) => {
  switch (action.type) {
    case GET_UNITS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_UNITS_SUCCESS:
      state = {
        ...state,
        loading: false,
        units: action.payload.units,
      };
      break;
    case GET_UNITS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_UNIT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_UNIT_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleUnit: action.payload.unit,
      };

      break;
    case GET_SINGLE_UNIT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        singleUnit: {},
      };
      break;

    case ADD_UNIT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_UNIT_SUCCESS:
      state = {
        ...state,
        loading: false,
        units: action.payload.units,
      };
      break;
    case ADD_UNIT_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_UNIT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_UNIT_SUCCESS:
      const unitsAfterDeleteing = [
        ...state?.units?.filter((unit) => getId(unit) !== action.payload),
      ];
      state = {
        ...state,
        loading: false,
        units: unitsAfterDeleteing,
      };
      break;
    case DELETE_UNIT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default features;
