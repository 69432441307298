import {
  GET_THEMES,
  GET_THEMES_SUCCESS,
  GET_THEMES_FAILURE,
  GET_SINGLE_THEME,
  GET_SINGLE_THEME_SUCCESS,
  GET_SINGLE_THEME_FAILURE,
  ADD_THEME,
  ADD_THEME_SUCCESS,
  ADD_THEME_FAILURE,
  EDIT_THEME,
  EDIT_THEME_SUCCESS,
  EDIT_THEME_FAILURE,
  DELETE_THEME,
  DELETE_THEME_SUCCESS,
  DELETE_THEME_FAILURE,
} from "./actionTypes";

export const getThemes = () => {
  return {
    type: GET_THEMES,
  };
};

export const getThemesSuccess = (themes) => {
  return {
    type: GET_THEMES_SUCCESS,
    payload: themes,
  };
};

export const getThemesFailure = (error) => {
  return {
    type: GET_THEMES_FAILURE,
    payload: error,
  };
};

export const getSingleTheme = (id) => {
  return {
    type: GET_SINGLE_THEME,
    payload: id,
  };
};

export const getSingleThemeSuccess = (theme) => {
  return {
    type: GET_SINGLE_THEME_SUCCESS,
    payload: theme,
  };
};

export const getSingleThemeFailure = (error) => {
  return {
    type: GET_SINGLE_THEME_FAILURE,
    payload: error,
  };
};

export const addTheme = (payload) => {
  return {
    type: ADD_THEME,
    payload,
  };
};

export const addThemeSuccess = (payload) => {
  return {
    type: ADD_THEME_SUCCESS,
    payload,
  };
};

export const addThemeFailure = (error) => {
  return {
    type: ADD_THEME_FAILURE,
    payload: error,
  };
};

export const editTheme = (payload) => {
  return {
    type: EDIT_THEME,
    payload,
  };
};

export const editThemeSuccess = (payload) => {
  return {
    type: EDIT_THEME_SUCCESS,
    payload,
  };
};

export const editThemeFailure = (error) => {
  return {
    type: EDIT_THEME_FAILURE,
    payload: error,
  };
};

export const deleteTheme = (id) => {
  return {
    type: DELETE_THEME,
    payload: id,
  };
};

export const deleteThemeSuccess = (id) => {
  return {
    type: DELETE_THEME_SUCCESS,
    payload: id,
  };
};

export const deleteThemeFailure = (error) => {
  return {
    type: DELETE_THEME_FAILURE,
    payload: error,
  };
};
