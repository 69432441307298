import React from "react";
import { useSelector } from "react-redux";
import Table from "./Table";
import ControlArea from "components/Layout/ControlArea";
import categoriesIcon from "assets/svgs/categories.svg";
import { useIntl } from "react-intl";

const PortalSections = () => {
  const { formatMessage } = useIntl();
  const { sections, loading } = useSelector((state) => state.portalSections);
  return (
    <>
      <div className="stores-wrap">
        <ControlArea
          btnTxt={formatMessage({ id: "addSection" })}
          cardTxt={formatMessage({ id: "sections" })}
          icon={categoriesIcon}
          // url="/portal/sections/add"
        />
        <Table data={sections} loading={loading} />
      </div>
    </>
  );
};

export default PortalSections;
