export const priceInputLength = 10;

export const textInputLength = 100,
  textInputPattern = /^[a-zA-Z 0-9\u0600-\u06FF]{1,100}$/;

export const numberInputValidations = {
  maxLength: priceInputLength,
  type: "text",
  placeholder: "0",
  pattern: "[d]{10}",
  onKeyPress: (event) => {
    if (!/[0-9.]/.test(event.key)) {
      event.preventDefault();
    }
  },
};
export const textInputValidations = {
  maxLength: textInputLength,
  type: "text",
  onKeyPress: (event) => {
    if (!/[0-9a-zA-Z]/.test(event.key)) {
      event.preventDefault();
    }
  },
};

export const portalHelpCategoriesTypes = [
  "discussions",
  "educationVedios",
  "suggestions",
];

export const portalFeaturesTypes = [
  "monthlySubscription",
  "paymentMethods",
  "marketingadvantages",
];
