import { useState, useEffect, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { deleteThemesComponent, editThemesComponent } from "store/actions";
import Swal from "sweetalert2";
import Select from "react-select";

import ImagesModal from "./ImagesModal";

import { ReactComponent as SettingIcon } from "assets/svgs/setting.svg";
import { ReactComponent as ThreeDotsIcon } from "assets/svgs/three-dots.svg";
// import { ReactComponent as ProductTitleIcon } from "assets/svgs/data/title.svg";
// import { ReactComponent as ProductPriceIcon } from "assets/svgs/data/price.svg";
// import { ReactComponent as ProductQtyIcon } from "assets/svgs/data/quantity.svg";
// import { ReactComponent as ProductSettingIcon } from "assets/svgs/data/settings.svg";
// import { ReactComponent as ProductMoreIcon } from "assets/svgs/data/more.svg";
// import { ReactComponent as ProductCategoryIcon } from "assets/svgs/data/category.svg";
// import { ReactComponent as DeleteIcon } from "assets/svgs/data/trash.svg";
// import { ReactComponent as CopyIcon } from "assets/builder/copy.svg";
// import { ReactComponent as RemoveIcon } from "assets/svgs/x.svg";
// import { ReactComponent as Drag } from "assets/svgs/drag.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getId, getImageVariant, handleImage } from "helpers/functions";
import MultiLangsWrapper from "components/shared/MultiLangsWrapper";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Card = ({
  data,
  index,
  isCheck,
  handleClick,
  // duplicateProduct,
}) => {
  const { locale, formatMessage } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const deviceType = searchParams.get("deviceType");
  const asPath = window.location.pathname;

  const { componentsTypes } = useSelector((state) => state.componentsTypes);
  const [modalType, setModalType] = useState("");
  const [images, setImages] = useState([]);
  // const [isDataChanges, setIsDataChanges] = useState(false);

  const [params] = useSearchParams();
  const {
    reset,
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  // useEffect(() => {
  //   dispatch(getThemesComponentsTypes(deviceType));
  // }, [deviceType, dispatch]);

  // useEffect(() => {
  //   const resetData = { ...data, componentType: getId(data?.componentType) };
  //   console.log(
  //     "JSON.stringify(resetData) !== JSON.stringify(getValues())",
  //     JSON.stringify(resetData),
  //     JSON.stringify(getValues())
  //   );
  //   if (JSON.stringify(resetData) !== JSON.stringify(getValues())) {
  //     setIsDataChanges(true);
  //   }
  // }, [data, getValues]);

  useEffect(() => {
    reset({ ...data, componentType: getId(data?.componentType) });
  }, [data, reset]);

  const renderListComponentsTypes = () => {
    return componentsTypes?.map((componentType) => ({
      label: componentType.name?.[locale],
      value: componentType?.name?.[locale],
      id: getId(componentType),
    }));
  };

  const handleClose = () => {
    const paramsStr = params?.size > 0 ? `?${params.toString()}` : "";
    navigate(`${asPath}${paramsStr}`, { replace: true });
  };

  const handleDeleteProduct = () => {
    Swal.fire({
      title: "هل تريد حذف العنصر!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2a89f3",
      cancelButtonColor: "#d33",
      confirmButtonText: "نعم",
      cancelButtonText: "لا",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteThemesComponent(getId(data)));
      }
    });
  };

  const handleNavigateToProduct = () => {
    const paramsStr = params?.size > 0 ? `?${params.toString()}` : "";
    navigate(`${asPath}/${getId(data)}${paramsStr}`);
  };

  const onSubmit = (data) => {
    // if (data.preview?.length > 0)
    //   data.preview = "uploads/" + data.preview.split("/").pop();
    // if (data.skeletonPreview?.length > 0)
    //   data.skeletonPreview = "uploads/" + data.skeletonPreview.split("/").pop();

    // if (id) {
    dispatch(
      editThemesComponent({ id: getId(data), data, navigate, deviceType })
    );
    // } else {
    //   dispatch(addThemesComponent({ data, navigate }));
    // }
  };

  return (
    <div className="card">
      <form onSubmit={handleSubmit(onSubmit)} className="productCard">
        <div className={data?.isNew ? "newProduct p-img" : "p-img"}>
          {/* <img src={handleImage(data?.preview)} alt="" /> */}
          <LazyLoadImage
            className="object-fit-contain"
            loading="lazy"
            src={getImageVariant(data?.preview, "thumbnail")}
            width={200}
            height={140}
            alt=""
          />
          {data?.isNew ? (
            <button
              type="button"
              onClick={() => {
                dispatch();
                // deleteEmptyProduct({
                //   id: getId(data),
                // })
              }}
              className="deleteNewProduct"
            >
              {/* <RemoveIcon fill="#fff" /> */}
            </button>
          ) : (
            <div className="corner-actions">
              {/* <div ref={(ref) => drag(ref)} className="drag">
                <Drag width={24} />
              </div> */}

              <input
                id={getId(data)}
                type="checkbox"
                onChange={handleClick}
                checked={isCheck.includes(getId(data))}
              />
            </div>
          )}
        </div>
        <div className="p-data">
          {/* <ProductTitleIcon fill="#E4E4E4" /> */}

          <MultiLangsWrapper errors={errors?.name} customClass="form-group">
            {({ lang, classes, key }) => (
              <input
                key={key}
                className={`form-control ${classes}`}
                type="text"
                placeholder={formatMessage({ id: "groupName" })}
                {...register(`name.${lang}`, { required: true })}
              />
            )}
            {/* <span className="error-hint" key="1">
                  {errors?.name && <FormattedMessage id="nameErr" />}
                </span> */}
          </MultiLangsWrapper>

          {/* <input
            type="text"
            className="form-control"
            min={0}
            id="stock"
            placeholder="نوع المنتج"
            value={data?.type?.name?.[locale]}
            disabled
          /> */}

          <div className="form-group">
            <div>
              <Controller
                control={control}
                name="componentType"
                render={({ field: { onChange, value } }) => (
                  <>
                    <Select
                      isRtl={true}
                      // menuIsOpen
                      isSearchable={false}
                      className="basic-single form-control"
                      classNamePrefix="select"
                      options={renderListComponentsTypes()}
                      placeholder="اختار النوع"
                      isMulti={false}
                      value={renderListComponentsTypes()?.find(
                        (t) => getId(t) === value || getId(t) === getId(value)
                      )}
                      onChange={(value) => {
                        onChange(getId(value));
                      }}
                    />
                  </>
                )}
              />
            </div>
          </div>

          <div className="form-group has-btns last-row">
            <button type="button" onClick={handleNavigateToProduct}>
              <SettingIcon />
              بناء العنصر
            </button>
            <Dropdown>
              <Dropdown.Toggle>
                <ThreeDotsIcon />
                {/* المـزيد */}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {/* <Dropdown.Item
                  className="delete"
                  onClick={() => {
                    duplicateProduct(data);
                  }}
                >
                  <FormattedMessage id="duplicate" />
                </Dropdown.Item> */}
                <Dropdown.Item
                  className="delete"
                  onClick={() => {
                    handleDeleteProduct(getId(data));
                  }}
                >
                  {/* <DeleteIcon fill="#f00" /> */}
                  <FormattedMessage id="delete" />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className="form-group">
            <div>
              <button
                type="submit"
                className="btn btn-blue"
                // disabled={!isDataChanges}
              >
                حفظ
              </button>
            </div>
          </div>
        </div>
      </form>

      <ImagesModal
        handleClose={handleClose}
        modalType={modalType}
        images={images}
        setImages={setImages}
      />
    </div>
  );
};

export default Card;
