import ControlArea from "components/Layout/ControlArea";
import React from "react";
import { useSelector } from "react-redux";
import packagesIcon from "assets/svgs/packages.svg";
import Table from "./Table";
const Index = () => {
  const { packages, loading } = useSelector((state) => state.packages);
  return (
    <>
      <div className="stores-wrap">
        <ControlArea
          btnTxt="اضافة باقة"
          cardTxt="الباقات"
          icon={packagesIcon}
          url="/portal/packages/addpackage"
        />
        <Table data={packages} loading={loading} />
      </div>
    </>
  );
};

export default Index;
