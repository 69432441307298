import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { addFont, getFonts, getLanguages } from "store/actions";
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { getId } from "helpers/functions";

const AddFont = () => {
  const { fonts } = useSelector((state) => state.fonts);
  const { languages } = useSelector((state) => state.languages);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const deviceType = searchParams.get("deviceType");

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const fontsProviders = [...new Set(fonts?.map((f) => f?.provider))]?.filter(
    (e) => e?.length > 0
  );

  useEffect(() => {
    dispatch(getLanguages());
    dispatch(getFonts({ deviceType }));
  }, [deviceType, dispatch]);

  const onSubmit = (data) => {
    dispatch(addFont({ data, navigate, deviceType }));
  };
  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>اضف خط جديدة</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>اسم الخط</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم الخط"
                          {...register("name", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.type === "required" &&
                          "يرجي ادخال اسم  الخط"}
                      </p>
                    </div>
                  </Col>

                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>مزود الخط</h5>
                      <div>
                        <Controller
                          control={control}
                          name="provider"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <CreatableSelect
                              isRtl={true}
                              className="basic-single"
                              classNamePrefix="select"
                              options={fontsProviders?.map((lang) => ({
                                label: lang,
                                value: lang,
                              }))}
                              value={{ value: value, label: value }}
                              placeholder="اختار مزود الخط"
                              onChange={({ value }) => {
                                onChange(value);
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <p className="error-hint">
                      {errors?.provider?.type === "required" &&
                        "يرجي اختيار لغة عالاقل"}
                    </p>
                  </Col>

                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>لغات الخط</h5>
                      <div>
                        <Controller
                          control={control}
                          name="language"
                          rules={{ required: true }}
                          render={({ field: { onChange } }) => (
                            <Select
                              isRtl={true}
                              className="basic-single"
                              classNamePrefix="select"
                              options={languages?.map((lang) => ({
                                label: lang?.name,
                                value: getId(lang),
                              }))}
                              placeholder="اختار اللغات المدعومة"
                              isMulti={true}
                              onChange={(ops) => {
                                onChange(ops?.map((e) => e?.value));
                              }}
                            />
                          )}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.languages?.type === "required" &&
                          "يرجي اختيار لغة عالاقل"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>سلاج الخط</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="السلاج"
                          {...register("slug", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.slug?.type === "required" &&
                          "يرجي ادخال سلاج الخط"}
                      </p>
                    </div>
                  </Col>

                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        اضافة خط
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddFont;
