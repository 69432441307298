import { getId } from "helpers/functions";
import {
  ADD_PORTAL_HELP_CATEGORY,
  ADD_PORTAL_HELP_CATEGORY_FAILURE,
  ADD_PORTAL_HELP_CATEGORY_SUCCESS,
  DELETE_PORTAL_HELP_CATEGORY,
  DELETE_PORTAL_HELP_CATEGORY_FAILURE,
  DELETE_PORTAL_HELP_CATEGORY_SUCCESS,
  GET_PORTAL_HELP_CATEGORIES,
  GET_PORTAL_HELP_CATEGORIES_FAILURE,
  GET_PORTAL_HELP_CATEGORIES_SUCCESS,
  GET_SINGLE_PORTAL_HELP_CATEGORY,
  GET_SINGLE_PORTAL_HELP_CATEGORY_FAILURE,
  GET_SINGLE_PORTAL_HELP_CATEGORY_SUCCESS,
} from "./actionTypes";

const initialState = {
  portalHelpCategories: [],
  loading: false,
  error: "",
  singlePortalHelpCategory: {},
};

const portalHelpCategories = (state = initialState, action) => {
  switch (action.type) {
    case GET_PORTAL_HELP_CATEGORIES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_PORTAL_HELP_CATEGORIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        portalHelpCategories: action.payload?.helpCenterCategories,
      };
      break;
    case GET_PORTAL_HELP_CATEGORIES_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_PORTAL_HELP_CATEGORY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_PORTAL_HELP_CATEGORY_SUCCESS:
      state = {
        ...state,
        loading: false,
        singlePortalHelpCategory: action.payload.helpCenterCategory,
      };
      break;
    case GET_SINGLE_PORTAL_HELP_CATEGORY_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        singlePortalHelpCategory: {},
      };
      break;

    case ADD_PORTAL_HELP_CATEGORY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_PORTAL_HELP_CATEGORY_SUCCESS:
      state = {
        ...state,
        loading: false,
        portalHelpCategories: action.payload.helpCenterCategories,
      };
      break;
    case ADD_PORTAL_HELP_CATEGORY_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_PORTAL_HELP_CATEGORY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_PORTAL_HELP_CATEGORY_SUCCESS:
      const portalHelpCategoriesAfterDeleteing = [
        ...state?.portalHelpCategories?.filter(
          (portalTechnicalSupport) =>
            getId(portalTechnicalSupport) !== action.payload
        ),
      ];
      state = {
        ...state,
        loading: false,
        portalHelpCategories: portalHelpCategoriesAfterDeleteing,
      };
      break;
    case DELETE_PORTAL_HELP_CATEGORY_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default portalHelpCategories;
