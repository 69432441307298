import {
  renderBuilderElements,
  getInputTree,
} from "components/shared/renderBuilderElements";
import React from "react";
import { Row } from "react-bootstrap";
import { useDrop } from "react-dnd";
import { v4 } from "uuid";

const InputsHolder = ({
  componentData,
  setComponentData,
  activeComponent,
  setActiveComponent,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [collectedProps, drop] = useDrop(() => ({
    accept: "text",
    drop: (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (didDrop) {
        return;
      }
      setComponentData((prevDropedElements) => [
        ...prevDropedElements,
        { id: v4(), parent: null, ...item },
      ]);
    },
  }));

  const inputsTree = getInputTree(componentData);


  return (
    <>
      <Row ref={drop} className="inputsHolder">
        {/* <Tree data={DATA} /> */}
        {renderBuilderElements({
          inputsTree,
          componentData,
          setComponentData,
          activeComponent,
          setActiveComponent,
        })}
      </Row>
    </>
  );
};

export default InputsHolder;
