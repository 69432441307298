import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Col, Dropdown, Row } from "react-bootstrap";
import {
  addItem,
  addItemUser,
  deleteItemUser,
  editItemUser,
  getItems,
  toggleActiveUser,
} from "store/actions";
import { ReactComponent as DotsIcon } from "assets/svgs/three-dots.svg";
import { ReactComponent as EditIcon } from "assets/svgs/edit.svg";
import { ReactComponent as ToggleIcon } from "assets/svgs/toggle.svg";
import { ReactComponent as DashboardIcon } from "assets/svgs/dashboard-active.svg";
import { ReactComponent as DeleteIcon } from "assets/svgs/delete.svg";

import "styles/permissions.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { getId, handleImage } from "helpers/functions";
import AddUserPopup from "./AddUserPopup";

const Users = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [showAddUserPopUp, setShowAddUserPopUp] = useState(false);
  const [userId, setUserId] = useState("");
  const { locale, formatMessage } = useIntl();
  const [searchParams] = useSearchParams();

  const { permissionsData, loading } = useSelector(
    (state) => state.permissionsData
  );
  const handleToggle = useCallback(() => {
    setShowAddUserPopUp((prev) => !prev);
  }, []);

  useEffect(() => {
    dispatch(getItems({ dataKey: "roles" }));
    dispatch(getItems({ dataKey: "auth/superAdmins" }));
  }, [dispatch, searchParams]);

  const handleToggleActivation = (id) => {
    dispatch(
      toggleActiveUser({
        id,
      })
    );
  };

  const handleDeletePermission = (name, id) => {
    Swal.fire({
      title: `${formatMessage({ id: "delete" })} ${formatMessage({
        id: "user2",
      })} ${name}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1b659e",
      cancelButtonColor: "#cc4949",
      confirmButtonText: "نعم",
      cancelButtonText: "لا",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteItemUser({ id, dataKey: "auth/superAdmins" }));
      }
    });

    // dispatch(deleteAdmin(id));
  };

  const activeUsersCount =
    permissionsData?.superAdmins?.filter((user) => user.isActive)?.length || 0;

  const inactiveUsersCount =
    permissionsData?.superAdmins?.filter((user) => !user.isActive)?.length || 0;

  // if (loading) return <Loader />;

  return (
    <>
      <div className="permissions">
        <div className="breadcrumb">
          <h2 className="active">
            <DashboardIcon />

            <FormattedMessage id="dashboard" />
          </h2>
          <h2>&gt;</h2>
          <h2>
            <FormattedMessage id="permissions" />
          </h2>
          <h2>&gt;</h2>
          <h2>
            <FormattedMessage id="users2" />
          </h2>
        </div>

        <button
          className="addRoleBtn"
          onClick={() => {
            handleToggle();
            setUserId("");
          }}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.4286 6.39286H9.60714V1.57143C9.60714 0.979799 9.12734 0.5 8.53571 0.5H7.46429C6.87266 0.5 6.39286 0.979799 6.39286 1.57143V6.39286H1.57143C0.979799 6.39286 0.5 6.87266 0.5 7.46429V8.53571C0.5 9.12734 0.979799 9.60714 1.57143 9.60714H6.39286V14.4286C6.39286 15.0202 6.87266 15.5 7.46429 15.5H8.53571C9.12734 15.5 9.60714 15.0202 9.60714 14.4286V9.60714H14.4286C15.0202 9.60714 15.5 9.12734 15.5 8.53571V7.46429C15.5 6.87266 15.0202 6.39286 14.4286 6.39286Z"
              fill="white"
            />
          </svg>

          {formatMessage({ id: "addUser2" })}
        </button>

        <div className="searchBarPermissions">
          <div className="right">
            <h3 className="active">مستخدم مفعل ({activeUsersCount})</h3>
            <h3>مستخدم معلق ({inactiveUsersCount})</h3>
          </div>
          <div className="left">
            <input
              type="text"
              placeholder={formatMessage({ id: "searchAboutUser" })}
            />
            <button className="searchBtn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="31"
                height="32"
                viewBox="0 0 31 32"
                fill="none"
              >
                <rect
                  width="30.125"
                  height="30.125"
                  rx="6.75"
                  transform="matrix(-1 0 0 1 30.125 0.9375)"
                  fill="url(#paint0_linear_83_4712)"
                />
                <circle
                  cx="14"
                  cy="15.9375"
                  r="4.3"
                  stroke="white"
                  strokeWidth="1.4"
                />
                <line
                  x1="19.0101"
                  y1="21.9375"
                  x2="17"
                  y2="19.9275"
                  stroke="white"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_83_4712"
                    x1="1.00971"
                    y1="0.00729205"
                    x2="58.354"
                    y2="50.0328"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#E74C3C" />
                    <stop offset="1" stopColor="#FC6B14" />
                  </linearGradient>
                </defs>
              </svg>
            </button>
          </div>
        </div>

        <div className="allRoles">
          <Row>
            {permissionsData?.superAdmins?.map((item) => (
              <Col key={getId(item)} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <div className="oneRole">
                  <div className="title">
                    <div className="imageUser">
                      <img src={handleImage(item?.image)} alt="user" />
                    </div>
                    <div>
                      <h3>{item?.name}</h3>
                      <h4>يمكن إدارة المستخدمين وإنشاء وتحديث المشاريع.</h4>
                    </div>
                  </div>
                  <div className="roleName">
                    <h3 className="active">مطور</h3>
                  </div>
                  <div className="roleName">
                    <h3>{item?.isActive ? "مفعل" : "غير مفعل"}</h3>
                  </div>
                  <div className="global-dropdown">
                    <Dropdown>
                      <Dropdown.Toggle
                        as="button"
                        className="btn btn-secondary"
                      >
                        <DotsIcon width="20" height="40" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          // onClick={(e) => handleEditPermission(getId(item))}
                          onClick={(e) => {
                            setUserId(getId(item));
                            handleToggle();
                          }}
                        >
                          <EditIcon className="bi bi-pencil edit" />
                          <FormattedMessage id="edit" />
                        </Dropdown.Item>
                        <Dropdown.Item
                          // onClick={(e) => handleEditPermission(getId(item))}
                          onClick={(e) => handleToggleActivation(getId(item))}
                        >
                          <ToggleIcon className="bg-info" />
                          <FormattedMessage
                            id={!item?.isActive ? "activate" : "deactivate"}
                          />
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) =>
                            handleDeletePermission(
                              item?.name?.[locale] || item?.name,
                              getId(item)
                            )
                          }
                        >
                          <DeleteIcon
                            className="bi bi-trash delete"
                            fill="#fff"
                          />
                          <FormattedMessage id="delete" />
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          {/* {loading && !showAddUserPopUp && <StickyLoader fill="#395cf5" />} */}
        </div>

        {showAddUserPopUp && (
          <AddUserPopup
            userId={userId}
            show={showAddUserPopUp}
            setShow={setShowAddUserPopUp}
            handleToggle={handleToggle}
            roles={permissionsData?.roles}
          />
        )}
      </div>
    </>
  );
};

export default Users;
