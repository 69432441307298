import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  getStoresApi,
  addStoreApi,
  editStoreApi,
  deleteStoreApi,
  getSingleStoreApi,
} from "../../api/stores";

// Login Redux States
import {
  ADD_STORE,
  DELETE_STORE,
  EDIT_STORE,
  GET_SINGLE_STORE,
  GET_STORES,
} from "./actionTypes";
import {
  addStoreFailure,
  addStoreSuccess,
  deleteStoreFailure,
  deleteStoreSuccess,
  editStoreFailure,
  editStoreSuccess,
  getSingleStoreFailure,
  getSingleStoreSuccess,
  getStoresFailure,
  getStoresSuccess,
} from "./actions";

function* getStores() {
  try {
    const { data } = yield call(getStoresApi);
    yield put(getStoresSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getStoresFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* getSingleStore({ payload }) {
  try {
    const { data } = yield call(getSingleStoreApi, payload);
    yield put(getSingleStoreSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleStoreFailure(error?.response?.data || error));
  }
}

function* addStore({ payload }) {
  try {
    const { data } = yield call(addStoreApi, payload.data);
    yield put(addStoreSuccess(data));
    yield payload.navigate("/stores");
  } catch (error) {
    console.log(error);
    yield put(addStoreFailure(error.response?.data?.message || error));
  }
}

function* editStore({ payload }) {
  try {
    yield call(editStoreApi, payload);
    yield put(editStoreSuccess(payload));
    yield payload.navigate("/stores");
  } catch (error) {
    console.log(error);
    yield put(editStoreFailure(error?.response?.data || error));
  }
}

function* deleteStore({ payload }) {
  try {
    yield call(deleteStoreApi, payload);
    yield put(deleteStoreSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteStoreFailure(error?.response?.data || error));
  }
}

export function* watchGetStores() {
  yield takeEvery(GET_STORES, getStores);
}

export function* watchGetSingleStore() {
  yield takeEvery(GET_SINGLE_STORE, getSingleStore);
}

export function* watchAddStore() {
  yield takeEvery(ADD_STORE, addStore);
}

export function* watchEditStore() {
  yield takeEvery(EDIT_STORE, editStore);
}

export function* watchDeleteStore() {
  yield takeEvery(DELETE_STORE, deleteStore);
}

function* storesSaga() {
  yield all([fork(watchGetStores)]);
  yield all([fork(watchGetSingleStore)]);
  yield all([fork(watchAddStore)]);
  yield all([fork(watchEditStore)]);
  yield all([fork(watchDeleteStore)]);
}

export default storesSaga;
