import {
  GET_PACKAGES,
  GET_PACKAGES_SUCCESS,
  GET_PACKAGES_FAILURE,
  ADD_PACKAGE,
  ADD_PACKAGE_SUCCESS,
  ADD_PACKAGE_FAILURE,
  EDIT_PACKAGE,
  EDIT_PACKAGE_SUCCESS,
  EDIT_PACKAGE_FAILURE,
  DELETE_PACKAGE,
  DELETE_PACKAGE_SUCCESS,
  DELETE_PACKAGE_FAILURE,
  GET_SINGLE_PACKAGE,
  GET_SINGLE_PACKAGE_SUCCESS,
  GET_SINGLE_PACKAGE_FAILURE,
} from "./actionTypes";

export const getPackages = () => {
  return {
    type: GET_PACKAGES,
  };
};

export const getPackagesSuccess = (stores) => {
  return {
    type: GET_PACKAGES_SUCCESS,
    payload: stores,
  };
};

export const getPackagesFailure = (error) => {
  return {
    type: GET_PACKAGES_FAILURE,
    payload: error,
  };
};

export const getSinglePackage = (id) => {
  return {
    type: GET_SINGLE_PACKAGE,
    payload: id,
  };
};

export const getSinglePackageSuccess = (theme) => {
  return {
    type: GET_SINGLE_PACKAGE_SUCCESS,
    payload: theme,
  };
};

export const getSinglePackageFailure = (error) => {
  return {
    type: GET_SINGLE_PACKAGE_FAILURE,
    payload: error,
  };
};

export const addPackage = (payload) => {
  return {
    type: ADD_PACKAGE,
    payload,
  };
};

export const addPackageSuccess = (payload) => {
  return {
    type: ADD_PACKAGE_SUCCESS,
    payload,
  };
};

export const addPackageFailure = (error) => {
  return {
    type: ADD_PACKAGE_FAILURE,
    payload: error,
  };
};

export const editPackage = (payload) => {
  return {
    type: EDIT_PACKAGE,
    payload,
  };
};

export const editPackageSuccess = (payload) => {
  return {
    type: EDIT_PACKAGE_SUCCESS,
    payload,
  };
};

export const editPackageFailure = (error) => {
  return {
    type: EDIT_PACKAGE_FAILURE,
    payload: error,
  };
};

export const deletePackage = (id) => {
  return {
    type: DELETE_PACKAGE,
    payload: id,
  };
};

export const deletePackageSuccess = (id) => {
  return {
    type: DELETE_PACKAGE_SUCCESS,
    payload: id,
  };
};

export const deletePackageFailure = (error) => {
  return {
    type: DELETE_PACKAGE_FAILURE,
    payload: error,
  };
};
