export const GET_THEMES_TEMPLATE_COMPONENTS = "GET_THEMES_TEMPLATE_COMPONENTS";
export const GET_THEMES_TEMPLATE_COMPONENTS_SUCCESS =
  "GET_THEMES_TEMPLATE_COMPONENTS_SUCCESS";
export const GET_THEMES_TEMPLATE_COMPONENTS_FAILURE =
  "GET_THEMES_TEMPLATE_COMPONENTS_FAILURE";
export const GET_SINGLE_THEMES_TEMPLATE_COMPONENT =
  "GET_SINGLE_THEMES_TEMPLATE_COMPONENT";
export const GET_SINGLE_THEMES_TEMPLATE_COMPONENT_SUCCESS =
  "GET_SINGLE_THEMES_TEMPLATE_COMPONENT_SUCCESS";
export const GET_SINGLE_THEMES_TEMPLATE_COMPONENT_FAILURE =
  "GET_SINGLE_THEMES_TEMPLATE_COMPONENT_FAILURE";
export const ADD_THEMES_TEMPLATE_COMPONENT = "ADD_THEMES_TEMPLATE_COMPONENT";
export const ADD_THEMES_TEMPLATE_COMPONENT_SUCCESS =
  "ADD_THEMES_TEMPLATE_COMPONENT_SUCCESS";
export const ADD_THEMES_TEMPLATE_COMPONENT_FAILURE =
  "ADD_THEMES_TEMPLATE_COMPONENT_FAILURE";
export const EDIT_THEMES_TEMPLATE_COMPONENT = "EDIT_THEMES_TEMPLATE_COMPONENT";
export const EDIT_THEMES_TEMPLATE_COMPONENT_SUCCESS =
  "EDIT_THEMES_TEMPLATE_COMPONENT_SUCCESS";
export const EDIT_THEMES_TEMPLATE_COMPONENT_FAILURE =
  "EDIT_THEMES_TEMPLATE_COMPONENT_FAILURE";
export const DELETE_THEMES_TEMPLATE_COMPONENT =
  "DELETE_THEMES_TEMPLATE_COMPONENT";
export const DELETE_THEMES_TEMPLATE_COMPONENT_SUCCESS =
  "DELETE_THEMES_TEMPLATE_COMPONENT_SUCCESS";
export const DELETE_THEMES_TEMPLATE_COMPONENT_FAILURE =
  "DELETE_THEMES_TEMPLATE_COMPONENT_FAILURE";
