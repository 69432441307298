import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addThemeApi,
  deleteThemeApi,
  editThemeApi,
  getSingleThemeApi,
  getThemesApi as getThemesComponentsApi,
} from "api/themesComponents";

// Login Redux States
import {
  GET_THEMES_COMPONENTS,
  ADD_THEMES_COMPONENT,
  EDIT_THEMES_COMPONENT,
  DELETE_THEMES_COMPONENT,
  GET_SINGLE_THEMES_COMPONENT,
} from "./actionTypes";
import {
  addThemesComponentFailure,
  addThemesComponentSuccess,
  deleteThemesComponentFailure,
  deleteThemesComponentSuccess,
  editThemesComponentFailure,
  editThemesComponentSuccess,
  getSingleThemesComponentFailure,
  getSingleThemesComponentSuccess,
  getThemesComponentsFailure,
  getThemesComponentsSuccess,
} from "./actions";

function* getThemes({ payload }) {
  try {
    const { data } = yield call(getThemesComponentsApi, payload);
    yield put(getThemesComponentsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(
      getThemesComponentsFailure(error?.response?.data?.errors?.[0]?.msg)
    );
  }
}

function* getSingleTheme({ payload }) {
  try {
    const { data } = yield call(getSingleThemeApi, payload);
    yield put(getSingleThemesComponentSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleThemesComponentFailure(error?.response?.data || error));
  }
}

function* add({ payload }) {
  try {
    const { data } = yield call(addThemeApi, payload);
    yield put(addThemesComponentSuccess(data));
    yield payload.navigate(
      `/themes/components?deviceType=${payload?.deviceType}`
    );
  } catch (error) {
    console.log(error);
    yield put(
      addThemesComponentFailure(error.response?.data?.message || error)
    );
  }
}

function* edit({ payload }) {
  try {
    const { data } = yield call(editThemeApi, payload);
    yield put(editThemesComponentSuccess(data));
    yield payload.navigate(
      `/themes/components?deviceType=${payload?.deviceType}`
    );
  } catch (error) {
    console.log(error);
    yield put(editThemesComponentFailure(error?.response?.data || error));
  }
}

function* deleteT({ payload }) {
  try {
    yield call(deleteThemeApi, payload);
    yield put(deleteThemesComponentSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteThemesComponentFailure(error?.response?.data || error));
  }
}

export function* watchGetThemes() {
  yield takeEvery(GET_THEMES_COMPONENTS, getThemes);
}

export function* watchGetSingleTheme() {
  yield takeEvery(GET_SINGLE_THEMES_COMPONENT, getSingleTheme);
}

export function* watchAddTheme() {
  yield takeEvery(ADD_THEMES_COMPONENT, add);
}

export function* watchEditTheme() {
  yield takeEvery(EDIT_THEMES_COMPONENT, edit);
}

export function* watchDeleteTheme() {
  yield takeEvery(DELETE_THEMES_COMPONENT, deleteT);
}

function* themesSaga() {
  yield all([fork(watchGetThemes)]);
  yield all([fork(watchGetSingleTheme)]);
  yield all([fork(watchAddTheme)]);
  yield all([fork(watchEditTheme)]);
  yield all([fork(watchDeleteTheme)]);
}

export default themesSaga;
