import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import {
  editThemesTemplateComponent,
  getSingleThemesTemplateComponent,
  getThemeComponents,
  getThemesPages,
} from "store/actions";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Select from "react-select";
import { useIntl } from "react-intl";
import Loader from "components/shared/Loader";
import { getId } from "helpers/functions";
import { toast } from "react-toastify";
const EditTheme = () => {
  const { locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { templateId, id } = useParams();
  const { pages } = useSelector((state) => state.pages);
  const { components } = useSelector((state) => state.components);
  const { singleComponent, loading } = useSelector(
    (state) => state?.templateComponents
  );
  const [searchParams] = useSearchParams();
  const deviceType = searchParams.get("deviceType");

  const { register, handleSubmit, control, reset } = useForm();

  useEffect(() => {
    dispatch(getThemeComponents(deviceType));
    dispatch(getThemesPages(deviceType));
    dispatch(getSingleThemesTemplateComponent({ templateId, id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    reset({
      sort: singleComponent?.sort,
      data: JSON.stringify(singleComponent?.data, undefined, 2),
      page: getId(singleComponent?.page) || singleComponent?.page,
      component:
        getId(singleComponent?.component) || singleComponent?.component,
    });
  }, [singleComponent, reset]);

  const onSubmit = (data) => {
    try {
      data.data = JSON.parse(data?.data);
    } catch (error) {
      toast(`${error}`);
    }

    dispatch(
      editThemesTemplateComponent({
        templateId,
        id,
        data,
        navigate,
        deviceType,
      })
    );
  };

  const renderListPages = () => {
    return pages?.map((category) => ({
      label: category.name?.[locale],
      value: category?.name?.[locale],
      id: getId(category),
    }));
  };
  const renderListComponents = () => {
    return components?.map((category) => ({
      label: category.name?.[locale],
      value: category?.name?.[locale],
      id: getId(category),
    }));
  };

  if (loading) return <Loader />;
  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>تعديل عنصر قالب</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col xl={10} md={12}>
                    <Row>
                      <Col lg={6} xs={12}>
                        <div className="form-group required">
                          <h5>رقم الترتيب</h5>
                          <div>
                            <input
                              type="number"
                              className="form-control form-outline"
                              placeholder="رقم الترتيب"
                              {...register("sort", { required: true })}
                            />
                          </div>
                        </div>
                      </Col>

                      <Col lg={6} xs={12}>
                        <div className="form-group required">
                          <h5>الصفحة</h5>
                          <div>
                            <Controller
                              control={control}
                              name="page"
                              render={({ field: { onChange, value } }) => (
                                <Select
                                  isRtl={true}
                                  // menuIsOpen={true}
                                  isSearchable={false}
                                  className="basic-single"
                                  classNamePrefix="select"
                                  options={renderListPages()}
                                  placeholder="اختار الصفحة"
                                  isMulti={false}
                                  value={renderListPages()?.find(
                                    (e) => e?.id === value
                                  )}
                                  onChange={(value) => {
                                    onChange(value.id);
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </Col>

                      <Col lg={6} xs={12}>
                        <div className="form-group required">
                          <h5>العنصر</h5>
                          <div>
                            <Controller
                              control={control}
                              name="component"
                              render={({ field: { onChange, value } }) => (
                                <Select
                                  isRtl={true}
                                  // menuIsOpen={true}
                                  isSearchable={false}
                                  className="basic-single"
                                  classNamePrefix="select"
                                  options={renderListComponents()}
                                  placeholder="اختار العنصر"
                                  isMulti={false}
                                  value={renderListComponents()?.find(
                                    (e) => e?.id === value
                                  )}
                                  onChange={(value) => {
                                    onChange(value.id);
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </Col>

                      <Col xs={12}>
                        <div className="form-group required">
                          <h5>بيانات العنصر</h5>
                          <div>
                            <textarea
                              className="form-control form-outline"
                              placeholder="بيانات العنصر "
                              style={{ direction: "ltr" }}
                              {...register("data", {
                                required: true,
                              })}
                            />
                          </div>
                        </div>
                      </Col>

                      <Col lg={4} xs={12}>
                        <div className="form-group">
                          <button type="submit" className="btn btn-blue">
                            حفظ التعديلات
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditTheme;
