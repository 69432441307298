import { all } from "redux-saga/effects";

//public
import AuthenticationSaga from "./authentication/saga";
import StoresSaga from "./stores/saga";
import CategoriesSaga from "./categories/saga";
import FontsSaga from "./fonts/saga";
import PackagesSaga from "./packages/saga";
import UsersSaga from "./users/saga";
import featuresSaga from "./features/saga";
import paymentMethodsSaga from "./paymentMethods/saga";
import themesSaga from "./themes/saga";

import components from "./themesComponents/saga";
import componentsTypes from "./themesComponentsTypes/saga";
import pages from "./themesPages/saga";
import templates from "./themesTemplates/saga";
import templateComponents from "./themesTemplates/components/saga";

import areasSaga from "./areas/saga";
import citiesSaga from "./cities/saga";
import countriesSaga from "./countries/saga";
import productTypesSaga from "./productTypes/saga";
import languagesSaga from "./languages/saga";
import measuringUnitsSaga from "./measuringUnits/saga";
import banksSaga from "./banks/saga";
import currenciesSaga from "./currencies/saga";
import portalTechnicalSupportSaga from "./portalTechnicalSupport/saga";
import portalReservationsSaga from "./portalReservations/saga";
import portalHelpCategoriesSaga from "./portalHelpCategories/saga";
import portalHelpBlogsSaga from "./portalHelpBlogs/saga";
import blogsSaga from "./blogs/saga";
import blogsCategoriesSaga from "./blogCategories/saga";
import portalFaqsSaga from "./portalFaqs/saga";
import subscribersSaga from "./subscribers/saga";
import portalSectionsSaga from "./portalSections/saga";
import settingsSaga from "./settings/saga";
import permissionsDataSaga from "./permissionsData/saga";
import seoRedirectsSaga from "./seoRedirects/saga";

export default function* rootSaga() {
  yield all([
    AuthenticationSaga(),
    StoresSaga(),
    CategoriesSaga(),
    FontsSaga(),
    PackagesSaga(),
    UsersSaga(),
    featuresSaga(),
    components(),
    componentsTypes(),
    pages(),
    templates(),
    templateComponents(),
    paymentMethodsSaga(),
    themesSaga(),
    areasSaga(),
    citiesSaga(),
    countriesSaga(),
    productTypesSaga(),
    languagesSaga(),
    measuringUnitsSaga(),
    banksSaga(),
    currenciesSaga(),
    portalTechnicalSupportSaga(),
    portalReservationsSaga(),
    portalHelpCategoriesSaga(),
    portalHelpBlogsSaga(),
    blogsSaga(),
    blogsCategoriesSaga(),
    portalFaqsSaga(),
    subscribersSaga(),
    portalSectionsSaga(),
    settingsSaga(),
    permissionsDataSaga(),
    seoRedirectsSaga(),
  ]);
}
