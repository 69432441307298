import {
  GET_THEMES_TEMPLATE_COMPONENTS,
  GET_THEMES_TEMPLATE_COMPONENTS_SUCCESS,
  GET_THEMES_TEMPLATE_COMPONENTS_FAILURE,
  GET_SINGLE_THEMES_TEMPLATE_COMPONENT,
  GET_SINGLE_THEMES_TEMPLATE_COMPONENT_SUCCESS,
  GET_SINGLE_THEMES_TEMPLATE_COMPONENT_FAILURE,
  ADD_THEMES_TEMPLATE_COMPONENT,
  ADD_THEMES_TEMPLATE_COMPONENT_SUCCESS,
  ADD_THEMES_TEMPLATE_COMPONENT_FAILURE,
  EDIT_THEMES_TEMPLATE_COMPONENT,
  EDIT_THEMES_TEMPLATE_COMPONENT_SUCCESS,
  EDIT_THEMES_TEMPLATE_COMPONENT_FAILURE,
  DELETE_THEMES_TEMPLATE_COMPONENT,
  DELETE_THEMES_TEMPLATE_COMPONENT_SUCCESS,
  DELETE_THEMES_TEMPLATE_COMPONENT_FAILURE,
} from "./actionTypes";

export const getThemesTemplateComponents = (payload) => {
  return {
    type: GET_THEMES_TEMPLATE_COMPONENTS,
    payload,
  };
};

export const getThemesTemplateComponentsSuccess = (themes) => {
  return {
    type: GET_THEMES_TEMPLATE_COMPONENTS_SUCCESS,
    payload: themes,
  };
};

export const getThemesTemplateComponentsFailure = (error) => {
  return {
    type: GET_THEMES_TEMPLATE_COMPONENTS_FAILURE,
    payload: error,
  };
};

export const getSingleThemesTemplateComponent = (id) => {
  return {
    type: GET_SINGLE_THEMES_TEMPLATE_COMPONENT,
    payload: id,
  };
};

export const getSingleThemesTemplateComponentSuccess = (theme) => {
  return {
    type: GET_SINGLE_THEMES_TEMPLATE_COMPONENT_SUCCESS,
    payload: theme,
  };
};

export const getSingleThemesTemplateComponentFailure = (error) => {
  return {
    type: GET_SINGLE_THEMES_TEMPLATE_COMPONENT_FAILURE,
    payload: error,
  };
};

export const addThemesTemplateComponent = (payload) => {
  return {
    type: ADD_THEMES_TEMPLATE_COMPONENT,
    payload,
  };
};

export const addThemesTemplateComponentSuccess = (payload) => {
  return {
    type: ADD_THEMES_TEMPLATE_COMPONENT_SUCCESS,
    payload,
  };
};

export const addThemesTemplateComponentFailure = (error) => {
  return {
    type: ADD_THEMES_TEMPLATE_COMPONENT_FAILURE,
    payload: error,
  };
};

export const editThemesTemplateComponent = (payload) => {
  return {
    type: EDIT_THEMES_TEMPLATE_COMPONENT,
    payload,
  };
};

export const editThemesTemplateComponentSuccess = (payload) => {
  return {
    type: EDIT_THEMES_TEMPLATE_COMPONENT_SUCCESS,
    payload,
  };
};

export const editThemesTemplateComponentFailure = (error) => {
  return {
    type: EDIT_THEMES_TEMPLATE_COMPONENT_FAILURE,
    payload: error,
  };
};

export const deleteThemesTemplateComponent = (id) => {
  return {
    type: DELETE_THEMES_TEMPLATE_COMPONENT,
    payload: id,
  };
};

export const deleteThemesTemplateComponentSuccess = (id) => {
  return {
    type: DELETE_THEMES_TEMPLATE_COMPONENT_SUCCESS,
    payload: id,
  };
};

export const deleteThemesTemplateComponentFailure = (error) => {
  return {
    type: DELETE_THEMES_TEMPLATE_COMPONENT_FAILURE,
    payload: error,
  };
};
