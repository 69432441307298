import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addCurrencyApi,
  deleteCurrencyApi,
  editCurrencyApi,
  getCurrenciesApi,
  getSingleCurrencyApi,
} from "api/currencies";

// Login Redux States
import {
  ADD_CURRENCY,
  DELETE_CURRENCY,
  EDIT_CURRENCY,
  GET_CURRENCIES,
  GET_SINGLE_CURRENCY,
} from "./actionTypes";

import {
  addCurrencyFailure,
  addCurrencysuccess,
  deleteCurrencyFailure,
  deleteCurrencysuccess,
  editCurrencyFailure,
  editCurrencysuccess,
  getCurrenciesFailure,
  getCurrenciesSuccess,
  getSingleCurrencyFailure,
  getSingleCurrencysuccess,
} from "./actions";

function* getCurrencies() {
  try {
    const { data } = yield call(getCurrenciesApi);
    yield put(getCurrenciesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getCurrenciesFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* addCurrency({ payload }) {
  try {
    const { data } = yield call(addCurrencyApi, payload.data);
    yield put(addCurrencysuccess(data));
    yield payload.navigate("/currencies");
  } catch (error) {
    console.log(error);
    yield put(addCurrencyFailure(error.response?.data?.message || error));
  }
}

function* getSingleCurrency({ payload }) {
  try {
    const { data } = yield call(getSingleCurrencyApi, payload);
    yield put(getSingleCurrencysuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleCurrencyFailure(error?.response?.data || error));
  }
}

function* editCurrency({ payload }) {
  try {
    yield call(editCurrencyApi, payload);
    yield put(editCurrencysuccess(payload));
    yield payload.navigate("/currencies");
  } catch (error) {
    console.log(error);
    yield put(editCurrencyFailure(error?.response?.data || error));
  }
}

function* deleteCurrency({ payload }) {
  try {
    yield call(deleteCurrencyApi, payload);
    yield put(deleteCurrencysuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteCurrencyFailure(error?.response?.data || error));
  }
}

export function* watchGetCurrencies() {
  yield takeEvery(GET_CURRENCIES, getCurrencies);
}

export function* watchAddCurrency() {
  yield takeEvery(ADD_CURRENCY, addCurrency);
}

export function* watchGetSingleCurrency() {
  yield takeEvery(GET_SINGLE_CURRENCY, getSingleCurrency);
}

export function* watchEditCurrency() {
  yield takeEvery(EDIT_CURRENCY, editCurrency);
}

export function* watchDeleteCurrency() {
  yield takeEvery(DELETE_CURRENCY, deleteCurrency);
}

function* currenciesSaga() {
  yield all([fork(watchGetCurrencies)]);
  yield all([fork(watchGetSingleCurrency)]);
  yield all([fork(watchAddCurrency)]);
  yield all([fork(watchEditCurrency)]);
  yield all([fork(watchDeleteCurrency)]);
}

export default currenciesSaga;
