import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addThemeApi,
  deleteThemeApi,
  editThemeApi,
  getSingleThemeApi,
  getThemesApi,
} from "api/themes";

// Login Redux States
import {
  GET_THEMES,
  ADD_THEME,
  EDIT_THEME,
  DELETE_THEME,
  GET_SINGLE_THEME,
} from "./actionTypes";
import {
  addThemeFailure,
  addThemeSuccess,
  deleteThemeFailure,
  deleteThemeSuccess,
  editThemeFailure,
  editThemeSuccess,
  getSingleThemeFailure,
  getSingleThemeSuccess,
  getThemesFailure,
  getThemesSuccess,
} from "./actions";

function* getThemes() {
  try {
    const { data } = yield call(getThemesApi);
    yield put(getThemesSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getThemesFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* getSingleTheme({ payload }) {
  try {
    const { data } = yield call(getSingleThemeApi, payload);
    yield put(getSingleThemeSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSingleThemeFailure(error?.response?.data || error));
  }
}

function* addTheme({ payload }) {
  try {
    const { data } = yield call(addThemeApi, payload.data);
    yield put(addThemeSuccess(data));
    yield payload.navigate(`/themes?deviceType=${payload?.deviceType}`);
  } catch (error) {
    console.log(error);
    yield put(addThemeFailure(error.response?.data?.message || error));
  }
}

function* editTheme({ payload }) {
  try {
    const { data } = yield call(editThemeApi, payload);
    yield put(editThemeSuccess(data));
    yield payload.navigate(`/themes?deviceType=${payload?.deviceType}`);
  } catch (error) {
    console.log(error);
    yield put(editThemeFailure(error?.response?.data || error));
  }
}

function* deleteTheme({ payload }) {
  try {
    yield call(deleteThemeApi, payload);
    yield put(deleteThemeSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteThemeFailure(error?.response?.data || error));
  }
}

export function* watchGetThemes() {
  yield takeEvery(GET_THEMES, getThemes);
}

export function* watchGetSingleTheme() {
  yield takeEvery(GET_SINGLE_THEME, getSingleTheme);
}

export function* watchAddTheme() {
  yield takeEvery(ADD_THEME, addTheme);
}

export function* watchEditTheme() {
  yield takeEvery(EDIT_THEME, editTheme);
}

export function* watchDeleteTheme() {
  yield takeEvery(DELETE_THEME, deleteTheme);
}

function* themesSaga() {
  yield all([fork(watchGetThemes)]);
  yield all([fork(watchGetSingleTheme)]);
  yield all([fork(watchAddTheme)]);
  yield all([fork(watchEditTheme)]);
  yield all([fork(watchDeleteTheme)]);
}

export default themesSaga;
