import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { addItem, getItems } from "store/actions";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "components/shared/Loader";
import { useEffect } from "react";
import MultiLangsWrapper from "components/shared/MultiLangsWrapper";

const AddGroup = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state?.permissionsData);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(getItems({ dataKey: "roles" }));
    dispatch(getItems({ dataKey: "groups" }));
  }, [dispatch]);

  const onSubmit = (data) => {
    dispatch(addItem({ dataKey: "groups", data, navigate }));
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="addGroup" />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <MultiLangsWrapper
                    errors={errors?.name}
                    label={
                      <label>
                        <FormattedMessage id={"name"} />
                      </label>
                    }
                    customClass="form-group"
                  >
                    {({ lang, classes, key }) => (
                      <input
                        key={key}
                        className={`form-control ${classes}`}
                        type="text"
                        placeholder={formatMessage({ id: "name" })}
                        {...register(`name.${lang}`, { required: true })}
                      />
                    )}
                    {/* <span className="error-hint" key="1">
                      {errors?.name && <FormattedMessage id="nameErr" />}
                    </span> */}
                  </MultiLangsWrapper>
                </Row>
                <Col lg={4} xs={12}>
                  <div className="form-group">
                    <button type="submit" className="btn btn-blue">
                      <FormattedMessage id="add" />
                    </button>
                  </div>
                </Col>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddGroup;
