import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { addFeature } from "store/actions";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { portalFeaturesTypes } from "helpers/configs";
import { getId } from "helpers/functions";
import ReactSelect from "react-select";
import Loader from "components/shared/Loader";
const AddFeature = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const { loading } = useSelector((state) => state?.features);

  const onSubmit = (data) => {
    dispatch(addFeature({ data, navigate }));
  };
  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="addFeature" />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم الميزة بالعربية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم الميزة بالعربية"
                          {...register("name[ar]", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.["ar"]?.type === "required" &&
                          "يرجي ادخال اسم الميزة بالعربية"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم الميزة بالانجليزية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم الميزة بالانجليزية"
                          {...register("name[en]", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.["en"]?.type === "required" &&
                          "يرجي ادخال اسم الميزة بالانجليزية"}
                      </p>
                    </div>
                  </Col>

                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="featureType" />
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          name="type"
                          render={({
                            field: { onChange, value, selected },
                          }) => (
                            <ReactSelect
                              isRtl={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"
                              options={portalFeaturesTypes?.map((item) => ({
                                label: formatMessage({ id: item || " " }),
                                value: getId(item),
                              }))}
                              isMulti={false}
                              defaultValue={portalFeaturesTypes
                                ?.map((item) => ({
                                  label: formatMessage({ id: item || " " }),
                                  value: getId(item),
                                }))
                                ?.find((item) => item?.value === value)}
                              onChange={({ value }) => {
                                onChange(value);
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        اضافة الميزة
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddFeature;
