import React from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { addLanguage } from "store/actions";
import { useNavigate } from "react-router-dom";
const AddLanguage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    dispatch(addLanguage({ data, navigate }));
  };

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>اضف لغة جديدة</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم اللغة</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم اللغة"
                          {...register("name", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.type === "required" &&
                          "يرجي ادخال اسم اللغة"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اختصار اللغة (locale)</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="locale"
                          {...register("locale", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اتجاه اللغة </h5>
                      <div>
                        <select
                          className="form-control"
                          name="dir"
                          {...register("dir", { required: true })}
                        >
                          <option value="">يرجي تحديد اتجاه اللغة</option>
                          <option value="ltr">من اليسار الي اليمين</option>
                          <option value="rtl">من اليمين الي اليسار</option>
                        </select>
                      </div>
                      <p className="error-hint">
                        {errors?.country?.type === "required" &&
                          "يرجي اختيار اتجاه اللغة"}
                      </p>
                    </div>
                  </Col>
                  <Row />
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        اضافة اللغة
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddLanguage;
