import Builder from "components/Layout/Builder";
import React from "react";

const BuilderPage = () => {
  return (
    <>
      <Builder />
    </>
  );
};

export default BuilderPage;
