import {
  GET_THEMES_TEMPLATES,
  GET_THEMES_TEMPLATES_SUCCESS,
  GET_THEMES_TEMPLATES_FAILURE,
  GET_SINGLE_THEMES_TEMPLATE,
  GET_SINGLE_THEMES_TEMPLATE_SUCCESS,
  GET_SINGLE_THEMES_TEMPLATE_FAILURE,
  ADD_THEMES_TEMPLATE,
  ADD_THEMES_TEMPLATE_SUCCESS,
  ADD_THEMES_TEMPLATE_FAILURE,
  EDIT_THEMES_TEMPLATE,
  EDIT_THEMES_TEMPLATE_SUCCESS,
  EDIT_THEMES_TEMPLATE_FAILURE,
  DELETE_THEMES_TEMPLATE,
  DELETE_THEMES_TEMPLATE_SUCCESS,
  DELETE_THEMES_TEMPLATE_FAILURE,
} from "./actionTypes";

export const getThemesTemplates = (payload) => {
  return {
    type: GET_THEMES_TEMPLATES,
    payload,
  };
};

export const getThemesTemplatesSuccess = (themes) => {
  return {
    type: GET_THEMES_TEMPLATES_SUCCESS,
    payload: themes,
  };
};

export const getThemesTemplatesFailure = (error) => {
  return {
    type: GET_THEMES_TEMPLATES_FAILURE,
    payload: error,
  };
};

export const getSingleThemesTemplate = (id) => {
  return {
    type: GET_SINGLE_THEMES_TEMPLATE,
    payload: id,
  };
};

export const getSingleThemesTemplateSuccess = (theme) => {
  return {
    type: GET_SINGLE_THEMES_TEMPLATE_SUCCESS,
    payload: theme,
  };
};

export const getSingleThemesTemplateFailure = (error) => {
  return {
    type: GET_SINGLE_THEMES_TEMPLATE_FAILURE,
    payload: error,
  };
};

export const addThemesTemplate = (payload) => {
  return {
    type: ADD_THEMES_TEMPLATE,
    payload,
  };
};

export const addThemesTemplateSuccess = (payload) => {
  return {
    type: ADD_THEMES_TEMPLATE_SUCCESS,
    payload,
  };
};

export const addThemesTemplateFailure = (error) => {
  return {
    type: ADD_THEMES_TEMPLATE_FAILURE,
    payload: error,
  };
};

export const editThemesTemplate = (payload) => {
  return {
    type: EDIT_THEMES_TEMPLATE,
    payload,
  };
};

export const editThemesTemplateSuccess = (payload) => {
  return {
    type: EDIT_THEMES_TEMPLATE_SUCCESS,
    payload,
  };
};

export const editThemesTemplateFailure = (error) => {
  return {
    type: EDIT_THEMES_TEMPLATE_FAILURE,
    payload: error,
  };
};

export const deleteThemesTemplate = (id) => {
  return {
    type: DELETE_THEMES_TEMPLATE,
    payload: id,
  };
};

export const deleteThemesTemplateSuccess = (id) => {
  return {
    type: DELETE_THEMES_TEMPLATE_SUCCESS,
    payload: id,
  };
};

export const deleteThemesTemplateFailure = (error) => {
  return {
    type: DELETE_THEMES_TEMPLATE_FAILURE,
    payload: error,
  };
};
