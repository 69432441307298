import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { addBlog, addSeoRedirect } from "store/actions";
import { useNavigate } from "react-router-dom";
import { cleanUrl } from ".";
import Loader from "components/shared/Loader";
import { useEffect } from "react";
import { toast } from "react-toastify";
const AddSeoRedirect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.seoRedirects);
  useEffect(() => {
    if (error) toast.error(`${error}`);
  }, [error]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const onSubmit = (data) => {
    dispatch(addSeoRedirect({ data, navigate }));
  };
  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>اضف لينك اعادة توجيه جديد</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col lg={8} md={12}>
                <Row>
                  <Col xs={12}>
                    <div className="form-group">
                      <h5>
                        <FormattedMessage id="source" />
                        {" *"}
                        <FormattedMessage id="pleaseAddShortPath" />
                      </h5>
                      <input
                        type="text"
                        name="source"
                        className="form-control form-outline"
                        {...register("source", {
                          required: true,
                          onBlur: (e) => {
                            let val = cleanUrl(e.target.value);
                            if (val?.[val?.length - 1] === "/")
                              val = val?.slice(0, -1);

                            setValue("source", val);
                          },
                        })}
                        dir="ltr"
                      />
                    </div>
                  </Col>

                  <Col xs={12}>
                    <div className="form-group">
                      <h5>
                        <FormattedMessage id="destination" />
                      </h5>
                      <input
                        type="text"
                        name="destination"
                        className="form-control form-outline"
                        {...register("destination", { required: true })}
                        dir="ltr"
                      />
                    </div>
                  </Col>

                  <Col xs={12}>
                    <div className="form-group">
                      <h5>
                        <FormattedMessage id="redirectCode" />
                      </h5>
                      <input
                        type="number"
                        name="redirectCode"
                        className="form-control form-outline"
                        {...register("redirectCode", { required: true })}
                        defaultValue={301}
                      />
                    </div>
                  </Col>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        اضافة
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSeoRedirect;
