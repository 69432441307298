import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Col, Row, Modal } from "react-bootstrap";

import { addThemesComponent, getThemesComponentsTypes } from "store/actions";
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import { useIntl } from "react-intl";
import MultiUpload from "components/shared/MultiUpload";
import { getId } from "helpers/functions";
import Builder from "components/Layout/Builder";
import Loader from "components/shared/Loader";
import { toast } from "react-toastify";

const AddThemesComponents = () => {
  const [selectedImage, setSelectedImage] = useState([]);
  const [selectedPreviewImage, setSelectedPreviewImage] = useState([]);
  const [componentData, setComponentData] = useState([]);
  const [searchParams] = useSearchParams();
  const deviceType = searchParams.get("deviceType");

  const { locale, formatMessage } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { componentsTypes } = useSelector((state) => state.componentsTypes);
  const { loading } = useSelector((state) => state?.components);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(getThemesComponentsTypes(deviceType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const onSubmit = (data) => {
    if (!selectedImage?.[0])
      return toast.error(formatMessage({ id: "pleaseSelectImage" }));

    data.preview = selectedImage?.[0];
    data.skeletonPreview = selectedPreviewImage?.[0];

    // if (data.preview?.length > 0)
    //   data.preview = "uploads/" + data.preview.split("/").pop();
    // if (data.skeletonPreview?.length > 0)
    //   data.skeletonPreview = "uploads/" + data.skeletonPreview.split("/").pop();

    data.data = componentData;

    dispatch(addThemesComponent({ data, navigate, deviceType }));
  };
  const renderListComponentsTypes = () => {
    return componentsTypes?.map((componentType) => ({
      label: componentType.name?.[locale],
      value: componentType?.name?.[locale],
      id: getId(componentType),
    }));
  };
  return (
    <Modal
      dialogClassName="productDataModal form-group"
      show={true}
      onHide={() => navigate(-1)}
      size="xl"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <h5 className="mb-0">اضف عنصر جديد</h5>
        </Modal.Header>

        {loading ? (
          <Loader />
        ) : (
          <Modal.Body>
            <Col md={12} className="m-auto">
              <Row>
                <Col xs={6} className="multiUploadWrapper">
                  <div className="form-group required">
                    <h5>صورة العنصر</h5>
                    <MultiUpload
                      breakPoints={{ xs: 6, md: 4, lg: 4 }}
                      images={selectedImage}
                      setImages={setSelectedImage}
                      single={true}
                      name="ارفع صورة (jpeg / png / jpg)"
                    />
                  </div>
                </Col>
                <Col lg={6} className="multiUploadWrapper">
                  <div className="form-group required">
                    <h5>معاينة العنصر</h5>
                    <MultiUpload
                      breakPoints={{ xs: 6, md: 4, lg: 4 }}
                      images={selectedPreviewImage}
                      setImages={setSelectedPreviewImage}
                      single={true}
                      name="ارفع صورة (jpeg / png / jpg)"
                    />
                  </div>
                </Col>

                <Col lg={4} xs={12}>
                  <div className="form-group required">
                    <h5>اسم العنصر بالعربية</h5>
                    <div>
                      <input
                        type="text"
                        className="form-control form-outline"
                        placeholder="اسم العنصر بالعربية"
                        {...register("name[ar]", { required: true })}
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={4} xs={12}>
                  <div className="form-group required">
                    <h5>اسم العنصر بالانجليزية</h5>
                    <div>
                      <input
                        type="text"
                        className="form-control form-outline"
                        placeholder="اسم العنصر بالانجليزية"
                        {...register("name[en]", { required: true })}
                      />
                    </div>
                  </div>
                </Col>

                <Col lg={4} xs={12}>
                  <div className="form-group required">
                    <h5>نوع العنصر</h5>
                    <div>
                      <Controller
                        control={control}
                        name="componentType"
                        render={({ field: { onChange, value } }) => (
                          <>
                            <Select
                              isRtl={true}
                              // menuIsOpen={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"
                              options={renderListComponentsTypes()}
                              placeholder="اختار النوع"
                              isMulti={false}
                              value={renderListComponentsTypes()?.find(
                                (t) => t.id === value || t.id === getId(value)
                              )}
                              onChange={(value) => {
                                onChange(value.id);
                              }}
                            />
                          </>
                        )}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="form-group builderForm">
                    <h5>بيانات العنصر</h5>
                    <div>
                      <Builder
                        componentData={componentData}
                        setComponentData={setComponentData}
                      />
                    </div>
                    <p className="error-hint">
                      {errors?.data?.type === "required" &&
                        "يرجي ادخال بيانات العنصر"}
                    </p>
                  </div>
                </Col>

                {/* <Col lg={4} xs={12}>
                       
                      </Col> */}
              </Row>
            </Col>
          </Modal.Body>
        )}
        <Modal.Footer>
          <div className="col-3">
            <button type="submit" className="btn btn-blue">
              اضافة العنصر
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddThemesComponents;
