import {
  GET_ALL_SETTINGS,
  GET_ALL_SETTINGS_SUCCESS,
  GET_ALL_SETTINGS_FAILURE,
  EDIT_MAIN_SETTINGS,
  EDIT_MAIN_SETTINGS_SUCCESS,
  EDIT_MAIN_SETTINGS_FAILURE,
  EDIT_MESSAGE_SETTINGS,
  EDIT_MESSAGE_SETTINGS_SUCCESS,
  EDIT_MESSAGE_SETTINGS_FAILURE,
  EDIT_SOCIALS_SETTINGS,
  EDIT_SOCIALS_SETTINGS_SUCCESS,
  EDIT_SOCIALS_SETTINGS_FAILURE,
  EDIT_MAIL_SETTINGS,
  EDIT_MAIL_SETTINGS_SUCCESS,
  EDIT_MAIL_SETTINGS_FAILURE,
  ADD_ACCOUNTS_SETTINGS,
  ADD_ACCOUNTS_SETTINGS_SUCCESS,
  ADD_ACCOUNTS_SETTINGS_FAILURE,
  EDIT_ACCOUNTS_SETTINGS,
  EDIT_ACCOUNTS_SETTINGS_SUCCESS,
  EDIT_ACCOUNTS_SETTINGS_FAILURE,
  DELETE_SETTINGS,
  DELETE_SETTINGS_SUCCESS,
  DELETE_SETTINGS_FAILURE,
} from "./actionTypes";

export const getAllSettings = () => {
  return {
    type: GET_ALL_SETTINGS,
  };
};
export const getAllSettingsSuccess = (payload) => {
  return {
    type: GET_ALL_SETTINGS_SUCCESS,
    payload: payload,
  };
};

export const getAllSettingsFailure = (error) => {
  return {
    type: GET_ALL_SETTINGS_FAILURE,
    payload: error,
  };
};

export const editMainSettings = (payload) => {
  return {
    type: EDIT_MAIN_SETTINGS,
    payload: payload,
  };
};
export const editMainSettingsSuccess = (payload) => {
  return {
    type: EDIT_MAIN_SETTINGS_SUCCESS,
    payload: payload,
  };
};

export const editMainSettingsFailure = (error) => {
  return {
    type: EDIT_MAIN_SETTINGS_FAILURE,
    payload: error,
  };
};
// -------------------

export const editMessageSettings = (payload) => {
  return {
    type: EDIT_MESSAGE_SETTINGS,
    payload: payload,
  };
};
export const editMessageSettingsSuccess = (payload) => {
  return {
    type: EDIT_MESSAGE_SETTINGS_SUCCESS,
    payload: payload,
  };
};

export const editMessageSettingsFailure = (error) => {
  return {
    type: EDIT_MESSAGE_SETTINGS_FAILURE,
    payload: error,
  };
};

// -------------------
export const addAccountsSettings = (payload) => {
  return {
    type: ADD_ACCOUNTS_SETTINGS,
    payload: payload,
  };
};
export const addAccountsSettingsSuccess = (payload) => {
  return {
    type: ADD_ACCOUNTS_SETTINGS_SUCCESS,
    payload: payload,
  };
};

export const addAccountsSettingsFailure = (error) => {
  return {
    type: ADD_ACCOUNTS_SETTINGS_FAILURE,
    payload: error,
  };
};

export const editAccountsSettings = (payload) => {
  return {
    type: EDIT_ACCOUNTS_SETTINGS,
    payload: payload,
  };
};
export const editAccountsSettingsSuccess = (payload) => {
  return {
    type: EDIT_ACCOUNTS_SETTINGS_SUCCESS,
    payload: payload,
  };
};

export const editAccountsSettingsFailure = (error) => {
  return {
    type: EDIT_ACCOUNTS_SETTINGS_FAILURE,
    payload: error,
  };
};
// -------------------
export const deleteSettings = (payload) => {
  return {
    type: DELETE_SETTINGS,
    payload: payload,
  };
};
export const deleteSettingsSuccess = (payload) => {
  return {
    type: DELETE_SETTINGS_SUCCESS,
    payload: payload,
  };
};

export const deleteSettingsFailure = (error) => {
  return {
    type: DELETE_SETTINGS_FAILURE,
    payload: error,
  };
};

export const editSocialsSettings = (payload) => {
  return {
    type: EDIT_SOCIALS_SETTINGS,
    payload: payload,
  };
};
export const editSocialsSettingsSuccess = (payload) => {
  return {
    type: EDIT_SOCIALS_SETTINGS_SUCCESS,
    payload: payload,
  };
};

export const editSocialsSettingsFailure = (error) => {
  return {
    type: EDIT_SOCIALS_SETTINGS_FAILURE,
    payload: error,
  };
};

export const editMailSettings = (payload) => {
  return {
    type: EDIT_MAIL_SETTINGS,
    payload: payload,
  };
};
export const editMailSettingsSuccess = (payload) => {
  return {
    type: EDIT_MAIL_SETTINGS_SUCCESS,
    payload: payload,
  };
};

export const editMailSettingsFailure = (error) => {
  return {
    type: EDIT_MAIL_SETTINGS_FAILURE,
    payload: error,
  };
};
