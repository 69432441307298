import React, { useState } from "react";
import { Col, OverlayTrigger, Tooltip } from "react-bootstrap";

import { ReactComponent as EditIcon } from "assets/builder/edit.svg";
import { ReactComponent as CopyIcon } from "assets/builder/copy.svg";
import { ReactComponent as DeleteIcon } from "assets/builder/delete.svg";
import { v4 } from "uuid";

const Select = ({
  componentId,
  componentData,
  setComponentData,
  activeComponent,
  setActiveComponent,
}) => {
  const [activeClass, setActiveClass] = useState(false);

  const currentInput = componentData?.find(
    (component) => component.id === componentId
  );

  const handleSelectChange = (e) => {
    const newComponentData = componentData?.map((component) => {
      if (component.id !== currentInput.id) return component;

      return {
        ...component,
        value: e.target.value,
      };
    });

    setComponentData(newComponentData);
  };

  const handleSelectMultipleChange = (e) => {
    const newComponentData = componentData?.map((component) => {
      if (component.id !== currentInput.id) return component;

      return {
        ...component,
        value: Array.from(e.target.selectedOptions, (option) => option.value),
      };
    });

    setComponentData(newComponentData);
  };

  const handleDeleteComponent = () => {
    const newComponentData = componentData.filter(
      (component) => component.id !== currentInput.id
    );

    setComponentData(newComponentData);
    currentInput.id === activeComponent.id && setActiveComponent({});
  };

  const handleCopyComponent = () => {
    const newComponentData = [
      ...componentData,
      {
        ...currentInput,
        id: v4(),
      },
    ];
    setComponentData(newComponentData);
  };

  const handleEditComponent = () => {
    setActiveComponent(currentInput);
    setActiveClass(true);
  };

  return (
    <Col lg={12} xs={12}>
      <div className={activeClass ? "input-view active" : "input-view"}>
        <label className="input-label">
          {currentInput?.props?.label || "Enter label"}
        </label>
        <div className="input-actions">
          <OverlayTrigger placement="top" overlay={<Tooltip>تعديل</Tooltip>}>
            <button
              type="button"
              className={activeClass ? "edit active" : "edit"}
              onClick={handleEditComponent}
            >
              <EditIcon />
            </button>
          </OverlayTrigger>

          <OverlayTrigger placement="top" overlay={<Tooltip>نسخ</Tooltip>}>
            <button
              type="button"
              className="copy"
              onClick={handleCopyComponent}
            >
              <CopyIcon />
            </button>
          </OverlayTrigger>

          <OverlayTrigger placement="top" overlay={<Tooltip>حذف</Tooltip>}>
            <button
              type="button"
              className="delete"
              onClick={handleDeleteComponent}
            >
              <DeleteIcon />
            </button>
          </OverlayTrigger>
        </div>
        <div className="input-wrapper">
          <select
            className="form-control form-select"
            {...currentInput?.props}
            value={currentInput?.value || ""}
            onChange={
              currentInput?.props?.multiple
                ? handleSelectMultipleChange
                : handleSelectChange
            }
          >
            <option value="">اختر</option>
            {currentInput?.props?.options?.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </Col>
  );
};

export default Select;
