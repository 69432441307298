import {
  ADD_PORTAL_FAQ,
  ADD_PORTAL_FAQ_FAILURE,
  ADD_PORTAL_FAQ_SUCCESS,
  DELETE_PORTAL_FAQ,
  DELETE_PORTAL_FAQ_FAILURE,
  DELETE_PORTAL_FAQ_SUCCESS,
  EDIT_PORTAL_FAQ,
  EDIT_PORTAL_FAQ_FAILURE,
  EDIT_PORTAL_FAQ_SUCCESS,
  GET_PORTAL_FAQS,
  GET_PORTAL_FAQS_FAILURE,
  GET_PORTAL_FAQS_SUCCESS,
  GET_SINGLE_PORTAL_FAQ,
  GET_SINGLE_PORTAL_FAQ_FAILURE,
  GET_SINGLE_PORTAL_FAQ_SUCCESS,
} from "./actionTypes";

export const getPortalFaqs = (payload) => {
  return {
    type: GET_PORTAL_FAQS,
    payload,
  };
};

export const getPortalFaqsSuccess = (banks) => {
  return {
    type: GET_PORTAL_FAQS_SUCCESS,
    payload: banks,
  };
};

export const getPortalFaqsFailure = (error) => {
  return {
    type: GET_PORTAL_FAQS_FAILURE,
    payload: error,
  };
};

export const getSinglePortalFaq = (id) => {
  return {
    type: GET_SINGLE_PORTAL_FAQ,
    payload: id,
  };
};

export const getSinglePortalFaqSuccess = (bank) => {
  return {
    type: GET_SINGLE_PORTAL_FAQ_SUCCESS,
    payload: bank,
  };
};

export const getSinglePortalFaqFailure = (error) => {
  return {
    type: GET_SINGLE_PORTAL_FAQ_FAILURE,
    payload: error,
  };
};

export const addPortalFaq = (payload) => {
  return {
    type: ADD_PORTAL_FAQ,
    payload,
  };
};

export const addPortalFaqSuccess = (payload) => {
  return {
    type: ADD_PORTAL_FAQ_SUCCESS,
    payload,
  };
};

export const addPortalFaqFailure = (error) => {
  return {
    type: ADD_PORTAL_FAQ_FAILURE,
    payload: error,
  };
};

export const editPortalFaq = (payload) => {
  return {
    type: EDIT_PORTAL_FAQ,
    payload,
  };
};

export const editPortalFaqSuccess = (payload) => {
  return {
    type: EDIT_PORTAL_FAQ_SUCCESS,
    payload,
  };
};

export const editPortalFaqFailure = (error) => {
  return {
    type: EDIT_PORTAL_FAQ_FAILURE,
    payload: error,
  };
};

export const deletePortalFaq = (id) => {
  return {
    type: DELETE_PORTAL_FAQ,
    payload: id,
  };
};

export const deletePortalFaqSuccess = (id) => {
  return {
    type: DELETE_PORTAL_FAQ_SUCCESS,
    payload: id,
  };
};

export const deletePortalFaqFailure = (error) => {
  return {
    type: DELETE_PORTAL_FAQ_FAILURE,
    payload: error,
  };
};
